import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ESDateLocale from "date-fns/locale/es";
import PTDateLocale from "date-fns/locale/pt";
import {
  Input,
  Label,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { MdBackspace } from "react-icons/md";
import i18n from "i18n";

import Locale from "constants/Locale";

const CustomInput = ({ value, onClick, info, setDate, type, title }) => (
  <Label>
    {info}
    <InputGroup>
      <Input value={value} onClick={onClick} />
      {type === "bill" && (
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <MdBackspace
              color="red"
              role="button"
              title="Eliminar fecha"
              onClick={() => setDate(null)}
              size={20}
            />
          </InputGroupText>
        </InputGroupAddon>
      )}
    </InputGroup>
  </Label>
);

const NoStyleButton = ({ onClick, info, titleHolder }) => (
  <Label>
    {info}
    <Input
      value={titleHolder ? titleHolder : "Seleccione una fecha"}
      onClick={onClick}
    />
  </Label>
);

const DatePicker = ({ date, setDate, info, type, titleHolder }) => {
  return (
    <ReactDatePicker
      selected={date}
      dateFormat="dd/MM/yyyy"
      locale={
        {
          [Locale.ES]: ESDateLocale,
          [Locale.PT]: PTDateLocale,
        }[i18n.language]
      }
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      onChange={(e) => setDate(e)}
      customInput={
        date ? (
          <CustomInput value={date} info={info} setDate={setDate} type={type} />
        ) : (
          <NoStyleButton info={info} titleHolder={titleHolder} />
        )
      }
    />
  );
};

export default DatePicker;
