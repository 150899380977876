import { z } from "zod";

import { isDate, isSelect } from "./utils";
import validationCIF from "helpers/validationCIF";
import validationDNI from "helpers/validationDNI";
import validationNIE from "helpers/validationNIE";


const createCaseFormSchema = z.object({
	fullName: z.string().nonempty("Campo obligatorio").max(100, {
		message: "Máximo 100 caracteres"
	}),
	phone: z.string().regex(new RegExp("^[679]{1}[0-9]{8}$"), { message: "Teléfono inválido" }),
	title: z.string().nonempty("Campo obligatorio"),
	description: z.string().nonempty("Campo obligatorio"),
	email: z.string().nonempty("Campo obligatorio").email("Email inválido")
});

const withdrawalReceptionFormSchema = z.object({
	fullName: z.string().nonempty("Campo obligatorio").max(100, {
		message: "Máximo 100 caracteres"
	}),
	identification: z
		.string()
		.refine(
			(identification) =>
				validationCIF(identification) ||
				validationDNI(identification) ||
				validationNIE(identification),
			{ message: "Identificación inválida" }
		),
	phone: z.string().regex(new RegExp("^[679]{1}[0-9]{8}$"), { message: "Teléfono inválido" }),
	email: z.string().email({ message: "Email inválido" }),
	make: z.string().optional(),
	model: z.string().optional(),
	plate: z.string().optional(),
	kmOnDelivery: z
		.number({ required_error: "Campo obligatorio" })
		.positive({ message: "Los Km deben ser mayor que 0" })
		.max(999999, { message: "Km debe ser igual o inferior a 999.999" }),
	warranty: isSelect,
	local: isSelect,
	agentFullName: z.string().nonempty("Campo obligatorio").max(100, {
		message: "Máximo 100 caracteres"
	}),
	afterSaleEmail: z.string().email({ message: "Email inválido" }),
	isOwnerVerified: z.boolean({ required_error: "Campo obligatorio" }),
	hasDrivingLicence: z.boolean({ required_error: "Campo obligatorio" }),
	clientIssue: z.string().max(500, { message: "Máximo 500 caracteres" }).optional()
});

const createAfterSalesAppointmentSchema = z.object({
	appointmentLocal: isSelect,
	appointmentDate: isDate
});

const updateCaseEmailSchema = z.object({
	email: z.string().nonempty("Campo obligatorio").email("Correo inválido")
});

export {
	createCaseFormSchema,
	withdrawalReceptionFormSchema,
	createAfterSalesAppointmentSchema,
	updateCaseEmailSchema
};
