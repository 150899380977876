import { useState } from "react";
import moment from "moment";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import styles from "./index.module.scss";
import FormattedNumberInput from "components/FormattedNumberInput";
import { getSignUrl, put } from "services/axiosCalls";
import { errorToast, successToast } from "helpers/toastFunctions";
import { FiFile } from "react-icons/fi";
import { API } from "services/urlConstants";

const CaseComment = ({ comment, setComment }) => {
  const [commentAmount, setCommentAmount] = useState(comment.importe);

  const updateCommentAmmount = async () => {
    // Update the amount of the comment
    try {
      await put(API.UPDATE_COMMENT(comment.documentId), {
        amount: commentAmount,
      });
      setComment({ ...comment, importe: commentAmount });
      successToast("Importe del comentario actualizado correctamente.");
    } catch (err) {
      errorToast(
        err?.response?.data?.message ||
          "Error al actualizar el importe del comentario"
      );
    }
  };

  const showCommentFile = async (fileId) => {
    try {
      const {
        data: { url },
      } = await getSignUrl(fileId);
      window.open(url, "_blank", "noopener,noreferrer");
    } catch (err) {
      errorToast(err.response.data.message);
    }
  };

  return (
    <div className={styles.commentWrapper}>
      <div className={styles.comment}>
        <div className={styles.commentInfo}>
          <span>{comment.comentario}</span>
          <span>{`${comment.nombre} ${comment.apellido1} ${comment.apellido2}`}</span>
          <span>{moment(comment.fecha).format("DD/MM/YYYY hh:mm")}</span>
        </div>

        <div className={styles.commentAmmount}>
          <label>Importe</label>
          <FormattedNumberInput
            value={commentAmount}
            onChange={(value) => setCommentAmount(value)}
            displayType="input"
          />
        </div>
      </div>

      {comment.sign_cloud_id && (
        <div className={styles.commentFile}>
          <div className={styles.commentFileName}>
            <FiFile size={36} />
            <span>{comment.url_titulo}</span>
          </div>
          <button
            className="btn"
            onClick={() => showCommentFile(comment.sign_cloud_id)}
          >
            <MdOutlineRemoveRedEye size={28} />
          </button>
        </div>
      )}

      <button
        className={`${styles.commentUpdateButton} btn btn-flexicar-orange`}
        onClick={updateCommentAmmount}
      >
        Guardar
      </button>
    </div>
  );
};

export default CaseComment;
