import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { post } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";
import moment from "moment";
import { errorToast } from "../../../helpers/toastFunctions";
import styles from "./index.module.scss";
import { MdAccountCircle, MdChat } from "react-icons/md";
import { useTranslation } from "react-i18next";

const ModalConversacionesLeeds = ({ lead_id, user }) => {
  const { t } = useTranslation("modalConversacionesLead");
  const [conversacion, setConversacion] = useState();
  const [conversaciones, setConversaciones] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const newConversation = async () => {
    try {
      await post(API.NEW_CONVERSATION_LEED, {
        lead_id,
        user,
        conversacion,
      });
      setModal(false);
      await getConversations();
    } catch {
      return errorToast(<span>{t("errors.errorMessage")}</span>);
    }
  };

  const getConversations = async () => {
    if (lead_id) {
      try {
        const response = await post(API.GET_CONVERSATIONS_LEEDS, { lead_id });

        await setConversaciones(response.data);
      } catch {
        return errorToast(<span>{t("errors.errorLoading")}</span>);
      }
    }
  };

  useEffect(() => {
    getConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !modal && setConversacion(null);
  }, [modal])
  
  return (
    <>
      <div>
        <MdChat color="white" role="button" size={30} onClick={toggle} />

        <Modal isOpen={modal} style={{ maxWidth: "700px" }} toggle={toggle}>
          <ModalHeader className={`${styles.modal_header}`}>
            Conversaciones con clientes
          </ModalHeader>
          <ModalBody>
            <div>
              {conversaciones.map((elm, idx) => (
                <div key={`conversaciones-${elm.nombre}-${idx}`} className="row">
                  <div className="col-md-12 col-sm-12">
                    <p className={styles.chat}>{elm.texto}</p>
                  </div>
                  <div
                    className="col-md-12 col-sm-12"
                    style={{ marginBottom: "10px" }}
                  >
                    <MdAccountCircle
                      className="md-36 m-2"
                      size={40}
                      role="button"
                    />

                    <small>
                      {" "}
                      {elm.nombre}{" "}
                      {moment(elm.fecha).format("DD-MM-YYYY | HH.mm")}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
          <ModalFooter style={{ width: "100%" }}>
            <div className="row form-inline">
              <div className="col-md-9 col-sm-9">
                <input
                  style={{ width: "100%" }}
                  id="conversacion"
                  placeholder={"Conversación con cliente"}
                  className="form-control "
                  type="text"
                  value={conversacion}
                  onChange={(event) => setConversacion(event.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-3">
                <Button
                  className={`${styles.btn_custom} ml-4`}
                  onClick={newConversation}
                >
                  Guardar
                </Button>
                {""}
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default ModalConversacionesLeeds;
