import React, { useState, useEffect } from "react";

import { post } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import { errorToast } from "../../../helpers/toastFunctions";
import { IoPeopleCircleOutline } from "react-icons/io5";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const TableEstadosLeeds = ({ lead_id }) => {
  const { t } = useTranslation("leadsStateTable");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [estados, setEstados] = useState([
    {
      empleado: "",
      estado: "",
      fecha: "",
    },
  ]);

  const [tooltipContent, setTooltipContent] = useState("");
  const getEstadosLeeds = async () => {
    if (lead_id) {
      try {
        const response = await post(API.GET_ESTADOS_LEEDS, { lead_id });
        setEstados(response.data);
      } catch {
        return errorToast(<span>{t("errors.errorLoading")}</span>);
      }
    }
  };

  useEffect(() => {
    getEstadosLeeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <IoPeopleCircleOutline
        color="white"
        size={30}
        role="button"
        onClick={toggle}
        className="mx-2"
      />

      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered="lg"
          style={{ maxWidth: "700px" }}
        >
          <ModalHeader className={`${styles.modal_header}`} toggle={toggle}>
            Registros de Estados por los que ha pasado el Lead
          </ModalHeader>
          <ModalBody>
            <div className="table-wrapper">
              <Table
                className="fl-table"
                striped
                style={{ overflow: "visible" }}
              >
                <thead>
                  <tr>
                    <th>EMPLEADO</th>
                    <th>ESTADO</th>
                    <th>FECHA</th>
                  </tr>
                </thead>
                <tbody>
                  {estados.map((elm, idx) => (
                    <tr key={`estados-${elm.estado}-${idx}`}>
                      <td>
                        {" "}
                        <span
                          href="#"
                          id="tooltip_agent"
                          onMouseEnter={() => {
                            setTooltipContent({ nombre: elm?.nombre + ' ' + elm?.apellido1 + ' ' + elm?.apellido2, mail: elm?.mail, telefono: elm?.telefono, agente_local: elm?.locales });
                          }}
                          onMouseLeave={() => {
                            setTooltipContent("");
                          }}
                        >
                          {elm.nombre}
                          {tooltipContent.nombre ?
                            <UncontrolledTooltip
                              placement="left"
                              flip
                              style={{ textAlign: "left" }}
                              target="tooltip_agent"
                            >
                              <strong>{tooltipContent.nombre}</strong> <br /><strong>Email: </strong>{tooltipContent.mail}<br /><strong>Teléfono: </strong>{tooltipContent.telefono} <br /> <div className={styles.overflow}><strong>Locales: </strong>{tooltipContent.agente_local}</div>
                            </UncontrolledTooltip> : null}
                        </span>
                      </td>
                      <td>{elm.estado}</td>
                      <td>{moment(elm.fecha).format("DD-MM-YYYY | HH.mm")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default TableEstadosLeeds;
