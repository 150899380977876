import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button } from "reactstrap";
import styles from "./index.module.scss";
import { post } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import Filter from "components/Filter";
import { UserContext } from "contexts/UserContext";
import { FaFilter } from "react-icons/fa";
import { environment } from "../../config/environment.config";
import moment from "moment";
import Select from "react-select";
const DeliveredCalendar = () => {

  const { local } = useContext(UserContext);

  const [week, setWeek] = useState();
  const [mounth, setMounth] = useState();
  const [year, setYear] = useState();
  const [today] = useState(new Date());
  const [books, setBooks] = useState([[], [], [], [], [], [], [], []]);
  const [booksByLocal, setBooksByLocal] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [totalBooks, setTotalBooks] = useState();
  const [locales, setLocales] = useState(0);
  const [filerLocales, setFilterLocales] = useState([]);
  const [misLocales, setMisLocales] = useState([0]);
  const [monsat, setMonsat] = useState([0, 0]);
  const [filterEstados, setFilterEstados] = useState([]);
  useEffect(() => {
    document.title = "Flexicar | Entregas";
    changeWeek();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (local) setMisLocales(local);
    if (local && local.length === 1) {
      getLocalNameByID(local[0]);
    }
    // eslint-disable-next-line
  }, [local]);

  const getLocalNameByID = async (id) => {
    const local_name = await post(API.GET_LOCAL_NAME_BY_ID, {
      id,
    });
    const response = local_name.name;
    setFilterLocales([{ value: local[0], label: response[0].nombre }]);
  };

  useEffect(() => {
    if (misLocales !== 0) {
      bookingList();
    }
    // eslint-disable-next-line
  }, [monsat, locales, misLocales]);
  useEffect(() => {
    bookingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEstados]);
  const changeWeek = () => {
    const first = today.getDate() - today.getDay();
    const dayList = [
      "Dom.",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    let week = [];
    let week2 = [];

    for (let i = 1; i < 9; i++) {
      let next = new Date(today.getTime());
      next.setDate(first + i);
      week.push(dayList[next.getDay()] + " " + next.getDate());
      week2.push(next);
    }
    setWeek(week);
    const actualMounth = new Intl.DateTimeFormat("es-ES", {
      month: "long",
    }).format(new Date(week2[0]));
    setMounth(actualMounth);
    setYear(new Date(week2[0]).getFullYear());
    const start = new Date(week2[0]).setHours(0, 0, 0, 0).toString();
    const end = new Date(week2[6]).setHours(23, 59, 59, 59).toString();
    setMonsat([parseInt(start.slice(0, -3)), parseInt(end.slice(0, -3))]);
  };

  const bookingList = async () => {
    let entregados = filterEstados.find((x) => x.value === 1) ? true : false;
    let pendientes = filterEstados.find((x) => x.value === 2) ? true : false;
    let confirmar = filterEstados.find((x) => x.value === 3) ? true : false;

    let calendarList = [[], [], [], [], [], [], [], [], []];
    let calendarListComp = [[], [], [], [], [], [], [], [], []];
    const bookings = await post(API.DELIVERED_CALENDAR, {
      start: monsat[0],
      end: monsat[1],
      locales: locales !== 0 ? locales : misLocales,
      entregados,
      pendientes,
      confirmar
    });
    const response = bookings.res;
    
    let total = 0;
    if (response) {
      let delivery_status
      let color_style
      for (let i = 0; i < response.length; i++) {
        total++;
        if (response[i].status){
          let status_id= response[i].status
          let today = moment().format("DD-MM-YYYY")
          let fecha_entrega = moment(response[i].fecha_entrega).format("DD-MM-YYYY")
          switch (status_id) {
            case 3:
              if (today >= fecha_entrega) {
                delivery_status = 'PDTE.CONFIRMAR'
                color_style = "bg_grey"
              }
              if (today < fecha_entrega) {
                delivery_status = 'PDTE.ENTREGA'            
                color_style = "bg_yellow"
              }
              break;
            case 4:
              delivery_status = 'ENTREGADO'            
              color_style = "bg_green"
              break; 
              
            default:
              break;
          }
        }
        
        const vehicleData = response[i].datos;
        calendarListComp[response[i].day_of_week].push(response[i]);

        calendarList[response[i].day_of_week].push({
          title:
            vehicleData.marca +
            " " +
            vehicleData.modelo +
            " / " +
            vehicleData.matricula
            + `${response[i].status ? " / " + delivery_status: ""}`,
          id_vehiculo: response[i].id_vehiculo,
          estado:color_style
        });
      }
    }
    setTotalBooks(total);
    if (locales) {
      setBooks(calendarList);
    } else {
      setBooks([[], [], [], [], [], [], [], [], []]);
    }

    calendarListComp.map((e, idx) => groupBy(e, idx));

    function groupBy(collection, property) {
      var i = 0,
        val,
        index,
        values = [],
        result = [];
      for (; i < collection.length; i++) {
        val = collection[i]["local"];
        index = values.indexOf(val);
        if (index > -1) result[index].push(collection[i]);
        else {
          values.push(val);
          result.push([collection[i]]);
        }
      }
      setBooksByLocal((prevState) => ({
        ...prevState,
        [property]: result,
      }));
    }
  };

  const getData = (filter, value) => {
    if (value[0] !== locales[0]) {
      let loc = [];
      for (let i = 0; i < value.length; i++) {
        loc.push(value[i].value);
      }
      setLocales(loc.length > 0 ? loc : 0);
    }
    setFilterLocales(value);
  };
  const getEstado = (e) => {

    setFilterEstados(e);
    
  };
  const selectLocal = (local, nombre) => {
    setFilterLocales([{ label: nombre, value: local }]);
    setLocales([local]);
  };

  const countByLocal = (arr) => {
    let count = 0;
    arr.map((e) =>
      e.forEach(() => {
        count++;
      })
    );
    return count;
  };

  const linkto = (id_vehiculo) => {
    window.open(`${environment.REACT_HOST}/main/vehicle/${id_vehiculo}?tab=datos`, "_blank");
  };

  return (
    <div>
      <Row className="m-1">
        <Col className={`mt-3 col-12 col-md-2 ${styles.filtersContent}`}>
          {" "}
          <FaFilter size={20} color="#007bff" /> Filtros
          <Filter
            key={"Locales"}
            multi={true}
            sendData={getData}
            parentValues={filerLocales}
            name={"Locales"}
            query={`locales`}
            data={[misLocales]}
          />
          <div className="mt-2">
            <Select
              className="my-2"
              closeMenuOnSelect={false}
              isMulti
              placeholder="Estado"
              options={[
                { label: "Entregado", value: 1 },
                { label: "Pdte. Entrega", value: 2 },
                { label: "Pdte. Confirmar", value: 3 },
              ]}
              onChange={getEstado}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#FF5D0E",
                  primary25: "rgba(255,93,14,0.1)",
                },
              })}
            />

          </div>
          <Button
            outline
            color="danger"
            block
            className="mt-2"
            onClick={() => {
              setFilterLocales([]);
              setFilterEstados([]);
            }}
          >
            BORRAR
          </Button>
          <div className="mt-3">
            Leyenda
          </div>
          <ul className={`${styles.legend}`}>
            <li>
              <span className={`${styles.bg_green}`}></span> <p>Entregado</p>
            </li>
            <li>
              <span className={`${styles.bg_yellow}`}></span> <p>Pdte. Entrega</p>
            </li>

            <li>
              <span className={`${styles.bg_grey}`}></span> <p>Pdte. Confirmar</p>
            </li>
          </ul>
        </Col>
        <Col className="col-12 col-md-10">
          <div className={`clearfix mt-3 mb-3 ${styles.container2}`}>
            <span className="tittle ml-4">ENTREGAS</span>
          </div>
          <div className={`${styles.fleft} ${styles.calendarPreHeader}`}>
            {mounth} {year}
          </div>
          <div className={`${styles.fright} ${styles.calendarPreHeader}`}>
            <span className={`${styles.white}`}>Total: </span>{" "}
            <span className={`${styles.numberTotalCircle}`}>
              {locales ? (
                <span>
                  {books[2].length +
                    books[3].length +
                    books[4].length +
                    books[5].length +
                    books[6].length +
                    books[7].length +
                    books[1].length}
                </span>
              ) : (
                <span>{totalBooks}</span>
              )}
            </span>
          </div>
          <Row
            className={`${styles.startRow} ml-0 text-center ${styles.calendarHeader}`}
          >
            <Col className={`col-12 col-md ${styles.calendarContent}`}>
              <div className={`${styles.calendarHeaderTitle}`}>
                <span
                  className={`${styles.calendarArrows}`}
                  onClick={() => changeWeek(today.setDate(today.getDate() - 7))}
                >
                  {"<"}
                </span>{" "}
                {week && week[0]}{" "}
                <span className={`${styles.numberCircle}`}>
                  {locales ? books[2].length : countByLocal(booksByLocal[2])}
                </span>
                <span
                  className={`${styles.calendarArrows} ${styles.mobile}`}
                  onClick={() => changeWeek(today.setDate(today.getDate() + 7))}
                >
                  {">"}
                </span>{" "}
              </div>
              <div className={`${styles.startCol}`}>
                {locales ? (
                  <React.Fragment>
                    {books[2].map((e, idx) => (
                      <div
                        key={`delivered-books-2-${idx}`}
                        onClick={() => { linkto(e.id_vehiculo) }}
                        className={`pointer text-center ${
                          styles.calendarBlock
                        } ${styles[`${e.estado}`]} `}
                      >
                        {e.title}
                      </div>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {booksByLocal[2]?.map((e, idx) => (
                      <div
                        key={`delivered-booksByLocal-2-${idx}`}
                        onClick={() =>
                          selectLocal(e[0]?.local, e[0]?.local_nombre)
                        }
                        className={`pointer text-center ${styles.calendarBlock} ${styles.pagado}`}
                      >
                        {e[0]?.local_nombre} ({e.length})
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </Col>
            <Col className={`col-12 col-md ${styles.calendarContent}`}>
              <div className={`${styles.calendarHeaderTitle}`}>
                {week && week[1]}{" "}
                <span className={`${styles.numberCircle}`}>
                  {locales ? books[3].length : countByLocal(booksByLocal[3])}
                </span>
              </div>
              <div className={`${styles.startCol}`}>
                {locales ? (
                  <React.Fragment>
                    {books[3].map((e, idx) => (
                      <div
                        key={`delivered-books-3-${idx}`}
                        onClick={() => linkto(e.id_vehiculo)}
                        className={`pointer text-center ${
                          styles.calendarBlock
                        } ${styles[`${e.estado}`]} `}
                      >
                        {e.title}
                      </div>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {booksByLocal[3]?.map((e, idx) => (
                      <div
                        key={`delivered-booksByLocal-3-${idx}`}
                        onClick={() =>
                          selectLocal(e[0]?.local, e[0]?.local_nombre)
                        }
                        className={`pointer text-center ${styles.calendarBlock} ${styles.pagado}`}
                      >
                        {e[0]?.local_nombre} ({e.length})
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </Col>
            <Col className={`col-12 col-md ${styles.calendarContent}`}>
              <div className={`${styles.calendarHeaderTitle}`}>
                {week && week[2]}{" "}
                <span className={`${styles.numberCircle}`}>
                  {locales ? books[4].length : countByLocal(booksByLocal[4])}
                </span>
              </div>
              <div className={`${styles.startCol}`}>
                {locales ? (
                  <React.Fragment>
                    {books[4].map((e, idx) => (
                      <div
                        key={`delivered-books-4-${idx}`}
                        onClick={() => linkto(e.id_vehiculo)}
                        className={`pointer text-center ${
                          styles.calendarBlock
                        } ${styles[`${e.estado}`]} `}
                      >
                        {e.title}
                      </div>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {booksByLocal[4]?.map((e, idx) => (
                      <div
                        key={`delivered-booksByLocal-4-${idx}`}
                        onClick={() =>
                          selectLocal(e[0]?.local, e[0]?.local_nombre)
                        }
                        className={`pointer text-center ${styles.calendarBlock} ${styles.pagado}`}
                      >
                        {e[0]?.local_nombre} ({e.length})
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </Col>
            <Col className={`col-12 col-md ${styles.calendarContent}`}>
              <div className={`${styles.calendarHeaderTitle}`}>
                {week && week[3]}{" "}
                <span className={`${styles.numberCircle}`}>
                  {locales ? books[5].length : countByLocal(booksByLocal[5])}
                </span>
              </div>
              <div className={`${styles.startCol}`}>
                {locales ? (
                  <React.Fragment>
                    {books[5].map((e, idx) => (
                      <div
                        key={`delivered-books-5-${idx}`}
                        onClick={() => linkto(e.id_vehiculo)}
                        className={`pointer text-center ${
                          styles.calendarBlock
                        } ${styles[`${e.estado}`]} `}
                      >
                        {e.title}
                      </div>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {booksByLocal[5]?.map((e, idx) => (
                      <div
                        key={`delivered-booksByLocal-5-${idx}`}
                        onClick={() =>
                          selectLocal(e[0]?.local, e[0]?.local_nombre)
                        }
                        className={`pointer text-center ${styles.calendarBlock} ${styles.pagado}`}
                      >
                        {e[0]?.local_nombre} ({e.length})
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </Col>
            <Col className={`col-12 col-md ${styles.calendarContent}`}>
              <div className={`${styles.calendarHeaderTitle}`}>
                {week && week[4]}{" "}
                <span className={`${styles.numberCircle}`}>
                  {locales ? books[6].length : countByLocal(booksByLocal[6])}
                </span>
              </div>
              <div className={`${styles.startCol}`}>
                {locales ? (
                  <React.Fragment>
                    {books[6].map((e, idx) => (
                      <div
                        key={`delivered-books-6-${idx}`}
                        onClick={() => linkto(e.id_vehiculo)}
                        className={`pointer text-center ${
                          styles.calendarBlock
                        } ${styles[`${e.estado}`]} `}
                      >
                        {e.title}
                      </div>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {booksByLocal[6]?.map((e, idx) => (
                      <div
                        key={`delivered-booksByLocal-6-${idx}`}
                        onClick={() =>
                          selectLocal(e[0]?.local, e[0]?.local_nombre)
                        }
                        className={`pointer text-center ${styles.calendarBlock} ${styles.pagado}`}
                      >
                        {e[0]?.local_nombre} ({e.length})
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </Col>
            <Col className={`col-12 col-md ${styles.calendarContent}`}>
              <div className={`${styles.calendarHeaderTitle}`}>
                {week && week[5]}{" "}
                <span className={`${styles.numberCircle}`}>
                  {locales ? books[7].length : countByLocal(booksByLocal[7])}
                </span>
              </div>
              <div className={`${styles.startCol}`}>
                {locales ? (
                  <React.Fragment>
                    {books[7].map((e, idx) => (
                      <div
                        key={`delivered-books-7-${idx}`}
                        onClick={() => linkto(e.id_vehiculo)}
                        className={`pointer text-center ${
                          styles.calendarBlock
                        } ${styles[`${e.estado}`]} `}
                      >
                        {e.title}
                      </div>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {booksByLocal[7]?.map((e, idx) => (
                      <div
                        key={`delivered-booksByLocal-7-${idx}`}
                        onClick={() =>
                          selectLocal(e[0]?.local, e[0]?.local_nombre)
                        }
                        className={`pointer text-center ${styles.calendarBlock} ${styles.pagado}`}
                      >
                        {e[0]?.local_nombre} ({e.length})
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </Col>
            <Col className={`col-12 col-md ${styles.calendarContent}`}>
              <div className={`${styles.calendarHeaderTitle}`}>
                {week && week[6]}{" "}
                <span className={`${styles.numberCircle}`}>
                  {locales ? books[1].length : countByLocal(booksByLocal[1])}
                </span>
                <span
                  className={`${styles.calendarArrows} ${styles.desktop}`}
                  onClick={() => changeWeek(today.setDate(today.getDate() + 7))}
                >
                  {">"}
                </span>{" "}
              </div>
              <div className={`${styles.startCol}`}>
                {locales ? (
                  <React.Fragment>
                    {books[1].map((e, idx) => (
                      <div
                        key={`delivered-books-1-${idx}`}
                        onClick={() => linkto(e.id_vehiculo)}
                        className={`pointer text-center ${
                          styles.calendarBlock
                        } ${styles[`${e.estado}`]} `}
                      >
                        {e.title}
                      </div>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {booksByLocal[1]?.map((e, idx) => (
                      <div
                        key={`delivered-booksByLocal-1-${idx}`}
                        onClick={() =>
                          selectLocal(e[0]?.local, e[0]?.local_nombre)
                        }
                        className={`pointer text-center ${styles.calendarBlock} ${styles.pagado}`}
                      >
                        {e[0]?.local_nombre} ({e.length})
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </Col>
          </Row>
          {/* <Row className={`ml-0 ${styles.calendarContent}`}></Row> */}
        </Col>
      </Row>
    </div>
  );
};

export default DeliveredCalendar;
