import { useContext, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { AiOutlineSend } from "react-icons/ai";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";
import Mainlayout from "layouts/MainLayout";
import { UserContext } from "contexts/UserContext";
import { errorToast, successToast } from "helpers/toastFunctions";
import { changePassword } from "services/Auth/AuthService";

const ChangePassword = () => {
  const router = useHistory();
  const { setPasswordChangeRequired } = useContext(UserContext);

  const [inputs, setInputs] = useState({
    actualPwd: "",
    newPwd: "",
    newPwdRepeat: "",
  });

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputs({
      ...inputs,
      [e.target.name]: value,
    });
  };

  const submitPwdChange = async (e) => {
    e.preventDefault();

    // Check that the inputs are filled
    if (!Object.values(inputs).every((x) => x))
      errorToast("Rellene todos los campos");
    // Compare that newPwd and newPwdRepeat are the same
    else if (inputs.newPwd !== inputs.newPwdRepeat)
      errorToast(
        "Las contraseñas no coinciden. Por favor, asegúrese de que ha ingresado correctamente la nueva contraseña en ambos campos."
      );
    else
      try {
        const response = await changePassword(inputs.actualPwd, inputs.newPwd);
        successToast(response.message);
        setPasswordChangeRequired(false);
        router.push("/main/admin");
      } catch (err) {
        if (err.response.status === 400)
          errorToast(
            "La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial."
          );
        else if (err.response.status === 401)
          errorToast("La contraseña actual es incorrecta.");
        else console.error(err);
      }
  };

  return (
    <Mainlayout>
      <Mainlayout.Content contentTitle="CAMBIAR CONTRASEÑA" full>
        <div className="d-flex flex-column align-items-center">
          <div className="col-12 col-lg-10 col-xl-8 ml-0">
            <Form
              onSubmit={submitPwdChange}
              className={styles.changePasswordForm}
            >
              <FormGroup>
                <Label for="actualPwd">Contraseña actual</Label>
                <Input
                  name="actualPwd"
                  placeholder="Contraseña actual"
                  type="password"
                  value={inputs.actualPwd}
                  onChange={handleInputChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="newPwd">Nueva contraseña</Label>
                <Input
                  name="newPwd"
                  placeholder="Nueva contraseña"
                  type="password"
                  value={inputs.newPwd}
                  onChange={handleInputChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="newPwdRepeat">Repetir nueva contraseña</Label>
                <Input
                  name="newPwdRepeat"
                  placeholder="Repetir nueva contraseña"
                  type="password"
                  value={inputs.newPwdRepeat}
                  onChange={handleInputChange}
                />
              </FormGroup>

              <Button
                outline
                className={`${styles.buttonPassword} d-flex align-items-center orange-btn`}
              >
                <div className="w-100">
                  <span>Cambiar contraseña</span>
                  <AiOutlineSend className="ml-3" />
                </div>
              </Button>
            </Form>
          </div>
        </div>
      </Mainlayout.Content>
    </Mainlayout>
  );
};

export default ChangePassword;
