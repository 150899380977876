const filterForSpecialChars = ({ props, state, methods }) => {
  return methods.sortBy().filter((item) => {
    if (item.label && item.label.length > 1) {
      return (
        item.label
          //.normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toLowerCase()
          .includes(state.search.toLowerCase())
      );
    } else {
      return item;
    }
  });
};

export default filterForSpecialChars;
