import permissions from "constants/permissions";
import { UserContext } from "contexts/UserContext";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Administration.css";
import SectionButton from "./components/sectionButton/SectionButton";
import { useTranslation } from 'react-i18next';
import Locale from "constants/Locale";

const Administration = () => {
  const { permission } = useContext(UserContext);
  const { t } = useTranslation('administracion');

  const { i18n } = useTranslation();


  const checkPermission = (itemPermissionId) => {
    let access = false;
    itemPermissionId.forEach((id) => {
      if (permission) {
        let i = 0;
        do {
          i++;
          if (permission.includes(id)) {
            access = true;
          }
        } while (!access && i < itemPermissionId.length);
      }
    });
    return access;
  };

  const portugueseAccessProtection = i18n.language === Locale.ES 

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <div>
          <Col xs={4} className="custom__col__size">
              {portugueseAccessProtection && ( 
            <div>
            <Row className="custom__title__box">
            <h2 className="custom__title__text">{t('contracts')}</h2>
            </Row>
            {checkPermission(permissions.contratos.documentacion) && (
              <div>
                <SectionButton
                  title={t('documentation')}
                  url="/main/postventa_contratos"
                />
              </div>
            )}
            </div>
            )}

            <Row className="custom__title__box">
              <h2 className="custom__title__text">{t('leadsList')}</h2>
            </Row>

            {checkPermission(permissions.leads.todosLeads) && (
              <SectionButton
                title={t('list')}
                url="/main/listaleads"
              />
            )}

            {checkPermission(permissions.leads.leadsPendientes) && (
              <SectionButton
                title={t('pendingLeads')}
                url="/main/listaleads?section=pendientes"
              />
            )}

            {checkPermission(permissions.leads.leadsPropios) && (
              <SectionButton
                title={t('ownLeads')}
                url="/main/listaleads?section=propios"
              />
            )}

            {checkPermission(permissions.leads.leadsPropiosPendientes) && (
              <SectionButton
                title={t('ownPendingLeads')}
                url="/main/listaleads?section=propios_pendientes"
              />
            )}
            {checkPermission(permissions.leads.leadsPendientesTasacion) && (
              <SectionButton
                title={t('ownPendingLeads')}
                url="/main/listaleads?section=pendientestasacion"
                isPhpView={false}
              ></SectionButton>
            )}

            <Row className="custom__title__box">
              <h2 className="custom__title__text">{t('salesLeads')}</h2>
            </Row>

            {checkPermission(permissions.leads.leadsVenta) && (
              <SectionButton
                title={t('list')}
                url="/main/listaleadsventa?section=todos"
              />
            )}

            {checkPermission(permissions.leads.destacados) && (
              <SectionButton
                title={t('featuredLeads')}
                url="/main/listaleadsventa?section=destacados"
              />
            )}

            {checkPermission(permissions.leads.leadsPropiosVenta) && (
              <SectionButton
                title={t('ownLeads')}
                url="/main/listaleadsventa?section=propios"
              />
            )}

            {checkPermission(permissions.leads.destacados) && (
              <SectionButton
                title={t('storeLeads')}
                url="/main/listaleadsventa?section=mitienda"
              />
            )}
          </Col>
        </div>
        <div>
          <Col xs={4} className="custom__col__size">
            <Row className="custom__title__box">
              <h2 className="custom__title__text">{t('vehicles')}</h2>
            </Row>

            {checkPermission(permissions.stock.id) && (
              <div>
                <SectionButton
                  title={t('stock')}
                  url="/main/stock"
                />
              </div>
            )}

            {portugueseAccessProtection &&  checkPermission(permissions.vehiculo.validacion) && (
              <div>
                <SectionButton
                  title={t('validation')}
                  url="/main/validacion-vehiculo"
                />
              </div>
            )}

            {checkPermission(permissions.citas.citasVenta) && (
              <div>
                <SectionButton
                  title={t('saleQuotes')}
                  url="/main/citas-ventas"
                />
              </div>
            )}

            {checkPermission(permissions.citas.citasCompra) && (
              <SectionButton
                title={t('shoppingQuotes')}
                url="/main/citas-compras"
              />
            )}
             {checkPermission(permissions.citas.citasPostVenta) && (
              <SectionButton
                title={t('afterSales')}
                url="/main/citas-post-venta"
              />
            )}

            {portugueseAccessProtection && checkPermission(permissions.citas.reservas) && (
              <SectionButton
                title={t('reservations')}
                url="/main/reservas"
              />
            )}

            {portugueseAccessProtection && checkPermission(permissions.citas.entregas) && (
              <SectionButton
                title={t('myDeliveries')}
                url="/main/entregas"
              />
            )}

            {checkPermission(permissions.casos.id) && (
              <div>
                <SectionButton
                  title={t('postSellingCases')}
                  url="/main/casos"
                />
              </div>
            )}
          </Col>
        </div>
        <div>
          <Col sm={4} className="custom__col__size">
            <Row className="custom__title__box">
              <h2 className="custom__title__text mt-2">{t('users')}</h2>
            </Row>
            <div>
              {checkPermission(permissions.empleados.id) && (
                <div>
                  <SectionButton
                    title={t('employees')}
                    url="/main/lista-empleados"
                  />
                </div>
              )}

              {checkPermission(permissions.leads.turnosAgentesVenta) && (
                <div>
                  <SectionButton
                    title={t('sellAgentsTurns')}
                    url="/main/turnos"
                  />
                </div>
              )}

              {checkPermission(permissions.leads.listaAgentesLeads) && (
                <div>
                  <SectionButton
                    title={t('agentLeadsList')}
                    url="/main/lista-agentes-leads"
                  />
                </div>
              )}
                <div>
                  <SectionButton
                    title={t('password')}
                    url="/main/cambiar-password"
                  />
                </div>
            </div>

            <Row className="custom__title__box">
              <h2 className="custom__title__text">{t('bussiness')}</h2>
            </Row>
            {checkPermission(permissions.locales.id) && (
              <div>
                <SectionButton
                  title={t('locals')}
                  url="/main/locales"
                />
              </div>
            )}
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default Administration;
