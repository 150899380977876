import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import { FiAlertCircle, FiPaperclip } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

import styles from "./index.module.scss";
import Mainlayout from "layouts/MainLayout";
import { errorToast } from "helpers/toastFunctions";

import FormattedNumberInput from "components/FormattedNumberInput";

import UploadNoCallComponent from "components/UploadNoCallComponent";
import {
  createPreparationComment,
  getPreparationComment,
  updatePreparationComment,
} from "services/Preparation/preparation.service";
import ModalSaveData from "components/ModalSaveData/modalSaveData";
import preparationSchema from "schema/preparationSchema";
import { zodResolver } from "@hookform/resolvers/zod";

const PreparationComment = () => {
  const { commentId, vehicleId } = useParams();
  const history = useHistory();

  const [comment, setComment] = useState({});
  const [file, setFile] = useState(null);
  const [showAddFileModal, setShowAddFileModal] = useState(false);
  const [showModalSaveData, setShowModalSaveData] = useState(false);
  const [fileAction, setFileAction] = useState(null);

  const defaultValues = {
    title: comment.title,
    file: file?.object,
    fileTitle: file?.title || "",
    comment: comment.comment || "",
    amount: comment.amount || 0,
  };
  const maxLengthFileTitle = 35;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    getValues,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(preparationSchema),
    defaultValues,
  });

  useEffect(() => {
    if (defaultValues.title === undefined) {
      return;
    }

    reset({
      ...getValues(),
      title: comment.title,
      file: file?.object,
      fileTitle: file?.title,
      comment: comment.comment || "",
      amount: comment.amount || 0,
    });
  }, [
    comment.amount,
    comment.comment,
    comment.title,
    defaultValues.title,
    file?.object,
    file?.title,
    getValues,
    reset,
  ]);

  const addNewComment = async (comment) => {
    try {
      const formData = new FormData();
      formData.append("title", comment.title);
      if (comment.comment) formData.append("comment", comment.comment);
      formData.append("amount", comment.amount);
      if (file?.object) {
        formData.append("file", file.object);
        formData.append("fileTitle", file.name);
      }
      if (commentId) {
        if (fileAction) formData.append("fileAction", fileAction);
        await updatePreparationComment(commentId, formData);
        history.push(`/main/vehicle/${vehicleId}?tab=preparacion`);
      } else {
        await createPreparationComment(vehicleId, formData);
        history.push(`/main/vehicle/${vehicleId}?tab=preparacion`);
      }
    } catch (err) {
      errorToast(err?.response?.data?.message || "Error al crear el registro");
    }
  };

  const onFile = (fileUpdate) => {
    if (!file && fileAction !== "DELETE") {
      setFileAction("NEW");
    } else {
      setFileAction("UPDATE");
    }
    setFile({ ...fileUpdate, title: fileUpdate.name, file: fileUpdate.object });
  };

  useEffect(() => {
    if (commentId) {
      (async () => {
        try {
          const caseResponse = await getPreparationComment(commentId);
          if (caseResponse.file) {
            setFile({ title: caseResponse.file.title });
          }
          setComment(caseResponse);
        } catch (err) {
          errorToast(
            err?.response?.data?.message || "Error al obtener el registro"
          );
        }
      })();
    }
  }, [commentId]);

  const handleBackButton = () => {
    if (isDirty || fileAction) {
      return setShowModalSaveData(true);
    }
    history.push(`/main/vehicle/${vehicleId}?tab=preparacion`);
  };

  const barButtons = (
    <>
      <button className="btn btn-flexicar" onClick={() => handleBackButton()}>
        Volver
      </button>
    </>
  );

  return (
    <>
      <Mainlayout>
        <Mainlayout.Content
          contentTitle="Registro preparación"
          buttons={barButtons}
        >
          <div>
            <form
              className={styles.newCommentForm}
              onSubmit={handleSubmit(addNewComment)}
            >
              <div>
                <div className={styles.inputWrapper}>
                  <label>Título *</label>
                  <input
                    placeholder={""}
                    className={`form-control ds-input mt-1 ${
                      errors.nombre && "is-invalid"
                    } `}
                    autoComplete="off"
                    type="text"
                    {...register("title")}
                  />
                  {errors.title && (
                    <p className={styles.customSelectError}>
                      <FiAlertCircle size={20} />
                      {errors.title.message}
                    </p>
                  )}
                </div>
              </div>

              <div className={styles.sectionCommentAndAmmount}>
                <div
                  className={`${styles.inputWrapper} ${styles.newCommentText}`}
                >
                  <label>Comentario</label>
                  <textarea
                    placeholder="Escribe aquí tu comentario"
                    {...register("comment")}
                  />
                  {errors.comment && (
                    <p className={styles.customSelectError}>
                      <FiAlertCircle size={20} />
                      {errors.comment.message}
                    </p>
                  )}
                </div>

                <div
                  className={`${styles.inputWrapper} ${styles.newCommentAmount}`}
                >
                  <label>Importe</label>
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field }) => <FormattedNumberInput {...field} />}
                  />
                  {errors.amount && (
                    <p className={styles.customSelectError}>
                      <FiAlertCircle size={20} />
                      {errors.amount.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles.newCommentDoc}>
                {file ? (
                  <span>
                    <RiDeleteBin6Line
                      size={36}
                      color="#ED0530"
                      onClick={() => {
                        setFile(null);
                        setFileAction("DELETE");
                      }}
                    />
                    <span style={{ color: "black" }}>{file.title}</span>
                  </span>
                ) : (
                  <span>
                    <FiPaperclip
                      size={36}
                      onClick={() => setShowAddFileModal(true)}
                    />
                    <span style={{ color: "#D34600" }}>Adjuntar archivo</span>
                  </span>
                )}
              </div>
              <button
                disabled={isSubmitting}
                className="btn btn-flexicar"
                type="submit"
              >
                {isSubmitting && (
                  <span className="spinner-grow spinner-grow-sm mr-1"></span>
                )}
                Guardar
              </button>
            </form>
          </div>
        </Mainlayout.Content>

        <UploadNoCallComponent
          onFile={onFile}
          show={showAddFileModal}
          setShow={setShowAddFileModal}
          maxLengthTitle={maxLengthFileTitle}
        />
      </Mainlayout>
      <ModalSaveData
        modalOpen={showModalSaveData}
        setModalOpen={setShowModalSaveData}
      />
    </>
  );
};

export default PreparationComment;
