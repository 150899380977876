import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";

import errorCall from "services/errorCall";
import filterForSpecialChars from "../../helpers/filterForSpecialChars";

const Filter = ({
  disabled,
  type,
  name,
  multi,
  sendData,
  parentValues,
  setter,
  className,
  data,
  mode,
  vehiculo,
}) => {
  const [options, setOptions] = useState([]);
  const [filterValues, setFilterValues] = useState([]);

  const launchQuery = async () => {
    try {
      const response = await type(mode, data);
      await setOptions(response);
    } catch (e) {
      errorCall(e);
    }
  };

  useEffect(() => {
    if (parentValues && filterValues) {
      if (parentValues?.length === 0) {
        setFilterValues([]);
      }

      if (
        (parentValues[0] &&
          filterValues[0] &&
          parentValues[0].label !== filterValues[0].label) ||
        vehiculo?.marca_vehiculo
      ) {
        setFilterValues(parentValues);
      }
    } else {
      setFilterValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValues]);

  const onChange = (value) => {
    sendData(setter ? setter : name, value);
    setFilterValues(value);
  };

  return (
    <div>
      {options !== null ? (
        <Select
          color={"#FF5D0E"}
          options={options?.map((x, y) => {
            return {
              label:
                x.name && !x.modelYear
                  ? x.name
                  : x.modelYear
                  ? x.name.concat(
                      " - ",
                      x.doors,
                      " ",
                      "P",
                      " ",
                      "(",
                      x.modelYear,
                      ")"
                    )
                  : x,
              value: x.id ? x.id : x,
            };
          })}
          searchFn={filterForSpecialChars}
          placeholder={name}
          values={filterValues}
          multi={multi}
          disabled={disabled}
          noDataRenderer={() => (
            <span style={{ textAlign: "center" }}>Sin datos</span>
          )}
          onChange={(value) => onChange(value)}
          className={`fix_selector_arrow ${className}`}
          onDropdownOpen={() => launchQuery()}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

export default Filter;
