import React, { useState, useEffect } from "react";
import { post } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  errorToast,
  successToast
} from "../../../helpers/toastFunctions";
import { useTranslation } from "react-i18next";

const ModalClient = ({
  client,
  setModal,
  status,
  receiveClient
}) => {
  const { t } = useTranslation("modalClient");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [id, setId] = useState(null);

  useEffect(() => {
    setName(client?.nombre)
    setLastname(client?.apellidos)
    setPhone(client?.telefono1)
    setMail(client?.email)
    setId(client?.id)
  }, [client]);

  const updateData = async () => {
    try {
      if(id){      
        const response = await post(API.UPDATE_CLIENT_DATA, {name, lastname, phone, mail, id});
        if(response){
          receiveClient(name, lastname, phone, mail)
          successToast("Datos actualizados correctamente");
        } else {
          errorToast(<span>{t("errors.errorUpdate")}</span>);
        }
      } else {
        errorToast(<span>{t("errors.errorUpdate")}</span>);
      }
    } catch(e) {
      errorToast(<span>{t("errors.errorUpdate2")}</span>);
    }
    return setModal();
  }

  return (
    <Modal
    className="modal-lg"
    centered
    isOpen={status}
    toggle={() => setModal()}
  >
    <ModalHeader>Editar datos del Cliente</ModalHeader>
    <ModalBody className="p-4">
    <div className="row">
      <div className="col-md-6 col-sm-12">
        Nombre<br/>
        <input
        className="w-100"
        onChange={(e)=>setName(e.target.value)}
        value={name}
        />
      </div>
      <div className="col-md-6 col-sm-12">
        Apellidos<br/>
        <input
        className="w-100"
        onChange={(e)=>setLastname(e.target.value)}
        value={lastname}
        />
      </div>
      <div className="col-md-6 col-sm-12 mt-4">
        Teléfono<br/>
        <input
        className="w-100"
        type="number"
        onChange={(e)=>setPhone(e.target.value)}
        value={phone}
        />
      </div>
      <div className="col-md-6 col-sm-12 mt-4">
        Email<br/>
        <input
        className="w-100"
        onChange={(e)=>setMail(e.target.value)}
        value={mail}
        />
      </div>
    </div>
    </ModalBody>
    <ModalFooter>
      <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => setModal()}>Cerrar</button>
      <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => updateData()}>Guardar</button>
    </ModalFooter>
  </Modal>
  );
};

export default ModalClient;