import React,{useEffect,useState} from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  Input,
  FormGroup,
} from "reactstrap";
import styles from "./index.module.scss";
import { useTranslation } from 'react-i18next';
import Locale from "constants/Locale";

const ModalCommentpdf = ({
  vehiculo,
  handleSimpleInput,
  gethtmltopdf,
  reset,
  publishVehicle,
  impresion,
  setImpresion,
  type,
}) => {
  const toggleComment = async () => {
    setImpresion(false);
    await reset();
  };
  const { i18n } = useTranslation();
  const portugueseAccessProtection = i18n.language === Locale.ES
  const defaultValue = "Ao PVP anunciado acresce o valor de 290,00€ (IVA Inc) referente a Despesas Administrativas.";
  const [comentarioImpresion, setComentarioImpresion] = useState(defaultValue);

  useEffect(() => {
    // Inicializar el estado con vehiculo.comentario_impresion si está definido
    if (vehiculo?.comentario_impresion !== undefined) {
      setComentarioImpresion(vehiculo.comentario_impresion);
    } else {
      // Si vehiculo.comentario_impresion es undefined, establecer el valor predeterminado
      setComentarioImpresion(portugueseAccessProtection ? vehiculo.comentario_impresion : defaultValue);
    }
  }, [vehiculo?.comentario_impresion, portugueseAccessProtection]);

  return (
    <>
      <Modal isOpen={impresion} toggle={toggleComment}>
        <ModalHeader className={styles.modalheader} toggle={toggleComment}>
          ¿Quieres imprimir la percha PDF del vehículo?
        </ModalHeader>
        <FormGroup style={{ padding: "20px" }}>
          <Input
            type="textarea"
            name="text"
            id="exampleText"
            placeholder="Comentario para el cliente en la ficha de vehículo"
            value={comentarioImpresion}
            onChange={(e) => {
              setComentarioImpresion(e.target.value);
              handleSimpleInput(e, "comentario_impresion");
            }}
          />
        </FormGroup>
        <ModalFooter>
          <Button color="primary" onClick={() => gethtmltopdf()}>
            Imprimir
          </Button>
          {type === "draft" ? (
            <Button color="secondary" onClick={() => publishVehicle()}>
              Publicar
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalCommentpdf;
