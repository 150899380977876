import { useContext, useEffect, useState } from "react";
import { Table } from "reactstrap";

import styles from "./index.module.scss";

import { dailyHours } from "../utils";
import Chip from "../Chip";
import { AppointmentContext } from "views/Appointments/AppointmentContext";

const TableCalendar = ({ calendarDayHeaders }) => {
	const [cells, setCells] = useState([]);

	const {
		type,
		calendarConfig,
		eventsFiltered,
		eventsFilteredCount,
		localSelected,
		localesOptions,
		setLocalSelected
	} = useContext(AppointmentContext);

	// Iterate the calendar matrix to get the events of each day-hour (cell)
	useEffect(() => {
		const newCells = dailyHours.map((hour) => (
			<tr key={`row-${hour}`}>
				<td>{hour}</td>
				{calendarDayHeaders.map(({ weekDay, dayFormatted }) => {
					const newCellData = eventsFiltered[dayFormatted]?.[hour] ?? {};

					return (
						<td key={`cell-${dayFormatted}-${hour}`}>
							<div className={styles.appointmentCalendarChip}>
								{Object.entries(newCellData).map((values) => {
									const firstEvent = values[1][0];
									const dealershipId =
										type === "post-venta" ? firstEvent.dealership.id : firstEvent.local.id;

									return Object.keys(localSelected).length === 0 ? (
										// Locales chips
										<Chip
											key={`cell-chip-${dayFormatted}-${hour}-${dealershipId}`}
											bgColor="#f0a23c"
											name={`${values[0]} (${values[1].length})`}
											onClick={() =>
												setLocalSelected(localesOptions.filter((x) => x.value === dealershipId)[0])
											}
										/>
									) : (
										values[1].map((appointment) => {
											const resourceId =
												type === "post-venta" ? appointment.case.id : appointment.lead.id;
											return (
												// Client name chip
												<Chip
													key={`cell-chip-${dayFormatted}-${hour}-${resourceId}`}
													bgColor={appointment.bgColor}
													name={calendarConfig.getChipName(appointment)}
													onClick={() =>
														window.open(
															calendarConfig.getChipLink(appointment),
															"_blank",
															"noopener,noreferrer"
														)
													}
												/>
											);
										})
									);
								})}
							</div>
						</td>
					);
				})}
			</tr>
		));

		setCells(newCells);
	}, [
		type,
		eventsFiltered,
		calendarDayHeaders,
		localSelected,
		localesOptions,
		setLocalSelected,
		calendarConfig
	]);

	return (
		<>
			<div className={styles.appointmentTotal}>
				<span className={styles.appointmentTotalText}>Citas totales: </span>
				<span className={styles.appointmentTotalCount}>{eventsFilteredCount}</span>
			</div>
			<Table
				className={styles.appointmentCalendar}
				bordered
				striped
			>
				<thead>
					<tr>
						<th></th>
						{calendarDayHeaders.map(({ weekDay, eventsCount }, idx) => (
							<th key={`header-day-${idx}`}>
								<div className={styles.appointmentCalendarHeaderDay}>
									<span className={styles.appointmentCalendarHeaderDayText}>{weekDay}</span>
									<span className={styles.appointmentCalendarHeaderDayCount}>{eventsCount}</span>
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>{cells}</tbody>
			</Table>
		</>
	);
};

export default TableCalendar;
