import { successToast } from "helpers/toastFunctions";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getDealers, patchDealer } from "services/Dealers/dealers.service";
import styles from "./index.module.scss";

const DeleteDealerModal = (props) => {
  const {
    modalDeleteDealer,
    setModalDeleteDealerHandler,
    setDealers,
    dealerId,
    setDealersCount,
  } = props;

  const deleteHandler = () => {
    patchDealer(`/dealers/${dealerId}`, { active: false })
      .then((response) => {
        getDealers(`/dealers`)
          .then((response) => {
            setDealers(response.data);
            setDealersCount(response.count);
            setModalDeleteDealerHandler();
            successToast("Local eliminado correctamente.");
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e) => {
        throw e;
      });
  };

  return (
    <div>
      <Modal isOpen={modalDeleteDealer} toggle={setModalDeleteDealerHandler}>
        <ModalHeader
          toggle={setModalDeleteDealerHandler}
          className={styles.modal_header}
        >
          Eliminar Concesionario
        </ModalHeader>
        <ModalBody>
          Pulse eliminar para confirmar el borrado del concesionario.
        </ModalBody>
        <ModalFooter>
          <Button className={styles.btn_custom} onClick={deleteHandler}>
            Eliminar
          </Button>{" "}
          <Button color="secondary" onClick={setModalDeleteDealerHandler}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteDealerModal;
