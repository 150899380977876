import React, { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Input,
  Label,
  Container,
  Row,
  Col,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";

import { errorToast, successToast } from "helpers/toastFunctions";
import revision from "helpers/revision";
import Filter from "components/Filter";
import Equipment from "components/EquipmentJato";
import styles from "./index.module.scss";
import Select from "react-dropdown-select";
import moment from "moment";
import ExtrasCategory from "./components/ExtrasCategory";
import classes from "./index.module.scss";
import DatePicker, { registerLocale } from "react-datepicker";

import es from "date-fns/locale/es";
import Gallery from "./components/Gallery";
import ModalManual from "./components/ModalManual";
import ModalPreview from "./components/ModalPreview";
import DatosVehiculoClasico from "../../components/DatosVehiculosClasico";

import { getDealer } from "services/Dealers/dealers.service";
import {
  createVehicle,
  getColors,
  getDraft,
  getVersionData,
  putSales,
  putVehicle,
  putExtras,
  putLocal,
  putAdditionalInfo,
} from "services/Vehicle/vehicle.service";
import compare from "helpers/compare";
import RolType from "constants/roles.js";
import ModalUpdateLocalVehicle from "components/ModalUpdateLocalVehicle";
import { useParams } from "react-router-dom";
import { UserContext } from "contexts/UserContext";
import { MdHelp } from "react-icons/md";
import { useTranslation } from "react-i18next";
import ModalCommentpdf from "components/ModalCommentpdf";
import thousandsFormat from "helpers/thousandsFormat";
import numberFormat from "helpers/numberFormat"

import AdditionalInfo from "./components/AdditionalInfo";
import ejsTemplateRender from "helpers/ejsTemplateRender";

registerLocale("es", es);

const VehicleValidation = ({ close, recall, recallGarantia, history }) => {
  const [vehiculo, setVehiculo] = useState({
    marca_vehiculo: "",
    modelo: "",
    matriculacion: "",
    combustible: "",
    ccambios: "",
    carroceria: "",
    version: "",
    jato: true,
    id_jato: null,
    modify: false,
    fecha_matriculacion: "",
    matriculacion_date: "",
    extras: [],
    color: [],
    local: [],
    procedencia: [],
    ecoSticker: [],
    resultado_itv: [],
    garantia: [],
    importe_garantia: 500,
    importe_transporte: 290,
    importe_trasnferencia: 290,
    interes_min: 9.99,
    meses_min: 72,
    aportacion_maxi: 10,
    vto_itv: null,
  });
  
   const { rol, userType } = useContext(UserContext);

  const { id } = useParams();
  const { t } = useTranslation('vehicleValidation');

  const [vehiculoOld, setVehiculoOld] = useState({});
  const [ventaOld, setVentaOld] = useState({});
  const [manual, setManual] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [color, setColor] = useState([{ label: "otro...", id: 1 }]);
  const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);
  const [localInfo, setLocalInfo] = useState();
  const [equipment, setEquipment] = useState([]);
  const [extras, setExtras] = useState([]);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [saveChanges, setSaveChanges] = useState();
  const [extrasOld, setExtrasOld] = useState([]);
  const [extrasSelected, setExtrasSelected] = useState([]);
  const [type, setType] = useState();
  const [changeLocal, setChangeLocal] = useState();
  const [impresion, setImpresion] = useState(false);
  const [ecoImg, setEcoImg] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({});
  
  const [documents, setDocuments] = useState([]);

  const toggleTab = async (tab) => {
    if (activeTab !== tab) {
      if (activeTab === "1") {
        const vehiculoModified = {
          marca_vehiculo: vehiculo?.marca_vehiculo,
          modelo: vehiculo?.modelo,
          matriculacion: vehiculo?.matriculacion,
          combustible: vehiculo?.combustible,
          ccambios: vehiculo?.ccambios,
          carroceria: vehiculo?.carroceria,
          version: vehiculo?.version,
          jato: vehiculo?.jato,
          cilindrada: vehiculo?.cilindrada,
          potencia: vehiculo?.potencia,
          emisiones: vehiculo?.emisiones,
          maletero: vehiculo?.maletero,
          consumo_combi: vehiculo?.consumo_combi,
          consumo_road: vehiculo?.consumo_road,
          consumo_urban: vehiculo?.consumo_urban,
          asientos: vehiculo?.asientos,
          puertas: vehiculo?.puertas,
          km: vehiculo?.km,
          procedencia: vehiculo?.procedencia,
          ecoSticker: vehiculo?.ecoSticker,
          importado: vehiculo?.importado,
          km0: vehiculo?.km0,
          fecha_matriculacion: vehiculo?.fecha_matriculacion,
          marchas: vehiculo?.marchas,
          upropietario: vehiculo?.upropietario,
          ultima_revision: vehiculo?.ultima_revision,
          ultima_itv: vehiculo?.ultima_itv,
          vto_itv: vehiculo?.vto_itv,
          resultado_itv: vehiculo?.resultado_itv,
          garantia: vehiculo?.garantia,
          fecha_garantia: vehiculo?.fecha_garantia,
          color: vehiculo?.color,
        };
        const diff = await compare(vehiculoModified, vehiculoOld);

        if (diff && diff.different.length > 0) {
          await setManual(true);
          await setSaveChanges("compra");
        }
      } else if (activeTab === "5") {
        const ventaModified = {
          pvp_contado: vehiculo.pvp_contado,
          pvp_financiado: vehiculo.pvp_financiado,
          oferta_particulares: vehiculo.oferta_particulares,
          precio_oferta_particulares: vehiculo.precio_oferta_particulares,
          libre_cargas: vehiculo.libre_cargas,
          iva_deducible: vehiculo.iva_deducible,
          compraventas: vehiculo.compraventas,
          pvd: vehiculo.pvd,
          oferta_compraventas: vehiculo.oferta_compraventas,
          precio_oferta_compraventas: vehiculo.precio_oferta_compraventas,
          gestion_venta: vehiculo.gestion_venta,
          penalizacion: vehiculo.penalizacion,
          comision_venta: vehiculo.comision_venta,
          precio_estimado: vehiculo.precio_estimado,
        };
        const diff = await compare(ventaModified, ventaOld);
        if (diff && diff.different.length > 0) {
          await setManual(true);
          await setSaveChanges("venta");
        }
      } else if (activeTab === "3") {
        const extrasModified = { ...extrasSelected };
        const extrasOldObject = { ...extrasOld };
        const diff = await compare(extrasModified, extrasOldObject);
        if (
          diff &&
          (diff.different.length > 0 ||
            diff.missing_from_first.length > 0 ||
            diff.missing_from_second.length > 0)
        ) {
          await setManual(true);
          await setSaveChanges("extras");
        }
      }

      await setActiveTab(tab);
    }
  };

  const getEcoStickerImg = () => {
    const ecoSticker = vehiculo?.ecoSticker[0]?.value;
    switch (ecoSticker) {
      case 1:
        setEcoImg(
          "https://storage.googleapis.com/crm_public_files/public/etiquetas_eco/zero-badge.png"
        );
        break;
      case 2:
        setEcoImg(
          "https://storage.googleapis.com/crm_public_files/public/etiquetas_eco/eco-badge.png"
        );
        break;
      case 3:
        setEcoImg(
          "https://storage.googleapis.com/crm_public_files/public/etiquetas_eco/c-badge.png"
        );
        break;
      case 4:
        setEcoImg(
          "https://storage.googleapis.com/crm_public_files/public/etiquetas_eco/b-badge.png"
        );
        break;
      default:
        setEcoImg(null);
        break;
    }
  };

  const changeDealer = async ({ dealer }) => {
    try {
      await putLocal({
        id: vehiculo.id,
        type: type,
        idDealer: dealer[0].value,
      });
      await successToast(`Vehículo trasladado `);
      await setChangeLocal({ ...changeLocal, open: false });
      await setVehiculo({ ...vehiculo, local: dealer });
    } catch (e) {
      errorToast(<span>{t("errors.errorTransfer")}</span>);
    }
  };

  const launchQuery = async () => {
    try {
      if (id && type) {
        const response = await getDraft(id, type);
        const specs = response?.specs;

        if (response) {
          await setVehiculo({
            ...vehiculo,
            marca_vehiculo: [
              { label: response?.make.name, value: response?.make.id },
            ],
            modelo: [
              { label: response?.model.name, value: response?.model.id },
            ],
            matriculacion: [{ label: response?.year, value: response?.year }],
            combustible: [
              { label: response?.fuel.name, value: response?.fuel.id },
            ],
            ccambios: [
              {
                label: response?.transmission.name,
                value: response?.transmission.id,
              },
            ],
            carroceria: [
              { label: response?.bodyType.name, value: response?.bodyType.id },
            ],
            version: [
              {
                label:
                  response?.mode === "JATO"
                    ? response?.version.name.concat(
                        " - ",
                        specs?.doors,
                        " ",
                        "P",
                        " ",
                        "(",
                        response?.modelYear,
                        ")"
                      )
                    : response?.version.name,
                value: response?.version.id,
              },
            ],
            jato: response?.mode === "JATO" ? true : false,
            id: response?.id,
            matricula: response?.plate,
            bastidor: response?.chassisNumber,
            cilindrada: specs?.cc,
            potencia: specs?.power,
            emisiones: specs?.emissions,
            maletero: specs?.cubinVolume,
            consumo_combi: specs?.consumptionMix,
            consumo_road: specs?.consumptionRoad,
            consumo_urban: specs?.consumptionUrban,
            asientos: specs?.seats,
            puertas: specs?.doors,
            km: response?.mileage,
            local: [
              {
                value: response?.dealership.id,
                label: response?.dealership.name,
              },
            ],
            precio_compra: response?.priceBought,
            procedencia: [
              { label: response?.origin.name, value: response?.origin.id },
            ],
            pvp_contado: response?.priceCash,
            pvp_financiado: response?.priceFinanced,
            oferta_particulares: response?.priceOffer ? true : false,
            precio_oferta_particulares: response?.priceOffer,
            libre_cargas: response?.chargeFree ? true : false,
            iva_deducible: response?.taxDeductible ? true : false,
            compraventas: response?.buyAndSell ? true : false,
            pvd: response?.priceDealerSelling,
            oferta_compraventas: response?.priceDealerSellingOffer
              ? true
              : false,
            precio_oferta_compraventas: response?.priceDealerSellingOffer,
            gestion_venta: !response?.owned ? true : false,
            penalizacion: response?.trading?.penalty,
            comision_venta: response?.trading?.commission,
            precio_estimado: response?.trading?.estimatedPrice,
            ecoSticker: [
              { label: specs?.ecosticker?.name, value: specs?.ecosticker?.id },
            ],
            importado: response?.imported,
            km0: response?.km0,
            fecha_matriculacion: new Date(response?.registrationDate),
            marchas: specs?.gearCount,
            km_correa_cambiada: response?.cambeltReplaceKm,
            upropietario: response?.uOwner,
            km_revision: response?.inspectionKm,
            ultima_revision: response?.lastInspectionDate
              ? new Date(response?.lastInspectionDate)
              : undefined,
            ultima_itv: response?.itvLastDate
              ? new Date(response?.itvLastDate)
              : undefined,
            vto_itv: response?.itvDueDate
              ? new Date(response?.itvDueDate)
              : undefined,
            resultado_itv: [{ value: response?.itvResult }],
            garantia: response?.warrantyDate ? [{ value: 3 }] : [{ value: 0 }],
            fecha_garantia: response?.warrantyDate
              ? new Date(response?.warrantyDate)
              : undefined,
            color: [
              { label: response?.color?.name, value: response?.color?.id },
            ]
          });

          const equipment = response?.equipment;
          const colores = await getColors();
          setColor(colores);
          setEquipment(equipment);
          setExtras(response?.extras);
          setImagesPreviewUrls(response?.images);
          setBasicsOk(true);
          setAdditionalInfo({
            comment: response?.comment && response?.comment.text,
            lastInspectionDate:
              response?.lastInspectionDate &&
              new Date(response?.lastInspectionDate),
            inspectionKm: response?.inspectionKm,
            cambeltReplaceKm: response?.cambeltReplaceKm,
            cambeltReplace: response?.cambeltReplaceKm ? true : false,
          });
          setDocuments(response.documents)

          if (
            ![
              RolType.GENERAL_ADMIN,
              RolType.TRADING_AGENT,
              RolType.TRADING_MANAGER,
            ].some((x) => rol.includes(x))
          ) {
            setBasicsBlock(true);
          }
          
          await setLocalInfo({ id: response?.dealership.id });
          await setVehiculoOld({
            marca_vehiculo: [
              { label: response?.make.name, value: response?.make.id },
            ],
            modelo: [
              { label: response?.model.name, value: response?.model.id },
            ],
            matriculacion: [{ label: response?.year, value: response?.year }],
            combustible: [
              { label: response?.fuel.name, value: response?.fuel.id },
            ],
            ccambios: [
              {
                label: response?.transmission.name,
                value: response?.transmission.id,
              },
            ],
            carroceria: [
              { label: response?.bodyType.name, value: response?.bodyType.id },
            ],
            version: [
              {
                label:
                  response?.mode === "JATO"
                    ? response?.version.name.concat(
                        " - ",
                        specs?.doors,
                        " ",
                        "P",
                        " ",
                        "(",
                        response?.modelYear,
                        ")"
                      )
                    : response?.version.name,
                value: response?.version.id,
              },
            ],
            jato: response?.mode === "JATO" ? true : false,
            cilindrada: specs?.cc,
            potencia: specs?.power,
            emisiones: specs?.emissions,
            maletero: specs?.cubinVolume,
            consumo_combi: specs?.consumptionMix,
            consumo_road: specs?.consumptionRoad,
            consumo_urban: specs?.consumptionUrban,
            asientos: specs?.seats,
            puertas: specs?.doors,
            km: response?.mileage,
            procedencia: [
              { label: response?.origin.name, value: response?.origin.id },
            ],
            ecoSticker: [
              { label: specs?.ecosticker?.name, value: specs?.ecosticker?.id },
            ],
            importado: response?.imported,
            km0: response?.km0,
            fecha_matriculacion: new Date(response?.registrationDate),
            marchas: specs?.gearCount,
            upropietario: response?.uOwner,
            ultima_revision: response?.lastInspectionDate
              ? new Date(response?.lastInspectionDate)
              : undefined,
            ultima_itv: response?.itvLastDate
              ? new Date(response?.itvLastDate)
              : undefined,
            vto_itv: response?.itvDueDate
              ? new Date(response?.itvDueDate)
              : undefined,
            resultado_itv: [{ value: response?.itvResult }],
            garantia: response?.warrantyDate ? [{ value: 3 }] : [{ value: 0 }],
            fecha_garantia: response?.warrantyDate
              ? new Date(response?.warrantyDate)
              : undefined,
            color: [
              { label: response?.color?.name, value: response?.color?.id },
            ],
          });
          await setVentaOld({
            pvp_contado: response?.priceCash,
            pvp_financiado: response?.priceFinanced,
            oferta_particulares: response?.priceOffer ? true : false,
            precio_oferta_particulares: response?.priceOffer,
            libre_cargas: response?.chargeFree ? true : false,
            iva_deducible: response?.taxDeductible ? true : false,
            compraventas: response?.buyAndSell ? true : false,
            pvd: response?.priceDealerSelling,
            oferta_compraventas: response?.priceDealerSellingOffer
              ? true
              : false,
            precio_oferta_compraventas: response?.priceDealerSellingOffer,
            gestion_venta: !response?.owned ? true : false,
            penalizacion: response?.trading?.penalty,
            comision_venta: response?.trading?.commission,
            precio_estimado: response?.trading?.estimatedPrice,
          });
        }
      } else {
      }
    } catch (e) {
      errorToast(<span>{t("errors.errorPermission")}</span>);
      if (type === "draft") {
        await history.push(`/main/validacion-vehiculo`);
      }
      if (type === "stock") {
        await history.push(`/main/stock`);
      }
    }
  };
  const getType = () => {
    const path = window.location.pathname.split("/");
    if (path.includes("validacion-vehiculo")) {
      setType("draft");
    }
    if (path.includes("edicion-vehiculo")) {
      setType("stock");
    }
  };
  useEffect(() => {
    document.title = "Flexicar | Validación de vehiculo";
    getType();
    if (id) {
      launchQuery();
    }
  }, [type]);

  useEffect(() => {
    const changeyear = async () => {
      if (vehiculo?.matriculacion[0]?.value) {
        await setMinDate(
          new Date(`${vehiculo?.matriculacion[0]?.value}-01-01`)
        );
        await setMaxDate(
          new Date(`${vehiculo?.matriculacion[0]?.value}-12-31`)
        );
        await setVehiculo({
          ...vehiculo,
          fecha_matriculacion: new Date(
            moment(vehiculo.fecha_matriculacion).set(
              "year",
              vehiculo?.matriculacion[0]?.value
            )
          ),
        });
      }
    };
    changeyear();
  }, [vehiculo?.matriculacion]);
  
  const getVersions = async () => {
    try {
      const response = await getVersionData({
        version: vehiculo?.version[0]?.value,
        mode: "JATO",
      });
      const specs = await response?.specs;
      if (response) {
        await setVehiculo({
          ...vehiculo,
          cilindrada: specs?.cc ? specs?.cc : "",
          potencia: specs?.power ? specs?.power : "",
          emisiones: specs?.emissions ? specs?.emissions : "",
          maletero: specs?.cubinVolume ? specs?.cubinVolume : "",
          consumo_combi: specs?.consumptionMix ? specs?.consumptionMix : "",
          consumo_road: specs?.consumptionRoad ? specs?.consumptionRoad : "",
          consumo_urban: specs?.consumptionUrban ? specs?.consumptionUrban : "",
          asientos: specs?.seats ? specs?.seats : "",
          puertas: specs?.doors ? specs?.doors : "",
          marchas: specs?.gearCount ? specs?.gearCount : "",
          ecoSticker: specs?.ecosticker
            ? [{ label: specs?.ecosticker?.name, value: specs?.ecosticker?.id }]
            : [{ label: "", value: "" }],
          id_version: response?.id,
        });

        const equipment = await response?.equipment;

        await setEquipment(equipment);
        await setExtras(response?.extras);
      } else {
        setManual(true);
      }
    } catch (e) {
      errorToast(e);
    }
  };
  useEffect(() => {
    if (vehiculo.jato && basicsOk) {
      if (!vehiculo.version) {
        setVehiculo({
          ...vehiculo,
          cilindrada: "",
          potencia: "",
          emisiones: "",
          maletero: "",
          consumo_combi: "",
          consumo_road: "",
          consumo_urban: "",
          asientos: "",
          puertas: "",
          marchas: "",
          ecoSticker: [{ label: "", value: "" }],
        });
      } else {
        getVersions();
      }
    }
  }, [vehiculo?.version[0]?.value]);

  const [errors, setErrors] = useState({});
  const [basicsOk, setBasicsOk] = useState(false);

  const [basicsBlock, setBasicsBlock] = useState(true); // Change default state when back-end team allows editing

  const [retailPrice, setRetailPrice] = useState(null);
  const [price, setPrice] = useState(null);

  const sendData = (extras) => {
    setVehiculo({
      ...vehiculo,
      extras,
    });
  };

  const handleFilter = (value, type) => {
    setVehiculo({
      ...vehiculo,
      [type]: [{ value: value[0].value, label: value[0].label }],
    });
  };

  const local_info = () => {
    getDealer(`/dealers`, localInfo?.id)
      .then((dealer) => {
        setLocalInfo(dealer);
      })
      .catch((e) => {
        throw e;
      });
  };

  const revisionImpresion = () => {
    const notEmptyInputs = [
      "matricula",
      "bastidor",
      "version",
      "procedencia",
      "km",
      "pvp_contado",
    ];

    let result = true;
    const errorsTotal = {};
    if (imagesPreviewUrls.length === 0) {
      errorToast(<span>{t("errors.errorImage")}</span>);
    }
    notEmptyInputs.forEach((e) => {
      if (!revision(t, "empty", e, vehiculo[e])) {
        result = false;
        errorsTotal[e] = true;
      }
    });

    setErrors({
      ...errors,
      ...errorsTotal,
    });

    return result;
  };

  const revisionVehicle = () => {
    const notEmptyInputs = [
      "marca_vehiculo",
      "modelo",
      "version",
      "procedencia",
      "km",
      "ccambios",
      "fecha_matriculacion",
      "potencia",
      "puertas",
      "asientos"

    ];
    const notZeroInputs = [
      "puertas",
      "asientos",
      "marchas",
    ];
    let result = true;
    const errorsTotal = {};
    notZeroInputs.forEach((e) => {
      if (!revision(t, "zero", e, vehiculo[e])) {
        result = false;
        errorsTotal[e] = true;
      }
    });
    notEmptyInputs.forEach((e) => {
      if (!revision(t, "empty", e, vehiculo[e])) {
        result = false;
        errorsTotal[e] = true;
      }
    });
    if (
      vehiculo?.potencia &&
      !revision(t, "potencia", "potencia", vehiculo.potencia)
    ) {
      result = false;
      errorsTotal.potencia = true;
    }
    if (
      vehiculo?.cilindrada &&
      !revision(t, "cilindrada", "cilindrada", vehiculo.cilindrada)
    ) {
      result = false;
      errorsTotal.cilindrada = true;
    }
    setErrors({
      ...errors,
      ...errorsTotal,
    });

    return result;
  };
  const revisionSales = () => {
    const notEmptyInputs = ["pvp_contado"];
    let result = true;
    const errorsTotal = {};

    notEmptyInputs.forEach((e) => {
      if (!revision(t, "empty", e, vehiculo[e])) {
        result = false;
        errorsTotal[e] = true;
      }
    });
    if (
      vehiculo?.oferta_particulares &&
      !revision(t, 
        "empty",
        "precio_oferta_particulares",
        vehiculo?.precio_oferta_particulares
      )
    ) {
      result = false;
      errorsTotal.precio_oferta_particulares = true;
    }
    if (vehiculo?.gestion_venta) {
      const notEmptyInputs = [
        "precio_estimado",
        "comision_venta",
        "penalizacion",
      ];
     
      notEmptyInputs.forEach((e) => {
        if (!revision(t, "empty", e, vehiculo[e])) {
          result = false;
          errorsTotal[e] = true;
        }
      });
    }
    if (vehiculo?.gestion_venta) {
      const notEmptyInputs = [
        "precio_estimado",
        "comision_venta",
        "penalizacion",
      ];

      notEmptyInputs.forEach((e) => {
        if (!revision(t, "empty", e, vehiculo[e])) {
          result = false;
          errorsTotal[e] = true;
        }
      });
    }
    if (vehiculo?.compraventas) {
      const notEmptyInputs = ["pvd"];

      notEmptyInputs.forEach((e) => {
        if (!revision(t, "empty", e, vehiculo[e])) {
          result = false;
          errorsTotal[e] = true;
        }
      });
      if (
        vehiculo?.oferta_compraventas &&
        !revision(t, 
          "empty",
          "precio_oferta_compraventas",
          vehiculo?.precio_oferta_compraventas
        )
      ) {
        result = false;
        errorsTotal.precio_oferta_compraventas = true;
      }
    }
    setErrors({
      ...errors,
      ...errorsTotal,
    });

    return result;
  };
  const handleSimpleInput = (e, type) => {
    setVehiculo({ ...vehiculo, [type]: e.target.value });
    setErrors({ ...errors, [type]: false });
  };
  const handleNumberThousands = (e, type) => {
    let thousandsNumber=thousandsFormat(e.target.value)
    setVehiculo({ ...vehiculo, [type]: thousandsNumber });
    setErrors({ ...errors, [type]: false });
  };
  const handleAdditionalInfo = (e, type) => {
    setAdditionalInfo({ ...additionalInfo, [type]: e.target.value });
    setErrors({ ...errors, [type]: false });
  };

    const handleDocuments = (e) => {
      setDocuments([...documents, e]);
  }; 
   const handleDeleteDocument = (deletedDocumentId) => {
     setDocuments(documents.filter((doc) => doc.id !== deletedDocumentId));
   };
  const handleCheckCambeltReplace = () => {
     setAdditionalInfo({ ...additionalInfo, cambeltReplace: !additionalInfo.cambeltReplace, cambeltReplaceKm:null });
  }
  const [modalPreview, setModalPreview] = useState(false);

  const togglePreview = () => setModalPreview(!modalPreview);
  let template;
  const reset = () =>
    (template = document.getElementById("template").innerHTML = <div></div>);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const saveSalesData = async (publish) => {
    const revisionS = await revisionSales();
    if (revisionS) {
      try {
        if (vehiculo?.version[0]?.value) {
          const id = vehiculo?.id;
          const dataSales = {
            priceFinanced:
              numberFormat(vehiculo?.pvp_financiado) === 0
                ? null
                : numberFormat(vehiculo?.pvp_financiado),
            priceCash:
              numberFormat(vehiculo?.pvp_contado) === 0
                ? null
                : numberFormat(vehiculo?.pvp_contado),
            priceOffer:
              numberFormat(vehiculo?.precio_oferta_particulares) === 0
                ? null
                : numberFormat(vehiculo?.precio_oferta_particulares),
            taxDeductible: vehiculo?.iva_deducible,
            chargeFree: vehiculo?.libre_cargas,
            buyAndSell: vehiculo?.compraventas,
            priceDealerSelling:
              numberFormat(vehiculo?.pvd) === 0 ? null : numberFormat(vehiculo?.pvd),
            priceDealerSellingOffer:
              numberFormat(vehiculo?.precio_oferta_compraventas) === 0
                ? null
                : numberFormat(vehiculo?.precio_oferta_compraventas),
            trading: {
              estimatedPrice:
                numberFormat(vehiculo?.precio_estimado) === 0
                  ? null
                  : numberFormat(vehiculo?.precio_estimado),
              commission:
                numberFormat(vehiculo?.comision_venta) === 0
                  ? null
                  : numberFormat(vehiculo?.comision_venta),
              penalty:
                numberFormat(vehiculo?.penalizacion) === 0
                  ? null
                  : numberFormat(vehiculo?.penalizacion),
            },
          };
          await putSales(id, dataSales, type);
          if (!publish) {
            await successToast("Datos de venta guardados correctamente");
          }
          await setVentaOld({
            pvp_contado: vehiculo.pvp_contado,
            pvp_financiado: vehiculo.pvp_financiado,
            oferta_particulares: vehiculo.oferta_particulares,
            precio_oferta_particulares: vehiculo.precio_oferta_particulares,
            libre_cargas: vehiculo.libre_cargas,
            iva_deducible: vehiculo.iva_deducible,
            compraventas: vehiculo.compraventas,
            pvd: vehiculo.pvd,
            oferta_compraventas: vehiculo.oferta_compraventas,
            precio_oferta_compraventas: vehiculo.precio_oferta_compraventas,
            gestion_venta: vehiculo.gestion_venta,
            penalizacion: vehiculo.penalizacion,
            comision_venta: vehiculo.comision_venta,
            precio_estimado: vehiculo.precio_estimado,
          });
        }
      } catch (e) {
        errorToast(<span>{t("errors.errorSave")}</span>);
      }
    }
  };
  const saveExtras = async () => {
    try {
      await putExtras(vehiculo.id, extrasSelected, type);
      await successToast("Extras guardados correctamente");
      await setExtrasOld(extrasSelected);
    } catch (e) {
      errorToast(<span>{t("errors.errorSaveExtra")}</span>);
    }
  };
  const saveAdditionalInfo = async () => {
    
    if (additionalInfo.cambeltReplace && !additionalInfo.cambeltReplaceKm)
      return errorToast("Introduzca Km del coche al cambio de correa");
    
    try {
      const additionalData = {
        comment: additionalInfo?.comment,
        lastInspectionDate:
          additionalInfo?.lastInspectionDate &&
          moment(additionalInfo?.lastInspectionDate).format("YYYY-MM-DD"),
        inspectionKm: additionalInfo?.inspectionKm
          ? Number(additionalInfo?.inspectionKm)
          : null,
        cambeltReplaceKm: additionalInfo?.cambeltReplaceKm
          ? Number(additionalInfo?.cambeltReplaceKm)
          : null,
      };
      const { data } = await putAdditionalInfo(id, type, additionalData);

      successToast("Información Adicional guardada correctamente");
      return { data };
    } catch (error) {
      errorToast("Error al guardar Información Adicional");
      return { error };
    }
  };
  const saveVehicleData = async (publish) => {
    const revisionV = await revisionVehicle();
    if (revisionV) {
      try {
        if (vehiculo.jato) {
          const id = vehiculo?.id;
          const dataVehicle = {
            originId: Number(vehiculo?.procedencia[0].value),
            mode: vehiculo.jato ? "JATO" : "MANUAL",
            versionId: vehiculo?.id_version
              ? vehiculo?.id_version
              : Number(vehiculo?.version[0].value),
            transmissionId: Number(vehiculo?.ccambios[0].value),
            registrationDate: moment(vehiculo?.fecha_matriculacion).format(
              "YYYY-MM-DD"
            ),
            mileage: vehiculo?.km ? numberFormat(vehiculo?.km):null,
            km0: vehiculo?.km0 ? true : false,
            uOwner: vehiculo?.upropietario ? true : false,
            warrantyDate: vehiculo?.fecha_garantia
              ? moment(vehiculo?.fecha_garantia).format("YYYY-MM-DD")
              : null,
            colorId: vehiculo?.color[0]?.value,
            upholsteryColor: vehiculo?.tcolor,
            itvDueDate: vehiculo?.vto_itv
              ? moment(vehiculo?.vto_itv).format("YYYY-MM-DD")
              : null,
            itvLastDate: vehiculo?.ultima_itv
              ? moment(vehiculo?.ultima_itv).format("YYYY-MM-DD")
              : null,
            itvResult: vehiculo?.resultado_itv[0]?.value,
          };

          await putVehicle(id, dataVehicle, type);

          if (!publish) {
            await successToast("Vehículo guardado correctamente");
          }
          await setVehiculoOld({
            marca_vehiculo: vehiculo?.marca_vehiculo,
            modelo: vehiculo?.modelo,
            matriculacion: vehiculo?.matriculacion,
            combustible: vehiculo?.combustible,
            ccambios: vehiculo?.ccambios,
            carroceria: vehiculo?.carroceria,
            version: vehiculo?.version,
            jato: vehiculo?.jato,
            cilindrada: vehiculo?.cilindrada,
            potencia: vehiculo?.potencia,
            emisiones: vehiculo?.emisiones,
            maletero: vehiculo?.maletero,
            consumo_combi: vehiculo?.consumo_combi,
            consumo_road: vehiculo?.consumo_road,
            consumo_urban: vehiculo?.consumo_urban,
            asientos: vehiculo?.asientos,
            puertas: vehiculo?.puertas,
            km: vehiculo?.km,
            procedencia: vehiculo?.procedencia,
            ecoSticker: vehiculo?.ecoSticker,
            importado: vehiculo?.importado,
            km0: vehiculo?.km0,
            fecha_matriculacion: vehiculo?.fecha_matriculacion,
            marchas: vehiculo?.marchas,
            upropietario: vehiculo?.upropietario,
            ultima_revision: vehiculo?.ultima_revision,
            ultima_itv: vehiculo?.ultima_itv,
            vto_itv: vehiculo?.vto_itv,
            resultado_itv: vehiculo?.resultado_itv,
            garantia: vehiculo?.garantia,
            fecha_garantia: vehiculo?.fecha_garantia,
          });
        } else {
          const id = vehiculo?.id;
          const dataVehicle = {
            originId: Number(vehiculo?.procedencia[0].value),
            mode: vehiculo.jato ? "JATO" : "MANUAL",
            versionName: vehiculo?.version[0]?.label,
            transmissionId: Number(vehiculo?.ccambios[0].value),
            modelId: vehiculo?.modelo[0]?.value,
            bodyTypeId: vehiculo?.carroceria[0]?.value,
            fuelId: vehiculo?.combustible[0]?.value,
            registrationDate: moment(vehiculo?.fecha_matriculacion).format(
              "YYYY-MM-DD"
            ),
            mileage: vehiculo?.km ? numberFormat(vehiculo?.km) : null,
            km0: vehiculo?.km0 ? true : false,
            uOwner: vehiculo?.upropietario ? true : false,
            warrantyDate: vehiculo?.fecha_garantia
              ? moment(vehiculo?.fecha_garantia).format("YYYY-MM-DD")
              : null,
            colorId: vehiculo?.color[0]?.value,
            upholsteryColor: vehiculo?.tcolor,
            itvDueDate: vehiculo?.vto_itv
              ? moment(vehiculo?.vto_itv).format("YYYY-MM-DD")
              : null,
            itvLastDate: vehiculo?.ultima_itv
              ? moment(vehiculo?.ultima_itv).format("YYYY-MM-DD")
              : null,
            itvResult: vehiculo?.resultado_itv[0]?.value,
            specs: {
              ecostickerId: vehiculo?.ecoSticker[0]?.value,
              cc: vehiculo?.cilindrada ? Number(vehiculo?.cilindrada) :null,
              power: Number(vehiculo?.potencia),
              emissions: vehiculo?.emisiones ?  Number(vehiculo?.emisiones): null,
              cubinVolume: vehiculo?.maletero ? Number(vehiculo?.maletero): null,
              consumptionUrban: vehiculo?.consumo_urban ? Number(vehiculo?.consumo_urban): null,
              consumptionMix: vehiculo?.consumo_combi ? Number(vehiculo?.consumo_combi) : null,
              consumptionRoad: vehiculo?.consumo_road ? Number(vehiculo?.consumo_road) : null,
              seats: Number(vehiculo?.asientos),
              doors: Number(vehiculo?.puertas),
              gearCount: vehiculo?.marchas ? Number(vehiculo?.marchas) :null,
            },
          };

          await putVehicle(id, dataVehicle, type);
          await successToast("Datos de vehículo guardados correctamente");
        }
      } catch (e) {
        errorToast(<span>{t("errors.errorSave")}</span>);
      }
    }
  };
  const publishVehicle = async () => {
    try {
      await saveVehicleData("publish");
      await saveSalesData("publish");
      await createVehicle(vehiculo?.id);
      await successToast("Vehículo publicado correctamente");
      await history.push(`/main/validacion-vehiculo`);
    } catch (e) {
      errorToast(<span>{t("errors.errorPublish")}</span>);
    }
  };
  const gethtmltopdf = async () => {
    try {

      const dataTemplate = {vehiculo, localInfo, imagesPreviewUrls, type: "VehicleValidation" };

      template = await ejsTemplateRender(2, dataTemplate);

      handlePrint();
    } catch (e) {
      errorToast(<span>{t("errors.errorPrint")}</span>);
    }
  };

  useEffect(() => {
    if (localInfo?.id) {
      local_info();
    }
  }, [localInfo?.id]);

  useEffect(() => {
    if (vehiculo?.ecoSticker[0]?.value) {
      getEcoStickerImg();
    }
  }, [vehiculo?.ecoSticker[0]?.value]);

  const saveData = async () => {
    switch (activeTab) {
      case "1":
        await saveVehicleData();
        break;
      case "5":
        await saveSalesData();
        break;
      case "3":
        await saveExtras();
        break;
      case "4":
        await saveAdditionalInfo();
        break;
      default:
        break;
    }
  };




  const getBtnVisibility = () => {

    if(rol.includes(1)){
      setShowButtons(true)
      return
    }
      else if (rol.includes(15) || 
               rol.includes(16) || 
               rol.includes(17) || 
               rol.includes(18) ||
               rol.includes(2)  ||
               rol.includes(3)  ||
               rol.includes(4)  ||
               rol.includes(5)  ||
               rol.includes(8)  ||
               rol.includes(13) ||
               rol.includes(14) ||
               rol.includes(19) ||
               rol.includes(22) ||
               rol.includes(23) ||
               rol.includes(24) ||
               rol.includes(25) ||
               rol.includes(26) ||
               rol.includes(27) ||
               rol.includes(28) ||
               rol.includes(29) ||
               rol.includes(35) 
               )
               {
        history.push("/main/validacion-vehiculo")
        errorToast(<span>{t("errors.errorPermissionPage")}</span>)
        setShowButtons(false)
        return 
        
        
    } else if (rol.includes(6) || rol.includes(7)) {
      setShowButtons(true)
      return 
    }
  } 
   
      useEffect(() => {
        getBtnVisibility()
      }, [rol])

const disabledEditPrice = () => {
	if (rol.some((rol) => rol === RolType.TRADING_MANAGER) || userType === 1) {
		return false;
	}

	return true;
};

  return (
    <div>
      <div style={{ display: "none" }}>
        <div ref={componentRef} className="m-5" id="template">
          {template}
        </div>
      </div>
      <div className={`clearfix mb-3 ${styles.container}`}>
        <span className="tittle ml-4">
          {type === "draft" ? `${t("validateVehicle")}` : `${t("editVehicle")}`}{" "}
        </span>
      </div>
      <Container>
        <Row className={styles.centerRow}>
          <Col xs="12" sm="6">
            <Label className={"m-0"}>{t("licensePlate")}</Label>
            <Input
              disabled={basicsBlock}
              placeholder={t("licensePlate")}
              className="form-control ds-input "
              type="text"
              value={vehiculo.matricula}
              invalid={errors.matricula === true}
              onChange={(e) => {
                handleSimpleInput(e, "matricula");
              }}
            />
          </Col>

          <Col xs="12" sm="6">
            <Label className={"m-0"}>{t("chassisNumber")}</Label>
            <Input
              disabled={basicsBlock}
              placeholder={t("chassisNumber")}
              className="form-control ds-input"
              type="text"
              invalid={errors.bastidor === true}
              value={vehiculo.bastidor}
              onChange={(e) => {
                handleSimpleInput(e, "bastidor");
              }}
            />
          </Col>
        </Row>

        <div style={{ marginTop: "40px" }}>
          <Nav tabs className="d-flex" style={{ position: "relative" }}>
            <NavItem>
              <NavLink
                className={`${activeTab === "1" && styles.active}`}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                {t("data")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "5" && styles.active}`}
                onClick={() => {
                  toggleTab("5");
                }}
              >
                {t("sale")}{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "2" && styles.active}`}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                {t("photos")}{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "6" && styles.active}`}
                onClick={() => {
                  toggleTab("6");
                }}
              >
                {t("equipment")}{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "3" && styles.active}`}
                onClick={() => {
                  toggleTab("3");
                }}
              >
                {t("extras")}{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "4" && styles.active}`}
                onClick={() => {
                  toggleTab("4");
                }}
              >
                 {t("additionalInfo")}{" "}
              </NavLink>
            </NavItem>
           
          </Nav>
          {showButtons ? (
              <div
                className={`ms-auto ${styles.buttons}`}
            
              >
                {activeTab !== "2" && activeTab !== "6" ? (
                  <button
                    className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar `}
                    onClick={() => saveData()}
                  >
                    {t("save")}
                  </button>
                ) : null}

                {type === "draft" ? (
                  <button
                    className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar `}
                    onClick={() => togglePreview()}
                  >
                    {t("publishVehicle")}
                  </button>
                ) : (
                  <button
                    className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar `}
                    onClick={() => setImpresion(true)}
                  >
                    Descargar pdf
                  </button>
                )}
              </div>
            ) : null}

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {activeTab === "1" ? (
                <>
                  <Row className={styles.centerRow}>
                    <Col xs="12" sm="12">
                      <DatosVehiculoClasico
                        className={"none"}
                        vehiculo={vehiculo}
                        setVehiculo={setVehiculo}
                        type={type}
                        vehiculoOld={vehiculoOld}
                        mode={vehiculo.jato ? "JATO" : "MANUAL"}
                      />
                    </Col>
                    <Col xs="12" sm="12">
                      <Col xs="12" sm="6">
                        <DatePicker
                          //scrollableYearDropdown
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          minDate={minDate}
                          maxDate={maxDate}
                          className={`${styles.date}`}
                          selected={vehiculo?.fecha_matriculacion}
                          onChange={(element) =>
                            handleSimpleInput(
                              { target: { value: element } },
                              "fecha_matriculacion"
                            )
                          }
                          locale="es"
                          customInput={
                            vehiculo.fecha_matriculacion ? (
                              <Label>
                                {t("registrationDate")}
                                <Input
                                  value={moment(
                                    vehiculo?.fecha_matriculacion
                                  ).format("DD-MM-YYYY")}
                                />
                              </Label>
                            ) : (
                              <Label>
                                {t("registrationDate")}
                                <Input value={"Seleccione una fecha"} />
                              </Label>
                            )
                          }
                        />
                      </Col>
                    </Col>
                  </Row>

                  <Row className={styles.centerRow}>
                    <Col xs="12" sm="12">
                      <h4 className={classes.bold}> {t("characteristics")}</h4>
                    </Col>

                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("displacement")} </Label>
                      <Input
                        placeholder={t("displacement")}
                        className="form-control ds-input mt-1"
                        type="number"
                        disabled={vehiculo.jato}
                        value={vehiculo?.cilindrada}
                        min="0"
                        onChange={(e) => {
                          handleSimpleInput(e, "cilindrada");
                        }}
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("power")} *</Label>
                      <Input
                        placeholder={t("power")}
                        className="form-control ds-input mt-1"
                        type="number"
                        disabled={vehiculo.jato}
                        value={vehiculo?.potencia}
                        min="0"
                        onChange={(e) => {
                          handleSimpleInput(e, "potencia");
                        }}
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("co2Emissions")} </Label>
                      <Input
                        placeholder={t("co2Emissions")}
                        className="form-control ds-input mt-1"
                        type="number"
                        disabled={vehiculo.jato}
                        value={vehiculo.emisiones}
                        min="0"
                        onChange={(e) => {
                          handleSimpleInput(e, "emisiones");
                        }}
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("trunkCapacity")}</Label>
                      <Input
                        placeholder={t("trunkCapacity")}
                        className="form-control ds-input mt-1"
                        type="number"
                        disabled={vehiculo.jato}
                        value={vehiculo.maletero}
                        min="0"
                        onChange={(e) => {
                          handleSimpleInput(e, "maletero");
                        }}
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("consumption")}</Label>
                      <div style={{ display: "flex" }}>
                        <Input
                          placeholder={"Combi l/100"}
                          className="form-control ds-input mt-1"
                          type="number"
                          disabled={vehiculo.jato}
                          value={vehiculo.consumo_combi}
                          min="0"
                          onChange={(e) => {
                            handleSimpleInput(e, "consumo_combi");
                          }}
                        />
                        <Input
                          placeholder={"Road l/100"}
                          className="form-control ds-input mt-1"
                          type="number"
                          disabled={vehiculo.jato}
                          value={vehiculo.consumo_road}
                          min="0"
                          onChange={(e) => {
                            handleSimpleInput(e, "consumo_road");
                          }}
                        />
                        <Input
                          placeholder={"Urban l/100"}
                          disabled={vehiculo.jato}
                          className="form-control ds-input mt-1"
                          type="number"
                          min="0"
                          value={vehiculo.consumo_urban}
                          onChange={(e) => {
                            handleSimpleInput(e, "consumo_urban");
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("seats")}*</Label>
                      <Input
                        placeholder={t("seats")}
                        className="form-control ds-input mt-1"
                        type="number"
                        disabled={vehiculo.jato}
                        value={vehiculo.asientos}
                        min="1"
                        onChange={(e) => {
                          handleSimpleInput(e, "asientos");
                        }}
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("doors")}*</Label>
                      <Input
                        placeholder={t("doors")}
                        className="form-control ds-input mt-1"
                        type="number"
                        disabled={vehiculo.jato}
                        value={vehiculo.puertas}
                        min="1"
                        onChange={(e) => {
                          handleSimpleInput(e, "puertas");
                        }}
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("numberOfGears")}</Label>
                      <Input
                        placeholder={t("numberOfGears")}
                        className="form-control ds-input mt-1"
                        type="number"
                        disabled={vehiculo.jato}
                        value={vehiculo.marchas}
                        min="1"
                        onChange={(e) => {
                          handleSimpleInput(e, "marchas");
                        }}
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("ecoStiker")}</Label>
                      <div className={styles.filterAdjust}>
                        <Input
                          placeholder={t("ecoStiker")}
                          className="form-control ds-input mt-1 pointer"
                          type="select"
                          invalid={errors.ecoSticker === true}
                          value={vehiculo?.ecoSticker[0]?.value}
                          disabled={vehiculo.jato}
                          onChange={(e) => {
                            setVehiculo({
                              ...vehiculo,
                              ecoSticker: [
                                {
                                  label: e.target.selectedOptions[0].text,
                                  value: Number(e.target.value),
                                },
                              ],
                            });
                          }}
                        >
                          <option value={0}> </option>
                          <option value={1}>0</option>
                          <option value={2}>E</option>
                          <option value={3}>C</option>
                          <option value={4}>B</option>
                        </Input>
                      </div>
                    </Col>
                  </Row>
                  <Row className={styles.centerRow}>
                    <Col xs="12" sm="12">
                      <h4 className={classes.bold}>{t("vehicleCondition")}</h4>
                    </Col>

                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("mileage")}*</Label>
                      <Input
                        placeholder={t("mileage")}
                        className="form-control ds-input mt-1"
                        type="text"
                        value={
                          typeof vehiculo.km === "number"
                            ? thousandsFormat(vehiculo.km)
                            : vehiculo.km
                        }
                        invalid={vehiculo.km === ""}
                        onChange={(e) => {
                          handleNumberThousands(e, "km");
                        }}
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>
                        {t("officialBrandWarranty")}
                      </Label>
                      <div className={styles.filterAdjust}>
                        <Input
                          placeholder={t("officialBrandWarranty")}
                          className="form-control ds-input mt-1 pointer"
                          type="select"
                          invalid={errors.garantia === true}
                          value={vehiculo?.garantia[0]?.value}
                          onChange={(e) => {
                            setVehiculo({
                              ...vehiculo,
                              garantia: [
                                {
                                  label: e.target.selectedOptions[0].text,
                                  value: Number(e.target.value),
                                },
                              ],
                            });
                          }}
                        >
                          <option value={3}>Sí</option>
                          <option value={0}>No</option>
                        </Input>
                      </div>
                    </Col>
                    {vehiculo?.garantia[0]?.value === 3 ? (
                      <Col xs="12" sm="4">
                        <DatePicker
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className={`${styles.date}`}
                          selected={vehiculo.fecha_garantia}
                          onChange={(element) =>
                            handleSimpleInput(
                              { target: { value: element } },
                              "fecha_garantia"
                            )
                          }
                          locale="es"
                          customInput={
                            vehiculo.fecha_garantia ? (
                              <Label>
                                {"Fecha Fin Garantía Oficial Marca"}
                                <Input
                                  value={moment(vehiculo.fecha_garantia).format(
                                    "DD-MM-YYYY"
                                  )}
                                />
                              </Label>
                            ) : (
                              <Label>
                                {"Fecha Fin Garantía Oficial Marca"}
                                <Input value={"Seleccione una fecha"} />
                              </Label>
                            )
                          }
                        />
                      </Col>
                    ) : null}
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("provenance")}*</Label>
                      <div className={styles.filterAdjust}>
                        <Input
                          placeholder={t("provenance")}
                          className="form-control ds-input mt-1 pointer"
                          type="select"
                          invalid={errors.procedencia === true}
                          value={vehiculo?.procedencia[0]?.value}
                          onChange={(e) => {
                            setVehiculo({
                              ...vehiculo,
                              procedencia: [
                                {
                                  label: e.target.selectedOptions[0].text,
                                  value: Number(e.target.value),
                                },
                              ],
                            });
                          }}
                        >
                          <option value={1}>{t("rentWithoutDriver")}</option>
                          <option value={2}>{t("privateIndividual")}</option>
                          <option value={3}>{t("publicService")}</option>
                          <option value={4}>{t("drivingSchool")}</option>
                          <option value={5}>{t("fleet")} </option>
                          <option value={6}>{t("independent")}</option>
                          <option value={7}>{t("company")} </option>
                        </Input>
                      </div>
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("bodyColor")}*</Label>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "100%" }}>
                          <Select
                            color={"#FF5D0E"}
                            options={color.map((x, y) => {
                              return {
                                label: x.name,
                                value: x.id,
                              };
                            })}
                            style={{ width: "100%" }}
                            placeholder={t("bodyColor")}
                            values={vehiculo?.color}
                            noDataRenderer={() => (
                              <span style={{ textAlign: "center" }}>
                                Sin datos
                              </span>
                            )}
                            onChange={(e) => {
                              setVehiculo({
                                ...vehiculo,
                                color: e,
                              });
                            }}
                            className={`fix_selector_arrow `}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("upholstery")}</Label>
                      <Input
                        placeholder={t("upholstery")}
                        className="form-control ds-input mt-1"
                        value={vehiculo.tcolor}
                        invalid={vehiculo.tcolor === ""}
                        onChange={(e) => {
                          handleSimpleInput(e, "tcolor");
                        }}
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <DatePicker
                        //scrollableYearDropdown
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className={`${styles.date}`}
                        selected={vehiculo?.vto_itv}
                        onChange={(element) =>
                          handleSimpleInput(
                            { target: { value: element } },
                            "vto_itv"
                          )
                        }
                        locale="es"
                        customInput={
                          vehiculo.vto_itv ? (
                            <Label>
                              {t("itvExpirationDate")}
                              <Input
                                value={moment(vehiculo.vto_itv).format(
                                  "DD-MM-YYYY"
                                )}
                              />
                            </Label>
                          ) : (
                            <Label>
                              {t("itvExpirationDate")}
                              <Input value={"Seleccione una fecha"} />
                            </Label>
                          )
                        }
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <div style={{ display: "flex" }}>
                        <DatePicker
                          //scrollableYearDropdown
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className={`${styles.date}`}
                          selected={vehiculo.ultima_itv}
                          maxDate={Date.now()}
                          onChange={(element) =>
                            handleSimpleInput(
                              { target: { value: element } },
                              "ultima_itv"
                            )
                          }
                          locale="es"
                          customInput={
                            vehiculo.ultima_itv ? (
                              <Label>
                                {t("lastItvDate")}
                                <Input
                                  value={moment(vehiculo.ultima_itv).format(
                                    "DD-MM-YYYY"
                                  )}
                                />
                              </Label>
                            ) : (
                              <Label>
                                {t("lastItvDate")}
                                <Input value={"Seleccione una fecha"} />
                              </Label>
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>{t("itvResult")}</Label>
                      <div className={styles.filterAdjust}>
                        <Input
                          placeholder={t("itvResult")}
                          className="form-control ds-input mt-1 pointer"
                          type="select"
                          invalid={errors.resultado_itv === true}
                          value={vehiculo?.resultado_itv[0]?.value}
                          onChange={(e) => {
                            setVehiculo({
                              ...vehiculo,
                              resultado_itv: [
                                {
                                  label: e.target.selectedOptions[0].text,
                                  value: Number(e.target.value),
                                },
                              ],
                            });
                          }}
                        >
                          <option value={0}></option>
                          <option value={3}>{t("unfavorable")}</option>
                          <option value={2}>
                            {t("favorableWithMinorDefects")}
                          </option>
                          <option value={1}>{t("favorable")}</option>
                          <option value={4}>{t("negative")}</option>
                        </Input>
                      </div>
                    </Col>
                    <Col xs="12" sm="12">
                      <Label
                        style={{ cursor: "pointer", marginLeft: "30px" }}
                        check
                      >
                        <Input
                          type="checkbox"
                          onChange={() => {
                            setVehiculo({
                              ...vehiculo,
                              noItv: vehiculo.noItv ? false : true,
                            });
                          }}
                          checked={vehiculo.noItv}
                        />
                        {t("noItv")}
                      </Label>
                    </Col>
                    <Col xs="12" sm="12">
                      <Label
                        style={{ cursor: "pointer", marginLeft: "30px" }}
                        check
                      >
                        <Input
                          type="checkbox"
                          onChange={() => {
                            setVehiculo({
                              ...vehiculo,
                              km0: vehiculo.km0 ? false : true,
                            });
                          }}
                          checked={vehiculo.km0}
                        />
                        {t("zeroKm")}
                      </Label>
                    </Col>
                    <Col xs="12" sm="12">
                      <Label
                        style={{ cursor: "pointer", marginLeft: "30px" }}
                        check
                      >
                        <Input
                          type="checkbox"
                          onChange={() => {
                            setVehiculo({
                              ...vehiculo,
                              upropietario: vehiculo.upropietario
                                ? false
                                : true,
                            });
                          }}
                          checked={vehiculo.upropietario}
                        />
                        {t("singleOwner")}
                      </Label>
                    </Col>
                    <Col xs="12" sm="12">
                      <Label
                        style={{ cursor: "pointer", marginLeft: "30px" }}
                        check
                      >
                        <Input
                          type="checkbox"
                          onChange={() => {
                            setVehiculo({
                              ...vehiculo,
                              importado: vehiculo.importado ? false : true,
                            });
                          }}
                          checked={vehiculo.importado}
                        />
                        {t("imported")}
                      </Label>
                    </Col>
                    {/* {vehiculo?.importado ?
                  <>
                    <Col xs="12" sm="12">
                      <h6 className={classes.bold}> Vehiculo Importado</h6>
                    </Col>
                    <Col xs="12" sm="6">
                      <DatePicker
                        //scrollableYearDropdown
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className={`${styles.date}`}
                        selected={vehiculo.fecha_rematriculacion}
                        onChange={(element) =>
                          handleSimpleInput(
                            { target: { value: element } },
                            "fecha_rematriculacion"
                          )
                        }
                        locale="es"
                        customInput={
                          vehiculo.fecha_rematriculacion ? (
                            <Label>
                              {"Fecha de rematriculación"}
                              <Input
                                value={moment(vehiculo.fecha_rematriculacion).format(
                                  "DD-MM-YYYY"
                                )}
                              />
                            </Label>
                          ) : (
                            <Label>
                              {"Fecha de Rematriculación"}
                              <Input value={"Seleccione una fecha"} />
                            </Label>
                          )
                        }
                      />

                    </Col>
                    <Col xs="12" sm="6">
                      <Label className={"m-0"}>Nueva matricula</Label>
                      <Input
                        placeholder={"Nueva matricula"}
                        className="form-control ds-input mt-1"
                        type="number"
                        value={vehiculo.nueva_matricula}
                        invalid={vehiculo.nueva_matricula === ""}
                        onChange={(e) => {
                          handleSimpleInput(e, "nueva_matricula");
                        }}
                      />
                    </Col>
                  </>
                  : null} */}
                  </Row>
                  <Row className={styles.centerRow}>
                    <Col xs="12" sm="12">
                      <h4 className={classes.bold}> {t("location")}</h4>
                    </Col>

                    <Col xs="12" sm="4">
                      <Label className={"m-0"}> {t("location")}*</Label>
                      <Filter
                        className={styles.disabled}
                        key={"local"}
                        multi={false}
                        sendData={(name, value) => {
                          handleFilter(value, "local");
                        }}
                        parentValues={vehiculo?.local}
                        name={"Empresas"}
                        disabled={true}
                        query={"locales_todos"}
                      />
                    </Col>
                    {rol?.some(
                      (e) =>
                        e === RolType.TRADING_MANAGER_PILOT ||
                        e === RolType.TRADING_MANAGER ||
                        e === RolType.GENERAL_ADMIN
                    ) ? (
                      <Button
                        className="btn-flexicar mt-4 mr-5 left no-print"
                        onClick={() => {
                          setChangeLocal({ open: true });
                        }}
                      >
                        {t("moveVehicle")}
                      </Button>
                    ) : null}
                  </Row>
                  <div className={styles.centerButton}></div>
                </>
              ) : null}
            </TabPane>
            <TabPane tabId="2">
              <Gallery
                imagesPreviewUrls={imagesPreviewUrls}
                setImagesPreviewUrls={setImagesPreviewUrls}
                vehicle_id={vehiculo?.id}
                type={type}
              />
            </TabPane>
            <TabPane tabId="6">
              {vehiculo.jato ? (
                <Equipment
                  load={true}
                  vehiculo={vehiculo}
                  dontShowColapse={true}
                  equipment={equipment}
                  setEquipment={setEquipment}
                ></Equipment>
              ) : null}
            </TabPane>
            <TabPane tabId="3">
              {vehiculo.jato ? (
                <ExtrasCategory
                  extras={extras}
                  setExtras={setExtras}
                  state={{ checkLead: { extras: vehiculo.extras } }}
                  setState={{ setVehiculo }}
                  load={true}
                  vehiculo={vehiculo}
                  setVehiculo={setVehiculo}
                  dontShowColapse={true}
                  sendData={sendData}
                  setRetailPrice={setRetailPrice}
                  retailPrice={retailPrice}
                  price={price}
                  setPrice={setPrice}
                  extrasSelected={extrasSelected}
                  setExtrasSelected={setExtrasSelected}
                  extrasOld={extrasOld}
                  saveExtras={saveExtras}
                ></ExtrasCategory>
              ) : null}
            </TabPane>
            <TabPane tabId="4">
              <AdditionalInfo
                errors={errors}
                handleAdditionalInfo={handleAdditionalInfo}
                handleDocuments={handleDocuments}
                handleDeleteDocument={handleDeleteDocument}
                handleCheckCambeltReplace={handleCheckCambeltReplace}
                documents={documents}
                additionalInfo={additionalInfo}
                saveAdditionalInfo={saveAdditionalInfo}
                type={type}
                id={id}
              ></AdditionalInfo>
            </TabPane>
            <TabPane tabId="5">
              <Row className={styles.centerRow}>
                <Col xs="12" sm="12">
                  <h6 className={classes.bold}>{t("particular")}</h6>
                </Col>
                <Col xs="12" sm="4">
                  <Label className={"m-0"}>{t("cashPrice")}*</Label>
                  <Input
                    placeholder={t("cashPrice")}
                    className="form-control ds-input mt-1"
                    disabled= {disabledEditPrice()}
                    type="text"
                    value={
                      typeof vehiculo.pvp_contado === "number"
                        ? thousandsFormat(vehiculo.pvp_contado)
                        : vehiculo.pvp_contado
                    }
                    onChange={(e) => {
                      handleNumberThousands(e, "pvp_contado");
                    }}
                  />
                </Col>
                <Col xs="12" sm="4">
                  <Label className={"m-0"}>
                    {t("financedPrice")}*{" "}
                    {vehiculo?.oferta_particulares ? (
                      <small style={{ fontStyle: "italic", color: "#ff5d0e" }}>
                        {" "}
                        *precio tachado web
                      </small>
                    ) : null}
                  </Label>
                  <Input
                    placeholder={t("financedPrice")}
                    className="form-control ds-input mt-1"
                    type="text"
                    value={
                      typeof vehiculo.pvp_financiado === "number"
                        ? thousandsFormat(vehiculo.pvp_financiado)
                        : vehiculo.pvp_financiado
                    }
                    disabled = {disabledEditPrice()}
                    onChange={(e) => {
                      handleNumberThousands(e, "pvp_financiado");
                    }}
                  />
                </Col>

                <Col xs="12" sm="4">
                  <Label
                    style={{
                      cursor: "pointer",
                      marginLeft: "30px",
                      marginTop: "20px",
                    }}
                    check
                  >
                    <Input
                      type="checkbox"
                      disabled = {disabledEditPrice()}
                      onChange={() => {
                        setVehiculo({
                          ...vehiculo,
                          oferta_particulares: vehiculo.oferta_particulares
                            ? false
                            : true,
                          precio_oferta_particulares:
                            vehiculo.oferta_particulares
                              ? null
                              : vehiculo.precio_oferta_particulares,
                        });
                      }}
                      checked={vehiculo.oferta_particulares}
                    />
                    {t("offer")}
                  </Label>
                </Col>
                {vehiculo?.oferta_particulares ? (
                  <Col xs="12" sm="4">
                    <Label className={"m-0"}>{t("offer")}</Label>
                    <Input
                      placeholder={t("offer")}
                      className="form-control ds-input mt-1"
                      type="text"
                      value={
                        typeof vehiculo.precio_oferta_particulares === "number"
                          ? thousandsFormat(vehiculo.precio_oferta_particulares)
                          : vehiculo.precio_oferta_particulares
                      }
                      disabled = {disabledEditPrice()}
                      onChange={(e) => {
                        handleNumberThousands(e, "precio_oferta_particulares");
                      }}
                    />
                  </Col>
                ) : null}
                <Col xs="12" sm="12">
                  <h6 className={classes.bold}>{t("financing")}</h6>
                </Col>
                <Col xs="12" sm="4">
                  <Label className={"m-0"}>{t("minimumInterestRate")}</Label>
                  <Input
                    placeholder={"Tipo interes minimo"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={vehiculo.interes_min}
                    disabled={true}
                    onChange={(e) => {
                      handleSimpleInput(e, "interes_min");
                    }}
                  />
                </Col>
                <Col xs="12" sm="4">
                  <Label className={"m-0"}>{t("minimumMonths")}</Label>
                  <Input
                    placeholder={t("minimumMonths")}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={vehiculo.meses_min}
                    disabled={true}
                    onChange={(e) => {
                      handleSimpleInput(e, "meses_min");
                    }}
                  />
                </Col>
                <Col xs="12" sm="4">
                  <Label className={"m-0"}>
                    {t("maximumInitialContribution")}
                  </Label>
                  <Input
                    placeholder={t("maximumInitialContribution")}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={vehiculo.aportacion_maxi}
                    disabled={true}
                    onChange={(e) => {
                      handleSimpleInput(e, "aportacion_max");
                    }}
                  />
                </Col>

                <Col xs="12" sm="12">
                  <h6 className={classes.bold}>{t("supplies")}</h6>
                </Col>
                <Col xs="12" sm="4">
                  <Label className={"m-0"}>{t("transportAmount")}</Label>
                  <Input
                    placeholder={"Importe transporte"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={vehiculo.importe_transporte}
                    disabled={true}
                    onChange={(e) => {
                      handleSimpleInput(e, "importe_transporte");
                    }}
                  />
                </Col>
                <Col xs="12" sm="4">
                  <Label className={"m-0"}>{t("flexicarWarrantyAmount")}</Label>
                  <Input
                    placeholder={"Importe Garantía Flexicar"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={vehiculo.importe_garantia}
                    disabled={true}
                    onChange={(e) => {
                      handleSimpleInput(e, "importe_garantia");
                    }}
                  />
                </Col>
                <Col xs="12" sm="4">
                  <Label className={"m-0"}>{t("transferAmount")}</Label>
                  <Input
                    placeholder={"Importe trasnferencia"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={vehiculo.importe_trasnferencia}
                    disabled={true}
                    onChange={(e) => {
                      handleSimpleInput(e, "importe_transferencia");
                    }}
                  />
                </Col>
                <Col xs="12" sm="12">
                  <h6 className={classes.bold}>Precios de Operativa</h6>
                </Col>

                <Col xs="12" sm="4">
                  <Label className={"m-0"}>{t("purchasePrice")}</Label>
                  <Input
                    placeholder={t("purchasePrice")}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={vehiculo.precio_compra}
                    disabled={true}
                    onChange={(e) => {
                      handleSimpleInput(e, "precio_compra");
                    }}
                  />
                </Col>
                <Col xs="12" sm="4">
                  <Label
                    style={{ cursor: "pointer", marginLeft: "30px" }}
                    check
                  >
                    <Input
                      type="checkbox"
                      onChange={() => {
                        setVehiculo({
                          ...vehiculo,
                          libre_cargas: vehiculo.libre_cargas ? false : true,
                        });
                      }}
                      checked={vehiculo.libre_cargas}
                    />
                    {t("lienFree")}
                  </Label>
                </Col>
                <Col xs="12" sm="4">
                  <Label
                    style={{ cursor: "pointer", marginLeft: "30px" }}
                    check
                  >
                    <Input
                      type="checkbox"
                      onChange={() => {
                        setVehiculo({
                          ...vehiculo,
                          iva_deducible: vehiculo.iva_deducible ? false : true,
                        });
                      }}
                      checked={vehiculo.iva_deducible}
                    />
                    {t("deductibleVAT")}
                  </Label>
                </Col>

                <Col xs="12" sm="12">
                  <h6 className={classes.bold}> {t("operationType")}</h6>
                </Col>
                <Col xs="12" sm="4">
                  <Label
                    style={{
                      cursor: "pointer",
                      marginLeft: "30px",
                      marginTop: "20px",
                    }}
                    check
                  >
                    <Input
                      type="checkbox"
                      onChange={() => {
                        setVehiculo({
                          ...vehiculo,
                          compraventas: vehiculo.compraventas ? false : true,
                        });
                      }}
                      checked={vehiculo.compraventas}
                    />
                    <h5>{t("carDealers")}</h5>
                  </Label>
                  <MdHelp id="PopoverFocus" className="md-36 m-1" />
                  <UncontrolledPopover
                    placement="bottom"
                    target="PopoverFocus"
                    trigger="hover"
                  >
                    <PopoverHeader>{t("carDealers")}</PopoverHeader>
                    <PopoverBody>{t("carDealersText")}</PopoverBody>
                  </UncontrolledPopover>
                </Col>
                <Col xs="12" sm="4">
                  <Label
                    style={{
                      cursor: "pointer",
                      marginLeft: "30px",
                      marginTop: "20px",
                    }}
                    check
                  >
                    <Input
                      type="checkbox"
                      onChange={() => {
                        setVehiculo({
                          ...vehiculo,
                          gestion_venta: vehiculo.gestion_venta ? false : true,
                        });
                      }}
                      checked={
                        vehiculo.gestion_venta || vehiculo.tipo_contrato === 4
                      }
                      disabled={true}
                    />
                    <h5>{t("salesManagement")}</h5>
                  </Label>
                </Col>

                {vehiculo?.compraventas ? (
                  <>
                    <Col xs="12" sm="12">
                      <h6 className={classes.bold}>{t("carDealers")}</h6>
                    </Col>

                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>PVD</Label>
                      <Input
                        placeholder={"PVD Contado"}
                        className="form-control ds-input mt-1"
                        type="text"
                        value={
                          typeof vehiculo.pvd === "number"
                            ? thousandsFormat(vehiculo.pvd)
                            : vehiculo.pvd
                        }
                        onChange={(e) => {
                          handleNumberThousands(e, "pvd");
                        }}
                      />
                    </Col>

                    <Col xs="12" sm="4">
                      <Label
                        style={{
                          cursor: "pointer",
                          marginLeft: "30px",
                          marginTop: "20px",
                        }}
                        check
                      >
                        <Input
                          type="checkbox"
                          onChange={() => {
                            setVehiculo({
                              ...vehiculo,
                              oferta_compraventas: vehiculo.oferta_compraventas
                                ? false
                                : true,
                            });
                          }}
                          checked={vehiculo.oferta_compraventas}
                        />
                        {t("offer")}
                      </Label>
                    </Col>
                    {vehiculo?.oferta_compraventas ? (
                      <Col xs="12" sm="4">
                        <Label className={"m-0"}>Precio</Label>
                        <Input
                          placeholder={"Precio"}
                          className="form-control ds-input mt-1"
                          type="text"
                          value={
                            typeof vehiculo.precio_oferta_compraventas ===
                            "number"
                              ? thousandsFormat(
                                  vehiculo.precio_oferta_compraventas
                                )
                              : vehiculo.precio_oferta_compraventas
                          }
                          onChange={(e) => {
                            handleNumberThousands(
                              e,
                              "precio_oferta_compraventas"
                            );
                          }}
                        />
                      </Col>
                    ) : null}
                  </>
                ) : null}
                {vehiculo?.gestion_venta ? (
                  <>
                    <Col xs="12" sm="12">
                      <h6 className={classes.bold}>Gestion de venta</h6>
                    </Col>

                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>Precio estimado de venta</Label>
                      <Input
                        placeholder={"Precio estimado venta"}
                        className="form-control ds-input mt-1"
                        type="text"
                        value={
                          typeof vehiculo.precio_estimado === "number"
                            ? thousandsFormat(vehiculo.precio_estimado)
                            : vehiculo.precio_estimado
                        }
                        onChange={(e) => {
                          handleNumberThousands(e, "precio_estimado");
                        }}
                      />
                    </Col>
                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>Comision en caso de venta</Label>
                      <Input
                        placeholder={"Gastos y comisiones"}
                        className="form-control ds-input mt-1"
                        type="text"
                        value={
                          typeof vehiculo.comision_venta === "number"
                            ? thousandsFormat(vehiculo.comision_venta)
                            : vehiculo.comision_venta
                        }
                        onChange={(e) => {
                          handleNumberThousands(e, "comision_venta");
                        }}
                      />
                    </Col>

                    <Col xs="12" sm="4">
                      <Label className={"m-0"}>Penalización</Label>
                      <Input
                        placeholder={"Penalización"}
                        className="form-control ds-input mt-1"
                        type="text"
                        value={
                          typeof vehiculo.penalizacion === "number"
                            ? thousandsFormat(vehiculo.penalizacion)
                            : vehiculo.penalizacion
                        }
                        onChange={(e) => {
                          handleNumberThousands(e, "penalizacion");
                        }}
                      />
                    </Col>
                  </>
                ) : null}
                <Col xs="12" sm="12">
                  <br />
                </Col>
              </Row>
              <div className={styles.centerButton}></div>
            </TabPane>
          </TabContent>
        </div>

        {/* )} */}
      </Container>
      <ModalPreview
        imagePrincipal={imagesPreviewUrls[0]}
        modalPreview={modalPreview}
        handleSimpleInput={handleSimpleInput}
        togglePreview={togglePreview}
        vehiculo={vehiculo}
        setImpresion={setImpresion}
      />
      <ModalManual
        setBasicsOk={setBasicsOk}
        manual={manual}
        saveVehicleData={saveVehicleData}
        saveSalesData={saveSalesData}
        setActiveTab={setActiveTab}
        setManual={setManual}
        activeTab={activeTab}
        mode={saveChanges}
        saveExtras={saveExtras}
      />
      <ModalUpdateLocalVehicle
        changeLocal={changeLocal}
        setChangeLocal={setChangeLocal}
        createVehicleDraft={changeDealer}
      />
      <ModalCommentpdf
        vehiculo={vehiculo}
        handleSimpleInput={handleSimpleInput}
        gethtmltopdf={gethtmltopdf}
        reset={reset}
        publishVehicle={publishVehicle}
        impresion={impresion}
        setImpresion={setImpresion}
        type={type}
      />
    </div>
  );
};

export default VehicleValidation;
