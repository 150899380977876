import {
	getAfterSalesAppointments,
	getPurchaseAppointments,
	getSalesAppointments
} from "services/Appointments/AppointmentsService";

import {
	PURCHASE_FILTER_STATE,
	getPurchaseColorByLeadState,
	getPurchaseTypeNameByLeadState,
	purchaseFiltersConfig
} from "./purchase";

import {
	SELL_FILTER_STATE,
	getSellColorByLeadState,
	getSellTypeNameByLeadState,
	sellFiltersConfig
} from "./sell";
import {
	AFTER_SALES_FILTER_STATE,
	afterSalesFiltersConfig,
	getAfterSalesColorByLeadState,
	getAfterSalesTypeNameByLeadState
} from "./afterSale";

export const CALENDAR_CONFIGS = [
	{
		type: "venta",
		getEvents: getSalesAppointments,
		getChipName: (appointment) =>
			`${appointment.vehicle.brand} ${appointment.vehicle.model} / ${
				appointment.client.name ?? ""
			} ${appointment.client.surname1 ?? ""} ${appointment.client.surname2 ?? ""}`,
		getChipLink: (appointment) => `lead-venta/${appointment.lead.id}/${appointment.lead.clientId}`,
		getChipBgColor: (event) => getSellColorByLeadState(event.lead.state),
		filterTypes: SELL_FILTER_STATE,
		filtersConfig: sellFiltersConfig,
		getFilterType: getSellTypeNameByLeadState
	},
	{
		type: "compra",
		getEvents: getPurchaseAppointments,
		getChipName: (appointment) =>
			`${appointment.client.name ?? ""} ${appointment.client.surname1 ?? ""} ${
				appointment.client.surname2 ?? ""
			}`,
		getChipLink: (appointment) => `lead-tasacion/${appointment.lead.id}`,
		getChipBgColor: (event) => getPurchaseColorByLeadState(event.lead.state),
		filterTypes: PURCHASE_FILTER_STATE,
		filtersConfig: purchaseFiltersConfig,
		getFilterType: getPurchaseTypeNameByLeadState
	},
	{
		type: "post-venta",
		getEvents: getAfterSalesAppointments,
		getChipName: (appointment) => `
			${appointment.vehicle.make} 
			${appointment.vehicle.model} / 
			${appointment.client.fullName ?? ""}
		`,
		getChipLink: (appointment) =>
			`vehicle/${appointment.vehicle.id}/caso/${appointment.case.id}?tab=citas`,
		getChipBgColor: getAfterSalesColorByLeadState,
		filterTypes: AFTER_SALES_FILTER_STATE,
		filtersConfig: afterSalesFiltersConfig,
		getFilterType: getAfterSalesTypeNameByLeadState
	}
];
