import { useParams } from "react-router-dom";
import { Table } from "reactstrap";
import { MdOutlineDirectionsCarFilled, MdOutlineForum } from "react-icons/md";
import moment from "moment";



import styles from "./index.module.scss";

const CasesTable = ({ cases, insideVehicle = false }) => {

   const { id: vehicleId } = useParams();

	

	return (
		<div className={styles.casesTable}>
			<Table striped>
				<thead>
					<tr>
						<th>Fecha</th>
						{insideVehicle && <th>Agente</th>}
						{!insideVehicle && <th>Vehículo</th>}
						{!insideVehicle && <th>Matrícula</th>}
						<th>Cliente</th>
						<th>Teléfono</th>
						<th>Email</th>
						<th>Título</th>
						<th>Estado</th>
						<th>Importe</th>
						<th>Acciones</th>
					</tr>
				</thead>

				<tbody>
					{cases.map((mCase, idx) => (
						<tr key={`case-body-tr${idx}`}>
							<td data-label="Fecha">
								<span>{moment.utc(mCase.fecha).format("DD/MM/YYYY HH:mm")}</span>
							</td>
							{insideVehicle && (
								<td
									title={`${mCase.user} ${mCase.userApellido1} ${mCase.userApellido2}`}
									data-label="Agente"
								>
									<span>{`${mCase.user} ${mCase.userApellido1} ${mCase.userApellido2}`}</span>
								</td>
							)}
							{!insideVehicle && (
								<>
									<td
										title={`${mCase.marca} ${mCase.modelo}`}
										data-label="Vehículo"
									>
										<span>{`${mCase.marca} ${mCase.modelo}`}</span>
									</td>
									<td
										title={mCase.matricula}
										data-label="Matrícula"
									>
										<span>{mCase.matricula}</span>
									</td>
								</>
							)}
							<td
								title={mCase.nombre}
								data-label="Cliente"
							>
								<span>{mCase.nombre}</span>
							</td>
							<td
								title={mCase.telefono}
								data-label="Teléfono"
							>
								<span>{mCase.telefono}</span>
							</td>
							<td title={mCase.email} data-label="Email">
                               <span>{mCase.email}</span>
                           </td>
							<td
								title={mCase.titulo}
								data-label="Título"
							>
								<span>{mCase.titulo}</span>
							</td>
							<td
								title={mCase.estadoNombre}
								data-label="Estado"
							>
								<span>{mCase.estadoNombre}</span>
							</td>
							<td data-label="Importe">
								<span>{mCase.totalAmount}</span>
							</td>
							
									<td>
									<div>
										{<a
											href={`/main/vehicle/${mCase.id_vehiculo ?? vehicleId}/caso/${
												mCase.id
											}?tab=comentarios`}
										>
											<MdOutlineForum size={24} />
										</a>}	
										{!insideVehicle && (
											<a href={`/main/vehicle/${mCase.id_vehiculo}?tab=datos`}>
												<MdOutlineDirectionsCarFilled size={24} />
											</a>
										)}
									</div>
							
							</td>
							
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

export default CasesTable;
