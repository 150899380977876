import React, { useContext, useState } from "react";
import { Button, Form, Input, Label } from "reactstrap";
import { MdVpnKey } from "react-icons/md";
import { Redirect } from "react-router";

import styles from "./index.module.scss";
import { UserContext } from "contexts/UserContext";

const Login = () => {
  const { isAuthenticated, logIn } = useContext(UserContext);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  if (isAuthenticated) return <Redirect to="/main/admin" />;

  const onCredentialsInput = (e) => {
    e.persist();
    setCredentials((prevCred) => ({
      ...prevCred,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await logIn(credentials);
  };

  return (
    <div className={styles.loginWrapper}>
      <div>
        <Form onSubmit={onSubmit}>
          <h2>Acceso</h2>
          <hr />
          <div className="d-flex flex-column mb-3">
            <Label>Usuario</Label>
            <Input
              name="email"
              type="email"
              placeholder="Email"
              onChange={onCredentialsInput}
            />
          </div>
          <div className="d-flex flex-column mb-3">
            <Label>Contraseña</Label>
            <Input
              name="password"
              type="password"
              placeholder="Contraseña"
              onChange={onCredentialsInput}
            />
          </div>
          <div className="mt-4 d-flex justify-content-end">
            <Button className="btn-flexicar p-2">
              <span className="font-weight-bold">CONECTAR</span>
              <MdVpnKey
                className="ml-2"
                style={{ verticalAlign: "top" }}
                size={22}
              />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
