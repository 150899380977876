import instance from "./BaseService";

export const createPreparationComment = async (vehicleId, data) => {
  try {
    const response = await instance.post(
      `/api/vehicles/${vehicleId}/preparation-comments`,
      data
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const getPreparationComments = async ({ vehicleId, size, page }) => {
  try {
    const response = await instance.get(
      `/api/vehicles/${vehicleId}/preparation-comments`,
      {
        params: { size: size, page: page },
      }
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const getPreparationComment = async (commentId) => {
  try {
    const response = await instance.get(
      `/api/preparation-comments/${commentId}`
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const getTotalAmountPreparation = async (vehicleId) => {
  try {
    const response = await instance.get(
      `/api/vehicles/${vehicleId}/preparation-comments/totalAmount`
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const updatePreparationComment = async (commentId, dataComment) => {
  try {
    const response = await instance.put(
      `/api/preparation-comments/${commentId}`,
      dataComment
    );
    return response;
  } catch (e) {
    throw e;
  }
};

export const deletePreparation = async (commentId) => {
  try {
    const response = await instance.delete(
      `api/preparation-comments/${commentId}`
    );
    return response;
  } catch (e) {
    throw e;
  }
};
//VERIFICATION

export const getVerification = async ( vehicleId ) => {
  try {
    const response = await instance.get(
      `/api/vehicles/${vehicleId}/preparation-verification`
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const updateVerification = async ({ vehicleId, checklistIds, verify }) => {
  try {
    const response = await instance.put(
      `/api/vehicles/${vehicleId}/preparation-verification`,
      {
        status:verify,
        checklistIds:checklistIds
      }
    );
    return response;
  } catch (e) {
    throw e;
  }
};