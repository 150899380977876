import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import DropzoneFile from "components/DropzoneFile";
import DropzoneFileMulti from "components/DropzoneFileMulti";

const UploadNoCallComponent = ({ onFile, show, setShow, multiple, type, allowedFormats, maxLengthTitle }) => {
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [disableAccept, setDisableAccept] = useState(true);

  const dispose = () => {
    setShow(false);
    setFile(null);
    setFileName(null);
    setDisableAccept(true);
  };

  const onAccept = () => {
    if (multiple) {
      onFile(files);
    }
    else{
      onFile({ name: fileName, object: file });
    }
    dispose();
  };

  return (
    <Modal className="modal-xl" centered isOpen={show} toggle={dispose}>
      <ModalHeader toggle={dispose}>Adjuntar documento</ModalHeader>
      <ModalBody>
        {!multiple ? (
          <DropzoneFile
            file={file}
            setFile={setFile}
            fileName={fileName}
            setFileName={setFileName}
            allowedFormats={allowedFormats}
            maxLengthTitle={maxLengthTitle}
            setDisableAccept={setDisableAccept}
          />
        ) : (
          <DropzoneFileMulti files={files} setFiles={setFiles} type={type} />
        )}
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex", gap: "15px" }}>
          <button className="btn btn-flexicar-inverse m-0" onClick={dispose}>
            Cancelar
          </button>
          <button
            className="btn btn-flexicar-orange"
            onClick={onAccept}
            disabled={disableAccept}
          >
            Aceptar
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default UploadNoCallComponent;
