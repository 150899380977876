import React from "react";
import { post } from "services/axiosCalls";
import { API } from "services/urlConstants";
import errorCall from "services/errorCall";
import styles from "./index.module.scss";
import { successToast } from "helpers/toastFunctions";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import moment from "moment";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";

const CancelSignComponent = ({ setCancelSign, cancelSign, recall }) => {

  const { t } = useTranslation('myDocumentation');

  const onConfirm = async () => {
    try {
      const response = await post(API.DELETE_SIGN_DATE, {
        id: cancelSign.document.documentId,
      });
      if (!response.error) {
        recall();
        reset();
        successToast(t('digitalSign.cancelSuccess'));
      } else {
        successToast(t('digitalSign.cancelError'));
      }
    } catch (e) {
      errorCall(e);
    }
  };

  const reset = () => {
    setCancelSign({ open: false, document: null });
  };

  return (
    <Modal
      className="modal-xs"
      isOpen={cancelSign?.open}
      toggle={() => reset()}
    >
      <ModalHeader>{t('digitalSign.inProgress')}</ModalHeader>
      <ModalBody className="p-4">
        <p>
          {`${t('digitalSign.dateInProgress')}
          ${moment(cancelSign?.document?.sign_start_date).format(
            "DD-MM-yyyy hh:mm"
          )} ${t('digitalSign.pending')}`}
        </p>
        <Button
          className="btn-flexicar mt-4 mr-5 right no-print"
          onClick={() => onConfirm()}
        >
          {t('digitalSign.cancel')}
        </Button>
      </ModalBody>
      <div onClick={() => reset()} className={styles.close}>
        <MdClose title={t('digitalSign.close')} size={30} />
      </div>
    </Modal>
  );
};

export default CancelSignComponent;
