import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Container, Row, Col, Table } from "reactstrap";
import { AiOutlineAreaChart } from "react-icons/ai";
import { MdBookmarks, MdContacts, MdMessage } from "react-icons/md";
import { IoMdDocument } from "react-icons/io";

import styles from "./index.module.scss";
import TabPaneHeader from "../TabPaneHeader";
import { environment } from "config/environment.config";
import Loading from "views/Loading/Loading";
import PaginationFooter from "views/VehicleList/components/PaginationFooter";
import { get, post } from "services/axiosCalls";
import { API } from "services/urlConstants";
import errorCall from "services/errorCall";
import { getCurrentPage } from "helpers/currentPage";

const InteresVehiculo = ({ vehicle, launchModal }) => {
  const { id: vehicleId } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [mensajes_VO, setMensajes_VO] = useState([]);
  const [av_daysInStock, setAv_daysInStock] = useState(0);
  const [av_marcaInStock, setAv_marcaInStock] = useState(0);
  const [av_localInStock, setAv_localInStock] = useState(0);
  const [estados, setEstados] = useState([]);

  const limit = 5;
  const [leadsOffset, setLeadsOffset] = useState(0);
  const [vehicleLeads, setVehicleLeads] = useState({
    leads: [],
    totalLeads: 0,
  });

  const daysInStock = () => {
    let start = new Date(vehicle?.fecha_alta);
    let total_days = Math.round((new Date() - start) / (1000 * 60 * 60 * 24));
    return total_days;
  };

  const changePage = (offset, rowCount) => {
    setLeadsOffset(offset);
  };

  const mediaDif = (data) => {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      total += data[i].dif;
    }
    return Math.round(total / data.length);
  };

  const interesVehiculo = useCallback(async () => {
    try {
      const vehicleIdPayload = { vehicle_id: vehicleId };

      const [
        vehicleEstados,
        vehicleDaysInStock,
        vehicleMarcaInStock,
        vehicleLocalInStock,
        mensajeVO,
      ] = await Promise.all([
        post(API.GET_ESTADOS, vehicleIdPayload),
        get(API.GET_AV_DAYS_IN_STOCK),
        post(API.GET_AV_MARCA_IN_STOCK, vehicleIdPayload),
        post(API.GET_AV_LOCAL_IN_STOCK, vehicleIdPayload),
        post(API.CARGAR_MENSAJES_VO, vehicleIdPayload),
      ]);
      setEstados(vehicleEstados);
      setAv_daysInStock(mediaDif(vehicleDaysInStock));
      setAv_marcaInStock(mediaDif(vehicleMarcaInStock));
      setAv_localInStock(mediaDif(vehicleLocalInStock));
      setMensajes_VO(mensajeVO);
    } catch (e) {
      errorCall(e);
    }
  }, [vehicleId]);

  const getVehicleLeads = useCallback(
    async (vehicle_id, leadsOffset, limit) => {
      try {
        const response = await post(API.GET_VEHICLE_LEADS, {
          vehicle_id,
          offset: leadsOffset,
          limit,
        });

        if (response && response.data) {
          setVehicleLeads({
            leads: [...response.data],
            totalLeads: response.count,
          });
        }
      } catch (error) {
        errorCall(error);
      }
    },
    []
  );

  const renderMensajesVO = () => {
    return mensajes_VO.map(function (elem, idx) {
      const d = new Date(elem.fecha);
      return (
        <div key={`mensajesVO-${idx}`} className="chat-message">
          <div className="chat-top">
            <span className="right">
              {moment(d).format("DD-MM-YYYY HH:YY")}
            </span>
            <span>
              <a
                style={{ color: "#FF9800", textDecoration: "none" }}
                href={`${environment.REACT_HOST}/main/lead-venta/${elem.id}/${elem.id_cliente}`}
                className="mx-1"
              >
                {elem.nombre}
              </a>
            </span>
          </div>
          <div className="chat-bottom">
            <span>{elem.comentario}</span>
          </div>
        </div>
      );
    });
  };

  const renderLeads = () => {
    const { leads } = vehicleLeads;
    return (
      <div className={styles.alignFullWidth}>
        <Table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Estado</th>
              <th>Procedencia</th>
              <th>Nombre</th>
              <th>Teléfono</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {leads.length > 0 &&
              leads.map((lead, idx) => {
                return (
                  <tr
                    key={"lead" + idx}
                    style={
                      idx % 2
                        ? { background: "#ffffff" }
                        : { background: "rgba(242,242,242,0.5)" }
                    }
                  >
                    <td>{lead.fecha}</td>
                    <td>{lead.estado}</td>
                    <td>{lead.procedencia}</td>
                    <td>{lead.nombre}</td>
                    <td>{lead.telefono}</td>
                    <td>
                      <a
                        href={`${environment.REACT_HOST}/main/lead-venta/${lead.id}/${lead.id_cliente_lead}`}
                        title="Ver Lead"
                      >
                        <IoMdDocument size={36} color="#007bff" />
                      </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  };

  const getEstados = () => {
    return (
      <Table style={{ width: "100%", textAlign: "center" }}>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {estados.length > 0 &&
            estados.map((estado, idx) => {
              const d = new Date(estado.fecha);
              return (
                <tr
                  key={"estado" + idx}
                  style={
                    idx % 2
                      ? { background: "#ffffff" }
                      : { background: "rgba(242,242,242,0.5)" }
                  }
                >
                  <td>{moment(d).format("DD-MM-YYYY")}</td>
                  <td>
                    {estado.url && estado.reserva_act ? (
                      <a href={`${environment.REACT_HOST}/main${estado.url}`}>
                        {estado.estado}
                      </a>
                    ) : (
                      estado.estado
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  useEffect(() => {
    (async () => {
      await interesVehiculo();
      setIsLoading(false);
    })();
  }, [interesVehiculo]);

  useEffect(() => {
    getVehicleLeads(vehicleId, leadsOffset, limit);
  }, [getVehicleLeads, leadsOffset, limit, vehicleId]);

  if (isLoading) return <Loading />;

  return (
    <>
      <div>
        <TabPaneHeader
          launchModal={launchModal}
          title="INTERÉS GENERADO POR EL VEHÍCULO"
          buttonType="lead"
          vehicle={vehicle}
        />
        <Container className="p-0">
          <Row className="m-0">
            <Col className="col-md-6 col-xs-12">
              <div className={`clearfix ${styles.basicBox}`}>
                <span className="tittle_sub ml-2">Estadísticas</span>
                <span className="tittle-rigth mr-2">
                  <AiOutlineAreaChart
                    color="#fe9800"
                    size={35}
                    className="right"
                  />
                </span>
              </div>

              <div className="flex-container">
                <div>
                  <span className="fontL">{daysInStock()}</span>
                  <br />
                  días en Stock
                </div>
                <div>
                  <span className="fontL">{av_marcaInStock}</span>
                  <br />
                  media días {vehicle?.nombre_marca}
                </div>
                <div>
                  <span className="fontL">{av_daysInStock}</span>
                  <br />
                  media días Flexicar{" "}
                </div>
                <br />
                <div>
                  <span className="fontL">{av_localInStock}</span>
                  <br />
                  media {vehicle?.nombre_local}
                </div>
              </div>

              <div className={`clearfix ${styles.marginBox}`}>
                <span className="tittle_sub ml-2">Estados</span>
                <span className="tittle-rigth mr-2">
                  <MdBookmarks color="#fe9800" size={25} className="right" />
                </span>
              </div>

              {getEstados()}
            </Col>

            <Col className="col-md-6 col-xs-12">
              <div className={`clearfix ${styles.basicBox}`}>
                <span className="tittle_sub ml-2">Leads</span>
                <span className="tittle-rigth mr-2">
                  <MdContacts color="#fe9800" size={25} className="right" />
                </span>
              </div>
              <div className="right-align">
                <span className="green-text">Leads totales: </span>
                <span id="total-leads" className="blue-text">
                  {vehicleLeads.totalLeads}
                </span>
              </div>
              {vehicleLeads.totalLeads > 0 ? renderLeads() : ""}
              {vehicleLeads.totalLeads > 0 && (
                <PaginationFooter
                  paginationMin={leadsOffset}
                  total={vehicleLeads.totalLeads}
                  showNumb={limit}
                  elements={vehicleLeads.leads}
                  page={getCurrentPage(
                    leadsOffset,
                    limit,
                    vehicleLeads.totalLeads
                  )}
                  changePagination={changePage}
                />
              )}

              <div className={`clearfix ${styles.marginBox}`}>
                <span className="tittle_sub ml-2">Mensajes</span>
                <span className="tittle-rigth mr-2">
                  <MdMessage color="#fe9800" size={25} className="right" />
                </span>
              </div>
              {renderMensajesVO()}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default InteresVehiculo;
