import { Fragment, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Nav, NavItem, NavLink } from "reactstrap";

import useSearchParam from "hooks/useSearchParam";

/**
 * Tab.Headers Object
 *
 * @typedef {Object} Headers
 * @property {string} tabName - The label for the tab.
 * @property {string} searchParamValue - Value of the search parameters for the tab.
 */

/**
 * Tab Object
 *
 * @typedef {Object} Tab
 * @property {Headers} headers - The headers object of the tab
 * @property {React.ReactNode} props.tabs[].content - React component to display as content.
 */

/**
 * A component that displays tabbed content with headers and search parameters.
 *
 * @param {Object} props - The component props.
 * @param {string|number} props.id - An id for the tab portal.
 * @param {string} props.searchParam - The search param name used for the tab panel.
 * @param {Tab[]} props.tabs - An array of tab objects.

 * @returns {React.ReactNode} The rendered React component.
 */
const TabPanel = ({ id, searchParam, tabs }) => {
  const { getSearchParam, setSearchParam } = useSearchParam();
  const tabSearchParam = getSearchParam(searchParam);

  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    setHasRendered(true);
  }, []);

  return (
    <>
      <Nav tabs>
        {tabs.map(({ headers, content }) => (
          <Fragment key={`tab-panel-${id}-${headers.searchParamValue}`}>
            <NavItem>
              <NavLink
                className={
                  tabSearchParam === headers.searchParamValue ? "active" : ""
                }
                onClick={() =>
                  setSearchParam(searchParam, headers.searchParamValue)
                }
              >
                {headers.tabName}
              </NavLink>
            </NavItem>

            {hasRendered &&
              tabSearchParam === headers.searchParamValue &&
              createPortal(content, document.getElementById(id))}
          </Fragment>
        ))}
      </Nav>
      <div id={id} />
    </>
  );
};

export default TabPanel;
