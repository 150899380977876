import Locale from "constants/Locale";
const regexMatricula = (plate, locale) => {
  const regexPlatePT = /^((?:(?:(?![KWY])[A-Z]){2}\d{2}(?:(?![KWY])[A-Z]){2})|(?:\d{2}(?:(?![KWY])[A-Z]){2}\d{2})|(?:\d{2}\d{2}(?:(?![KWY])[A-Z]){2}))$/g
  const regexPlateES = /^[0-9]{4}[BCDFGHJKLMNPRSTVWXYZ]{3}$/g
  const parsePlate = plate.toUpperCase()
  if(locale===Locale.ES){
    return regexPlateES.test(parsePlate)
  }
  else {
    return regexPlatePT.test(parsePlate)
  }
};

export default regexMatricula;
