import { combineReducers } from "redux";
import authReducer from "./authReducer";
import exampleReducer2 from "./exampleReducer2";

const reducers = {
  auth: authReducer,
  exampleReducer2,
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  return appReducer(state, action);
};
export default rootReducer;
