import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { post } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";
import errorCall from "services/errorCall";
import { errorToast } from "../../../helpers/toastFunctions";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Locale from "constants/Locale";

import styles from "./index.module.scss";
import { Col, Container, Row, Button } from "react-bootstrap";

const ModalCheckTasacion = ({ state, setState }) => {

  const { i18n } = useTranslation();
  const { t } = useTranslation("fichaLeadsCompra");
  const checkLead = async (data) => {
    try {
      const callData = {
        telefono: data.data.trim(),
      };
      const response = await post(API.CHECK_EXIST, callData);

      const checkLeadInfo = {
        cliente: response.cliente !== 0 ? response.cliente : null,
        id_existente_lead: response.id_lead,
        id_agente: response.id_agente,
        nombre_agente: response.nombre_agente,
        mi_id: response.mi_id,
        estado_anterior: response.nombre_estado,
        vehicle_id: callData.vehiculo,
      };

      if (response.count !== 0) {
        setState({
          ...state,
          checkLead: {
            ...state.checkLead,
            ...checkLeadInfo,
          },
          search: data.data.length,
        });
      } else if (!checkLeadInfo.id_existente_lead && checkLeadInfo.cliente) {
        setState({
          ...state,
          checkLead: {
            ...state.checkLead,
            ...checkLeadInfo,
          },
          search: data.data.length,
          add_mode: true,
        });
      } else {
        if (data.data.length === 9) {
          setState({
            ...state,
            checkLead: {
              ...state.checkLead,
              telefono1: data.data.trim(),
              ...checkLeadInfo,
            },
            search: data.data.length,
            modal_check: false,
            add_mode: true,
          });
        }
        if (data.data.length === 7 || data.data.length === 6) {
          setState({
            ...state,
            checkLead: {
              ...state.checkLead,
              matricula: data.data.trim().toUpperCase(),
              ...checkLeadInfo,
            },
            search: data.data.length,
            modal_check: false,
            add_mode: true,
            search_matricula: true,
          });
        }
      }
    } catch (e) {
      errorCall(e);
      errorToast(
        <span>{t("errors.errorProperty")}</span>
      );
    }
  };

  const NewLead = async () => {
    setState({
      ...state,
      checkLead: {
        ...state.checkLead,
        id_cliente_lead: state.checkLead.cliente[0].id,
        cliente_id: state.checkLead.cliente[0].id,
        nombre: state.checkLead.cliente[0].nombre,
        telefono1: state.checkLead.cliente[0].telefono1.trim(),
        email: state.checkLead.cliente[0].email,
        telefono2: state.checkLead.cliente[0].telefono2,
        apellido1: state.checkLead.cliente[0].apellido1,
        apellido2: state.checkLead.cliente[0].apellido2,
        provincia: state.checkLead.cliente[0].provincia_id
          ? [
              {
                label: state.checkLead.cliente[0].provincia_nombre,
                value: state.checkLead.cliente[0].provincia_id,
              },
            ]
          : null,
        municipio: state.checkLead.cliente[0].municipio,
        matricula: state.checkLead.cliente[0].matricula,
      },
      modal_check: false,
      add_mode: true,
    });
  };

  let history = useHistory();
  const goback = () => {
    history.push(`/main/listaleads?section=todos`);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const validatePhoneAndPlate = (str) => {
    const regexPlatePT = /^((?:(?:(?![KWY])[A-Z]){2}\d{2}(?:(?![KWY])[A-Z]){2})|(?:\d{2}(?:(?![KWY])[A-Z]){2}\d{2})|(?:\d{2}\d{2}(?:(?![KWY])[A-Z]){2}))$/g
    const regexPlateES = /^[0-9]{4}[BCDFGHJKLMNPRSTVWXYZ]{3}$/g
    const parsePlate = str.toUpperCase()
         if (i18n.language === Locale.ES && (/^[5-9]{1}[0-9]{8}$/g.test(str) || regexPlateES.test(parsePlate))) {
          return true;
        }
         else if (i18n.language === Locale.PT && (/^[9287]{1}[0-9]{8}$/g.test(str) || regexPlatePT.test(parsePlate))) {
          return true;
        }
        else{
          return false;
        }
      } 


  return (
    <>
      <Modal className="modal-lg" isOpen={state.modal_check}>
        <ModalHeader toggle={goback} className={`${styles.modal_header}`}>
          {t('addLead')}
        </ModalHeader>
        <ModalBody className="p-4">
          <input
            readOnly={state.checkLead.cliente ? true : false}
            className={`form-control ${errors.data && "is-invalid"} `}
            placeholder={t('contactPlate')}
            {...register("data", {
              required: true,
              validate: validatePhoneAndPlate,
            })}
          />
          {errors.data?.type === "required" && (
            <div className={`${styles.error} mt-1`}>
              Introduza teléfono o matrícula
            </div>
          )}
          {errors.data && errors.data?.type !== "required" && (
            <div className={`${styles.error} mt-1`}>
              Teléfono o matrícula incorrectos
            </div>
          )}

          {state.checkLead.cliente && state.checkLead.cliente[0]?.leed_id ? (
            <div>
              <p>Ya existe un lead :</p>
              {state.checkLead.cliente.map((elm) => (
                <div key={elm.id}>
                  <p>
                    {elm.nombre} {elm.apellido1} {elm.apellido2} {elm.telefono1}{" "}
                    {elm.email} {elm.marca_name} {elm.modelo_name} {""}
                    <a href={`/main/lead-tasacion/${elm.leed_id}`}>
                      {!state.checkLead.captacion
                        ? "Editar lead"
                        : "Copiar Lead"}
                    </a>
                  </p>
                </div>
              ))}
            </div>
          ) : null}
          {state.checkLead.cliente && !state.checkLead.cliente[0]?.leed_id ? (
            <Container>
              <Row className="mt-2">
                <p>Ya existe el cliente:</p>
              </Row>
              <Row>
                <Col>
                  {state.checkLead.cliente[0] && (
                    <Row
                      className={`border rounded mb-1`}
                      key={state.checkLead.cliente[0].id}
                    >
                      <Row className="m-1">
                        <Col>
                          <h6>
                            Nombre: {state.checkLead.cliente[0].nombre}{" "}
                            {state.checkLead.cliente[0].apellido1}{" "}
                            {state.checkLead.cliente[0].apellido2}
                          </h6>
                        </Col>
                      </Row>
                      <Row className="mt-1 mx-1">
                        <Col>
                          <h6>
                            Teléfono: {state.checkLead.cliente[0].telefono1}
                          </h6>
                        </Col>
                        <Col>
                          <h6>Email: {state.checkLead.cliente[0].email}</h6>
                        </Col>
                      </Row>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <>
            {state.checkLead.cliente && state.checkLead.id_leed ? (
              <Button color="primary" type="submit" onClick={NewLead}>
                Agregar Nuevo Lead para este cliente
              </Button>
            ) : null}
            {state.checkLead.cliente && !state.checkLead.id_leed ? (
              <Button
                color="primary"
                type="submit"
                onClick={NewLead}
                className={`${styles.btn_custom}`}
              >
                Agregar Lead para este cliente
              </Button>
            ) : null}
            {!state.checkLead.cliente ? (
              <Button
                className={`${styles.btn_custom}`}
                type="submit"
                onClick={handleSubmit(checkLead)}
              >
                {t('addLead')}
              </Button>
            ) : null}
          </>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalCheckTasacion;
