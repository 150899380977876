import React, { useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { post } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import errorCall from "services/errorCall";
import { errorToast, warningToast } from "../../helpers/toastFunctions";
import { MdAccountCircle, MdPhone } from "react-icons/md";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { UserContext } from "contexts/UserContext";
import RolType from "constants/roles";
import { useTranslation } from 'react-i18next';
import Locale from "constants/Locale";

const ModalLead = ({ state, handleValues, launchModal, setState, user }) => {
  const { rol } = useContext(UserContext);
  const { i18n, t } = useTranslation('modalLead');


  const validatePhone = (str) => {
         if (i18n.language === Locale.ES && /^[679]{1}[0-9]{8}$/g.test(str)) {
          return true;
        }
         else if (i18n.language === Locale.PT && /^[9287]{1}[0-9]{8}$/g.test(str)) {
          return true;
        }
        else{
          return false;
        }
      } 

 
  const checkLead = async () => {
    const { checkLead,vehicle } = state;
    const vehicleId = checkLead.vehicle ? checkLead.vehicle : vehicle.id
    if (checkLead.nombre === "") {
      errorToast(<span>{t("errors.errorName")}</span>);
      return;
    }
    if (!validatePhone(checkLead.telefono)) {
      errorToast(<span>{t("errors.errorPhone")}</span>);
      return;
    }

    try {
      const callData = {
        telefono: checkLead.telefono.trim(),
        vehiculo: vehicleId,
        usuario: user,
      };
      const response = await post(API.CHECK_LEAD, callData);
      const checkLeadInfo = {
        cliente: response.cliente !== 0 ? response.cliente : null,
        id_existente_lead: response.id_lead,
        id_agente: response.id_agente,
        nombre_agente: response.nombre_agente,
        mi_id: response.mi_id,
        estado_anterior: response.nombre_estado,
        vehicle_id: callData.vehiculo,
      };
      setState({
        ...state,
        checkLead: {
          ...state.checkLead,
          ...checkLeadInfo,
        },
        modal_check: false,
      });
      if (response.cliente === 0) {
        // nuevo Cliente
        return setState({
          ...state,
          checkLead: {
            ...state.checkLead,
            ...checkLeadInfo,
          },
          modal_add: true,
        });
      }
      if (response.id_gvo_vehiculo === vehicleId) {
        // Cliente con lead en ese mismo vehiculo
        if (
          rol.includes(RolType.SALES_CALL_CENTER_AGENT) &&
          response.aviso_agente === 1 &&
          response.aviso_lead === 0
        ) {
          return setState({
            ...state,
            checkLead: {
              ...state.checkLead,
              ...checkLeadInfo,
            },
            modal_conflict: true,
          });
        }

        if (response.aviso_agente === 1 && response.aviso_lead === 0) {
          return warningToast(
            <span>
              <span>El cliente ya tiene un lead sobre este vehículo </span>
              <Link
                className="toast-link"
                to={`/main/lead-venta/${response.id_lead}/${response.cliente}`}
              >
                VER LEAD
              </Link>
            </span>
          );
        } else {
          return warningToast(
            <span>
              <span>El cliente ya tiene un lead sobre este vehículo </span>
              <Link
                className="toast-link"
                to={`/main/lead-venta/${response.id_lead}/${response.cliente}`}
              >
                VER LEAD
              </Link>
            </span>
          );
        }
      }
      if (
        response.aviso_agente === 1 &&
        rol.includes(RolType.SALES_CALL_CENTER_AGENT)
      ) {
        // Cliente con lead vinculado a otro agente distinto del usuario
        return setState({
          ...state,
          checkLead: {
            ...state.checkLead,
            ...checkLeadInfo,
          },
          modal_aviso: true,
          modal_conflict: true,
        });
      }
      return setState({
        ...state,
        checkLead: {
          ...state.checkLead,
          ...checkLeadInfo,
        },
        modal_add: true,
      });
    } catch (e) {
      errorCall(e);
      errorToast(
        <span>{t("errors.errorLead")}</span>
      );
    }
  };

  return (
    <Modal
      className="modal-lg"
      isOpen={state.modal_check}
      toggle={() => launchModal("modal_check", null, false)}
    >
      <ModalHeader className={styles.modal_header}>Agregar Lead</ModalHeader>
      <ModalBody className="p-4">
        <span className="flex">
          <MdAccountCircle className="md-36 m-1" size={40} />

          <input
            autoComplete="off"
            placeholder={"Nombre"}
            className="form-control ds-input mt-1"
            type="text"
            value={state.checkLead?.nombre}
            onChange={(e) => handleValues(e, "nombre", "checkLead")}
          />
        </span>
        <span className="flex">
          <MdPhone className="md-36 m-1" size={40} />
          <input
            autoComplete="off"
            placeholder={"Teléfono"}
            className="form-control ds-input mt-1"
            type="tel"
            value={state.checkLead?.telefono}
            onChange={(e) => handleValues(e, "telefono", "checkLead")}
          />
        </span>
      </ModalBody>
      <ModalFooter>
        <Button className={styles.btn_custom} onClick={() => checkLead()}>
          Agregar Lead
        </Button>
        <Button
          color="secondary"
          onClick={() => launchModal("modal_check", null, false)}
        >
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalLead;
