export const PURCHASE_FILTER_STATE = Object.freeze({
  APPOINTED: "citados",
  WON: "ganados",
  ABSENCE: "no asiste",
  ARCHIVED: "archivados",
  MANAGING: "en gestión",
});

export const purchaseFiltersConfig = [
  {
    filteri18n: "appointeds",
    filter: PURCHASE_FILTER_STATE.APPOINTED,
    color: "#f0a23c",
  },
  {
    filteri18n: "won",
    filter: PURCHASE_FILTER_STATE.WON,
    color: "#ff6252",
  },
  {
    filteri18n: "abscense",
    filter: PURCHASE_FILTER_STATE.ABSENCE,
    color: "#82a2dd",
  },
  {
    filteri18n: "archived",
    filter: PURCHASE_FILTER_STATE.ARCHIVED,
    color: "#2ecaac",
  },
  {
    filteri18n: "managing",
    filter: PURCHASE_FILTER_STATE.MANAGING,
    color: "#bfbfbf",
  },
];

export const PURCHASE_LEAD_STATES = Object.freeze({
  appointed: [12],
  absence: [5, 6, 32],
  won: [44, 28, 2],
  archived: [13, 3],
});

export const getPurchaseColorByLeadState = (leadState) => {
  if (PURCHASE_LEAD_STATES.appointed.includes(leadState)) return "#f0a23c";
  else if (PURCHASE_LEAD_STATES.won.includes(leadState)) return "#ff6252";
  else if (PURCHASE_LEAD_STATES.absence.includes(leadState)) return "#82a2dd";
  else if (PURCHASE_LEAD_STATES.archived.includes(leadState)) return "#2ecaac";
  return "#bfbfbf";
};

export const getPurchaseTypeNameByLeadState = (event) => {
  const leadState = event.lead.state;

  if (PURCHASE_LEAD_STATES.appointed.includes(leadState))
    return PURCHASE_FILTER_STATE.APPOINTED;
  else if (PURCHASE_LEAD_STATES.won.includes(leadState))
    return PURCHASE_FILTER_STATE.WON;
  else if (PURCHASE_LEAD_STATES.absence.includes(leadState))
    return PURCHASE_FILTER_STATE.ABSENCE;
  else if (PURCHASE_LEAD_STATES.archived.includes(leadState))
    return PURCHASE_FILTER_STATE.ARCHIVED;
  return PURCHASE_FILTER_STATE.MANAGING;
};
