import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { getVideos, patchVideo } from "services/youtubeApi.service";
import getRoles from "services/filters.service";
import Select from "react-select";
import styles from "./index.module.scss";
import { UserContext } from "contexts/UserContext";

import VideosTable from "./components/VideosTable/index";
import CheckVideoModal from "./components/CheckVideoModal";
import AddVideoModal from "./components/AddVideoModal";
import DeleteVideoModal from "./components/DeleteVideoModal";
import UpdateVideoModal from "./components/UpdateVideoModal";
import SearchFilterInput from "./components/SearchFilterInput";
import { FaFilter } from "react-icons/fa";

const VideoTutorial = () => {
  const { rol } = useContext(UserContext);
  const { user } = useContext(UserContext);

  const [videos, setVideos] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [adminRoles] = useState([1, 25]);
  const [isAdmin, setIsAdmin] = useState();
  const [videoApiData, setVideoApiData] = useState(null);
  const [modalAddVideo, setModalAddVideo] = useState(false);
  const [modalUpdateVideo, setModalUpdateVideo] = useState(false);
  const [modalCheckVideo, setModalCheckVideoHandler] = useState(false);
  const [modalDeleteVideo, setModalDeleteVideo] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [videosCount, setVideosCount] = useState(null);
  const [roles, setRoles] = useState(null);
  const [videoId, setvideoId] = useState(null);

  const [selectedRoles, setSelectedRoles] = useState([]);

  const checkAdmin = () => {
    return rol.some((item) => adminRoles.includes(item));
  };

  useEffect(() => {
    getVideos(`/videos`)
      .then((response) => {
        setVideos(response.data);
      })
      .catch((e) => {
        throw e;
      });
    checkAdmin() ? setIsAdmin(true) : setIsAdmin(false);
    getRoles(`/roles`)
      .then((response) => {
        setRoles(response);
      })
      .catch((e) => {
        throw e;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const visibilityHandler = (id, posted) => {
    patchVideo(`/videos/${id}`, { posted: !posted })
      .then((res) => {
        getVideos(`/videos`)
          .then((response) => {
            setVideos(response.data);
            setVideosCount(response.count);
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e) => {
        throw e;
      });
  };

  const setVideosHandler = (data) => {
    getVideos(`/videos`)
      .then((response) => {
        setVideos(data);
        setVideosCount(response.count);
      })
      .catch((e) => {
        throw e;
      });
  };

  const setModalAddVideoHandler = () => {
    setModalAddVideo(!modalAddVideo);
  };

  const setModalUpdateVideoHandler = () => {
    setModalUpdateVideo(!modalUpdateVideo);
  };

  const setModalDeleteVideoHandler = (id) => {
    setModalDeleteVideo(!modalDeleteVideo);
    setvideoId(id);
  };

  const editSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const updateVideoHandler = (data) => {
    data.roles.forEach((rol) => {
      rol["value"] = rol["id"];
      rol["label"] = rol["nombre"];
    });
    setVideoData(data);
    setModalUpdateVideoHandler();
  };

  const handleChange = (selectedRole) => {
    const rolesValues = selectedRole.map((currentRole) => parseInt(currentRole.value));
		setSelectedRoles(rolesValues);
  };

  return (
    <Container fluid>
      <Row className="m-1">
        <Col className={`col-2 mt-3 mb-4 ${styles.filtersContent}`}>
          <Row className="ml-2">
            <FaFilter size={20} color="#007bff" /> Filtros
          </Row>
          <Row className="ml-1">
            <SearchFilterInput
              className="w-25"
              videos={videos}
              editSearchTerm={editSearchTerm}
              searchTerm={searchTerm}
            />
          </Row>
          <Row className="ml-1">
            {isAdmin ? (
              <Select
                closeMenuOnSelect={false}
                className="w-100"
                isMulti
                placeholder="Roles"
                options={roles}
                onChange={handleChange}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "0.25rem",
                  colors: {
                    ...theme.colors,
                    primary25: "#FF5D0E",
                    primary: "#fe9800",
                  },
                })}
              />
            ) : (
              <></>
            )}
          </Row>
        </Col>
        <Col className="col-10">
          <Row>
            <Col className="col-12">
              <div className={`clearfix mt-3 mb-3 ${styles.container2}`}>
                <span className="tittle ml-4">AYUDA</span>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col className="col-3">
              {isAdmin && (
                <Button
                  outline
                  block
                  className="mt-2 mb-3 orange-btn"
                  onClick={setModalCheckVideoHandler}
                >
                  ✚ Añadir Video
                </Button>
              )}
            </Col>
            <Col className="col-3 mt-3">
              <h6 className={`text-success ${styles.videoCount}`}>
                Cantidad total: {videosCount}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <VideosTable
                visibilityHandler={visibilityHandler}
                setModalCheckVideoHandler={setModalCheckVideoHandler}
                updateVideoHandler={updateVideoHandler}
                setModalDeleteVideoHandler={setModalDeleteVideoHandler}
                isAdmin={isAdmin}
                videos={videos}
                videosCount={videosCount}
                setVideosCount={setVideosCount}
                selectedRoles={selectedRoles}
                searchTerm={searchTerm}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <div className="mt-5 mb-3 mx-5">
          <UpdateVideoModal
            modalUpdateVideo={modalUpdateVideo}
            modalUpdateVideoHandler={setModalUpdateVideoHandler}
            videoData={videoData}
            rol={rol}
            adminRoles={adminRoles}
            user={user}
            onVideosHandler={setVideosHandler}
          />
        </div>
      </Row>
      <Row>
        <div className="mt-5 mb-3 mx-5">
          <AddVideoModal
            modalAddVideo={modalAddVideo}
            setModalAddVideoHandler={setModalAddVideoHandler}
            videoApiData={videoApiData}
            onVideosHandler={setVideosHandler}
            user={user}
            rol={rol}
            adminRoles={adminRoles}
          />
        </div>
      </Row>
      <Row>
        <div className="mt-5 mb-3 mx-5">
          <CheckVideoModal
            modalCheckVideo={modalCheckVideo}
            setModalAddVideoHandler={setModalAddVideoHandler}
            setModalCheckVideoHandler={setModalCheckVideoHandler}
            setVideoApiData={setVideoApiData}
          />
        </div>
      </Row>
      <Row>
        <div className="mt-5 mb-3 mx-5">
          <DeleteVideoModal
            modalDeleteVideo={modalDeleteVideo}
            modalDeleteVideoHandler={setModalDeleteVideoHandler}
            setVideos={setVideos}
            videoId={videoId}
            rol={rol}
          />
        </div>
      </Row>
    </Container>
  );
};

export default VideoTutorial;
