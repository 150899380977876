import React from "react";
import Colors from "constants/Colors";
import styles from "./index.module.scss";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const TableRowDays = ({ agent, idx, changeDay, day }) => {
  return (
    <td>
      <span className={styles.container}>
        {agent[day] && agent.vacaciones === 1 ? (
          <div key={`${idx}a${day}`} className={styles.box}>
            <MdCheckBox
              size={40}
              color={Colors.disable}
              onClick={async () => await changeDay(idx, day)}
            />
          </div>
        ) : agent[day] ? (
          <div key={`${idx}b${day}`} className={styles.box}>
            <MdCheckBox
              size={40}
              color={Colors.primary}
              onClick={async () => await changeDay(idx, day)}
            />
          </div>
        ) : (
          <div key={`${idx}c${day}`} className={styles.box}>
            <MdCheckBoxOutlineBlank
              size={40}
              onClick={async () => await changeDay(idx, day)}
            />
          </div>
        )}
      </span>
    </td>
  );
};
export default TableRowDays;
