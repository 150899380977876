import { z } from "zod";

const preparationSchema = z.object({
  title: z
    .string()
    .min(1, { message: "Título es requerido" })
    .max(35, { message: "Titulo, máximo 35 caracteres" }),
  comment: z
    .string()
    .max(150, { message: "Comentario, máximo 150 caracteres" })
    .optional(),
  amount: z
    .number()
    .min(0, { message: "Coste, minimo 0 €" })
    .max(99999.99, { message: "Coste, máximo 99.999,99 €" })
    .optional(),
});

export default preparationSchema;
