import React, {useState} from "react";
import { Button, Toast, ToastBody, ToastHeader } from "reactstrap";

const DatosVehiculosPortales = ({
  vehiculo,
  setVehiculo,
  vehiculoOld,
}) => {
  const [show] = useState(true);

  const toggle = () => {
    setVehiculo({ ...vehiculo, modify: true });
  };




  return (
    <>
      {!vehiculoOld.jato ? (
        <div
          className="p-12 bg-danger my-12 rounded"
          style={{ marginBottom: "10px" }}
        >
          <Toast style={{ maxWidth: "100% " }} isOpen={show}>
            <ToastHeader>Aviso del sistema</ToastHeader>
            <ToastBody>
              {" "}
              {!vehiculo.modify ? (
                <div
                  className="row justify-content-between "
                  style={{ marginTop: ".25rem" }}
                >
                  <div className="col-md-8 col-sm-12">
                    <p>
                      Para modicar los datos tendrás que volver a introducir los
                      datos del vehiculo.
                    </p>
                  </div>

                  <div className="col-md-4 col-sm-12">
                    <Button
                      outline
                      color="danger"
                      size="sm"
                      onClick={toggle}
                      className="float-right"
                    >
                      Modificar
                    </Button>
                  </div>
                  <hr />
                </div>
              ) : null}
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <p>Marca: {vehiculoOld?.marca_vehiculo[0]?.label}</p>
                </div>
                <div className="col-md-4 col-sm-12">
                  <p>Modelo: {vehiculoOld?.modelo[0]?.label}</p>
                </div>
                <div className="col-md-4 col-sm-12">
                  <p>
                    Año de Matriculación: {vehiculoOld?.matriculacion[0]?.label}{" "}
                  </p>
                </div>
              </div>
              <div className="row" style={{ marginTop: ".25rem" }}>
                <div className="col-md-4 col-sm-12">
                  <p>Combustible: {vehiculoOld?.combustible[0]?.label}</p>
                </div>
                <div className="col-md-4 col-sm-12">
                  <p>Caja de cambios: {vehiculoOld?.ccambios[0]?.label}</p>
                </div>
                <div className="col-md-4 col-sm-12">
                  <p>Versión: {vehiculoOld?.version}</p>
                </div>
              </div>
            </ToastBody>
          </Toast>
        </div>
      ) : null}
     
    </>
  );
};

export default DatosVehiculosPortales;
