import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { FormGroup, Label, Input, Collapse } from "reactstrap";
import { post } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";

const EstadosVehiculos = ({ lead_id, estado, sendEstados }) => {
  const { t } = useTranslation("fichaLeadsCompra");

  const toggle = () => setIsOpen(!isOpen);
  const [isOpen, setIsOpen] = useState(false);

  const [estados, setEstados] = useState([]);

  const getEstadosVehiculo = async () => {
    const response = await post(API.GET_ESTADO_VEHICULO, { lead_id });
    await setEstados(response);
    await sendEstados(estados);
  };

  useEffect(() => {
    getEstadosVehiculo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkEstado = async (elm, option) => {
    let estadoUpdate = await estados.findIndex(
      (el) => el.id_tipo === elm.id_tipo && el.id_extra === elm.id_extra
    );
    estados[estadoUpdate] = {
      estado: option,
      id_extra: elm.id_extra,
      id_tipo: elm.id_tipo,
      nombre_extra: elm.nombre_extra,
      nombre_tipo: elm.nombre_tipo,
    };

    await sendEstados(estados);
  };

  return (
    <>
      {estados !== null ? (
        <>
          <>
            <div
              className="clearfix "
              style={{
                padding: ".7rem",
                backgroundColor: "#2C2C2C",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginBottom: "40px",
                marginTop: "1rem",
              }}
              onClick={toggle}
            >
              <span className="tittle ml-4">{t("vehicleState")}</span>

              {isOpen === false ? (
                <MdExpandMore size={25} color="red" />
              ) : (
                <MdExpandLess size={25} color="red" />
              )}
            </div>
            <Collapse isOpen={isOpen}>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <h5>Mecánica:</h5>
                  <hr />
                  <div className="container">
                    {estados
                      .filter((tipo) => tipo.id_tipo === 1)
                      .map((elm, idx) => (
                        <div key={`estados-mecanica-${idx}`} className="row">
                          <div className="col-md-12 col-sm-12">
                            <p style={{ fontWeight: "600" }}>
                              {elm.nombre_extra}:
                            </p>
                          </div>{" "}
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 0 ? true : ""
                                  }
                                  onClick={() => checkEstado(elm, 0)}
                                />{" "}
                                N/A
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 1 ? "checked" : ""
                                  }
                                  onClick={() => checkEstado(elm, 1)}
                                />{" "}
                                Bien
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 2 ? "checked" : ""
                                  }
                                  onClick={() => checkEstado(elm, 2)}
                                />{" "}
                                Mal
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 3 ? "checked" : ""
                                  }
                                  onClick={() => checkEstado(elm, 3)}
                                />{" "}
                                Regular
                              </div>
                            </Label>
                          </FormGroup>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <h5>Electricidad:</h5>
                  <hr />
                  <div className="container">
                    {estados
                      .filter((tipo) => tipo.id_tipo === 2)
                      .map((elm, idx) => (
                        <div key={`estados-electricidad-${idx}`} className="row">
                          <div className="col-md-12 col-sm-12">
                            <p style={{ fontWeight: "600" }}>
                              {elm.nombre_extra}:
                            </p>
                          </div>{" "}
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 0 ? true : ""
                                  }
                                  onClick={() => checkEstado(elm, 0)}
                                />{" "}
                                N/A
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 1 ? "checked" : ""
                                  }
                                  onClick={() => checkEstado(elm, 1)}
                                />{" "}
                                Bien
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 2 ? "checked" : ""
                                  }
                                  onClick={() => checkEstado(elm, 2)}
                                />{" "}
                                Mal
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 3 ? "checked" : ""
                                  }
                                  onClick={() => checkEstado(elm, 3)}
                                />{" "}
                                Regular
                              </div>
                            </Label>
                          </FormGroup>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <h5>Prueba en Conducción:</h5>
                  <hr />
                  <div className="container">
                    {estados
                      .filter((tipo) => tipo.id_tipo === 3)
                      .map((elm, idx) => (
                        <div key={`estados-prueba-conduccion-${idx}`} className="row">
                          <div className="col-md-12 col-sm-12">
                            <p style={{ fontWeight: "600" }}>
                              {elm.nombre_extra}:
                            </p>
                          </div>{" "}
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 0 ? true : ""
                                  }
                                  onClick={() => checkEstado(elm, 0)}
                                />{" "}
                                N/A
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 1 ? "checked" : ""
                                  }
                                  onClick={() => checkEstado(elm, 1)}
                                />{" "}
                                Bien
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <div className="col-md-4 col-sm-12 pointer">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 2 ? "checked" : ""
                                  }
                                  onClick={() => checkEstado(elm, 2)}
                                />{" "}
                                Mal
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label className="pointer" check>
                              <div className="col-md-4 col-sm-12">
                                <Input
                                  type="radio"
                                  name={elm.nombre_extra}
                                  defaultChecked={
                                    elm.estado === 3 ? "checked" : ""
                                  }
                                  onClick={() => checkEstado(elm, 3)}
                                />{" "}
                                Regular
                              </div>
                            </Label>
                          </FormGroup>
                        </div>
                      ))}
                  </div>
                </div>{" "}
              </div>
            </Collapse>{" "}
          </>
        </>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default EstadosVehiculos;
