import React from "react";
import { errorToast } from "helpers/toastFunctions";

const errorCall = (error) => {
  const status = error?.response?.status
    ? `Status ${error.response.status}`
    : "";
  const text = error?.response?.statusText ? error?.response?.statusText : "";
  const errorText = (
    <div>
      <p>{`Error ${status}`}</p>
      <p>{text}</p>
    </div>
  );
  errorToast(errorText);
};

export default errorCall;
