import { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import Select from "react-select";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import {
  MdEmail,
  MdLock,
  MdLockOpen,
  MdSend,
  MdSettingsPhone,
} from "react-icons/md";
import { RiSave3Fill, RiArrowGoBackFill } from "react-icons/ri";

import Mainlayout from "layouts/MainLayout";
import styles from "./index.module.scss";
import ModalEditPerson from "./ModalEditPerson";
import {
  getCompanies,
  getEmployeeInfo,
  getEmployeeType,
  getEmployeeLocales,
  getEmployeeBrands,
  getEmployeeCompanies,
  updatePersonCall,
  updateEmployeeCall,
  updateLocalsForEmployee,
  updateCommercialBrandsForEmployee,
  updateCompaniesForEmployee,
  getBrands,
} from "services/Employees/EditEmployeeService";
import { UserContext } from "contexts/UserContext";
import { errorToast, successToast } from "helpers/toastFunctions";
import MultiSelect from "components/MultiSelect";

const EditEmployee = () => {
  const { id } = useParams();
  const { user: employeeId } = useContext(UserContext);

  const history = useHistory();

  const [employee, setEmployee] = useState({
    id: 0,
    personId: 0,
    typeId: 0,
    email: "",
    resetPassword: false,
    isActive: false,
    tel1: "",
    tel2: "",
  });
  const [person, setPerson] = useState({
    id: 0,
    name: "",
    surname1: "",
    surname2: "",
    dni: "",
    birthDate: "",
  });

  const [localSelectOptions, setLocalSelectOptions] = useState([]);
  const [localesSelected, setLocalesSelected] = useState([]);
  const [employeeTypeSelectOptions, setEmployeeTypeSelectOptions] = useState(
    []
  );
  const [employeeTypeSelected, setEmployeeTypeSelected] = useState({});
  const [brandSelectOptions, setBrandSelectOptions] = useState([]);
  const [brandsSelected, setBrandsSelected] = useState([]);
  const [companySelectOptions, setCompanySelectOptions] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);

  const [showEditPersonModal, setShowEditPersonModal] = useState(false);

  const toSelectOptions = (values) =>
    values.map((x) => ({
      value: x.id,
      label: x.nombre,
    }));

  useEffect(() => {
    // Fetch locales
    (async () => {
      try {
        const localsOptions = toSelectOptions(
          await getEmployeeLocales(employeeId)
        );
        const sortedLocalsOptions = localsOptions.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        setLocalSelectOptions(sortedLocalsOptions);

        // Fetch employee types
        const employeeTypesOptions = toSelectOptions(
          await getEmployeeType(employeeId)
        );
        setEmployeeTypeSelectOptions(employeeTypesOptions);

        // Fetch marcas comerciales
        const brandsOptions = toSelectOptions(
          await getBrands()
        );
        setBrandSelectOptions(brandsOptions);

        // Fetch empresas
        const companiesOptions = toSelectOptions(
          await getCompanies(employeeId)
        );
        setCompanySelectOptions(companiesOptions);

        // Fetch employee
        const info = await getEmployeeInfo(id);

        // Set employee current locales
        setLocalesSelected(toSelectOptions(await getEmployeeLocales(id)));
        // Set employee current type
        setEmployeeTypeSelected(
          employeeTypesOptions.filter((x) => x.value === info.typeId)
        );
        // Set employee current brands
        setBrandsSelected(toSelectOptions(await getEmployeeBrands(id)));
        // Set employee current companies
        setCompaniesSelected(toSelectOptions(await getEmployeeCompanies(id)));

        setEmployee({
          id,
          personId: info.personId,
          typeId: info.typeId,
          email: info.email,
          resetPassword: info.resetPassword,
          isActive: info.isActive,
          tel1: info.telefono1,
          tel2: info.telefono2,
        });

        setPerson({
          id: info.personId,
          name: info.nombre,
          surname1: info.apellido1,
          surname2: info.apellido2,
          dni: info.dni,
          birthDate: new Date(info.fecha_nacimiento),
        });
      } catch (e) {
        errorToast("Error al cargar los datos del empleado");
      }
    })();
  }, [id, employeeId]);

  const updatePerson = async () => {
    // Update person data
    try {
      await updatePersonCall(person.id, {
        ...person,
        birthDate: person.birthDate.toLocaleDateString("es-ES"),
      });
      successToast("Datos actualizados correctamente");
    } catch (e) {
      errorToast("Error al actualizar los datos de la persona");
    }
  };

  const updateEmployee = async (e) => {
    e.preventDefault();
    // Update employee data
    try {
      await updateEmployeeCall(employee.id, employee);
      await updateLocalsForEmployee(employee.id, {
        localesId: localesSelected.map((x) => x.value),
      });
      await updateCommercialBrandsForEmployee(employee.id, {
        commercialBrandsId: brandsSelected.map((x) => x.value),
      });
      await updateCompaniesForEmployee(employee.id, {
        companiesId: companiesSelected.map((x) => x.value),
      });
      successToast("Datos actualizados correctamente");
    } catch (e) {
      errorToast("Error al actualizar los datos del empleado");
    }
  };

  return (
    <Mainlayout>
      <Mainlayout.Content full contentTitle="EDITAR EMPLEADO">
        <div
          className={`col-12 col-lg-10 d-flex flex-column align-self-center ${styles.card} `}
        >
          <a
            className="btn orange-btn ml-3 mb-4 maxContent"
            target="_blank"
            rel="noopener noreferrer"
            href={`/main/roles-usuario/${id}`}
          >
            <MdLockOpen size={24} /> Ver roles
          </a>

          <Form onSubmit={updateEmployee}>
            <FormGroup className="d-flex flex-row flex-wrap">
              <FormGroup className="col-12 col-lg-6">
                <label>Seleccione el local al que pertenece</label>

                <MultiSelect
                  placeholder="Seleccionar..."
                  options={localSelectOptions}
                  value={localesSelected}
                  onChange={(options) => setLocalesSelected(options)}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <Label>Seleccione el tipo de empleado</Label>
                <Select
                  placeholder="Seleccionar..."
                  options={employeeTypeSelectOptions}
                  value={employeeTypeSelected}
                  onChange={(option) => {
                    setEmployeeTypeSelected(option);
                    setEmployee((prevEmployee) => ({
                      ...prevEmployee,
                      typeId: option.value,
                    }));
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <Label>Seleccione una o varias Marcas Comerciales</Label>
                <MultiSelect
                  placeholder="Seleccionar..."
                  options={brandSelectOptions}
                  value={brandsSelected}
                  onChange={(options) => setBrandsSelected(options)}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <Label>Seleccione una o varias Empresas</Label>
                <MultiSelect
                  placeholder="Seleccionar..."
                  options={companySelectOptions}
                  value={companiesSelected}
                  onChange={(options) => setCompaniesSelected(options)}
                />
              </FormGroup>
            </FormGroup>

            <blockquote>Datos de acceso</blockquote>

            <FormGroup className="d-flex flex-row flex-wrap">
              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdEmail size={24} />
                  <Label>Email*</Label>
                </div>
                <Input
                  required
                  type="email"
                  placeholder="example@example.com"
                  value={employee.email}
                  onChange={(e) => {
                    e.persist();
                    setEmployee((prevEmployee) => ({
                      ...prevEmployee,
                      email: e.target.value,
                    }));
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdLock size={24} />
                  <Label>Password*</Label>
                </div>
                <div className={styles.switchWrapper}>
                  <Label for="reset-password-switch">Off</Label>
                  <label className={styles.switch}>
                    <input
                      type="checkbox"
                      id="reset-password-switch"
                      checked={employee.resetPassword ? true : false}
                      onChange={() =>
                        setEmployee((prevEmployee) => ({
                          ...prevEmployee,
                          resetPassword:
                            prevEmployee.resetPassword === 1 ? 0 : 1,
                        }))
                      }
                    />
                    <span className={styles.slider}></span>
                  </label>
                  <Label for="reset-password-switch">Resetear contraseña</Label>
                </div>
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdSettingsPhone size={24} />
                  <Label>Teléfono 1*</Label>
                </div>
                <Input
                  required
                  type="tel"
                  value={employee.tel1}
                  onChange={(e) => {
                    e.persist();
                    setEmployee((prevEmployee) => ({
                      ...prevEmployee,
                      tel1: e.target.value,
                    }));
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdSettingsPhone size={24} />
                  <Label>Teléfono 2*</Label>
                </div>
                <Input
                  type="tel"
                  value={employee.tel2}
                  onChange={(e) => {
                    e.persist();
                    setEmployee((prevEmployee) => ({
                      ...prevEmployee,
                      tel2: e.target.value,
                    }));
                  }}
                />
              </FormGroup>
              <div className={`col-12 ${styles.switchWrapper}`}>
                <Label for="state-employee-switch">Desactivar empleado</Label>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    id="state-employee-switch"
                    checked={employee.isActive ? true : false}
                    onChange={() =>
                      setEmployee((prevEmployee) => ({
                        ...prevEmployee,
                        isActive: prevEmployee.isActive === 1 ? 0 : 1,
                      }))
                    }
                  />
                  <span className={styles.slider}></span>
                </label>
                <Label for="state-employee-switch">Activar Empleado</Label>
              </div>
            </FormGroup>

            <div className={styles.info_panel}>
              <span>
                La password se configura por defecto a "Flexic@r2023" sin
                comillas. Cuando el usuario inicie sesión por primera vez, le
                aparecerá un formulario para cambiar su password.
              </span>
            </div>

            <blockquote>Datos personales</blockquote>

            <div className={`${styles.info_panel} mb-4`}>
              <span>
                Al editar a un empleado sólo le podras cambiar de identidad por
                una persona que ya exista. Para editar los datos personales de
                la persona asociada al empleado hacer click en "Editar sus datos
                personales" Los empleados no se pueden eliminar, sólo
                desactivar.
              </span>
            </div>

            <FormGroup className="col-12 d-flex flex-row flex-wrap flex-wrap justify-content-around align-items-center mt-4 mt-lg-2">
              <label>{`${person.name} ${person.surname1} ${person.surname2}`}</label>
              <Button
                outline
                className="col-12 col-lg-5 mt-2 orange-btn"
                onClick={() => setShowEditPersonModal(true)}
              >
                EDITAR SUS DATOS PERSONALES
              </Button>
            </FormGroup>

            <div className="d-flex justify-content-end pt-3">
              <Button outline className="orange-btn" type="submit">
                <span>ACTUALIZAR</span>
                <MdSend className="ml-2 mb-1" size={24} />
              </Button>
            </div>
          </Form>
        </div>
        <Button
          color="primary"
          className={styles.back_icon}
          onClick={() => history.goBack()}
        >
          <RiArrowGoBackFill size={30} role="button" />
        </Button>
        <Button
          color="success"
          className={styles.save_icon}
          onClick={updateEmployee}
        >
          <RiSave3Fill size={30} role="button" />
        </Button>

        <ModalEditPerson
          show={showEditPersonModal}
          setShow={setShowEditPersonModal}
          person={person}
          setPerson={setPerson}
          updatePerson={updatePerson}
        />
      </Mainlayout.Content>
    </Mainlayout>
  );
};

export default EditEmployee;
