import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";

import styles from "./index.module.scss";
import {  post } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import ModalLead from "views/NewLead/ModalCheckTasacion/ModalCheckTasacion";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../helpers/toastFunctions";
import FormLead from "views/NewLead/FormLead/FormLead";
import classnames from "classnames";
import getLastLeadId from "../../helpers/getLastLeadId";
import TableTasacionesPrev from "views/NewLead/TableTasacionesPrev/TableTasacionesPrev";
import ModalPrintPercha from "components/ModalPrintPercha";
import { UserContext } from "contexts/UserContext";
import { Link, useParams } from "react-router-dom";
import { environment } from "config/environment.config";
import RolType from "constants/roles";
import { useTranslation } from 'react-i18next';
import Locale from "constants/Locale";



const NewLead = ({ props }) => {
  const { id } = useParams();
  const { user, local, rol } = useContext(UserContext);
  const [localActual, setLocalActual] = useState(null);
  const history = useHistory();
  const { i18n, t } = useTranslation("newLead");

  const lead_id = id;

  const [modalPrintPercha, setModalPrintPercha] = useState();

  const [state, setState] = useState({
    modal_check: true,
    add_mode: false,
    modal_conflict: false,
    edit_mode: false,
    search: "",
    checkLead: {
      toggle: false,
      nombre: null,
      telefono1: null,
      cliente: null,
      cliente_id: null,
      id_cliente_lead: null,
      id_existente_lead: null,
      condiciones: false,
      comercial: false,
      provincia: null,
      municipio: null,
      estado: null,
      origen: null,
      forma_contacto: null,
      marca_comercial: null,
      email: null,
      telefono2: null,
      apellido1: null,
      apellido2: null,
      kilometros: null,
      importado: false,
      matricula: null,
      bastidor: null,
      valoracion: null,
      tasacion_max: null,
      tasacion_min: null,
      buscado: null,
      pactado: null,
      comision: null,
      adelanto: null,
      url_anuncio: null,
      comentarios: null,
      combustible: null,
      ccambios: null,
      itv: null,
      cita: null,
      local: null,
      carroceria: null,
      captacion: rol.map((elm) => elm === 23 || elm === 24).includes(true)
        ? 1
        : 0,
      extras: [],
      estados: [],
    },
  });
  const [statusChecker, setStatusChecker] = useState(false);
  const [editLead, setEditLead] = useState(false);
  const [mercedes, setMercedes] = useState(false);
  const [vehiculo, setVehiculo] = useState({
    marca_vehiculo: "",
    modelo: "",
    matriculacion: "",
    combustible: "",
    ccambios: "",
    carroceria: "",
    version: "",
    jato: i18n.language === Locale.ES ? true : false,
    id_jato: null,
    modify: false,
  });

  const [vehiculoOld, setVehiculoOld] = useState({
    marca_vehiculo: null,
    modelo: null,
    matriculacion: null,
    combustible: null,
    ccambios: null,
    carroceria: null,
    version: null,
  });

  const [retailPrice, setRetailPrice] = useState(null);
  const [price, setPrice] = useState(null);
  const [tasacionesCount, setTasacionesCount] = useState(null);

  useEffect(() => {
    if (props.location.copyLead) {
      setState({
        ...props.location.copyLead,
        edit_mode: false,
        add_mode: true,
        checkLead: {
          ...props.location.copyLead.checkLead,
          id_existente_lead: null,
          captacion: rol
            .map(
              (elm) =>
                elm === RolType.CUSTOMER_CATCHER ||
                elm === RolType.CUSTOMER_CATCHER_SUPERVISOR
            )
            .includes(true)
            ? 1
            : 0,
          agente: [{ value: user }],
        },
      });
      if (!props.location.jato) {
        setVehiculoOld(props.location.vehiculoOld);
        setVehiculo(props.location.vehiculo);
      }
      if (props.location.jato) {
        setVehiculo(props.location.vehiculo);
      }
      setEditLead(true);
    }

    if (!props.location.copyLead) {
      check_mode();
    }

    if (rol.map((elm) => elm === 35).includes(true)) {
      setMercedes(true);
      setState({
        ...state,
        modal_check: false,
        add_mode: true,
        checkLead: {
          ...state.checkLead,
          nombre: "Defecto",
          telefono1: "666666666",
          cliente: null,
          cliente_id: 1,
          id_cliente_lead: 1,
          condiciones: false,
          comercial: false,
          provincia: [{ label: "Madrid", value: 15 }],
          estado: [{ label: "Formulario Nuevo", value: 1 }],
          origen: [{ label: "B2B", value: 36 }],
          forma_contacto: [{ label: "Presencial", value: 4 }],
          //marca_comercial: [{ label: 'GRUPO FERPER VW', value: 14 }],
          email: "defecto@flexicar.es",
          apellido1: "Defecto",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  
  const loadLocalPresencial = async () => {
    if (!rol?.some((e) => e === RolType.CALL_CENTER)) {
      try {
        const response = await post(`/api/get_user_local`, { user });
        setLocalActual([response[0]]);
      } catch (err) {
        errorToast(err.message);
      }
    }
  };
  
  const loadLocal = (local) => {
    if (local) {
      setLocalActual(local)
    }
    else {
      loadLocalPresencial()
    }
  }
  const getLead = async () => {
    const response = await post(API.GET_LEAD, { lead_id });
    loadLocal(response[0].local_actual)
    setState({ ...state, edit_mode: true, checkLead: response[0] });
    setVehiculo(response[1]);
    if (response[1].jato === false && !response[1].modelo[0]?.value) {
      setVehiculo({
        ...vehiculo,
        jato: i18n.language === Locale.ES 
      })
      setVehiculoOld({
        marca_vehiculo: response[0].marca_vehiculo,
        modelo: response[0].modelo,
        matriculacion: response[0].matriculacion,
        combustible: response[0].combustible,
        ccambios: response[0].ccambios,
        carroceria: response[0].carroceria,
        version: response[0].version_old,
        jato: response[0].jato
      });
    }
  }

  const check_mode = async (key) => {
    if (lead_id && !state.checkLead.id_existente_lead) {
      await getLead()
    }
    else {
      loadLocalPresencial()
    };
  }

  const createLead = async (createCbImageUpload) => {
    const { checkLead } = state;
    const PRESENCIAL= 4
    if (checkLead.estado === "" || checkLead.estado === null) {
      return errorToast(<span>{t("errors.errorLeadSelected")}</span>);
    }
    if (checkLead.origen === "" || checkLead.origen === null) {
      return errorToast(<span>{t("errors.errorLeadSource")}</span>);
    }
    if (checkLead.forma_contacto === "" || checkLead.forma_contacto === null) {
      return errorToast(<span>{t("errors.errorContactForm")}</span>);
    }
    if (
      checkLead.marca_comercial === "" ||
      checkLead.marca_comercial === null
    ) {
      return errorToast(<span>{t("errors.errorCommercialBrand")}</span>);
    }
    if (checkLead.nombre === "" || checkLead.nombre === null) {
      return errorToast(<span>{t("errors.errorName")}</span>);
    }
    if (
      checkLead.telefono1 === "" ||
      checkLead.telefono1 === null ||
      checkLead.telefono1.length !== 9
    ) {
      return errorToast(<span>{t("errors.errorPhone")}</span>);
    }

    if (checkLead.origen === "" || checkLead.origen === null) {
      return errorToast(<span>{t("errors.errorLeadOrigin")}</span>);
    }
    if ((checkLead.origen && checkLead.origen[0].value === PRESENCIAL) && !localActual) {
      return errorToast(<span>{t("errors.errorLocal")}</span>);
    }
    if (vehiculo.marca_vehiculo === "" || vehiculo.marca_vehiculo === null) {
      return errorToast(<span>{t("errors.errorVehicleBrand")}</span>);
    }
    if (vehiculo.modelo === "" || vehiculo.modelo === null) {
      return errorToast(<span>{t("errors.errorVehicleModel")}</span>);
    }

    try {
      const dataCall = {
        data: checkLead,
        agente: state.checkLead.agente ? state.checkLead.agente[0].value : user,
        id_agente_modify: user,
        rol: rol,
        vehiculo: {
          ...vehiculo,
          id_jato: (vehiculo.jato && vehiculo.version[0]?.value) ? vehiculo.version[0].value : null
        },
        extras: JSON.stringify(checkLead.extras),
        estados: checkLead.estados?.filter((el) => el.estado !== 0),
        precio_nuevo: retailPrice,
        precio_final: price,
        id_local_actual: localActual ? localActual[0].value : null
      };
      const email = checkLead.email;
      const telefono = checkLead.telefono1;

      const cliente = await post(API.CHECK_CLIENTE_UPDATE, { email, telefono });

      if (
        cliente.length === 0 ||
        cliente[0]?.id === state.checkLead?.id_cliente_lead
      ) {
        const response = await post(API.CREATE_LEAD_TASACION, dataCall);

        setState({
          ...state,
          modal_add: false,
          modal_check: false,
        });
        if (response.status === "update") {

          setState({
            ...state,
            checkLead: {
              ...state.checkLead,
              estado: [{ label: response.lead_status.nombre, value: response.lead_status.id }],
            },
          });
          
          successToast("Lead modificado correctamente");
        } else if (response.status === "create") {
          await createCbImageUpload(undefined, response.id_lead);
          history.push(`/main/lead-tasacion/${response.id_lead}`);
          window.location.reload(false);
        } else if (response.status === "ok") {
          warningToast("No hay nada que actualizar");
        }
      } else {
        return errorToast(
          <span>{t("errors.errorEmailPhone")}</span>,
        );
      }
    } catch (e) {
      errorToast(<span>{t("errors.errorCreateLead")}</span>);
    }
  };

  const launchPrintView = () => {
    setModalPrintPercha({ open: true });
  };

  const handleValues = (e, field, type) => {
    let val = e.target.value;
    if (field === "valoracion" || field === "buscado" || field === "tasacion_precio_preliminar") {
      if (val === "") {
        val = null;
      } else {
        val = parseFloat(val);
      }
    }
    setState({
      ...state,
      [type]: {
        ...state[type],
        [field]: val,
      },
    });
  };
  const launchModal = (modal, change) => {
    setState({
      ...state,
      [modal]: change,
      checkLead: {
        ...state.checkLead,
        cliente: null,
      },
    });
  };
  const getData_CheckBox = (value, filter) => {
    setState({
      ...state,
      checkLead: {
        ...state.checkLead,
        [filter]: value,
      },
    });
  };

  //Recibe data de los componentes Dropdown_Dc
  const getDataAdd = (filter, value) => {
    setState({
      ...state,
      checkLead: {
        ...state.checkLead,
        [filter]: value,
      },
    });
  };
  const getDataLocal = (filter,value) => {
    setLocalActual(value)
  }
  const getDataAddMulti = (filter, value) => {
    setState({
      ...state,
      checkLead: {
        ...state.checkLead,
        [filter]: value,
      },
    });
  };

  const sendData = (extras) => {
    setState({
      ...state,
      checkLead: {
        ...state.checkLead,
        extras: extras,
      },
    });
  };
  const sendEstados = (estados) => {
    setState({
      ...state,
      edit_mode: true,
      checkLead: {
        ...state.checkLead,
        estados: estados,
      },
    });
  };

  const updateEstado = async (filter,value) => {
    if(state.checkLead.estado !== value && statusChecker){
    const last_id = await getLastLeadId(lead_id);
    const estado = value[0].value;
    await post(API.UPDATE_ESTADO, {
      lead_id,
      user,
      estado,
      last_id: last_id.id,
    });
    setState({
      ...state,
      checkLead: {
        ...state.checkLead,
        [filter]: value,
      },
    });
      return successToast(<span>Estado cambiado correctamente</span>);
    }
    else {
      setState({
        ...state,
        checkLead: {
          ...state.checkLead,
          [filter]: value,
        },
      });
    }
  };


  useEffect(() => {
    if (state.checkLead.id_existente_lead) {
      setStatusChecker(true);

      if (
        state.checkLead.captacion === 0 &&
        rol.map((elm) => elm === 23 || elm === 24).includes(true) === false
      ) {
        setEditLead(true);
      }
      if (
        state.checkLead.captacion === 1 &&
        state.checkLead.agente[0].value === user
      ) {
        setEditLead(true);
      }
      if (state.checkLead.agente[0].value === user) {
        setEditLead(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checkLead.id_existente_lead]);

  const [show] = useState(true);

  return (
    <Container className="mt-5 mb-5">
      {state.edit_mode ? (
        <>
          {!editLead ? (
            <div
              className="p-12 bg-danger my-12 rounded"
              style={{ marginBottom: "10px" }}
            >
              <Toast style={{ maxWidth: "100% " }} isOpen={show}>
                <ToastHeader>Aviso del sistema</ToastHeader>
                <ToastBody>
                  <div
                    className="row justify-content-between "
                    style={{ marginTop: ".25rem" }}
                  >
                    <div className="col-md-8 col-sm-12">
                      <p>No tienes permiso para modificar este lead.</p>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <Link
                        to={{
                          pathname: "/main/lead-tasacion/",
                          copyLead: state,
                          vehiculo: vehiculo,
                          vehiculoOld: vehiculoOld,
                          jato: vehiculoOld.jato,
                        }}
                      >
                        <Button
                          outline
                          color="danger"
                          size="sm"
                          className="float-right"
                        >
                          Copiar lead
                        </Button>
                      </Link>
                    </div>
                  </div>
                </ToastBody>
              </Toast>
            </div>
          ) : null}
          <Nav
            tabs
            className={
              mercedes ? `${styles.mercedes}` : `d-flex justify-content-between`
            }
          >
            <div className="d-flex pointer">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggleTab("1");
                  }}
                >
                  DATOS
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "5" })}
                  onClick={() => {
                    toggleTab("5");
                  }}
                >
                  TASACIONES PREVIAS{" "}
                  <span className={styles.count}>
                    {tasacionesCount ? tasacionesCount : ""}
                  </span>
                </NavLink>
              </NavItem>
            </div>
            {(
              (state.checkLead.valoracion || state.checkLead.tasacion_precio_preliminar) || rol?.some(
                (e) =>
                  e === RolType.GENERAL_ADMIN ||
                  e === RolType.ADMINISTRATION ))  &&
            <NavItem className="ms-auto ">
              <a
                className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar `}
                href={`${environment.REACT_HOST}/main/nuevo_contrato/${lead_id}`}
              >
                {t("createContract")}
              </a>
              </NavItem>
            }
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className={!editLead ? `${styles.notAllowed}` : null}></div>
              <FormLead
                launchModal={launchModal}
                getDataAdd={getDataAdd}
                getDataAddMulti={getDataAddMulti}
                getDataLocal={getDataLocal}
                handleValues={handleValues}
                getData_CheckBox={getData_CheckBox}
                toggle={toggle}
                createLead={createLead}
                launchPrintView={launchPrintView}
                state={state}
                setState={setState}
                isOpen={isOpen}
                lead_id={lead_id}
                vehiculo={vehiculo}
                setVehiculo={setVehiculo}
                vehiculoOld={vehiculoOld}
                sendData={sendData}
                sendEstados={sendEstados}
                editLead={editLead}
                modalprint={modalPrintPercha}
                setModalPrintPercha={setModalPrintPercha}
                setRetailPrice={setRetailPrice}
                retailPrice={retailPrice}
                price={price}
                setPrice={setPrice}
                mercedes={mercedes}
                updateEstado={updateEstado}
                localActual={localActual}
              />
            </TabPane>

            <TabPane tabId="5">
              <h3
                style={{
                  textAlign: "center",
                  padding: "20px 0",
                  fontSize: "1.5rem",
                  color: "#fe9800",
                }}
              >
                Otras Tasaciones enviadas por la misma persona
              </h3>
              {state.edit_mode ? (
                <TableTasacionesPrev
                  cliente={state.checkLead.cliente}
                  setTasacionesCount={setTasacionesCount}
                />
              ) : null}
            </TabPane>
          </TabContent>
        </>
      ) : null}
      {!state.edit_mode && !props.location.copyLead && !id && !mercedes ? (
        <ModalLead
          state={state}
          launchModal={launchModal}
          handleValues={handleValues}
          setState={setState}
          user={user}
        />
      ) : null}
      {state.add_mode ? (
        <FormLead
          launchModal={launchModal}
          getDataAdd={getDataAdd}
          getDataAddMulti={getDataAddMulti}
          getDataLocal={getDataLocal}
          handleValues={handleValues}
          getData_CheckBox={getData_CheckBox}
          toggle={toggle}
          createLead={createLead}
          launchPrintView={launchPrintView}
          state={state}
          setState={setState}
          isOpen={isOpen}
          lead_id={lead_id}
          vehiculo={vehiculo}
          setVehiculo={setVehiculo}
          vehiculoOld={vehiculoOld}
          sendData={sendData}
          sendEstados={sendEstados}
          editLead={editLead}
          modalprint={modalPrintPercha}
          setModalPrintPercha={setModalPrintPercha}
          setRetailPrice={setRetailPrice}
          retailPrice={retailPrice}
          price={price}
          setPrice={setPrice}
          mercedes={mercedes}
          updateEstado={updateEstado}
          localActual={localActual}
        />
      ) : null}

      <ModalPrintPercha
        title={modalPrintPercha?.title || "Ver documento"}
        open={modalPrintPercha?.open === true}
        close={() => setModalPrintPercha()}
        misLocales={local}
        leadId={lead_id}
        contractSignStartDate={modalPrintPercha?.contractSignStartDate}
        tipo={modalPrintPercha?.tipo}
        user={user}
      />
    </Container>
  );
};
const mapStateToProps = (store, props) => ({
  props: props,
});
export default connect(mapStateToProps)(NewLead);
