import { API } from "../../services/urlConstants";
import { post } from "../../services/axiosCalls";

const getIdTipo = async (user) => {
  const typeId = await post(API.GET_USER_ID_TIPO, {
    user,
  });
  return typeId?.id_tipo;
};

export default getIdTipo;
