import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Filter from "components/Filter";
import { getVideos, postVideo } from "services/youtubeApi.service";

import {
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import styles from "./index.module.scss";
import YoutubeEmbed from "../YoutubeEmbeded";
import { getYoutubeVideoId, defaultRoles } from "../../helpers/videoTutorials";

const AddVideoModal = (props) => {
  const {
    modalAddVideo,
    setModalAddVideoHandler,
    videoApiData,
    onVideosHandler,
    adminRoles,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      title: videoApiData ? videoApiData.title : "",
      description: videoApiData ? videoApiData.description : "",
      url: videoApiData ? videoApiData.url : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoApiData]);

  const onSubmit = (data) => {
    Object.assign(data, {
      roles: defaultRoles(roles, adminRoles),
      duration: videoApiData.duration,
    });

    setRoles(undefined);

    postVideo(`/videos`, data)
      .then((response) => {
        getVideos(`/videos`)
          .then((response) => {
            reset();
            setModalAddVideoHandler(false);
            onVideosHandler(response.data);
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e) => {
        throw e;
      });
  };

  const [roles, setRoles] = useState();

  const getDataAddMulti = (filter, value) => {
    setRoles({
      ...roles,
      [filter]: value,
    });
  };

  return (
    <div>
      <Modal isOpen={modalAddVideo}>
        <ModalHeader className={styles.modal_header}>
          {"Añadir nuevo video"}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Row className="m-3">
                <input
                  className={`${errors.title && "is-invalid"} ${styles.input}`}
                  placeholder="Título"
                  {...register("title", { required: true })}
                />
                {errors.title?.type === "required" && (
                  <div className="invalid-feedback">Introduzca título</div>
                )}
              </Row>
              <Row className="m-3">
                <textarea
                  className={`${errors.title && "is-invalid"} ${styles.input}`}
                  placeholder="Descripción"
                  {...register("description", { required: true })}
                />
                {errors.description?.type === "required" && (
                  <div className="invalid-feedback">
                    Introduzca descripción del video
                  </div>
                )}
              </Row>
              <Row className="m-3">
                <input
                  className={`${errors.title && "is-invalid"} ${styles.input}`}
                  type="url"
                  placeholder="url"
                  {...register("url", { required: true })}
                  readOnly
                />
              </Row>
              <Row>
                <Filter
                  className={`${styles.filter}`}
                  key={"roles"}
                  setter={"roles"}
                  multi={true}
                  sendData={getDataAddMulti}
                  name={"Roles"}
                  query={`roles`}
                />
              </Row>

              <YoutubeEmbed
                embedId={videoApiData && getYoutubeVideoId(videoApiData.url)}
              />
            </Container>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            className={styles.btn_custom}
            onClick={handleSubmit(onSubmit)}
          >
            Añadir
          </Button>
          <Button color="secondary" onClick={setModalAddVideoHandler}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddVideoModal;
