import React, { useState, useEffect, useContext, Fragment, useCallback } from "react";

import {
  Button,
  Input,
  Label,
  Container,
  Row,
  Col,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";

import Select from "react-select";

import { post } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import { errorToast } from "helpers/toastFunctions";
import revision from "helpers/revision";
import Filter from "components/Filter";
import styles from "./index.module.scss";
import { registerLocale, DatePicker } from "react-datepicker";
import es from "date-fns/locale/es";
import moment from "moment";
import ExtrasCategory from "views/NewLead/ExtrasCategory/ExtrasCategory";
import DatosVehiculoClasico from "components/DatosVehiculosClasico";
import Checkbox from "components/Checkbox/";
import validationDNI from "helpers/validationDNI";
import validationCIF from "helpers/validationCIF";
import validationNIE from "helpers/validationNIE";
import removeSpaces from "helpers/removeSpaces";
import { UserContext } from "contexts/UserContext";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { getSocieties } from "services/Dealers/dealers.service";
import { Form } from "react-bootstrap";
import { environment } from "../../config/environment.config";
import RolType from "constants/roles";
import validaNIF from "helpers/validationNifPt";
import { useTranslation } from 'react-i18next';
import Locale from "constants/Locale";
import validationPostalCode from "helpers/validationPostalCode";
import FormattedNumberInput from "components/FormattedNumberInput";

registerLocale("es", es);

const NewContract = () => {
  const { user } = useContext(UserContext);
  const { rol } = useContext(UserContext);
  const { id } = useParams();
  const [societies, setSocieties] = useState([]);
  const { i18n } = useTranslation();
  const { t } = useTranslation('newContract');
  const [localDocument, setLocalDocument] = useState({
    extras: [],
    gestoria: i18n.language === Locale.ES ? "APM" : "Ninguna",
    id_gestoria: i18n.language === Locale.ES? 1 : 0,
  });
  const [localEmpresa, setLocalEmpresa] = useState([]);
  const [clientInfo, setClientInfo] = useState(false);
  const [documentType, setDocumentType] = useState(null);
  const [importado, setImportado] = useState(false);
  const [errors, setErrors] = useState({});
  const [basicsOk, setBasicsOk] = useState(false);
  const [empresa, setEmpresa] = useState(false);
  const [titular2, setTitular2] = useState(false);
  const [basicsBlock, setBasicsBlock] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const [vehiculo, setVehiculo] = useState({
    marca_vehiculo: "",
    modelo: "",
    matriculacion: "",
    combustible: "",
    ccambios: "",
    carroceria: "",
    version: "",
    jato: i18n.language === Locale.ES ?  true : false,
    id_jato: null,
    modify: false,
  });
  const [retailPrice, setRetailPrice] = useState(null);
  const [price, setPrice] = useState(null);
  const [vehiculoOld] = useState({
    marca_vehiculo: "",
    modelo: "",
    matriculacion: "",
    combustible: "",
    ccambios: "",
    carroceria: "",
    version: "",
  });

  const { control } = useForm();

  const typesOfContract = {
    "es-ES": [
      { label: `${t("directPurchase")}`, value: 3 },
      { label: `${t("salesManagement")}`, value: 4 },
    ],
    "pt-PT": [{ label: `${t("directPurchase")}`, value: 3 }],
  };

 
  const getLead = useCallback(async (leadId) => {
    const lead = await post(API.GET_LEAD, {
      lead_id: leadId,
    });

    setLocalDocument((prevLocalDocument) => ({
			...prevLocalDocument,
			nombre: lead[0].nombre,
			apellido1: lead[0].apellido1,
			apellido2: lead[0].apellido2,
			provincia: lead[0].provincia,
			email: lead[0].email,
			poblacion: lead[0].municipio,
			telefono1: lead[0].telefono1,
			telefono2: lead[0].telefono2,
			marca_nombre: lead[1].marca_vehiculo,
			bastidor: lead[0].bastidor,
			modelo_nombre: lead[0].modelo,
			extras: lead[0].extras,
			kilometros: lead[0].kilometros,
			matricula: lead[0].matricula,
			importe_vehiculo: lead[0]?.valoracion
				? lead[0].valoracion
				: lead[0].tasacion_precio_preliminar,
			isPreliminary: !lead[0]?.valoracion && lead[0]?.tasacion_precio_preliminar ? true : false
		}));
		setVehiculo(lead[1]);
   }, []);

  const sendData = (extras) => {
    setLocalDocument({
      ...localDocument,
      extras,
    });
  };

  const [id_type, setId_type] = useState(null);

  const setLocalSelected = async (e) => {
    const id = e.value
    const phoneNumber = await post(API.GET_LOCAL_PHONE, {
      id,
    });
    setLocalDocument({
      ...localDocument,
      local_nombre: e.label,
      local_id: e.value,
      empresa_telefono: phoneNumber.telefono,
    });
  }

  const getEmpresaDataById = async (id) => {
    if (id) {
      const empresaData = await post(API.GET_EMPRESA_BY_ID, {
        id,
      });

      const empresaLocals = await post("/api/filtros", {
        dataCall: {
          data_call: id,
          data_query: "locales_empresa",
        },
      });

      const localSociety = empresaLocals.map((item) => {
        return {
          label: item.nombre,
          value: item.id,
        };
      });
      setLocalEmpresa(localSociety);

      setLocalDocument((prevLocalDocument) => ({
        ...prevLocalDocument,
        empresa_id: empresaData?.res?.id,
        id_administrador: empresaData?.res?.id_administrador,
        empresa_nombre: empresaData?.res?.nombre,
        empresa_provincia: empresaData?.res?.provincia,
        empresa_sello: empresaData?.res?.sello,
        sello: empresaData?.res?.sello,
        empresa_CIF: empresaData?.res?.CIF,
        empresa_direccion: empresaData?.res?.direccion,
        empresa_email: empresaData?.res?.email
      }));
    }
  };

  const getLeed = async (matricula) => {
    const leed = await post(API.GET_LEED_BY_PLATE, { matricula });
    if (leed.length > 0) {
      setLeadId(leed[0].id);
    }
    setClientInfo(true);
  };

  const handleGestoria = (tipo, element) => {
    setLocalDocument({
      ...localDocument,
      gestoria: element[0].label,
      id_gestoria: element[0].value,
    });
  };

  const launchClientData = useCallback(async () => {
    setClientInfo(false);
    const clientData = {
      DNI: localDocument.document_id,
      CIF: documentType === "cif" ? localDocument.document_id : null,
    };
    
    const { client } = await post(API.GET_CLIENT_BY_DNI, clientData);
    if (client) {
      setLocalDocument({
        ...localDocument,
        id_persona: client.id_persona,
        id_cliente: client.id_cliente,
        CP: client.CP,
        apellido1: client.apellido1,
        apellido2: client.apellido2,
        cuenta: client.cuenta,
        direccion: client.direccion,
        DNI: client.dni,
        provincia: null,
        email: client.email,
        estado_civil: client.estado_civil,
        nombre: client.nombre,
        pagas: client.pagas,
        sueldo: client.sueldo,
        poblacion: client.poblacion,
        telefono1: client.telefono1,
        telefono2: client.telefono2,
        fecha_nacimiento: new Date(client.fecha_nacimiento),
        nombre_empresa_cliente: client?.nombre_empresa,
        direccion_empresa_cliente: client?.direccion_empresa,
        CIF_empresa_cliente: client?.cif,
        id_empresa_cliente: client?.id_empresa,
        extras: [],
      });
    }
  }, [documentType, localDocument]);

  const basicConfirm = async () => {
    const validateCIF = await validateID();
    setDocumentType(validateCIF);
    const rev = await revisionBasics();
    if (rev && validateCIF !== false) {
      try {
        if (validateCIF === "cif") {
          setEmpresa(true);
        }

        if (!id) {
          getLeed(localDocument.matricula);
        } else {
          setClientInfo(true);
        }
      } catch (e) {
        setBasicsOk(true);
      }
      setBasicsOk(true);
      setBasicsBlock(true);
    }
  };

  const restart = () => {
    setLocalDocument({
      ...localDocument,
      color_id: null,
      color_nombre: null,
      marca_id: null,
      marca_nombre: null,
      tipo_id: null,
      tipo_nombre: null,
      modelo_nombre: null,
      CP: null,
      apellido1: null,
      apellido2: null,
      cuenta: null,
      direccion: null,
      provincia: null,
      email: null,
      estado_civil: null,
      nombre: null,
      pagas: null,
      sueldo: null,
      poblacion: null,
      telefono1: null,
      telefono2: null,
      fecha_nacimiento: null,
      id_vehiculo_GVO: null,
      contrato_vinculado_marca: null,
      contrato_vinculado_modelo: null,
      contrato_vinculado_matricula: null,
      vehicle_id_gvo: null,
      nombre_empresa_cliente: null,
      CIF_empresa_cliente: null,
      direccion_empresa_cliente: null,
    });
    setEmpresa(false);
    setBasicsBlock(false);
    setBasicsOk(false);
  };
  const createContract = async () => {
    const revi = revisionContract();
    const revision2 = revisionVehicle();
    if (revi && revision2) {
      try {
        const dataCall = {
          data: {
            ...localDocument,
            lead_id: leadId,
            primer_pago: localDocument.primer_pago
              ? moment(localDocument.primer_pago).format("YYYY/MM/DD  HH:mm:ss")
              : null,
            fecha_nacimiento: localDocument.fecha_nacimiento
              ? moment(localDocument.fecha_nacimiento).format(
                  "YYYY/MM/DD  HH:mm:ss"
                )
              : null,
          },
          vehiculo: {
            ...vehiculo,
            id_jato: vehiculo.jato ? vehiculo.version[0].value : null,
            version: vehiculo.version[0].label ? vehiculo.version : [{label:vehiculo.version}]
          },
          id_empleado: user,
          precio_coche_nuevo: retailPrice ? retailPrice : null ,
          precio_coche_final: price ? price.toFixed(2): null,
        };

        const response = await post(API.CREATE_CONTRACT, dataCall);

        if (response.error) {
          errorToast(<span>{t("errors.errorContract")}</span>);
        } else {
          return (window.location.href = `${environment.REACT_HOST}/main/postventa_contratos`);
        }
      } catch (e) {
        errorToast(<span>{t("errors.errorContract")}</span>);
      }
    }
  };

  const revisionContract = () => {
    const notEmptyInputs = [
      "nombre",
      "apellido1",
      "apellido2",
      "CP",
      "poblacion",
      "direccion",
      "provincia",
      "matricula",
      "bastidor",
      "importe_vehiculo",
      "forma_pago",
      "fecha_nacimiento",
      "email",
      "telefono1",
      "estado_civil",
      "kilometros",
    ];
    let result = true;
    const errorsTotal = {};
    notEmptyInputs.forEach((e) => {
      if (!revision(t, "empty", e, localDocument[e])) {
        result = false;
        errorsTotal[e] = true;
      }
    });
    if (empresa) {
      const notEmptyEmpresa = [
        "nombre_empresa_cliente",
        "CIF_empresa_cliente",
        "direccion_empresa_cliente",
      ];
      notEmptyEmpresa.forEach((e) => {
        if (!revision(t, "empty", e, localDocument[e])) {
          result = false;
          errorsTotal[e] = true;
        }
      });
    }
    if (titular2) {
      const notEmptyTitular2 = [
        "titular2_nombre",
        "titular2_apellido1",
        "titular2_apellido2",
        "titular2_dni",
        "titular2_direccion",
      ];
      notEmptyTitular2.forEach((e) => {
        if (!revision(t, "empty", e, localDocument[e])) {
          result = false;
          errorsTotal[e] = true;
        }
      });
    }
    if (
      localDocument.telefono1 &&
      !revision(t, "phone", "telefono1", localDocument.telefono1)
    ) {
      result = false;
      errorsTotal.telefono1 = true;
    }
    if (localDocument.CP && !revision(t, "CP", "CP", localDocument.CP)) {
      result = false;
    }
    if (
      localDocument.bastidor &&
      !revision(t, "bastidor", "bastidor", localDocument.bastidor)
    ) {
      result = false;
      errorsTotal.bastidor = true;
    }
    if (
      !importado &&
      localDocument.matricula &&
      !revision(t, "matricula", "matricula", localDocument.matricula, i18n.language)
    ) {
      result = false;
      errorsTotal.matricula = true;
    }
    if (
      localDocument.forma_pago === "3" &&
      !revision(t, "empty", "cuenta", localDocument.cuenta)
    ) {
      result = false;
      errorsTotal.cuenta = true;
    }

    if (
      localDocument.forma_pago === "4" &&
      !revision(t, 
        "coche_forma_pago",
        "contrato_vinculado_matricula",
        localDocument.contrato_vinculado_matricula
      )
    ) {
      result = false;
      errorsTotal.forma_pago = true;
    }
    setErrors({
      ...errors,
      ...errorsTotal,
    });
    if (
      localDocument.cuenta &&
      !revision(t, "cuenta", "cuenta", localDocument.cuenta)
    ) {
      result = false;
      errorsTotal.cuenta = true;
    }
    if (
      localDocument.banco_iban &&
      !revision(t, "cuenta", "cuenta", localDocument.banco_iban, i18n.language === Locale.PT)
    ) {
      result = false;
      errorsTotal.banco_iban = true;
    }

    setErrors({
      ...errors,
      ...errorsTotal,
    });
    return result;
  };

  const revisionVehicle = () => {
    const notEmptyInputs = [
      "marca_vehiculo",
      "modelo",
      "matriculacion",
      "combustible",
      "ccambios",
      "carroceria",
      "version",
    ];
    let result = true;
    const errorsTotal = {};
    notEmptyInputs.forEach((e) => {
      if (!revision(t, "empty", e, vehiculo[e])) {
        result = false;
        errorsTotal[e] = true;
      }
    });

    setErrors({
      ...errors,
      ...errorsTotal,
    });

    return result;
  };

  const revisionBasics = () => {
    const notEmptyInputs = [
      "tipoContrato_id",
      "empresa_id",
      "local_nombre",
      "matricula",
      "document_id",
    ];
    let result = true;
    const errorsTotal = {};

    notEmptyInputs.forEach((e) => {
      if (!revision(t, "empty", e, localDocument[e])) {
        result = false;
        errorsTotal[e] = true;
      }
    });

    if (
      !importado &&
      localDocument.matricula &&
      !revision(t, "matricula", "matricula", localDocument.matricula, i18n.language)
    ) {
      result = false;
      errorsTotal.matricula = true;
    }
    if (localDocument.CP && !revision(t, "CP", "CP", localDocument.CP)) {
      result = false;
    }

    setErrors({
      ...errors,
      ...errorsTotal,
    });
    return result;
  };

  const validatePortugeseID = () => {
    if (validaNIF(localDocument.document_id)) {
      setLocalDocument({ ...localDocument, DNI: localDocument.document_id });
      setId_type("dni");
      return "nie";
    } 
    else {
      errorToast(<span>{t("errors.errorDocumentation")}</span>);
      return false;
    }
  }


  const validateSpanishID = () => {
    if (validationDNI(localDocument.document_id)) {
      setLocalDocument({ ...localDocument, DNI: localDocument.document_id });
      setId_type("dni");
      return "dni";
    }
    if (validationCIF(localDocument.document_id)) {
      setLocalDocument({
        ...localDocument,
        CIF_empresa_cliente: localDocument.document_id,
      });
      setId_type("cif");
      return "cif";
    }
    
    if (validationNIE(localDocument.document_id)) {
      setLocalDocument({ ...localDocument, DNI: localDocument.document_id });
      setId_type("dni");
      return "nie";
    }
    if (
      !validationCIF(localDocument.document_id) &&
      localDocument.document_id.length > 9 &&
      importado
    ) {
      setLocalDocument({
        ...localDocument,
        CIF_empresa_cliente: localDocument.document_id,
      });
      setId_type("cif");
      return "cif";
    } else {
      errorToast(<span>{t("errors.errorDocumentation")}</span>);
      return false;
    }
  };

  

  const validateID = () => {
         if (i18n.language === Locale.ES) {
          return validateSpanishID();
        }
         else if (i18n.language === Locale.PT) {
          return validatePortugeseID();
        }
        else{
          return false;
        }
      } 

  const handleContractType = (id) => {
    setLocalDocument({ ...localDocument, tipoContrato_id: +id });
    setErrors({ ...errors, tipoContrato_id: false });
  };

  const handleSimpleInput = (e, type) => {
    if (type === "cuenta") {
      setLocalDocument({
        ...localDocument,
        [type]: removeSpaces(e.target.value),
      });
    } else {
      setLocalDocument({ ...localDocument, [type]: e.target.value });
    }
    setErrors({ ...errors, [type]: false });
  };

  const setDate = (date) => {
    setLocalDocument({ ...localDocument, fecha_nacimiento: date });
  };

  const handleUpperInput = (e, type) => {
    setLocalDocument({
      ...localDocument,
      [type]: e.target.value.toUpperCase(),
    });
    setErrors({ ...errors, [type]: false });
  };

  const switchEmpresa = () => {
    setEmpresa(!empresa);
  };
  const switchTitular2 = () => {
    setTitular2(!titular2);
  };

  const switchKey = () => {
    setLocalDocument({
      ...localDocument,
      key: localDocument.key ? false : true,
    });
  };

  const validateMatricula = async () => {
    const matricula = localDocument.matricula_contrato_vinculado;
    const matricula_contract = await post(API.GET_CONTRACT, {
      matricula,
    });

    if (matricula_contract && matricula_contract?.[0]?.id) {
      setLocalDocument({
        ...localDocument,
        id_vehiculo_GVO: matricula_contract?.[0].id,
        contrato_vinculado_marca: matricula_contract?.[0].marca,
        contrato_vinculado_modelo: matricula_contract?.[0].modelo,
        contrato_vinculado_matricula: matricula_contract?.[0].matricula,
        vehicle_id_gvo: matricula_contract?.[0].vehicle_id,
      });
    } else {
      errorToast(<span>{t("errors.errorContractNotFound")}</span>);
    }
  };

  const disableInputPointsAndCommas = (e) => {
    if(e.key === "," || e.key === ".") e.preventDefault();
  };


  useEffect(() => {
		if (clientInfo) {
			launchClientData();
		}
	}, [clientInfo, launchClientData]);

	useEffect(() => {
		document.title = "Flexicar | Nuevo Contrato Compra";
		if (id) {
			setLeadId(id);
			getLead(id);
		}

		getSocieties(`/society`)
			.then((response) => {
				setSocieties(response);
			})
			.catch((e) => {
				throw e;
			});
	}, [getLead, id]);

  return (
    <div>
      <div className={`clearfix mb-3 ${styles.container}`}>
        <span className="tittle ml-4">{t('createContractTitle')}</span>
      </div>
      <Container>
        <Row className={styles.centerRow}>
          <Col xs="12" sm="6">
            <Label className={"m-0"}>{t('contractType')}*</Label>
            <Controller
              name="contract"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  placeholder={t('selectContractType')}
                  {...field}
                  options={typesOfContract[i18n.language]}
                  onChange={(e) => handleContractType(e.value)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#fe9800",
                      primary25: "#ffc548",
                    },
                  })}
                />
              )}
            />
          </Col>
          <Col xs="12" sm="6">
            <Label className={"m-0"}> </Label>
            {t('society')}*
            <Controller
              name="society"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  placeholder={t('selectSociety')}
                  {...field}
                  options={societies}
                  onChange={(e) => getEmpresaDataById(e.value)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#fe9800",
                      primary25: "#ffc548",
                    },
                  })}
                />
              )}
            />
          </Col>

          <Col xs="12" sm="6">
            <Label className={"m-0"}> </Label>
            {t('local')}*
            <Controller
              name="local"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  isDisabled={!Number.isInteger(localDocument.empresa_id)}
                  placeholder={t('local')}
                  {...field}
                  options={localEmpresa}
                  onChange={(e) => setLocalSelected(e)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#fe9800",
                      primary25: "#ffc548",
                    },
                  })}
                />
              )}
            />
          </Col>

          <Col xs="12" sm="6">
            <Label className={"m-0"}>{t('DNICIF')}*</Label>
            <Input
              disabled={basicsBlock}
              placeholder={"DNI/CIF/NIE"}
              className="form-control ds-input  "
              type="text"
              invalid={errors.DNI === true}
              value={localDocument.document_id}
              onChange={(e) => {
                handleUpperInput(e, "document_id");
              }}
            />
          </Col>
          <Col xs="12" sm="6">
            <Label className={"m-0"}>{t('plate')}*</Label>
            <Input
              disabled={basicsBlock}
              placeholder={t('plate')}
              className="form-control ds-input "
              type="text"
              value={localDocument.matricula}
              invalid={errors.matricula === true}
              onChange={(e) => {
                handleSimpleInput(e, "matricula");
              }}
            />
            <Checkbox
              key={"Importado"}
              name={t('isImported')}
              status={importado}
              onChange={() => setImportado(!importado)}
            />
          </Col>
        </Row>
        <div className={styles.centerButton}>
          <Button
            className="btn-flexicar mt-4 mr-5 right no-print"
            onClick={basicsBlock ? restart : basicConfirm}
          >
            {basicsBlock ? "Cancelar" : `${t('confirm')}`}
          </Button>
        </div>

        {basicsOk && (
          <div>
            <Row className={styles.centerRow}>
              <Col xs="12" sm="6" style={{ display: "contents" }}>
                <h4 className={`mr-5 ${styles.bold}`}>{t('clientData')}</h4>
                <Label style={{ cursor: "pointer", marginLeft: "30px" }} check>
                  <Input
                    type="checkbox"
                    onChange={switchEmpresa}
                    checked={empresa}
                  />
                  {t('isCompany')}
                </Label>
                <Label style={{ cursor: "pointer", marginLeft: "30px" }} check>
                  <Input
                    type="checkbox"
                    onChange={switchTitular2}
                    checked={titular2}
                  />
                  {t('secondOwner')}
                </Label>
              </Col>
            </Row>

            {empresa && (
              <Row className={styles.company_box}>
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>{t('companyName')}</Label>
                  <Input
                   
                    placeholder={t('name')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={errors.nombre_empresa_cliente === true}
                    value={localDocument.nombre_empresa_cliente}
                    onChange={(e) => {
                      handleSimpleInput(e, "nombre_empresa_cliente");
                    }}
                  />
                </Col>
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>{t('companyCIF')}</Label>
                  <Input
                    disabled={basicsBlock && id_type === "cif"}
                    placeholder={t('companyCIF')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={errors.CIF_empresa_cliente === true}
                    value={localDocument.CIF_empresa_cliente}
                    onChange={(e) => {
                      handleSimpleInput(e, "CIF_empresa_cliente");
                    }}
                  />
                </Col>
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>{t('companyAddress')}</Label>
                  <Input
                    placeholder={t('address')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={errors.direccion_empresa_cliente === true}
                    value={localDocument.direccion_empresa_cliente}
                    onChange={(e) => {
                      handleSimpleInput(e, "direccion_empresa_cliente");
                    }}
                  />
                </Col>
              </Row>
            )}

            <Row className={styles.centerRow}>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('name')}*</Label>
                <Input
                  placeholder={t('name')}
                  className="form-control ds-input mt-1"
                  type="text"
                  invalid={errors.nombre === true}
                  value={localDocument.nombre}
                  onChange={(e) => {
                    handleSimpleInput(e, "nombre");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('firstSurname')}*</Label>
                <Input
                  placeholder={t('firstSurname')}
                  className="form-control ds-input mt-1"
                  type="text"
                  invalid={errors.apellido1 === true}
                  value={localDocument.apellido1}
                  onChange={(e) => {
                    handleSimpleInput(e, "apellido1");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('secondSurname')}*</Label>
                <Input
                  placeholder={t('secondSurname')}
                  className="form-control ds-input mt-1"
                  type="text"
                  invalid={errors.apellido2 === true}
                  value={localDocument.apellido2}
                  onChange={(e) => {
                    handleSimpleInput(e, "apellido2");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('DNI')}*</Label>
                <Input
                  disabled={basicsBlock && id_type === "dni"}
                  placeholder={t('DNI')}
                  className="form-control ds-input mt-1"
                  type="text"
                  invalid={errors.DNI === true}
                  value={localDocument.DNI}
                  onChange={(e) => {
                    handleSimpleInput(e, "DNI");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('birth')}*</Label>
                <Form.Control
                  type="date"
                  name="fecha_nacimiento"
                  placeholder={t('birth')}
                  value={moment(localDocument.fecha_nacimiento).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Col>

              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('civilStatus')}*</Label>
                <div className={styles.filterAdjust}>
                  <Input
                    placeholder={"Estado"}
                    className="form-control ds-input mt-1 pointer"
                    type="select"
                    invalid={errors.estado_civil === true}
                    value={localDocument.estado_civil}
                    onChange={(e) => {
                      setLocalDocument({
                        ...localDocument,
                        estado_civil_name: e.target.selectedOptions[0].text,
                        estado_civil: Number(e.target.value),
                      });
                    }}
                  >
                    <option value={0}>{t('civilStatusOption')}</option>
                    <option value={1}>{t('single')}</option>
                    <option value={2}>{t('separated')}</option>
                    <option value={3}>{t('married')}</option>
                    <option value={4}> {t('divorced')}</option>
                    <option value={5}>{t('widowed')}</option>
                    {i18n.language === Locale.PT ? <option value={6}>União de facto</option> : null  }
                  </Input>
                </div>
              </Col>

              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('email')}*</Label>
                <Input
                  /*                   disabled={
                    localDocument.forma_pago === "pasarela" &&
                    document?.tipo === 2
                  } */
                  placeholder={t('email')}
                  className="form-control ds-input mt-1"
                  invalid={localDocument.email === ""}
                  type="text"
                  value={localDocument.email}
                  onChange={(e) => {
                    handleSimpleInput(e, "email");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('phone1')}*</Label>
                <Input
                  placeholder={t('phone1')}
                  className="form-control ds-input mt-1"
                  type="text"
                  invalid={errors.telefono1 === true}
                  value={localDocument.telefono1}
                  onChange={(e) => {
                    handleSimpleInput(e, "telefono1");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('phone2')}</Label>
                <Input
                  placeholder={t('phone2')}
                  className="form-control ds-input mt-1"
                  type="text"
                  //invalid={localDocument.telefono2 === ""}
                  value={localDocument.telefono2}
                  onChange={(e) => {
                    handleSimpleInput(e, "telefono2");
                  }}
                />
              </Col>
            </Row>
            <Row className={styles.centerRow}>
              <Col xs="12" sm="6">
                <Label>{t('province')}*</Label>
                <div className={styles.filterAdjust}>
                  <Filter
                    multi={false}
                    key={t('province')}
                    sendData={(name, value) =>
                      setLocalDocument({ ...localDocument, provincia: value })
                    }
                    parentValues={localDocument?.provincia}
                    name={t('province')}
                    query={"provincias"}
                  />
                </div>
              </Col>

              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('town')}*</Label>
                <Input
                  placeholder={t('town')}
                  className="form-control ds-input mt-1"
                  type="text"
                  invalid={errors.poblacion === true}
                  value={localDocument.poblacion}
                  onChange={(e) => {
                    handleSimpleInput(e, "poblacion");
                  }}
                />
              </Col>
            </Row>

            <Row className={styles.centerRow}>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('address')}*</Label>
                <Input
                  placeholder={t('address')}
                  className="form-control ds-input mt-1"
                  invalid={errors.direccion === true}
                  type="text"
                  value={localDocument.direccion}
                  onChange={(e) => {
                    handleSimpleInput(e, "direccion");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('postcode')}*</Label>
                <Input
                  placeholder={t('postcode')}
                  className="form-control ds-input mt-1"
                  type="text"
                  invalid={!validationPostalCode(localDocument.CP)}
                  value={localDocument.CP}
                  onChange={(e) => {
                    handleSimpleInput(e, "CP");
                  }}
                />
              </Col>
            </Row>
            <Row className={styles.centerRow}>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('salary')}</Label>
                <Input
                  placeholder={t('salary')}
                  className="form-control ds-input mt-1"
                  type="number"
                  value={localDocument.sueldo}
                  onKeyPress={disableInputPointsAndCommas}
                  onChange={(e) => {
                    handleSimpleInput(e, "sueldo");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('payments')}</Label>
                <Input
                  placeholder={t('payments')}
                  className="form-control ds-input mt-1"
                  type="number"
                  value={localDocument.pagas}
                  onKeyPress={disableInputPointsAndCommas}
                  onChange={(e) => {
                    handleSimpleInput(e, "pagas");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('bankAccount')}</Label>
                <Input
                  placeholder={t('bankAccount')}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={localDocument.cuenta}
                  onChange={(e) => {
                    handleSimpleInput(e, "cuenta");
                  }}
                />
              </Col>
            </Row>
            {titular2 && (
              <Row className={styles.company_box}>
                <Col xs="12" sm="12">
                  <h5>{t('secondOwnerData')}</h5>
                </Col>
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>{t('name')}*</Label>
                  <Input
                    placeholder={t('name')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={errors.titular2_nombre === true}
                    value={localDocument.titular2_nombre}
                    onChange={(e) => {
                      handleSimpleInput(e, "titular2_nombre");
                    }}
                  />
                </Col>
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>{t('firstSurname')}*</Label>
                  <Input
                    placeholder={t('firstSurname')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={errors.titular2_apellido1 === true}
                    value={localDocument.titular2_apellido1}
                    onChange={(e) => {
                      handleSimpleInput(e, "titular2_apellido1");
                    }}
                  />
                </Col>
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>{t('secondSurname')}*</Label>
                  <Input
                    placeholder={t('secondSurname')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={errors.titular2_apellido2 === true}
                    value={localDocument.titular2_apellido2}
                    onChange={(e) => {
                      handleSimpleInput(e, "titular2_apellido2");
                    }}
                  />
                </Col>
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>{t('DNI')}*</Label>
                  <Input
                    placeholder={t('DNI')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={errors.titular2_dni === true}
                    value={localDocument.titular2_dni}
                    onChange={(e) => {
                      handleSimpleInput(e, "titular2_dni");
                    }}
                  />
                </Col>
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>
                    {t('fullAddress')}*
                  </Label>
                  <Input
                    placeholder={t('fullAddress')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={errors.titular2_direccion === true}
                    value={localDocument.titular2_direccion}
                    onChange={(e) => {
                      handleSimpleInput(e, "titular2_direccion");
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row className={styles.centerRow}>
              <Col xs="12" sm="12">
                <h4 className={styles.bold}>{t('vehicleData')}</h4>
              </Col>

              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('plate')}*</Label>
                <Input
                  disabled={basicsBlock}
                  placeholder={t('plate')}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={localDocument.matricula}
                  onChange={(e) => {
                    handleSimpleInput(e, "matricula");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('chassisNumber')}*</Label>
                <Input
                  placeholder={t('chassisNumber')}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={localDocument.bastidor}
                  invalid={errors.bastidor === true}
                  onChange={(e) => {
                    handleSimpleInput(e, "bastidor");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label className={"m-0"}>{t('km')}*</Label>
                <Input
                  placeholder={t('km')}
                  className="form-control ds-input mt-1"
                  type="number"
                  value={localDocument.kilometros}
                  invalid={errors.kilometros === true}
                  onChange={(e) => {
                    handleSimpleInput(e, "kilometros");
                  }}
                />
              </Col>
              <Col xs="12" sm="6">
                <Label style={{ cursor: "pointer", marginLeft: "30px" }} check>
                  <Input
                    type="checkbox"
                    onChange={switchKey}
                    checked={localDocument.key}
                  />
                  {t('key')}
                </Label>
              </Col>

              <Col>
                <DatosVehiculoClasico
                  className={"none"}
                  vehiculo={vehiculo}
                  setVehiculo={setVehiculo}
                  type={'draft'}
                  vehiculoOld={vehiculoOld}
                  mode={vehiculo.jato ? "JATO" : "MANUAL"}
                />
              </Col> 

              <Col xs="12" sm="12">
                <Label className={"m-0"}>{t('vehicleObservations')}</Label>
                <textarea
                  placeholder={t('vehicleObservations')}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={localDocument.observaciones_vehiculo}
                  onChange={(e) => {
                    handleSimpleInput(e, "observaciones_vehiculo");
                  }}
                />
              </Col>
            </Row>
            {(i18n.language === Locale.ES && vehiculo?.jato) && (
              <Fragment>
                <Row className={styles.centerRow}>
                  <Col xs="12" sm="6">
                    <h4 className={styles.bold}>Extras del vehiculo</h4>
                  </Col>
                </Row>
                {vehiculo?.version[0]?.value && (
                  <ExtrasCategory
                    query={`${vehiculo?.version[0]?.value}`}
                    state={{ checkLead: { extras: localDocument.extras } }}
                    setState={{ setLocalDocument }}
                    load={true}
                    vehiculo={vehiculo}
                    dontShowColapse={true}
                    sendData={sendData}
                    setRetailPrice={setRetailPrice}
                    retailPrice={retailPrice}
                    price={price}
                    setPrice={setPrice}
                  ></ExtrasCategory>
                )}
              </Fragment>
            )}

            <Row className={styles.centerRow}>
              <Col xs="12" sm="12">
                <h4 className={styles.bold}>{t('purchaseData')}</h4>
              </Col>

              <Col xs="12" sm="4">
                <Label className={"m-0"}>{t('price')}*</Label>
                <Input
                  placeholder={t('price')}
                  className="form-control ds-input mt-1"
                  type="number"
                  disabled={!rol?.some(
                    (e) =>
                      e === RolType.GENERAL_ADMIN ||
                      e === RolType.CENTRAL_APPRAISER ||
                      e === RolType.ADMINISTRATION
                  )}
                  value={localDocument.importe_vehiculo}
                  invalid={localDocument.importe_vehiculo === ""}
                  onKeyPress={disableInputPointsAndCommas}
                  onChange={(e) => {
                    handleSimpleInput(e, "importe_vehiculo");
                  }}
                />
              </Col>
              <Col xs="12" sm="4">
                <Label className={"m-0"}>{t('payType')}*</Label>
                <div className={styles.filterAdjust}>
                  <Input
                    placeholder={t('payType')}
                    className="form-control ds-input mt-1 pointer"
                    type="select"
                    value={localDocument.forma_pago}
                    onChange={(e) => {
                      handleSimpleInput(e, "forma_pago");
                    }}
                  >
                    <option value={0}>{t('selectPayType')}</option>
                    <option value={1}>{t('cash')}</option>
                    <option value={2}>{t('check')}</option>
                    <option value={3}>{t('transfer')}</option>
                    <option value={4}>{t('carPay')}</option>
                  </Input>
                </div>
              </Col>
              <Col xs="12" sm="4">
                <Label className={"m-0"}> </Label>
                {t('management')}*
                <Filter
                  //disabled={basicsBlock}
                  key={"gestoria"}
                  multi={false}
                  sendData={handleGestoria}
                  parentValues={[
                    {
                      label: localDocument?.gestoria,
                      value: localDocument?.id_gestoria,
                    },
                  ]}
                  name={"Gestorías"}
                  query={`gestorias`}
                />
              </Col>

              {localDocument?.forma_pago === "4" &&
              !localDocument.id_vehiculo_GVO ? (
                <>
                  <div className="p-3 my-2 rounded col-12 col-sm-12">
                    <Toast style={{ maxWidth: "100%" }}>
                      <ToastHeader>Coche Vinculado</ToastHeader>
                      <ToastBody>
                        <Row>
                          <Col xs="12" sm="8">
                            <Input
                              placeholder={"Matricula"}
                              className="form-control ds-input mt-1"
                              value={localDocument.matricula_contrato_vinculado}
                              invalid={
                                localDocument.forma_pago === "4" &&
                                localDocument.matricula_contrato_vinculado ===
                                  ""
                              }
                              onChange={(e) => {
                                handleSimpleInput(
                                  e,
                                  "matricula_contrato_vinculado"
                                );
                              }}
                            />
                          </Col>
                          <Col xs="12" sm="4">
                            <Button
                              className="btn-flexicar right no-print"
                              onClick={() => validateMatricula()}
                            >
                              Validar vehiculo asociado
                            </Button>
                          </Col>{" "}
                        </Row>
                      </ToastBody>
                    </Toast>
                  </div>
                </>
              ) : null}
              {localDocument?.forma_pago === "4" &&
              localDocument.id_vehiculo_GVO ? (
                <>
                  <div className="p-3 my-2 rounded col-12 col-sm-12">
                    <Toast style={{ maxWidth: "100%" }}>
                      <ToastHeader>Coche Vinculado</ToastHeader>
                      <ToastBody>
                        <Row>
                          <Col xs="12" sm="8">
                            <p>
                              {localDocument.contrato_vinculado_marca}{" "}
                              {localDocument.contrato_vinculado_modelo}{" "}
                              {localDocument.matricula_contrato_vinculado}{" "}
                              <a
                                href={`/main/vehicle/${localDocument.vehicle_id_gvo}?tab=datos`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Ver vehículo
                              </a>
                            </p>
                          </Col>
                          <Col xs="12" sm="4">
                            <Button
                              className="btn-flexicar right no-print"
                              onClick={() =>
                                setLocalDocument({
                                  ...localDocument,
                                  id_vehiculo_GVO: "",
                                  contrato_vinculado: "",
                                  contrato_vinculado_marca: "",
                                  contrato_vinculado_modelo: "",
                                  vehicle_id_gvo: "",
                                })
                              }
                            >
                              Cambiar coche asociado
                            </Button>
                          </Col>
                        </Row>
                      </ToastBody>
                    </Toast>
                  </div>
                </>
              ) : null}
              {localDocument?.forma_pago === "3" ? (
                <>
                  <div className="p-3 my-2 rounded col-12 col-sm-12">
                    <Toast style={{ maxWidth: "100%" }}>
                      <ToastHeader>{t('bankAccount')}</ToastHeader>
                      <ToastBody>
                        <Row>
                          <Col xs="12" sm="8">
                            <Input
                              placeholder={t('bankAccount')}
                              className="form-control ds-input mt-1"
                              type="text"
                              value={localDocument.cuenta}
                              onChange={(e) => {
                                handleSimpleInput(e, "cuenta");
                              }}
                            />
                          </Col>
                        </Row>
                      </ToastBody>
                    </Toast>
                  </div>
                </>
              ) : null}
              {localDocument.tipoContrato_id === 4 && (
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>Comisión en caso de venta</Label>
                  <Input
                    placeholder={"Comisión en caso de venta"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.importe_venta}
                    invalid={localDocument.importe_venta === ""}
                    onKeyPress={disableInputPointsAndCommas}
                    onChange={(e) => {
                      handleSimpleInput(e, "importe_venta");
                    }}
                  />
                </Col>
              )}
              {localDocument.tipoContrato_id === 4 && (
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>Gastos y comisiones mensuales</Label>
                  <Input
                    placeholder={"Gastos y comisiones mensuales"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.comision_retirada}
                    invalid={localDocument.comision_retirada === ""}
                    onKeyPress={disableInputPointsAndCommas}
                    onChange={(e) => {
                      handleSimpleInput(e, "comision_retirada");
                    }}
                  />
                </Col>
              )}
              {localDocument.tipoContrato_id === 4 && (
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>
                    Penalizacion en caso de retirada
                  </Label>
                  <Input
                    placeholder={"Penalizacion en caso de retirada"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.penalizacion_retirada}
                    invalid={localDocument.penalizacion_retirada === ""}
                    onKeyPress={disableInputPointsAndCommas}
                    onChange={(e) => {
                      handleSimpleInput(e, "penalizacion_retirada");
                    }}
                  />
                </Col>
              )}
              {(localDocument.tipoContrato_id === 0 ||
                localDocument.tipoContrato_id === 1) && (
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>
                    Pago Mensual (30 días naturales)
                  </Label>
                  <Input
                    placeholder={"Pago Mensual (30 días naturales)"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.pago_mensual}
                    invalid={localDocument.pago_mensual === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "pago_mensual");
                    }}
                  />
                </Col>
              )}
              {(localDocument.tipoContrato_id === 0 ||
                localDocument.tipoContrato_id === 1) && (
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>Recompra vehículo primer año</Label>
                  <Input
                    placeholder={"recompra primer año"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.recompra_primero}
                    invalid={localDocument.recompra_primero === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "recompra_primero");
                    }}
                  />
                </Col>
              )}
              {(localDocument.tipoContrato_id === 0 ||
                localDocument.tipoContrato_id === 1) && (
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>Recompra vehículo segundo año</Label>
                  <Input
                    placeholder={"recompra segundo año"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.recompra_segundo}
                    invalid={localDocument.recompra_segundo === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "recompra_segundo");
                    }}
                  />
                </Col>
              )}
              {(localDocument.tipoContrato_id === 0 ||
                localDocument.tipoContrato_id === 1) && (
                <Col xs="12" sm="6">
                  <Label className={"m-0"}>Recompra vehículo tercer año</Label>
                  <Input
                    placeholder={"recompra tercer año"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.recompra_tercero}
                    invalid={localDocument.recompra_tercero === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "recompra_tercero");
                    }}
                  />
                </Col>
              )}
              {(localDocument.tipoContrato_id === 0 ||
                localDocument.tipoContrato_id === 1) && (
                <Col xs="12" sm="6">
                  <DatePicker
                    selected={localDocument.primer_pago}
                    onChange={(element) =>
                      handleSimpleInput(
                        { target: { value: element } },
                        "primer_pago"
                      )
                    }
                    locale="es"
                    customInput={
                      localDocument.primer_pago ? (
                        <Label>
                          {"Fecha primer pago"}
                          <Input
                            value={moment(localDocument.primer_pago).format(
                              "DD-MM-YYYY"
                            )}
                          />
                        </Label>
                      ) : (
                        <Label>
                          {"Fecha de primer pago"}
                          <Input value={"Seleccione una fecha"} />
                        </Label>
                      )
                    }
                  />
                </Col>
              )}
            </Row>
            <Row className={styles.centerRow}>
              <Col xs="12" sm="12">
                <Label className={"m-0"}>{t('observations')} </Label>
                <textarea
                  placeholder={t('observations')} 
                  className="form-control ds-input mt-1"
                  type="text"
                  maxLength={500}
                  value={localDocument.observaciones}
                  onChange={(e) => {
                    handleSimpleInput(e, "observaciones");
                  }}
                />
              </Col>
            </Row>
            <Row className={styles.centerRow}>
              <Col xs="12" sm="12">
                <Label className={"m-0"}>
                  {t('annex')} {" "}
                </Label>
                <textarea
                  placeholder={"Anexo"}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={localDocument.anexo}
                  onChange={(e) => {
                    handleSimpleInput(e, "anexo");
                  }}
                />
              </Col>
            </Row>
            {i18n.language === Locale.PT && (
              <>
               <Row className={styles.centerRow}>
                <Col xs="12" sm="6">
                  <h4 className={styles.bold}>{t('reservaDominio')}</h4>
                </Col>
               </Row>

               <Label className={"m-0"}>{t('reservaDominio')}*</Label>
               <Col xs="12" sm="3">
                  <Input
                    placeholder={t('reservaDominioChoice')}
                    className="form-control ds-input mt-1 pointer"
                    type="select"
                    value={localDocument.reserva_dominio}
                    onChange={(e) => {
                      handleSimpleInput(e, "reserva_dominio");
                    }}
                  >
                    <option value={0}>{t('No')}</option>
                    <option value={1}>{t('Sí')}</option>
                  </Input>
                  </Col>
                {localDocument.reserva_dominio === '1' && (
                  <Row className={styles.centerRow}>
                    
               <Col xs="12" sm="3">
                 <Label style={{fontSize: '12px'}} className={"m-0"}>{t('bancoDeReservaDePropiedade')}*</Label>
                    <Input
                      placeholder={t('bancoDeReservaDePropiedade')}
                      className="form-control ds-input mt-1"
                      type="text"
                      maxLength={50}
                      required={localDocument.reserva_dominio === '1'}
                      value={localDocument.banco_reserva_dominio}
                      onChange={(e) => {
                      handleSimpleInput(e, "banco_reserva_dominio");
                      }}
                      />
               </Col>
               <Col xs="12" sm="3">
                 <Label style={{fontSize: '12px'}} className={"m-0"}>{t('bancoIban')}*</Label>
                    <Input
                      placeholder={t('bancoIban')}
                      className="form-control ds-input mt-1"
                      type="text"
                      required={localDocument.reserva_dominio === '1' && !localDocument.entidad_referencia_reserva_dominio}
                      value={localDocument.banco_iban}
                      onChange={(e) => {
                      handleSimpleInput(e, "banco_iban");
                      }}
                      />
               </Col>
               <Col xs="12" sm="3">
                 <Label style={{fontSize: '12px'}} className={"m-0"}>{t('entidadYReferencia')}*</Label>
                    <Input
                      placeholder={t('entidadYReferencia')}
                      className="form-control ds-input mt-1"
                      maxLength={50}
                      type="text"
                      required={localDocument.reserva_dominio === '1' && !localDocument.banco_iban}
                      value={localDocument.entidad_referencia_reserva_dominio}
                      onChange={(e) => {
                      handleSimpleInput(e, "entidad_referencia_reserva_dominio");
                      }}
                      />
               </Col>
               <Col xs="12" sm="3">
                 <Label style={{fontSize: '12px'}} className={"m-0"}>{t('valorReserva')}*</Label>
                
                      <FormattedNumberInput
                         value={localDocument.valor_reserva_dominio}
                         maxLength={12}
                         onChange={(value) => {
                          setLocalDocument({ ...localDocument, valor_reserva_dominio: value });
                          }}
                         displayType="input"
                          />
                      
               </Col>
               </Row>
                )}  
                </>
            )}
            <div className={styles.centerButton}>
              <Button
                className="btn-flexicar mt-4 mr-5 right no-print"
                onClick={createContract}
              >
                {t('createContract')} 
              </Button>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default NewContract;
