import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { MdOutlineFindInPage } from "react-icons/md";

import styles from "./index.module.scss";

const ModalUploadMultiImages = ({ show, toggle, uploadCb }) => {
  const [files, setFiles] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const reset = () => {
    setSpinner(false);
    setFiles(null);
    toggle();
  };

  return (
    <Modal className="modal-xs" isOpen={show} toggle={reset}>
      <ModalHeader toggle={reset}>Subir documento</ModalHeader>
      <ModalBody className="p-4">
        <div className={styles.center}>
          <div className={styles.container}>
            {files ? (
              <h5>Archivos seleccionados</h5>
            ) : (
              <h5>Seleccione archivos</h5>
            )}
            {files && (
              <div className={styles.textBox}>
                {files.map((e) => (
                  <strong key={`file${e.name}`}>{e.name}</strong>
                ))}
              </div>
            )}

            <div className={styles.uploadBox}>
              {files ? (
                <div className={styles.iconBox}>
                  <button
                    onClick={reset}
                    className={styles.buttonCancel}
                  >
                    Cancelar
                  </button>

                  {!spinner ? (
                      <button
                        className={styles.buttonAccept}
                        onClick={async () => {
                          setSpinner(true);
                          await uploadCb(files);
                          reset();
                        }}
                      >
                        Confirmar
                      </button>
                    ) : (
                      <Spinner type="grow" className="flexicarColor" />
                    )}
                </div>
              ) : (
                <div>
                  <label for="file-input" className="pointer">
                    <MdOutlineFindInPage
                      title="Buscar archivo"
                      size={75}
                      color="#007bff"
                    />
                  </label>
                  <input
                    className={styles.uploadInput}
                    id="file-input"
                    type="file"
                    multiple
                    name="customFile"
                    onChange={(e) => setFiles(Object.values((e.target.files)))}
                    label="Seleccion un archivo"
                    accept="image/*"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>

  );
};

export default ModalUploadMultiImages;
