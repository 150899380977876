import React, { useState, useEffect } from "react";
import { Modal, ModalFooter, Button, ModalBody } from "reactstrap";

const ModalManual = ({
  manual,
  saveVehicleData,
  setActiveTab,
  setManual,
  saveSalesData,
  activeTab,
  mode,
  saveExtras,
}) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (manual === true) {
      setModal(true);
    }
  }, [manual]);

  const toggle = async () => {
    await setModal(false);
    await setManual(false);
    if (activeTab === "1") {
      await setActiveTab("1");
    }
    if (activeTab === "5") {
      await setActiveTab("5");
    }
  };
  
  const send = async () => {
    if (mode === "compra") {
      await saveVehicleData();
    }
    if (mode === "venta") {
      await saveSalesData();
    }
    if (mode === "extras") {
      await saveExtras();
    }
    await setModal(false);
    await setManual(false);
  };

  return (
    <>
      <div>
        <Modal isOpen={modal}>
          <ModalBody>¿Guadar los cambios antes de continuar?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={send}>
              Guardar
            </Button>
            {""}
            <Button color="secondary" onClick={() => toggle()}>
              Descartar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default ModalManual;
