import React, { useState, useEffect, useContext } from "react";
import { Form, Table } from "react-bootstrap";
import { post, getSignUrl } from "services/axiosCalls";
import { API } from "services/urlConstants";
import errorCall from "services/errorCall";
import moment from "moment";
import styles from "./index.module.scss";
import UploadComponent from "components/UploadComponent";
import CancelSignComponent from "components/CancelSignComponent";
import PaginationFooter from "components/PaginationFooter";
import ModalWarning from "components/ModalWarning";
import { successToast, errorToast } from "helpers/toastFunctions";
import ModalCalendar from "components/ModalCalendar";
import ModalPrintView from "components/ModalPrintView";
import Checkbox from "components/Checkbox";
import RolType  from "constants/roles";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import Locale from "constants/Locale";

import { createDraft, putLocal } from "services/Vehicle/vehicle.service";

import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
} from "reactstrap";

import ModalUpdateContract from "components/ModalUpdateContract";
import { UserContext } from "contexts/UserContext";
import {
  FaFilter,
  FaEye,
  FaRegCheckCircle,
} from "react-icons/fa";
import {
  MdEdit,
  MdDirectionsCarFilled,
  MdDeleteForever,
  MdErrorOutline,
  MdRefresh,
  MdSchedule,
  MdCarRepair,
} from "react-icons/md";
import { BsPaperclip } from "react-icons/bs";
import { IoMdDocument } from "react-icons/io";
import Select from "react-select";
import ModalTraslado from "components/ModalUpdateLocalVehicle";
import { environment } from "../../config/environment.config";

const PostventaContratos = ({ history }) => {
  const { user, rol, local} = useContext(UserContext);
  const { i18n } = useTranslation();
  const { t } = useTranslation('myDocumentation');
  const adminRoles = [RolType.GENERAL_ADMIN, RolType.TRADING_MANAGER, RolType.ADMINISTRATION]; 
  const docuRoles = [RolType.GENERAL_ADMIN, RolType.TRADING_AGENT, RolType.TRADING_MANAGER, RolType.ADMINISTRATION]; // roles 1 6 7 9
  const vtcRoles = [RolType.PURCHASING_AGENT]; // rol 17


  const [cancelSign, setCancelSign] = useState({
    open: false,
    document: null,
  });
  const [uploadDocument, setUploadDocument] = useState({
    open: false,
    document: null,
    type: "",
  });
  const [dropdownIdx, setDropdownIdx] = useState(-1);
  const [warningAlert, setWarningAlert] = useState(false);
  const [warningAlertDocument, setWarningAlertDocument] = useState(false);

  const [localFilter, setLocalFilter] = useState(null);

  const [elementToDelete, setElementToDelete] = useState(null);
  const [toggleDocuments, setToggleDocuments] = useState(null);
  const [documentsInfo, setDocumentsInfo] = useState([]);
  const [modalCalendar, setModalCalendar] = useState(null);
  const [modalPrintView, setModalPrintView] = useState();
  const [totalDocumentsInfo, setTotalDocumentsInfo] = useState(0);
  const [paginationMin, setPaginationMin] = useState(0);
  const [showNumb, setShowNumb] = useState(15);
  const [filterLocal, setFilterLocal] = useState([]);
  const [filterDocument, setFilterDocument] = useState([]);

  const [freeFilter, setFreeFilter] = useState("");
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();

  const [modalUpdateContract, setModalUpdateContract] = useState();
  const [changeLocal, setChangeLocal] = useState();
  const [checkFilters, setCheckFilters] = useState({
    contract_ok: false,
    delivery_date_ok: false,
    bill_date_ok: false,
    notAllOk: true,
  });
  const [disabledButton, setDisabledButton] = useState(false);
  const debouncedSearch = useDebounce(freeFilter, 750);

  const portugueseAccessProtection = i18n.language === Locale.ES 
 
  useEffect(() => {
    post(`/api/get_user_local`,{user})
      .then((res) => setLocalFilter(res))
      .catch((err) => errorToast(err.response.data.message));
  }, [user]);

  useEffect(() => {
    if (local) {
      changeDocumentState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    local,
    filterLocal,
    debouncedSearch,
    checkFilters,
    filterDocument,
    dateStart,
    dateEnd,
  ]);

  const dateStartHandler = (date) => {
    setDateStart(date);
  };

  const dateEndHandler = (date) => {
    setDateEnd(date);
  };


  useEffect(() => {
    document.title = "Flexicar | Contratos";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recallDocuments = async () => {
    changeDocumentState();
  };
  //Comprobamos los permisos de usuario activar el acceso en STATE

  const changeDocumentState = async (min, num) => {
    try {
      let documentos;
      if (filterDocument?.length > 0) {
        documentos = [];
        filterDocument.forEach((element) => {
          documentos.push(element.value);
        });
      }

      const dataCall = {
        min: min > -1 ? min : paginationMin,
        limit: num > -1 ? num : showNumb,
        getLocales: local,
        checkFilters,
        freeFilter,
        documentos,
        dateStart: dateStart
          ? moment(dateStart).format("YYYY-MM-DD")
          : undefined,
        dateEnd: dateEnd ? moment(dateEnd).format("YYYY-MM-DD") : undefined,
        onlyForLocal: filterLocal?.length > 0 ? filterLocal.map(function(l) {
          return l.value
        }) : false,
        user,
      };

      const getDocuments = await post(
        API.GET_DOCUMENTACION_FIRMAS_BY_LOCALES,
        dataCall
      );
      setTotalDocumentsInfo(getDocuments.count);
      setDocumentsInfo(getDocuments.data);
    } catch (e) {
      errorCall(e);
    }
  };

  const deleteStart = (id, type, id_documento) => {
    setElementToDelete({ id, type, id_documento });
    setWarningAlert(true);
  };

  const deleteStartDocument = (id_documento) => {
    setElementToDelete({ id_documento });
    setWarningAlertDocument(true);
  };

  const redirectURL = (url) => {
    window.open(url);
  };

  const goSignedUrl = async (id) => {
    const response = await getSignUrl(id);
    window.open(response.data.url);
  };

  const handleToggleDocument = (idx) => {
    toggleDocuments === idx
      ? setToggleDocuments(null)
      : setToggleDocuments(idx);
  };

  const cancelDelete = () => {
    setElementToDelete(null);
    setWarningAlert(false);
    setWarningAlertDocument(false);
  };

  const deleteFilters = () => {
    setPaginationMin(0);
    setShowNumb(15);
    setCheckFilters({
      contract_ok: false,
      delivery_date_ok: false,
      bill_date_ok: false,
      notAllOk: true,
    });
    setFilterLocal([]);
    setFilterDocument([]);
    setDateStart("");
    setDateEnd("");
    setFreeFilter("");
  };

  const resetPagination = () => {
    setPaginationMin(0);
    setShowNumb(15);
  };

  const handleDealersFilter = (e) => {
    setFilterLocal(e);
    resetPagination();
  };

  const handleDocumentFilter = (e) => {
    setFilterDocument(e);
    resetPagination();
  };

  const deleteEnd = async () => {
    try {
      const response = await post(API.DELETE_FILES, elementToDelete);

      if (!response.error) {
        recallDocuments();

        cancelDelete();
        successToast("Elemento borrado con exito");
      } else {
        errorToast(<span>{t("errors.errorDeletingElement")}</span>);
      }
    } catch (e) {
      errorCall(e);
    }
  };

  const deleteDocument = async () => {
    try {
      const response = await post(API.DELETE_DOCUMENT, elementToDelete);
      if (!response.error) {
        recallDocuments();
        cancelDelete();
        successToast("Elemento borrado con exito");
      } else {
        errorToast(<span>{t("errors.errorDeletingElement")}</span>);
      }
    } catch (e) {
      errorCall(e);
    }
  };

  const changePagination = (min, num) => {
    setPaginationMin(min);
    setShowNumb(num);
    changeDocumentState(min, num);
  };

  const updateState = (data) => {
    let newDocumentsInfo = documentsInfo;
    if (data.type === "documents") {
      let files = newDocumentsInfo.find(
        (e) => e.documentId === data.info.id_documento
      );
      if (files.files) {
        files.files.unshift(data.info);
      } else {
        files.files = [data.info];
      }
    }
    if (data.type === "contracts") {
      let file = newDocumentsInfo.find(
        (e) => e.documentId === data.info.id_documento
      );
      file.url = data.info.url;
    }

    setDocumentsInfo(newDocumentsInfo);
  };
  const redirectCar = (id) => history.push(`/main/vehicle/${id}?tab=datos`);
  const redirectLead = (id_lead, id_cliente, tipo) => {
    if (tipo === 1 || tipo === 2) {
      window.location.href = `${environment.REACT_HOST}/main/lead-venta/${id_lead}/${id_cliente}`;
    }
    if (tipo === 3 || tipo === 4) {
      window.location.href = `${environment.REACT_HOST}/main/lead-tasacion/${id_lead}`;
    }
  };

  const changeDropdownIdx = (idx) => {
    handleToggleDocument(idx);
    let selected = -1;
    if (idx !== dropdownIdx) selected = idx;
    setDropdownIdx(selected);
  };

  const createVehicleDraft = async ({ id, dealer }) => {
    
  try {
    const response = await createDraft(id);
    if (response.type === 2) {
      errorToast(<span>{t("errors.errorVehiclePublished")}</span>);
    } else if (response.id && !response.new && response.type === 1) {
      if (!vtcRoles.some((r) => rol.includes(r))) {
        history.push(`/main/validacion-vehiculo/${response.id}`);
      } else {
        errorToast(<span>{t("errors.errorVehicleValidation")}</span>);
      }
    } else if (response.id && response.new) {
      if (!vtcRoles.some((r) => rol.includes(r))) {
        successToast("Vehículo en validación creado");
      } else {
        await changeDealer({
          id: response.id,
          type: "draft",
          idDealer: dealer[0].value,
        });
        successToast("Vehículo en validación creado");
      }
    }
  } catch (e) {
    const codeError = e?.response?.data?.code;

    if (codeError === "vehicle.exists.chassisNumber") {
      errorToast(<span>{t("errors.errorVehicleInValidationExists")}</span>);
    } else if (codeError === "vehicle.exists.plate") {
      errorToast(<span>{t("errors.errorVehicleInValidationExistsPlate")}</span>);
    } else {
      errorToast(<span>{t("errors.errorCreatingVehicle")}</span>);
    }
  } finally {
    setDisabledButton(false)
  }
};

  const changeDealer = async ({ id, type, idDealer }) => {
    try {
      await putLocal({ id, type: type, idDealer: idDealer });
      await successToast(`Vehículo trasladado `);
      await setChangeLocal({ ...changeLocal, open: false });
    } catch (e) {
      errorToast(<span>{t("errors.errorVehicleTransfer")}</span>);
    }
  };

  const findStatus = (document) => {

    let today = moment().format("DD-MM-YYYY")
    let fecha_entrega = moment(document.fecha_entrega).format("DD-MM-YYYY")
    let delivery_status
    let button_style
    let disabledButton = false

    switch (document.status) {

      case 3:
        if (today >= fecha_entrega) {
          delivery_status = 'PDTE.CONFIRMAR'
          disabledButton = false
          button_style = `${styles.grey} ${styles.btnFlexicarMini}`
        }
        if (today < fecha_entrega) {
          delivery_status = 'PDTE.ENTREGA'
          disabledButton = false
          button_style = `${styles.yellow} ${styles.btnFlexicarMini}`
        }
        break;
      case 4:
        delivery_status = 'ENTREGADO'
        disabledButton = true
        button_style = `${styles.green} ${styles.btnFlexicarMini} ${styles.disabledBtn}`
        break;
      case 5:
        delivery_status = 'RETIRADO'
        disabledButton = false
        button_style = `${styles.grey} ${styles.btnFlexicarMini}`
        break;
      default:
        delivery_status = 'ENTREGAR'
        disabledButton = false
        button_style = `${styles.red} ${styles.btnFlexicarMini}`
    }

    return <button
        className={
          document.fecha_entrega
            ? button_style
            : `${styles.red} ${styles.btnFlexicarMini} `
        }
        disabled={disabledButton}
        onClick={() =>
          setModalCalendar({
            type: "delivery",
            document,
          })
        }
      >

      {document.fecha_entrega

        ? delivery_status
        : "ENTREGAR"
      }

      </button>
  }


  return (
    <div>
      <Container fluid={true}>
        <Row className="mt-4">
          <Col className={`col-md-2 col-xs-12`}>
            <div className={`pt-3 pb-3 ${styles.filtersContent}`}>
              <FaFilter size={20} color="#007bff" /> {t('filters')}
              <Select
                className="my-2"
                closeMenuOnSelect={false}
                isMulti
                placeholder={t('document')}
                options={[
                  { label: t('Contrato de Venta'), value: 1 },
                  { label: t('Reserva'), value: 2 },
                  { label: t('Compra Directa'), value: 3 },
                  { label: t('Gestión de Venta'), value: 4 },
                ]}
                onChange={handleDocumentFilter}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#fe9800",
                    primary25: "#ffc548",
                  },
                })}
              />
              {localFilter ? (
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  placeholder={t('locals')}
                  options={localFilter}
                  onChange={handleDealersFilter}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#fe9800",
                      primary25: "#ffc548",
                    },
                  })}
                />
              ) : null}
              <br />
              <div className="mb-3">
                <label>{t("from") + ":"}</label>
                <Form.Control
                  type="date"
                  name="dateStart"
                  value={dateStart}
                  onChange={(e) => dateStartHandler(e.target.value)}
                  className="mb-1"
                />
                <label>{t("to") + ":"}</label>
                <Form.Control
                  type="date"
                  name="dateEnd"
                  value={dateEnd}
                  onChange={(e) => dateEndHandler(e.target.value)}
                />
              </div>
              <Checkbox
                key={"notAllOK"}
                name={t('pendingTasks')}
                status={checkFilters.notAllOk}
                onChange={() => {
                  setCheckFilters({
                    contract_ok: false,
                    bill_date_ok: false,
                    delivery_date_ok: false,
                    notAllOk: !checkFilters.notAllOk,
                  });
                  setPaginationMin(0);
                }}
              />
              <Checkbox
                key={"contract_ok"}
                name={t('okContract')}
                status={checkFilters.contract_ok}
                onChange={() => {
                  setCheckFilters({
                    ...checkFilters,
                    notAllOk: false,
                    contract_ok: !checkFilters.contract_ok,
                  });
                  setPaginationMin(0);
                }}
              />
              <Checkbox
                key={"bill_date_ok"}
                name={t('okBilling')}
                status={checkFilters.bill_date_ok}
                onChange={() => {
                  setCheckFilters({
                    ...checkFilters,
                    notAllOk: false,
                    bill_date_ok: !checkFilters.bill_date_ok,
                  });
                  setPaginationMin(0);
                }}
              />
              <Checkbox
                key={"delivery_date_ok"}
                name={t('okSent')}
                status={checkFilters.delivery_date_ok}
                onChange={() => {
                  setCheckFilters({
                    ...checkFilters,
                    notAllOk: false,
                    delivery_date_ok: !checkFilters.delivery_date_ok,
                  });
                  setPaginationMin(0);
                }}
              />
              <br />
              <Button outline color="danger" block onClick={deleteFilters}>
              {t('delete')}
              </Button>
              <Button
                outline
                className="orange-btn mt-2"
                block
                onClick={() =>
                  window.open(
                    `${environment.PHP_HOST}/index.php?seccion=listacontratoscompraventa`
                  )
                }
              >
               {t('purchaseHistoric')}
              </Button>
            </div>
          </Col>
          <Col className="col-md-10 col-xs-6">
            <div className={`clearfix mb-3 ${styles.container2}`}>
              <span className="tittle ml-4">{t('myDocument')}</span>
            </div>
            <Row>
              <Col>
                <input
                  autoComplete="off"
                  placeholder={t('search')+ "..."}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={freeFilter}
                  onChange={(e) => setFreeFilter(e.target.value)}
                />
              </Col>
              <Col>
                <div className="text-right mt-3">
                  <span className="text-success">{t('documents') + ":"} </span>
                  <span className="text-primary">{totalDocumentsInfo}</span>
                </div>
              </Col>
            </Row>
            <div className={styles.margin10}>
              {documentsInfo ? (
                <div className={styles.smallFont}>
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t('type')}</th>
                        <th>{t('date')}</th>
                        <th>{t('client')}</th>
                        <th>{t('vehicle')}</th>
                        <th>{t('plate')}</th>
                        <th>{t('actions')}</th>
                      </tr>
                    </thead>
                    {documentsInfo.map((document, idx) => {
                      const datos = document.datos;
                      return (
                        <tbody key={`documentsInfo-${document.documentId}-${idx}`}>
                          <tr
                            key={"vehicle" + idx}
                            style={
                              idx % 2
                                ? {
                                    background: "#ffffff",
                                  }
                                : {
                                    background: "rgba(242,242,242,0.5)",
                                  }
                            }
                          >
                            <td
                              onClick={() => changeDropdownIdx(idx)}
                              className={document.files ? "pointer" : null}
                            >
                              {(document.files ||
                                document.url ||
                                document.documentNameCloud) &&
                                (dropdownIdx === idx ? (
                                  <div
                                    key="right"
                                    className={
                                      styles.marginPointer + " text-primary"
                                    }
                                  >
                                    ▼
                                  </div>
                                ) : (
                                  <div
                                    key="right"
                                    className={
                                      styles.marginPointer + " text-primary"
                                    }
                                  >
                                    ►
                                  </div>
                                ))}
                            </td>
                            <td>
                              <div className={styles.row}>
                                {document.url || document.documentNameCloud ? (
                                  <div
                                    title={t('contractUpdoaded')}
                                    className={styles.okIcon}
                                  >
                                    <FaRegCheckCircle size={23} />
                                  </div>
                                ) : document.sign_start_date ? (
                                  <div className={styles.iconsContainer}>
                                    <div
                                      title={t('refresh')}
                                      onClick={() => recallDocuments()}
                                      className={styles.refreshIcon}
                                    >
                                      <MdRefresh size={25} />
                                    </div>
                                    <div
                                      title={t('contractSend')}
                                      onClick={() =>
                                        setCancelSign({
                                          open: true,
                                          document,
                                        })
                                      }
                                      className={styles.pendingIcon}
                                    >
                                      <MdSchedule size={25} />
                                    </div>{" "}
                                  </div>
                                ) : (
                                  <div
                                  title={t('attachContract')}
                                    onClick={() =>
                                      setUploadDocument({
                                        open: true,
                                        document,
                                        type: "contracts",
                                        type_contract: document.tipo,
                                        documentId: document.documentId,
                                      })
                                    }
                                    className={styles.warningIcon}
                                  >
                                    <MdErrorOutline size={25} />
                                  </div>
                                )}
                                <span className={`ml-2 ${styles.noDecoration}`}>
                                  { t(`${document.tipoNombre}`) }
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className={styles.noDecoration}>
                                {document.fecha_contrato
                                  ? moment(document.fecha_contrato).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "Fecha no disponible"}
                              </span>
                            </td>
                            <td>
                              <span className={styles.noDecoration}>
                                {`${datos?.nombre} ${
                                  datos?.apellido1 ? datos.apellido1 : ""
                                } ${datos?.apellido2 ? datos.apellido2 : ""}`}
                              </span>
                            </td>
                            <td>
                              <span className={styles.noDecoration}>
                                {document.tipo === 1 ||
                                document.tipo === 3 ||
                                document.tipo === 4
                                  ? `${datos?.marca ? datos?.marca : ""}  ${
                                      datos?.modelo ? datos?.modelo : ""
                                    }`
                                  : `${datos?.vehiculo ? datos?.vehiculo : ""}`}
                              </span>
                            </td>
                            <td>
                              <span className={styles.noDecoration}>
                                {datos?.matricula
                                  ? datos?.matricula
                                  : "No disponible"}
                              </span>
                            </td>

                            <td className={`${styles.centerMargin} border-0`}>
                              <span className={`${styles.iconBox}`}>
                                {!(
                                  document.url || document.documentNameCloud
                                ) && (
                                  <FaEye
                                    title={t('see')}
                                    size={26}
                                    color={"#007bff"}
                                    role="button"
                                    onClick={() =>
                                      setModalPrintView({
                                        open: true,
                                        contractId: document.documentId,
                                        contractSignStartDate:
                                          document.sign_start_date,
                                        title: document.tipoNombre,
                                        tipo: document.tipo,
                                      })
                                    }
                                  />
                                )}
                              </span>
                              
                               <span className={styles.iconBox}>
                               {!(
                                 document.url ||
                                 document.documentNameCloud ||
                                 document.sign_start_date)
                                && (
                                 <MdEdit
                                   title={t('modify')}
                                   size={26}
                                   color={"#007bff"}
                                   role="button"
                                   onClick={() =>
                                     setModalUpdateContract({
                                       tipo:document.tipo,
                                       open: true,
                                       document,
                                     })
                                   }
                                 />
                               )}
                             </span> 
                            
                           
                              <span className={styles.bigIconBox}>
                                <div
                                  onClick={() =>
                                    setUploadDocument({
                                      open: true,
                                      type: "documents",
                                      document,
                                    })
                                  }
                                  className={styles.marginPointerBox}
                                >
                                  <BsPaperclip
                                    title={t('attach')}
                                    size={26}
                                    color={"#007bff"}
                                  />

                                  <div
                                    style={{
                                      width: "22px",
                                      textAlign: "center",
                                    }}
                                    className="btn-flexicar-blue-mini"
                                    onClick={() =>
                                      setUploadDocument({
                                        open: true,
                                        type: "documents",
                                        document,
                                      })
                                    }
                                  >
                                    {document.url || document.documentNameCloud
                                      ? document?.files?.length
                                        ? document?.files?.length + 1
                                        : 1
                                      : document?.files?.length}
                                  </div>
                                </div>
                              </span>
                             

                              <span className={styles.buttonBox}>
                                {document.tipoNombre === "Contrato de Venta" &&
                                  adminRoles.some((r) => rol.includes(r)) && (
                                    <button
                                      className={
                                        document.fecha_facturacion
                                          ? `${styles.green} ${styles.btnFlexicarMini}`
                                          : `${styles.red} ${styles.btnFlexicarMini}`
                                      }
                                      onClick={() =>
                                        setModalCalendar({
                                          type: "bill",
                                          document,
                                          matricula:
                                            datos?.matricula,
                                        })
                                      }
                                    >
                                      {document.fecha_facturacion
                                        ? "FACTURADO"
                                        : "FACTURAR"}
                                    </button>
                                  )}
                              </span>
                              <span className={styles.buttonBox}>
                                {document.tipoNombre === "Contrato de Venta" && !document.vehicle_id_stock &&
                                 docuRoles.some((r) => rol.includes(r)) && (
                                    <button
                                      className={
                                        document.fecha_entrega
                                          ? `${styles.green} ${styles.btnFlexicarMini}`
                                          : `${styles.red} ${styles.btnFlexicarMini}`
                                      }
                                      onClick={() =>
                                        setModalCalendar({
                                          type: "delivery",
                                          document,
                                        })
                                      }
                                    >
                                      {document.fecha_entrega
                                        ? "ENTREGADO"
                                        : "ENTREGAR"}

                                    </button>
                                  )}
                                {document.tipoNombre === "Contrato de Venta" && document.vehicle_id_stock &&
                                  docuRoles.some((r) => rol.includes(r)) && (
                                    findStatus(document)

                                  )}
                              </span>
                              <span className={styles.iconBox}>
                                {(document.tipo === 1 ||
                                  document.tipo === 2) && (
                                  <div
                                    onClick={() => {

                                      redirectCar(document.id_vehiculo);
                                    }}
                                    className={styles.marginPointer}
                                  >
                                    <MdDirectionsCarFilled
                                      title="Ir al vehículo"
                                      size={26}
                                      color={"#007bff"}
                                    />
                                  </div>
                                )}
                                {(document.tipo === 3 || document.tipo === 4) && (document.url || document.documentNameCloud) && !vtcRoles.some((r) => rol.includes(r)) ? (
                                  <button
                                  disabled={disabledButton}
                                  onClick={async () => {
                                      setDisabledButton(true);
                                      await createVehicleDraft({ id: document.documentId });
                                  }}
                                  className={styles.marginPointer}
                                >
                                  <MdCarRepair
                                    title="Crear validación VO"
                                    color={"#007bff"}
                                    size={28}
                                  />
                                </button>
                                ) : null}
                                {(document.tipo === 3 || document.tipo === 4) && (document.url || document.documentNameCloud) && vtcRoles.some((r) => rol.includes(r)) ? (
                                  <button
                                    disabled={!portugueseAccessProtection}
                                    onClick={() => setChangeLocal({ open: true, id_document: document.documentId })
                                    }
                                    className={styles.marginPointer}
                                  >
                                    <MdCarRepair
                                      title="Crear validación VO"
                                      color={portugueseAccessProtection ? "#007bff" : "#ccc"} 
                                      size={28}
                                      
                                    />
                                  </button>
                                ) : null}
                              </span>
                              <span className={styles.iconBox}>
                                {(document.tipo === 1 ||
                                  document.tipo === 2) && (
                                  <div
                                    onClick={() => {

                                      redirectLead(
                                        document.id_lead,
                                        document.id_cliente,
                                        document.tipo
                                      );
                                    }}
                                    className={styles.marginPointer}
                                  >
                                    <IoMdDocument
                                      title="Ir al lead"
                                      size={26}
                                      color={"#007bff"}
                                    />
                                  </div>
                                )}
                                {(document.tipo === 3 || document.tipo === 4) &&
                                  document.id_lead != null && (
                                    <div
                                      onClick={() => {
                                        redirectLead(
                                          document.id_lead,
                                          document.id_cliente,
                                          document.tipo
                                        );
                                      }}
                                      className={styles.marginPointer}
                                    >
                                      <IoMdDocument
                                        title="Ir al lead"
                                        size={26}
                                        color={"#007bff"}
                                      />
                                    </div>
                                  )}
                              </span>
                              <span className={styles.iconBox}>
                                {(rol.includes(RolType.GENERAL_ADMIN) || rol.includes(RolType.ADMINISTRATION) ||
                                  rol.includes(
                                    RolType.TRADING_MANAGER
                                  ) || rol.includes(
                                    RolType.TRADING_MANAGER_PILOT
                                  )  ) && (
                                  <div
                                    onClick={() =>
                                      deleteStartDocument(document.documentId)
                                    }
                                    className={styles.marginPointer}
                                  >
                                    <MdDeleteForever
                                      title={t('deleteDocument')}
                                      size={26}
                                      color="red"
                                    />
                                  </div>
                                )}
                              </span>
                            </td>
                          </tr>

                          {toggleDocuments === idx &&
                            (document.url || document.documentNameCloud) && (
                              <tr
                                key={document.fecha}
                                style={{
                                  background: "#a5e2ff",
                                }}
                              >
                                <td colSpan="12">
                                  <div className={styles.wrapRowSpace}>
                                    <div className={styles.width70rowSpace}>
                                      <span className={styles.noDecoration}>
                                        {`Contrato firmado, subido el ${moment(
                                          document.fecha_url
                                        ).format("DD-MM-YYYY HH:mm")}`}
                                      </span>
                                    </div>

                                    <div className={styles.width20rowSpace}>
                                      {document.documentNameCloud ? (
                                        <div
                                          onClick={() =>
                                            goSignedUrl(
                                              document.documentNameCloud
                                            )
                                          }
                                          className={styles.centerPointer}
                                        >
                                          <FaEye
                                            title={t('see')}
                                            size={26}
                                            color={"#007bff"}
                                            role="button"
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          onClick={() =>
                                            redirectURL(document.url)
                                          }
                                          className={styles.centerPointer}
                                        >
                                          <FaEye
                                            title={t('see')}
                                            size={26}
                                            color={"#007bff"}
                                            role="button"
                                          />
                                        </div>
                                      )}
                                      { (rol.includes(RolType.GENERAL_ADMIN) ||
                                          rol.includes(RolType.ADMINISTRATION) ||
                                          rol.includes(RolType.TRADING_MANAGER))
                                         && (
                                          <div
                                            onClick={() =>
                                              deleteStart(
                                                document.documentId,
                                                "contracts"
                                              )
                                            }
                                            className={styles.centerPointer}
                                          >
                                            <MdDeleteForever
                                              title={t('deleteDocument')}
                                              size={26}
                                              color="red"
                                            />
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          {toggleDocuments === idx &&
                            document.files &&
                            document.files.map((e, index) => (
                              <tr
                                key={e.fecha}
                                style={
                                  index % 2
                                    ? {
                                        background: "#f1f3f5",
                                      }
                                    : {
                                        background: "#d5dce1",
                                      }
                                }
                              >
                                <td colSpan="12">
                                  <div className={styles.wrapRowSpace}>
                                    <div className={styles.width70rowSpace}>
                                      <span className={styles.noDecoration}>
                                        {`${e.nombre} subido el ${moment(
                                          e.fecha
                                        ).format("DD-MM-YYYY HH:mm")}`}
                                      </span>
                                    </div>

                                    <div className={styles.width20rowSpace}>
                                      {e.sign_url_id ? (
                                        <div
                                          onClick={() =>
                                            goSignedUrl(e.sign_url_id)
                                          }
                                          className={styles.centerPointer}
                                        >
                                          <FaEye
                                            title={t('see')}
                                            size={26}
                                            color={"#007bff"}
                                            role="button"
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          onClick={() => redirectURL(e.url)}
                                          className={styles.centerPointer}
                                        >
                                          <FaEye
                                            title={t('see')}
                                            size={26}
                                            color={"#007bff"}
                                            role="button"
                                          />
                                        </div>
                                      )}

                                      <div
                                        onClick={() =>
                                          deleteStart(
                                            e.id,
                                            "documents",
                                            e.id_documento
                                          )
                                        }
                                        className={styles.centerPointer}
                                      >
                                        <MdDeleteForever
                                          title={t('deleteDocument')}
                                          size={26}
                                          color="red"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      );
                    })}
                  </Table>
                  <PaginationFooter
                    paginationMin={paginationMin}
                    total={totalDocumentsInfo}
                    showNumb={showNumb}
                    elements={documentsInfo}
                    changePagination={changePagination}
                  ></PaginationFooter>
                </div>
              ) : (
                <div className="d-flex justify-content-center m-5">
                  <Spinner type="grow" className="flexicarColor mr-5" />
                  <p className="flexicarColor"> Cargando documentación...</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <UploadComponent
        setUploadDocument={setUploadDocument}
        uploadDocument={uploadDocument}
        updateState={updateState}
        recall={recallDocuments}
        createVehicleDraft={createVehicleDraft}
        vtcRoles={vtcRoles}
        rol={rol}
      ></UploadComponent>
      <CancelSignComponent
        recall={recallDocuments}
        setCancelSign={setCancelSign}
        cancelSign={cancelSign}
      ></CancelSignComponent>
      <ModalWarning
        okButton={deleteEnd}
        cancelButton={cancelDelete}
        open={warningAlert}
      ></ModalWarning>
      <ModalWarning
        okButton={deleteDocument}
        cancelButton={cancelDelete}
        open={warningAlertDocument}
      ></ModalWarning>
      <ModalCalendar
        title={
          modalCalendar?.type === "bill"
            ? "Fecha de facturación"
            : "Fecha de entrega"
        }
        open={modalCalendar?.type}
        matricula={modalCalendar?.matricula}
        document={modalCalendar?.document}
        type={modalCalendar?.type}
        close={() => setModalCalendar(null)}
        recallDocuments={recallDocuments}
        setDocumentsInfo={setDocumentsInfo}
        documentsInfo={documentsInfo}
        findStatus={findStatus}
      />
      <ModalPrintView
        title={modalPrintView?.title}
        recall={recallDocuments}
        open={modalPrintView?.open === true}
        close={() => setModalPrintView()}
        contractId={modalPrintView?.contractId}
        contractSignStartDate={modalPrintView?.contractSignStartDate}
        tipo={modalPrintView?.tipo}
      />
      {modalUpdateContract?.open && (
        <ModalUpdateContract
          open={modalUpdateContract?.open === true}
          close={() => setModalUpdateContract()}
          document={modalUpdateContract?.document}
          recall={recallDocuments}
          user={user}
        />)}
      <ModalTraslado
        changeLocal={changeLocal}
        setChangeLocal={setChangeLocal}
        createVehicleDraft={createVehicleDraft}
      />
    </div>
  );
};

export default PostventaContratos;
