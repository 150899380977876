import React, { useEffect, useState, useRef } from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Checkbox from "components/Checkbox/";
import { post } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import errorCall from "services/errorCall";
import { successToast } from "../../helpers/toastFunctions";
import { environment } from "config/environment.config";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./index.module.scss";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Email from "../AutocompleteEmail/Email";
import { MdAccountCircle, MdPhone, MdEmail, MdEdit } from "react-icons/md";
import NoAutoCompleteInputSelect from "components/NoAutoCompleteInputSelect";


const ModalAddLead = ({
  state,
  launchModal,
  handleValues,
  setState,
  user,
  vehiculo,
}) => {
  const [stateTypes, setStateTypes] = useState([]);
  const [originTypes, setOriginTypes] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);
  const [provincias, setProvincias] = useState([]);

  const [condiciones, setCondiciones] = useState(false);
  const [comercial, setComercial] = useState(false);
  const customDomains = [
    "gmail.com", 
    "hotmail.com",
    "yahoo.es",
    "yahoo.com",
    "icloud.es",
    "icloud.com",
    "msn.com"
  ];
  
  const [inputVal, setInputVal] = useState("");
  const emailAutoCompleteRef = useRef();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const launchQuery = async (query, data) => {
    try {
      const dataCall = {
        data_query: query,
        data_call: data ? data : null,
      };
      const response = await post(API.FILTROS, { dataCall });
      const mapped = response.map((item) => ({ label: item.nombre, value: item.id }));
      query === "provincias" && setProvincias(mapped);
      query === "estado_lead_venta_acciones" && setStateTypes(mapped);
      query === "tipos_procedencia_lead" && setOriginTypes(mapped);
      query === "tipos_contacto_lead" && setContactTypes(mapped);
    } catch (e) {
      errorCall(e);
    }
  };

  useEffect(() => {
    launchQuery("estado_lead_venta_acciones", null);
    launchQuery("tipos_procedencia_lead", null);
    launchQuery("tipos_contacto_lead", null);
    launchQuery("provincias", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    setState({
      ...state,
      modal_add: false,
      modal_check: false,
      checkLead: {
        ...state.checkLead,
        estado: data.estado,
        forma_contacto: data.forma_contacto,
        origen: data.origen,
        email: data.email,
        provincia: data.provincia,
        municipio: data.municipio,
        nombre: data.nombre,
        apellido_1: data.apellido_1,
        apellido_2: data.apellido_2,
        telefono: data.telefono,
        telefono_secundario: data.telefono2,
        condiciones: data.condiciones,
        comercial: data.comercial,
      },
    });

    try {
      const dataCall = {
        data: {
          ...state.checkLead,
          estado: data.estado,
          forma_contacto: data.forma_contacto,
          origen: data.origen,
          email: data.email,
          provincia: data.provincia,
          municipio: data.municipio,
          nombre: data.nombre,
          apellido_1: data.apellido_1,
          apellido_2: data.apellido_2,
          telefono: data.telefono,
          telefono_secundario: data.telefono2,
          condiciones: data.condiciones,
          comercial: data.comercial,
        },
        agente: user,
        vehiculo,
      };
      const response = await post(API.CREATE, dataCall);

      if (response.status === "update") {
        const url = `${environment.REACT_HOST}/main/lead-venta/${response.id_lead}/${response.cliente}`;
        successToast(
          <span>
            <span>Lead modificado correctamente </span>
            <a
              className="toast-link"
              rel="noopener noreferrer"
              target="_blank"
              href={url}
            >
              VER LEAD
            </a>
          </span>
        );
      } else if (response.status === "create") {
        const url = `${environment.REACT_HOST}/main/lead-venta/${response.id_lead}/${response.cliente}`;
        successToast(
          <span>
            <span>Lead creado correctamente </span>
            <a
              className="toast-link"
              rel="noopener noreferrer"
              target="_blank"
              href={url}
            >
              VER LEAD
            </a>
          </span>
        );
      }
    } catch (e) {
      errorCall(e);
    }
  };

  useEffect(() => {
    setState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);


  return (
    <Modal
      className="modal-lg"
      isOpen={state.modal_add}
      toggle={() => launchModal("modal_add", null, false)}
    >
      <ModalHeader className={styles.modal_header}>Agregar Lead</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody className="p-4">
          <div
            className="mb-3"
            style={{
              padding: ".7rem",
              backgroundColor: "#2C2C2C",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span className="tittle ml-4">Datos de Contacto</span>
          </div>

          <Container>
            <Row>
              <Col>
              <span className="flex">
              <MdAccountCircle className="md-36 m-1" size={42} />
                <input
                  placeholder={"Nombre"}
                  className={`form-control ds-input mt-1 ${
                    errors.nombre && "is-invalid"
                  } `}
                  autocomplete="off"
                  type="text"
                  defaultValue={state.checkLead?.nombre}
                  {...register("nombre", {
                    required: true,
                  })}
                />
                </span>
              </Col>
              

              <Col>
                <input
                  placeholder={"1º apellido"}
                  className="form-control ds-input mt-1"
                  type="text"
                  autocomplete="off"
                  defaultValue={state.checkLead?.apellido_1}
                  {...register("apellido_1")}
                />
              </Col>

              <Col>
                <input
                  placeholder={"2º apellido"}
                  className="form-control ds-input mt-1"
                  type="text"
                  autocomplete="off"
                  defaultValue={state.checkLead?.apellido_2}
                  {...register("apellido_2")}
                />
              </Col>
            </Row>
            <Row className="my-4">
              <Col xs={3}>
              <span className="flex">
              <MdPhone className="md-36 m-1" size={42} />
                <input
                  placeholder={"Teléfono 1"}
                  className={`form-control ds-input mt-1 ${
                    errors.telefono && "is-invalid"
                  } `}
                  type="tel"
                  autocomplete="off"
                  {...register("telefono", {
                    required: true,
                  })}
                  defaultValue={state.checkLead?.telefono}
                />
                </span>
              </Col>

              <Col xs={3}>
              <span className="flex">
              <MdPhone className="md-36 m-1" size={42} />
                <input
                  placeholder={"Teléfono 2"}
                  className="form-control ds-input mt-1"
                  type="tel"
                  autocomplete="off"
                  defaultValue={state.checkLead?.telefono_secundario}
                  {...register("telefono2")}
                />
                </span>
              </Col>
              <Col>
              <span className="flex">
              <MdEmail className="md-36 m-1" size={36} />
              <Email domains={customDomains} ref={emailAutoCompleteRef} onCompletion={val => setInputVal(val)}>
                <input
                  type="email"
                  placeholder={"Email"}
                  autocomplete="off"
                  className="form-control ds-input mt-1"
                  defaultValue={state.checkLead?.email}
                  {...register("email")}
                  value={inputVal}
                  onChange={(e) => {setInputVal(e.target.value); emailAutoCompleteRef.current.change(e)}} onKeyDown={(e) => emailAutoCompleteRef.current.check(e)}
                />
                </Email>
                </span>
              </Col>
            </Row>

            <Row>
              <Col>
                <Controller
                  name="provincia"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Provincia"
                      {...field}
                      options={provincias}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#fe9800",
                          primary25: "#ffc548",
                        },
                      })}
                      components={{ Input: NoAutoCompleteInputSelect }}
                    />
                  )}
                />
              </Col>
              <Col>
                <input
                  placeholder={"Municipio"}
                  className="form-control ds-input mt-1"
                  type="text"
                  autocomplete="off"
                  defaultValue={state.checkLead?.municipio}
                  {...register("municipio")}
                />
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <Checkbox
                  key={"Condiciones"}
                  name={"He leído y acepto los términos y condiciones"}
                  defaultValue={state.checkLead.condiciones}
                  onClick={() => setCondiciones(!condiciones)}
                />

                <Checkbox
                  key={"Comercial"}
                  name={"Acepto recibir comunicaciones comerciales"}
                  defaultValue={state.checkLead.comercial}
                  onClick={() => setComercial(!comercial)}
                />
              </Col>
            </Row>
          </Container>
          <div
            className="clearfix mb-3"
            style={{
              padding: ".7rem",
              backgroundColor: "#2C2C2C",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span className="tittle ml-4">Información Básica del Lead</span>
          </div>
          <Container>
            <Row>
              <Col>
                <Controller
                  name="estado"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      placeholder="Estado"
                      {...field}
                      options={stateTypes}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#fe9800",
                          primary25: "#ffc548",
                        },
                      })}
                      components={{ Input: NoAutoCompleteInputSelect }}
                    />
                  )}
                />
                {errors.estado && (
                  <span className={styles.custom_error}>
                    Seleccione estado del lead
                  </span>
                )}
              </Col>

              <Col>
                <Controller
                  name="origen"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      placeholder="Origen"
                      {...field}
                      options={originTypes}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#fe9800",
                          primary25: "#ffc548",
                        },
                      })}
                    />
                  )}
                />
                {errors.origen && (
                  <span className={styles.custom_error}>
                    Seleccione origen del lead
                  </span>
                )}
              </Col>

              <Col>
                <Controller
                  name="forma_contacto"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      placeholder="Forma de contacto"
                      {...field}
                      options={contactTypes}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#fe9800",
                          primary25: "#ffc548",
                        },
                      })}
                    />
                  )}
                />
                {errors.forma_contacto && (
                  <span className={styles.custom_error}>
                    Seleccione forma de contacto
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
              <span className="flex">
              <MdEdit className="md-36 m-1" size={36} />
                <input
                  placeholder={"Observaciones sobre el vehículo"}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={state.checkLead?.observaciones}
                  onChange={(e) =>
                    handleValues(e, "observaciones", "checkLead")
                  }
                />
                </span>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button className={styles.btn_custom} type="submit">
            Crear Lead
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => launchModal("modal_add", null, false)}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModalAddLead;
