import DatePicker from "components/DatePicker";
import { MdAccountCircle, MdToday, MdVpnKey } from "react-icons/md";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import styles from "../index.module.scss";

const ModalEditPerson = ({
  show,
  setShow,
  person,
  setPerson,
  updatePerson,
}) => {
  const disposeModal = () => setShow(false);

  return (
    <Modal className="modal-lg" centered isOpen={show} toggle={disposeModal}>
      <ModalHeader toggle={disposeModal}>Editar persona</ModalHeader>

      <ModalBody>
        <Form className={`${styles.card} d-flex flex-row flex-wrap`}>
          <FormGroup className="col-12 col-lg-4">
            <div className={styles.icon_label}>
              <MdAccountCircle size={24} />
              <Label>Nombre*</Label>
            </div>
            <Input
              required
              type="text"
              value={person.name}
              onChange={(e) => {
                e.persist();
                setPerson((prevPerson) => ({
                  ...prevPerson,
                  name: e.target.value,
                }));
              }}
            />
          </FormGroup>
          <FormGroup className="col-12 col-lg-4">
            <Label>Primer Apellido*</Label>
            <Input
              required
              type="text"
              value={person.surname1}
              onChange={(e) => {
                e.persist();
                setPerson((prevPerson) => ({
                  ...prevPerson,
                  surname1: e.target.value,
                }));
              }}
            />
          </FormGroup>
          <FormGroup className="col-12 col-lg-4">
            <Label>Segundo Apellido*</Label>
            <Input
              required
              type="text"
              value={person.surname2}
              onChange={(e) => {
                e.persist();
                setPerson((prevPerson) => ({
                  ...prevPerson,
                  surname2: e.target.value,
                }));
              }}
            />
          </FormGroup>
          <FormGroup className="col-12 col-lg-4">
            <div className={styles.icon_label}>
              <MdVpnKey size={24} />
              <Label>DNI*</Label>
            </div>
            <Input
              required
              type="text"
              value={person.dni}
              onChange={(e) => {
                e.persist();
                setPerson((prevPerson) => ({
                  ...prevPerson,
                  dni: e.target.value,
                }));
              }}
            />
          </FormGroup>
          <FormGroup className="col-12 col-lg-4">
            <div className={styles.icon_label}>
              <MdToday size={24} />
              <Label>Fecha de nacimiento</Label>
            </div>
            <DatePicker
              date={new Date(person.birthDate)}
              setDate={(date) =>
                setPerson((prevPerson) => ({
                  ...prevPerson,
                  birthDate: new Date(date),
                }))
              }
            />
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <button
          className="btn orange-btn"
          onClick={async () => {
            await updatePerson();
            disposeModal();
          }}
        >
          Actualizar persona
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEditPerson;
