const RolType = {
  GENERAL_ADMIN: 1,
  PAWN_MANAGER: 2,
  SALES_AGENT: 3,
  PENALTY_FEE_MANAGER: 4,
  CALL_CENTER: 5,
  TRADING_AGENT: 6,
  TRADING_MANAGER: 7,
  MARKETING: 8,
  ADMINISTRATION: 9,
  BUSINESS: 10,
  EXTERNAL_LEADS: 13,
  USER_MANAGER: 14,
  SALES_CALL_CENTER_AGENT: 15,
  SALES_CALL_CENTER_SUPERVISOR: 16,
  PURCHASING_AGENT: 17,
  AFTER_SALES_AGENT: 18,
  AFTER_SALES_SUPERVISOR: 19,
  TRADING_MANAGER_PILOT: 20,
  TRADING_AGENT_PILOT: 21,
  FINANCING_AGENT: 22,
  CUSTOMER_CATCHER: 23,
  CUSTOMER_CATCHER_SUPERVISOR: 24,
  VIDEOTUTORIALS_ADMIN: 25,
  LEGAL_ADVISER: 26,
  PURCHASING_CALL_CENTER_SUPERVISOR: 27,
  CENTRAL_ADMIN: 28,
  CENTRAL_APPRAISER:36,
  WARRANTY: 37
};

export const IS_SUPER_ADMIN = (userType) => userType === 1;

export default RolType;
