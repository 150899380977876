import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiAlertCircle, FiPaperclip } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

import styles from "./index.module.scss";
import FormattedNumberInput from "components/FormattedNumberInput";
import CaseComment from "components/CaseComment";
import UploadNoCallComponent from "components/UploadNoCallComponent";
import { UserContext } from "contexts/UserContext";
import { errorToast } from "helpers/toastFunctions";
import instance from "services/base";
import { API } from "services/urlConstants";
import Loading from "views/Loading/Loading";

const CasoComments = ({ caseId }) => {
  const { user: agentId } = useContext(UserContext);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
    formState,
  } = useForm();

  const [isLoading, setIsLoading] = useState(true);

  const [comments, setComments] = useState([]);

  const [file, setFile] = useState(null);
  const [showAddFileModal, setShowAddFileModal] = useState(false);

  const resetAddCommentForm = useCallback(() => {
    resetForm();
    setFile(null);
  }, [resetForm]);

  const addNewComment = async (commentInfo) => {
    try {
      const formData = new FormData();
      if (file) {
        formData.append("file", file.object);
        formData.append("fileName", file.name);
      }
      formData.append("caso", caseId);
      formData.append("comentario", commentInfo.newCommentContent);
      formData.append("agente", agentId);
      if (commentInfo.newCommentAmount)
        formData.append("amount", commentInfo.newCommentAmount);

      await instance.post(API.ADD_COMMENT, formData);
      const newComments = await instance.post(API.GET_COMMENTS, {
        id: Number(caseId),
      });
      setComments(newComments.data);
    } catch (err) {
      errorToast(
        err?.response?.data?.message || "Error al crear el comentario"
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const commentsResponse = await instance.post(API.GET_COMMENTS, {
          id: Number(caseId),
        });
        setComments(commentsResponse.data);
      } catch (err) {
        errorToast(
          err?.response?.data?.message || "Error al obtener los comentarios"
        );
      }

      setIsLoading(false);
    })();
  }, [caseId]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) resetAddCommentForm();
  }, [formState, resetAddCommentForm]);

  if (isLoading) return <Loading />;

  return (
    <div className={styles.wrapper}>
      <form
        className={styles.newCommentForm}
        onSubmit={handleSubmit(addNewComment)}
      >
        <div>
          <div className={`${styles.inputWrapper} ${styles.newCommentText}`}>
            <label>Comentario *</label>
            <textarea
              placeholder="Escribe aquí tu comentario"
              {...register("newCommentContent", {
                required: {
                  value: true,
                  message: "El comentario no puede estar vacio",
                },
              })}
            />
            {errors.newCommentContent && (
              <p className={styles.customSelectError}>
                <FiAlertCircle size={20} />
                {errors.newCommentContent.message}
              </p>
            )}
          </div>

          <div className={`${styles.inputWrapper} ${styles.newCommentAmount}`}>
            <label>Importe</label>
            <Controller
              name="newCommentAmount"
              control={control}
              render={({ field }) => <FormattedNumberInput {...field} />}
            />
          </div>
        </div>

        <div className={styles.newCommentDoc}>
          {!file ? (
            <span>
              <FiPaperclip
                size={36}
                onClick={() => setShowAddFileModal(true)}
              />
              <span style={{ color: "#D34600" }}>Adjuntar archivo</span>
            </span>
          ) : (
            <span>
              <RiDeleteBin6Line
                size={36}
                color="#ED0530"
                onClick={() => setFile(null)}
              />
              <span style={{ color: "black" }}>{file.name}</span>
            </span>
          )}
        </div>

        <button className="btn btn-flexicar-orange" type="submit">
          Añadir
        </button>
      </form>

      <hr className={styles.divider} />

      {comments.map((comment) => (
        <Fragment key={comment.documentId}>
          <CaseComment
            comment={comment}
            setComment={(updatedComment) =>
              setComments(
                comments.map((comm) =>
                  comm.documentId === updatedComment.documentId
                    ? { ...comm, ...updatedComment }
                    : comm
                )
              )
            }
          />
          <hr className={styles.divider} />
        </Fragment>
      ))}

      <UploadNoCallComponent
        onFile={setFile}
        show={showAddFileModal}
        setShow={setShowAddFileModal}
      />
    </div>
  );
};

export default CasoComments;
