import { environment } from "config/environment.config";
import { getCookie } from "../../helpers/getCookie";
import instance from "../BaseService";

export const getDealers = async (route, offset) => {
  try {
    const response = await instance.get(`/api/v1${route}`, {
      headers: { Authorization: `Bearer ${getCookie("JWT")}` },
      params: {
        /* limit: 20, offset: offset */
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getDealer = async (route, id) => {
  try {
    const response = await instance.get(`/api/v1${route}/${id}`, {
      headers: { Authorization: `Bearer ${getCookie("JWT")}` },
      params: {},
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const postDealer = async (route, data) => {
  try {
    const response = await instance.post(`/api/v1${route}`, data, {
      headers: {
        Authorization: `Bearer ${getCookie("JWT")}`,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const patchDealer = async (route, data) => {
  try {
    const response = await instance.patch(`/api/v1${route}`, data, {
      headers: {
        Authorization: `Bearer ${getCookie("JWT")}`,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getSocieties = async (route) => {
  try {
    const response = await instance.get(`/api/v1${route}`, {
      headers: { Authorization: `Bearer ${getCookie("JWT")}` },
      params: {
        /* limit: 20, offset: offset */
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getEncargados = async (route) => {
  try {
    const response = await instance.get(`/api/v1${route}`, {
      headers: { Authorization: `Bearer ${getCookie("JWT")}` },
      params: {
        /* limit: 20, offset: offset */
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getAddress = async (address) => {
  try {
    const response = await instance.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${environment.API_KEY_GOOGLEMAPS}`
    );
    return response;
  } catch (e) {
    throw e;
  }
};
