import { getCookie } from "helpers/getCookie";
import instance from "./BaseService";

export const getRoles = async (route) => {
  try {
    const response = await instance.get(`/api/v1/filters${route}`, {
      headers: { Authorization: `Bearer ${getCookie("JWT")}` },
      params: {},
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export default getRoles;
