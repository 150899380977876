import { useForm } from "react-hook-form";
import { checkVideo } from "services/youtubeApi.service";
import {
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import styles from "./index.module.scss";
import {
  checkChannelId,
  getDuration,
  getYoutubeVideoId,
} from "../../helpers/videoTutorials";

const CheckVideoModal = (props) => {
  const {
    modalCheckVideo,
    setModalCheckVideoHandler,
    setModalAddVideoHandler,
    setVideoApiData,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    checkVideo(getYoutubeVideoId(data.url))
      .then((response) => {
        setVideoApiData({
          title: response.items[0].snippet.title,
          description: response.items[0].snippet.description,
          duration: getDuration(response.items[0].contentDetails.duration),
          url: data.url,
        });
        setModalCheckVideoHandler(false);
        setModalAddVideoHandler(true);
        reset();
      })
      .catch((e) => {
        throw e;
      });
  };

  const cancelModal = () => {
    reset();
    setModalCheckVideoHandler();
  };

  return (
    <div>
      <Modal isOpen={modalCheckVideo} toggle={cancelModal}>
        <ModalHeader className={styles.modal_header} toggle={cancelModal}>
          {"Añadir nuevo video"}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Row className="m-3">
                <input
                  className={`${errors.url && "is-invalid"} ${styles.input}`}
                  placeholder="Introduzca url del video"
                  {...register("url", {
                    required: true,
                    pattern: {
                      value:
                        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_~#?&/=]*)/,
                      message: "Introduzca una URL válida",
                    },
                    validate: {
                      videoFlexicar: (value) => {
                        return checkVideo(getYoutubeVideoId(value))
                          .then((response) => checkChannelId(response))
                          .catch((e) => {
                            throw e;
                          });
                      },
                    },
                  })}
                />
                {errors.url?.type === "required" && (
                  <div className="invalid-feedback">Introduzca URL</div>
                )}
                {errors.url?.message && (
                  <div className="invalid-feedback">{errors.url.message}</div>
                )}
                {errors.url?.type === "videoFlexicar" && (
                  <div className="invalid-feedback">
                    El video no pertenece al canal de Flexicar España
                  </div>
                )}
              </Row>
            </Container>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            className={styles.btn_custom}
            onClick={handleSubmit(onSubmit)}
          >
            Añadir
          </Button>
          <Button color="secondary" onClick={cancelModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CheckVideoModal;
