import axios from "axios";
import { environment } from "config/environment.config";
import { getCookie } from "helpers/getCookie";

const instance = axios.create({
  baseURL: environment.API_CRM,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  timeout: 25000,
});

instance.interceptors.request.use(config => {
  const token = getCookie("JWT");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
export default instance
