import React from "react";
import Filter from "components/FilterManual";
import styles from "./index.module.scss";
import { Input, Col } from "reactstrap";
import {
  makes,
  models,
  years,
  fuels,
  transmision,
  body,
  version,
} from "services/Vehicle/vehicle.service";
import classes from "./index.module.scss";
import Locale from "constants/Locale";
import { useTranslation } from "react-i18next";
const DatosVehiculoClasico = ({
  vehiculo,
  setVehiculo,
  className,
  mode,
  type,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('classicVehicleData');
  const getDataAdd = (filter, value) => {
    if (!vehiculo?.jato || mode === "MANUAL") {
      switch (filter) {
        case "jato":
          setVehiculo({
            ...vehiculo,
            [filter]: !value,
            modelo: "",
            matriculacion: "",
            combustible: "",
            ccambios: "",
            carroceria: "",
            version: "",
          });
          break;
        case "marca_vehiculo":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            modelo: "",
          });
          break;
        default:
          setVehiculo({
            ...vehiculo,
            [filter]: value,
          });

          break;
      }
    }
    if (vehiculo?.jato) {
      switch (filter) {
        case "jato":
          setVehiculo({
            ...vehiculo,
            [filter]: !value,
          });
          break;

        case "marca_vehiculo":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            modelo: "",
            matriculacion: "",
            combustible: "",
            ccambios: "",
            carroceria: "",
            version: "",
          });
          break;
        case "modelo":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            matriculacion: "",
            combustible: "",
            ccambios: "",
            carroceria: "",
            version: "",
          });
          break;
        case "matriculacion":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            combustible: "",
            ccambios: "",
            carroceria: "",
            version: "",
          });
          break;
        case "combustible":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            ccambios: "",
            carroceria: "",
            version: "",
          });
          break;
        case "ccambios":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            carroceria: "",
            version: "",
          });
          break;
        case "carroceria":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
            version: "",
          });
          break;
        case "version":
          setVehiculo({
            ...vehiculo,
            [filter]: value,
          });
          break;
        default:
          break;
      }
    }
  };
  return (
    <>
      <h4 className={classes.bold} style={{ display: "inline-block" }}>
        Datos básicos del vehículo
      </h4>
      {type === "draft" && i18n.language === Locale.ES ? (
        <label for="valid" title="Change to true" className={styles.switch}>
          <input
            id="control-valid"
            type="checkbox"
            setter={"jato"}
            name="valid"
            checked={vehiculo.jato}
            onChange={() => {
              getDataAdd("jato", vehiculo.jato);
            }}
          />
          <span>Jato</span>
          <span>Clásico</span>
        </label>
      ) : null}

      <Col xs="12" sm="12">
        <div className={styles.row} style={{ marginTop: ".25rem" }}>
          <div
            className={`col-md-4 col-sm-12 ${
              !className ? styles.floatingLabel : "none"
            }`}
          >
            <label
              className={
                vehiculo?.marca_vehiculo ? styles.select : styles.notSelect
              }
            >
              {t('brand')}:{" "}
            </label>
            <div className={styles.filter}>
              <Filter
                key={"Marca del Vehiculo"}
                setter={"marca_vehiculo"}
                sendData={getDataAdd}
                parentValues={vehiculo?.marca_vehiculo}
                name={t('brand')}
                mode={mode}
                type={makes}
                vehiculo={vehiculo}
                disabled={type === "stock" ? true : false}
              />
            </div>
          </div>

          <div
            className={`col-md-4 col-sm-12 ${
              !className ? styles.floatingLabel : "none"
            }`}
          >
            <label
              className={vehiculo?.modelo ? styles.select : styles.notSelect}
            >
              {t('model')}:
            </label>
            <div className={styles.filter}>
              <Filter
                key={"Modelo Vehiculo"}
                disabled={
                  (!vehiculo.marca_vehiculo && mode === "JATO") 
                    ? true
                    : false
                }
                sendData={getDataAdd}
                vehiculo={vehiculo}
                parentValues={vehiculo?.modelo}
                setter={"modelo"}
                name={t('model')}
                type={models}
                mode={mode}
                data={vehiculo?.marca_vehiculo[0]?.value}
              />
            </div>
          </div>

          <div
            className={`col-md-4 col-sm-12 ${
              !className ? styles.floatingLabel : "none"
            }`}
          >
            <label
              className={
                vehiculo?.matriculacion ? styles.select : styles.notSelect
              }
            >
              {t('year')} :
            </label>
            <div className={styles.filter}>
              <Filter
                vehiculo={vehiculo}
                setVehiculo={setVehiculo}
                key={"Año"}
                sendData={getDataAdd}
                parentValues={vehiculo?.matriculacion}
                setter={'matriculacion'}
                type={years}
                mode={mode}
                data={{
                  makeId: vehiculo?.marca_vehiculo[0]?.value,
                  modelId: vehiculo?.modelo[0]?.value,
                }}
                className={
                  !className
                    ? !vehiculo.modelo
                      ? ` ${styles.disabled} `
                      : ` ${styles.outline}`
                    : "none"
                }
                disabled={!vehiculo.modelo && mode === "JATO" ? true : false}
              />
            </div>
          </div>
        </div>

        <div className={styles.row} style={{ marginTop: ".25rem" }}>
          <div
            className={`col-md-6 col-sm-12 ${
              !className ? styles.floatingLabel : "none"
            }`}
          >
            <label
              className={
                vehiculo?.combustible ? styles.select : styles.notSelect
              }
            >
              {t('fuel')}:
            </label>
            <div className={styles.filter}>
              <Filter
                vehiculo={vehiculo}
                setVehiculo={setVehiculo}
                key={"Combustible"}
                sendData={getDataAdd}
                parentValues={vehiculo?.combustible}
                setter={"combustible"}
                type={fuels}
                mode={mode}
                data={{
                  makeId: vehiculo?.marca_vehiculo[0]?.value,
                  modelId: vehiculo?.modelo[0]?.value,
                  year: vehiculo?.matriculacion[0]?.value,
                }}
                className={
                  !className
                    ? !vehiculo.matriculacion
                      ? ` ${styles.disabled} `
                      : ` ${styles.outline}`
                    : "none"
                }
                disabled={
                  !vehiculo.matriculacion && mode === "JATO" ? true : false
                }
              />
            </div>
          </div>

          <div
            className={`col-md-6 col-sm-12 ${
              !className ? styles.floatingLabel : "none"
            }`}
          >
            <label
              className={vehiculo?.ccambios ? styles.select : styles.notSelect}
            >
              {t('gearbox')}:
            </label>
            <div className={styles.filter}>
              <Filter
                vehiculo={vehiculo}
                setVehiculo={setVehiculo}
                key={"Caja de Cambios"}
                sendData={getDataAdd}
                parentValues={vehiculo?.ccambios}
                setter={"ccambios"}
                type={transmision}
                mode={mode}
                data={{
                  makeId: vehiculo?.marca_vehiculo[0]?.value,
                  modelId: vehiculo?.modelo[0]?.value,
                  year: vehiculo?.matriculacion[0]?.value,
                  fuelId: vehiculo?.combustible[0]?.value,
                }}
                className={
                  !className
                    ? !vehiculo.combustible
                      ? ` ${styles.disabled} `
                      : ` ${styles.outline}`
                    : "none"
                }
                disabled={
                  !vehiculo.combustible && mode === "JATO" ? true : false
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.row} style={{ marginTop: ".25rem" }}>
          <div
            className={`col-md-6 col-sm-12 ${
              !className ? styles.floatingLabel : "none"
            }`}
          >
            <label
              className={
                vehiculo?.carroceria ? styles.select : styles.notSelect
              }
            >
              {t('body')}:
            </label>
            <div className={styles.filter}>
              <Filter
                vehiculo={vehiculo}
                setVehiculo={setVehiculo}
                key={"Carrocería"}
                sendData={getDataAdd}
                parentValues={vehiculo?.carroceria}
                name={t('body')}
                setter={"carroceria"}
                type={body}
                mode={mode}
                data={{
                  makeId: vehiculo?.marca_vehiculo[0]?.value,
                  modelId: vehiculo?.modelo[0]?.value,
                  year: vehiculo?.matriculacion[0]?.value,
                  fuelId: vehiculo?.combustible[0]?.value,
                  transmissionId: vehiculo?.ccambios[0]?.value,
                }}
                className={
                  !className
                    ? !vehiculo.ccambios
                      ? ` ${styles.disabled} `
                      : ` ${styles.outline}`
                    : "none"
                }
                disabled={!vehiculo.ccambios && mode === "JATO" ? true : false}
              />
            </div>
          </div>

          <div
            className={`col-md-6 col-sm-12 ${
              !className ? styles.floatingLabel : "none"
            }`}
          >
            <label
              className={
                !className
                  ? vehiculo?.version
                    ? styles.select
                    : styles.notSelect
                  : "none"
              }
            >
              {t('version')}:
            </label>
            <div className={!className ? styles.filter : "none"}>
              {mode === "JATO" ? (
                <Filter
                  vehiculo={vehiculo}
                  setVehiculo={setVehiculo}
                  key={"Versión"}
                  setter={"version"}
                  sendData={getDataAdd}
                  disabled={!vehiculo.carroceria ?? true}
                  parentValues={vehiculo?.version}
                  name={t('version')}
                  type={version}
                  mode={mode}
                  data={{
                    makeId: vehiculo?.marca_vehiculo[0]?.value,
                    modelId: vehiculo?.modelo[0]?.value,
                    year: vehiculo?.matriculacion[0]?.value,
                    fuelId: vehiculo?.combustible[0]?.value,
                    transmissionId: vehiculo?.ccambios[0]?.value,
                    bodyId: vehiculo?.carroceria[0]?.value,
                  }}
                  className={
                    !className
                      ? !vehiculo.carroceria
                        ? ` ${styles.disabled} `
                        : ` ${styles.outline}`
                      : "none"
                  }
                />
              ) : (
                <Input
                  placeholder={"Version"}
                  className="form-control ds-input "
                  type="text"
                  value={vehiculo?.version instanceof Array ? vehiculo?.version[0]?.label : vehiculo?.version}
                  onChange={(e) => {
                    setVehiculo({
                      ...vehiculo,
                      version: [
                        {
                          value: e.target.value,
                          label: e.target.value,
                          id: e.target.id,
                        },
                      ],
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default DatosVehiculoClasico;
