import { IoClose } from "react-icons/io5";

import MultiSelect from "components/MultiSelect";

import "styles/components/search-filter-extended.scss"

/**
 * A component that displays an input search and a dropdown multiselect to filter a list.
 *
 * @param {Object} props - The component props.
 * 
 * @param {string} props.searchLabel - Label for the input search.
 * @param {string} props.searchValue - Value of the input search.
 * @param {function} props.handleChange - Function to display the search from the input search.
 * @param {string} props.dropdownLabel - Label for the dropdown multiselect.
 * @param {string} props.dropdownPlaceholder - Placeholder for the dropdown multiselect.
 * @param {Array<{value: number, label: string}>} props.dropdownSelectOptions - An array of dropdown multiselect options.
 * @param {Array<{value: number, label: string}>} props.dropdownSelected - An array of dropdown multiselect values selected.
 * @param {function} props.setDropdownSelected - Sets dropdown multiselect values.

 * @returns {React.ReactNode} The rendered React component.
 */

const SearchFilterExtended = ({
    searchLabel,
    searchValue,
    handleChange,
    dropdownLabel,
    dropdownPlaceholder,
    dropdownSelectOptions,
    dropdownSelected,
    setDropdownSelected
}) => {

    const quitValue = (value) => {
        const _dropdownSelected = [...dropdownSelected];
        setDropdownSelected(_dropdownSelected.filter(x => x.value !== value));
    };

    const renderFilterListRender = dropdownSelected.map(d => (
        <div className="chipContainer" key={d.value}>
            {d.label}{" "}
            <span
                className="react-dropdown-select-option-remove pointer ml-1"
                onClick={() => quitValue(d.value)}
            >
                <IoClose size={14} />
            </span>
        </div>
    ));

    return (
        <div className="d-flex flex-wrap">
            <div className="col-12 col-md-6 mb-2 pl-md-3 px-0">
                    <label className="m-0">{searchLabel}</label>
                    <input
                        style={{ height: "42px" }}
                        autoComplete="off"
                        placeholder="Buscar..."
                        className="form-control ds-input"
                        type="text"
                        value={searchValue}
                        onChange={(e) => handleChange(e)}
                    />
            </div>
            <div className="col-12 col-md-6 mb-2 pl-md-3 px-0 multiselectCustomChips">
                <label className="m-0">{dropdownLabel}</label>
                <MultiSelect
                    placeholder={dropdownPlaceholder}
                    options={dropdownSelectOptions}
                    value={dropdownSelected}
                    onChange={(values) => setDropdownSelected(values)}
                />
            </div>
            <div className="col-12 d-flex flex-wrap mb-2 mt-1">
                {renderFilterListRender}
            </div>
        </div>
    );
};

export default SearchFilterExtended;
