import React from "react";
import styles from "./index.module.scss";

const ValidationGroupButtons = ({ disabled, onOpenModal, isVerifiable }) => {

  return (
    <>
      <button
        disabled={disabled}
        className={`${styles.btnSecondary} mr-3`}
        type="submit"
      >
        Guardar Borrador
      </button>
      <button
        disabled={disabled && !isVerifiable}
        className={`${styles.btnFlexicar} mr-3`}
        onClick={onOpenModal}
        type="button"
      >
        Verificar Preparación
      </button>
    </>
  );
};

export default ValidationGroupButtons;