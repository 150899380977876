import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import FiltersBlock from "./components/FiltersBlock";
import Filter from "../../components/Filter";
import Pagination from "./components/Pagination";
import styles from "./index.module.scss";
import TableStock from "./components/TableStock";
import { dispatcher } from "../../redux/actions/dispatchers";
import { post } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import errorCall from "services/errorCall";
import ModalLead from "components/ModalLead";
import ModalAddLead from "components/ModalAddLead";
import ModalCambioPosesion from "components/ModalCambioPosesion";
import { errorToast } from "../../helpers/toastFunctions";
import { useDebouncedEffect } from "../../helpers/useDebouncedEffect";
import moment from "moment";
import { UserContext } from "contexts/UserContext";
import { GoGraph } from "react-icons/go";
import { FiFilter } from "react-icons/fi";
import Loading from "views/Loading/Loading";
import ModalFilters from "components/ModalFilters";
import Mainlayout from "layouts/MainLayout";
import { useTranslation } from "react-i18next";

const Stock = ({ vehicle_search, history, vehicleSearch, locals }) => {
  const { t } = useTranslation("stock");
  const { rol } = useContext(UserContext);
  const { user } = useContext(UserContext);

  const [callBackSetState, setCallBackSetState] = useState({
    searchCars: false,
    filter: false,
    filterList_Km: false,
    filterList_Euros: false,
    filterList_Days: false,
    filterList_Mod: false,
    filterList_Anio: false,
    filterList_Cv:false
  });
  const [brandDisabled, setBrandDisabled] = useState(true);
  const [brandList, setBrandList] = useState([0]);
  const [cars, setCars] = useState([]);
  const [totalCars, setTotalCars] = useState(0);
  const [paginationMin, setPaginationMin] = useState(0);
  const [showNumb, setShowNumb] = useState(25);

  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState({ value: null, dir: null });

  const [access, setAccess] = useState(false);
  const [showAvg, setShowAvg] = useState(false);

  const [state, setState] = useState({
    filterIVA: { label: "", value: "" },
    filterDisponible: true,
    filterReservado: true,
    filterHistorical: {
      label: rol?.some((e) => e === 18 || e === 19) ? "aplica_historico" : "",
      value: rol?.some((e) => e === 18 || e === 19) ? true : false,
    },
    filterList: [],
    filterDays: [],
    filterUpdate: [],
    filterAnio: [],
    filterKm: [],
    filterEuros: [],
    filterCv: [],
    filters: {
      Marcas: [],
      Modelos: [],
      Locales: [],
      Provincias: [],
      Carroceria: [],
      Combustible: [],
      Transmision: [],
      Plazas: [],
      EmpresaStock: [],
    },
    km: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    mod: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    anio: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    euros: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    days_in_stock: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    cv: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    // brandList: [0],
    // brandDisabled: true,
    modal_aviso: false,
    modal_check: false,
    modal_add: false,
    modal_conflict: false,
    modal_filters: false,
    checkLead: {
      toggle: false,
      vehicle_id: null,
      vehicle: null,
      nombre: "",
      telefono: "",
      cliente: null,
      id_existente_lead: null,
      condiciones: false,
      comercial: false,
      provincia: "",
      municipio: "",
      estado: "",
      origen: "",
      forma_contacto: "",
      observaciones: "",
      email: "",
      telefono_secundario: "",
      apellido_1: "",
      apellido_2: "",
    },
  });

  const [avg, setAvg] = useState();

  useEffect(() => {
    if (showAvg) {
      searchCars();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAvg]);

  //Montaje de la página con consulta sobre todos los coches en Stock
  useEffect(() => {
    if (vehicle_search != null) {
      const ant_val = vehicle_search;
      setSearchValue(ant_val.searchValue);
      setState(ant_val);
      setPaginationMin(ant_val.paginationMin);
      setShowNumb(ant_val.showNumb);
      renderFilterList(ant_val);
      setCallBackSetState({ ...callBackSetState, searchCars: true });
      vehicleSearch("");
    } else {
      document.title = "Flexicar | Stock";
      const loadCars = async () => {
        try {
          const dataCall = {
            historical: state.filterHistorical.value,
            filters: "",
            states: "",
            data: "",
            min: paginationMin,
            limit: showNumb,
            showAvg,
          };
          const { data, count, avg } = await post(API.SEARCH_CARS, dataCall);
          setCars(data);
          setTotalCars(count);
          setAvg(avg);
        } catch (e) {
          errorCall(e);
        }
      };
      loadCars();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (callBackSetState.searchCars) {
      searchCars(null, vehicle_search);
      setCallBackSetState({ ...callBackSetState, searchCars: false });
    }

    if (callBackSetState.filter) {
      renderFilterList();
      setCallBackSetState({ ...callBackSetState, filter: false });
    }
    if (callBackSetState.filterList_Km) {
      renderFilterListSelected("filterKm", "km");
      setCallBackSetState({ ...callBackSetState, filterList_Km: false });
    }
    if (callBackSetState.filterList_Euros) {
      renderFilterListSelected("filterEuros", "euros");
      setCallBackSetState({ ...callBackSetState, filterList_Euros: false });
    }
    if (callBackSetState.filterList_Days) {
      renderFilterListSelected("filterDays", "days_in_stock");
      setCallBackSetState({ ...callBackSetState, filterList_Days: false });
    }
    if (callBackSetState.filterList_Mod) {
      renderFilterListSelected("filterUpdate", "mod");
      setCallBackSetState({ ...callBackSetState, filterList_Mod: false });
    }
    if (callBackSetState.filterList_Anio) {
      renderFilterListSelected("filterAnio", "anio");
      setCallBackSetState({ ...callBackSetState, filterList_Anio: false });
    }
    if (callBackSetState.filterList_Cv) {
      renderFilterListSelected("filterCv", "cv");
      setCallBackSetState({ ...callBackSetState, filterList_Cv: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    callBackSetState.searchCars,
    callBackSetState.filter,
    callBackSetState.filterList_Km,
    callBackSetState.filterList_Euros,
    callBackSetState.filterList_Days,
    callBackSetState.filterList_Anio,
    callBackSetState.filterList_Cv,
    // callBackSetState.filterList_Mod,
  ]);

  //Comprobamos los permisos de usuario activar el acceso en STATE
  useEffect(() => {
    if (rol && access === false) {
      try {
        const dataCall = {
          rol: rol,
          // INDICAR EL PERMISO QUE DEBE TENER EL ROL en tabla PermisosRoles
          permission: [3271, 3275, 3276, 3272, 3273, 3274],
        };
        const asyncFunction = async () => {
          const response = await post(API.CHECK_PAGE_PERMISSION, dataCall);
          response && (await setAccess(true));
        };
        asyncFunction();
      } catch (e) {
        errorCall(e);
      }
    }
  });

  //Paginación recibiendo 'valor de inicio' y 'número de valores por página'
  const changePagination = (min, num) => {
    setPaginationMin(min);
    setShowNumb(num);
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  //Recibe y lanza la busqueda de stock con filtro de orden
  const changeOrder = (value, dir) => {
    setOrder({ value, dir });
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  //Cambial el estado de filterList, que renderizará una lista con los filtros
  //aplicados en cada Componente sobre el input de búsqueda
  const renderFilterList = (prevState) => {
    const selectedState = prevState ? prevState : state;
    let filterList = [];
    for (let key in selectedState.filters) {
      if (selectedState.filters[key].length > 0) {
        selectedState.filters[key].forEach((v, i) => {
          filterList.push(v.label);
        });
      }
    }
    setState({ ...selectedState, filterList: filterList });
  };

  const renderFilterListSelected = (stateName, option) => {
    let filter = [];
    for (let key in state[option]) {
      if (state[option][key].label.length > 0) {
        filter.push(state[option][key].label);
      }
    }
    setState({ ...state, [stateName]: filter });
  };

  //Lanza la consulta de búsqueda al servidor
  const searchCars = async (searchInfo, prevState) => {
    const selectedState = prevState ? prevState : state;

    //Creamos el listado de Marcas seleccionadas
    //Activamos/Desactivamos el Filtro Modelos
    let list = [0];
    if (selectedState.filters.Marcas.length > 0) {
      selectedState.filters.Marcas.forEach((el) => list.push(el.value));
      setBrandDisabled(false);
      setBrandList(list);
    } else {
      setBrandDisabled(true);
      setBrandList(list);
    }

    //Añadimos tablas y sus valores a la consulta en función de los filtros aplicados
    let sqlFilters = "";
    let sqlState = "";
    for (let key in selectedState.filters) {
      if (selectedState.filters[key].length > 0) {
        let table = "";
        let values = [];
        switch (key) {
          case "Marcas":
            table = "ma.id";
            break;
          case "Modelos":
            table = "mo.id";
            break;
          case "Locales":
            table = "sed.nombre";
            break;
          case "Provincias":
            table = "sed.provincia";
            break;
          case "Carroceria":
            table = "v.carroceria";
            break;
          case "Combustible":
            table = "v.combustible";
            break;
          case "Transmision":
            table = "v.transmision";
            break;
          case "Plazas":
            table = "v.num_asientos";
            break;
          case "EmpresaStock":
            table = "v.stock_externo";
            break;
          default:
            break;
        }
        selectedState.filters[key].forEach(function (v, i) {
          if (isNaN(v.value)) {
            values.push(`"${v.value}"`);
          } else {
            values.push(v.value);
          }
        });
        sqlFilters += ` AND ${table} IN (${values})`;
      }
    }

    for (let key in selectedState.days_in_stock) {
      if (selectedState.days_in_stock[key].value !== "") {
        sqlFilters += " and " + selectedState.days_in_stock[key].value;
      }
    }

    for (let key in selectedState.km) {
      if (selectedState.km[key].value !== "") {
        sqlFilters += " and " + selectedState.km[key].value;
      }
    }
    for (let key in selectedState.cv) {
      if (selectedState.cv[key].value !== "") {
        sqlFilters += " and " + selectedState.cv[key].value;
      }
    }
    for (let key in selectedState.anio) {
      if (selectedState.anio[key].value !== "") {
        sqlFilters += " and " + selectedState.anio[key].value;
      }
    }

    for (let key in selectedState.euros) {
      if (selectedState.euros[key].value !== "") {
        sqlFilters += " and " + selectedState.euros[key].value;
      }
    }

    for (let key in selectedState.mod) {
      if (selectedState.mod[key].value !== "") {
        sqlFilters += " and " + selectedState.mod[key].value;
      }
    }

    if (selectedState.filterIVA.value === true) {
      sqlFilters +=
        " and " +
        selectedState.filterIVA.label +
        " = " +
        selectedState.filterIVA.value;
    }

    if (
      !selectedState.filterHistorical.value &&
      (selectedState.filterDisponible || selectedState.filterReservado)
    ) {
      sqlState += " and (";

      if (selectedState.filterDisponible) {
        sqlState += " v.estado_crm = 'Disponible'";
        if (selectedState.filterReservado) {
          sqlState += " or ";
        }
      }
      if (selectedState.filterReservado) {
        sqlState += " v.estado_crm = 'Reservado'";
      }

      sqlState += " ) ";
    }

    //Separamos los elementos del input de búsqueda por espacio
    //let search_elements = state.searchValue.split(" ")
    let textSearchValue = searchInfo ? searchInfo : searchValue;
    let words = textSearchValue?.split(" ");
    let search_elements = [];
    let count = 1;
    for (let i = 0; i < words?.length; i++) {
      if (words[i].length === 1 && i !== 0) {
        search_elements[i - count] += " " + words[i];
        count++;
      } else {
        search_elements.push(words[i]);
      }
    }
    search_elements = searchInfo?.length === 0 ? "" : search_elements;
    try {
      const dataCall = {
        historical: selectedState.filterHistorical.value,
        filters: sqlFilters,
        states: sqlState,
        data: search_elements,
        min: paginationMin,
        limit: showNumb,
        showAvg,
        order,
      };

      const response = await post(API.SEARCH_CARS, dataCall);
      setCallBackSetState({ ...callBackSetState, searchCars: false });

      setCars(response.data);
      setTotalCars(response.count);
      setAvg(response.avg);
    } catch (e) {
      errorCall(e);
    }
  };

  //Lanza la búsqueda de stock al rellenar el input
  const handleChange = (event) => {
    setPaginationMin(0);
    setSearchValue(event.target.value);
  };

  useDebouncedEffect(
    () => searchValue?.length > 2 && searchCars(searchValue),
    300,
    [searchValue]
  );

  //Recibe data de los componentes Filter
  const getData = (filter, value) => {
    setState({
      ...state,
      filters: {
        ...state.filters,
        [filter]: value,
      },
    });
    setPaginationMin(0);

    setCallBackSetState({ searchCars: true, filter: true });
  };

  //Recibe data del filtro Crear Lead sin Vehículo
  const getData_Create = (filter, value) => {
    launchModal("modal_check", value[0].value, true);
  };

  //Recibe data de los componentes Filter_From_To
  const getData_From_To = (filter, value, refr) => {
    let direction = "";
    if (filter === ">" || filter === ">=") {
      direction = "greater";
    } else if (filter === "<" || filter === "<=") {
      direction = "smaller";
    }
    if (state.days_in_stock[direction] !== "" && value.length > 0) {
      setState({
        ...state,
        [refr]: {
          ...state[refr],
          [direction]: { value: value[0].value, label: value[0].label },
        },
      });

      setCallBackSetState({
        ...callBackSetState,
        searchCars: true,
        filterList_Km: true,
        filterList_Euros: true,
        filterList_Anio: true,
        filterList_Cv: true,
      });
    } else {
      setState({
        ...state,
        [refr]: {
          ...state[refr],
          [direction]: { value: "", label: "" },
        },
      });
      setCallBackSetState({
        ...callBackSetState,
        searchCars: true,
        filterList_Km: true,
        filterList_Euros: true,
        filterList_Anio: true,
        filterList_Cv: true,
      });
    }
  };

  //Recibe data de los componentes FilterDaysInStock
  const getData_Days = (filter, value, column) => {
    const col = column === "fecha_alta" ? "filterList_Days" : "filterList_Mod";
    const el = column === "fecha_alta" ? "days_in_stock" : "mod";
    let direction = "";
    if (filter === ">") {
      direction = "greater";
    } else if (filter === "<") {
      direction = "smaller";
    }

    if (state.days_in_stock[direction] !== "" && value.length > 0) {
      setState({
        ...state,
        [el]: {
          ...state[el],
          [direction]: { value: value[0].value, label: value[0].label },
        },
      });
      setCallBackSetState({
        ...callBackSetState,
        searchCars: true,
        [col]: true,
      });
    } else if (state[el][direction] === "" && value.length > 0) {
      setState({
        ...state,
        [el]: {
          ...state[el],
          [direction]: { value: value[0].value, label: value[0].label },
        },
      });
      setCallBackSetState({
        ...callBackSetState,
        searchCars: true,
        [col]: true,
      });
    } else {
      setState({
        ...state,
        [el]: {
          ...state[el],
          [direction]: { value: "", label: "" },
        },
      });
    }
    setCallBackSetState({
      ...callBackSetState,
      searchCars: true,
      [col]: true,
    });
  };

  //Recibe data de los componentes FilterCheckbox
  const getData_Disponible = (value, filter, query) => {
    setState(() => ({ ...state, [filter]: value }));
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  const getData_Reservado = (value, filter, query) => {
    setState(() => ({ ...state, [filter]: value }));
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  const getData_IVA = (value, filter, query) => {
    setState(() => ({ ...state, [filter]: { label: query, value } }));
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  const getData_Historical = (value, filter, query) => {
    setState(() => ({ ...state, [filter]: { label: query, value } }));
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  //Elimina valores en el renderizado de filtros
  const quitValue = (val, idx) => {
    setState({
      ...state,
      filterList: state.filterList.filter((_, i) => i !== idx),
    });
    for (let key in state.filters) {
      if (state.filters[key].length > 0) {
        state.filters[key].forEach(function (v, i) {
          if (v.label === val) {
            const keyDeletedRes = state.filters[key].filter(
              (item) => item.label !== val
            );
            state.filters[key] = keyDeletedRes;
          }
        });
      }
    }
  };

  //Elimina valores en el renderizado de filtros
  const quitValueDoubleFilter = (val, idx, label, filter) => {
    setState({ ...state, [label]: state[label].filter((_, i) => i !== idx) });

    for (let key in state[filter]) {
      if (state[filter][key].label === val) {
        state[filter][key] = { value: "", label: "" };
      }
    }
  };

  const quitValueCheckFilter = (filter) => {
    setState({ ...state, [filter]: { label: "", value: "" } });
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  //Reset de filtros y stock
  const reset = () => {
    setSearchValue("");
    setPaginationMin(0);
    setOrder({ value: null, dir: null });
    setState({
      ...state,
      filterIVA: { label: "", value: "" },
      filterDisponible: true,
      filterReservado: true,
      filterHistorical: { label: "", value: "" },
      filters: {
        Marcas: [],
        Modelos: [],
        Locales: [],
        Carroceria: [],
        Provincias: [],
        Combustible: [],
        Transmision: [],
        Plazas: [],
      },
      km: {
        smaller: { value: "", label: "" },
        greater: { value: "", label: "" },
      },
      anio: {
        smaller: { value: "", label: "" },
        greater: { value: "", label: "" },
      },
      mod: {
        smaller: { value: "", label: "" },
        greater: { value: "", label: "" },
      },
      euros: {
        smaller: { value: "", label: "" },
        greater: { value: "", label: "" },
      },
      cv: {
        smaller: { value: "", label: "" },
        greater: { value: "", label: "" },
      },
      days_in_stock: {
        smaller: { value: "", label: "" },
        greater: { value: "", label: "" },
      },
    });
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  const launchModal = (modal, vehicle_id, change) => {
    setState({
      ...state,
      [modal]: change,
      checkLead: {
        ...state.checkLead,
        vehicle: vehicle_id,
        cliente: null,
      },
    });
  };

  const handleValues = (e, field, type) => {
    const val = e.target.value;
    setState({
      ...state,
      [type]: {
        ...state[type],
        [field]: val,
      },
    });
  };

  const loadVehiclePage = (id) => {
    let data = state;
    data.searchValue = searchValue;

    data.paginationMin = paginationMin;
    data.showNumb = showNumb;

    vehicleSearch(data);
    history.push(`/main/vehicle/${id}?tab=datos`);
  };

  const loadVehicleEdition = (id) => {
    let data = state;
    data.searchValue = searchValue;

    data.paginationMin = paginationMin;
    data.showNumb = showNumb;

    vehicleSearch(data);
    history.push(`/main/edicion-vehiculo/${id}`);
  };

  const launchToggle = () => {
    const op = !state.checkLead.toggle;
    setState({
      ...state,
      checkLead: {
        ...state.checkLead,
        toggle: op,
      },
    });
  };

  const launchPromoState = async (status, vehicle_id, idx) => {
    const dataCall = {
      status,
      vehicle_id,
    };
    try {
      const response = await post(API.CHANGE_PROMO_STATE, dataCall);
      if (response.changed) {
        let elem = document.getElementById("outlet-" + vehicle_id).classList;
        if (elem.contains("verde_activo")) {
          document
            .getElementById("outlet-" + vehicle_id)
            .classList.remove("verde_activo");
          document
            .getElementById("outlet-" + vehicle_id)
            .classList.add("verde_inactivo");
        } else if (elem.contains("verde_inactivo")) {
          document
            .getElementById("outlet-" + vehicle_id)
            .classList.remove("verde_inactivo");
          document
            .getElementById("outlet-" + vehicle_id)
            .classList.add("verde_activo");
        }
        let newCars = cars;
        newCars[idx].outlet = status;
        setCars(newCars);
      } else {
        return errorToast(<span>{t("errors.errorUpdateState")}</span>);
      }
    } catch (e) {
      errorCall(e);
    }
  };

  const renderFilterListRender = state.filterList.map((d, idx) => (
    <span className={styles.classFilterList} key={d}>
      {d}{" "}
      <span
        className="react-dropdown-select-option-remove pointer"
        onClick={() => quitValue(d, idx)}
      >
        ×
      </span>
    </span>
  ));

  const renderFilterListSelectedRender = (stateName, option) => {
    return state[stateName]?.map((d, idx) => (
      <span className={styles.classFilterList} key={d}>
        {d}{" "}
        <span
          className="react-dropdown-select-option-remove pointer"
          onClick={() => quitValueDoubleFilter(d, idx, stateName, option)}
        >
          ×
        </span>
      </span>
    ));
  };

  const renderFilterList_IVA_Render = state.filterIVA.value ? (
    <span className={styles.classFilterList} key={"IVAdeducible"}>
      {" "}
      IVA deducible
      <span
        className="react-dropdown-select-option-remove pointer"
        onClick={() => quitValueCheckFilter("filterIVA")}
      >
        ×
      </span>
    </span>
  ) : (
    ""
  );

  const renderFilterList_Historical_Render = state.filterHistorical.value ? (
    <span className={styles.classFilterList} key={"Historical"}>
      {" "}
      Histórico{" "}
      <span
        className="react-dropdown-select-option-remove pointer"
        onClick={() => quitValueCheckFilter("filterHistorical")}
      >
        ×
      </span>
    </span>
  ) : (
    ""
  );

  return (
    <>
      <Mainlayout>
        <Mainlayout.Sidebar>
          <FiltersBlock
            state={state}
            getData={getData}
            reset={reset}
            getData_From_To={getData_From_To}
            getData_Days={getData_Days}
            getData_IVA={getData_IVA}
            getData_Disponible={getData_Disponible}
            getData_Reservado={getData_Reservado}
            getData_Historical={getData_Historical}
            brandDisabled={brandDisabled}
            brandList={brandList}
          />
        </Mainlayout.Sidebar>
        <Mainlayout.Content contentTitle="STOCK">
          <div className="flex flex-wrap mb-2">
            <Row className={`col-12 ${styles.filters_list}`}>
              {renderFilterListRender}{" "}
              {renderFilterListSelectedRender("filterDays", "days_in_stock")}{" "}
              {renderFilterListSelectedRender("filterUpdate", "mod")}{" "}
              {renderFilterListSelectedRender("filterKm", "km")}{" "}
              {renderFilterListSelectedRender("filterEuros", "euros")}{" "}
              {renderFilterListSelectedRender("filterAnio", "anio")}{" "}
              {renderFilterListSelectedRender("filterCv", "cv")}{" "}
              {renderFilterList_IVA_Render}
              {renderFilterList_Historical_Render}
            </Row>
            <Col xs="12" md="6" className="d-flex">
              <div className="d-flex flex-grow-1">
                <input
                  autoComplete="off"
                  placeholder={"Buscar ..."}
                  className={`form-control ds-input ${styles.w90}`}
                  type="text"
                  value={searchValue}
                  onChange={(e) => handleChange(e)}
                />
                <Button
                  outline
                  className="ml-2 d-xl-none d-block"
                  onClick={() => launchModal("modal_filters", null, true)}
                >
                  <FiFilter size={25} />
                </Button>
              </div>
            </Col>

            <Col xs="0" md="1" />
            <Col xs="12" md="5" className="d-flex">
              <div className="d-flex flex-grow-1 mt-3 mt-md-0">
                <div className="d-block w-100">
                  <Filter
                    key={"CreateLeadWVehicle"}
                    multi={false}
                    sendData={getData_Create}
                    name={"Crear Lead sin Vehículo"}
                    query={`vehiculo_generico`}
                  />
                </div>
                <Button
                  outline
                  color="danger"
                  className="ml-2"
                  onClick={() => {
                    if (showAvg) {
                      setShowAvg(false);
                      setAvg(null);
                    } else {
                      setShowAvg(true);
                    }
                  }}
                >
                  <GoGraph size={25} color="red" />
                </Button>
              </div>
            </Col>
          </div>
          <div className="flex mt-3 justify-content-between justify-content-md-start">
            <div className="p-0 pl-sm-4">
              <span className="text-success">Stock: </span>
              <span className="text-primary">{totalCars}</span>
            </div>
            <div className="d-flex p-0 pb-3 pl-md-5 pb-md-3">
              <Pagination
                changePagination={changePagination}
                total={totalCars}
                showNumb={showNumb}
              />
            </div>
          </div>
          {showAvg && (
            <div className="container">
              <div className="row" style={{ margin: "0 auto" }}>
                <div className={`col-sm ${styles.avgBox}`}>
                  <span className="fontL">
                    {avg && Math.round(avg.pvp).toLocaleString("es")} €
                  </span>
                  <br /> pvp medio contado
                  <br />
                </div>
                <div className={`col-sm ${styles.avgBox}`}>
                  <span className="fontL">
                    {avg && Math.round(avg.financiado).toLocaleString("es")} €
                  </span>
                  <br /> pvp medio financiado
                </div>
                <div className={`col-sm ${styles.avgBox}`}>
                  <span className="fontL">
                    {avg && moment(avg.time).format("MM / YYYY")}
                  </span>
                  <br />
                  antigüedad
                </div>
                <div className={`col-sm ${styles.avgBox}`}>
                  <span className="fontL">
                    {avg && Math.round(avg.km).toLocaleString("es")}
                  </span>
                  <br />
                  kilómetros
                </div>
                <div className={`col-sm ${styles.avgBox}`}>
                  <span className="fontL">{avg && Math.round(avg.days)}</span>
                  <br />
                  días en stock
                </div>
              </div>
            </div>
          )}
          {access ? (
            <TableStock
              cars={cars}
              totalCars={totalCars}
              setTotalCars={setTotalCars}
              paginationMin={paginationMin}
              showNumb={showNumb}
              rol={rol}
              locals={locals}
              loadVehiclePage={loadVehiclePage}
              loadVehicleEdition={loadVehicleEdition}
              changePagination={changePagination}
              launchModal={launchModal}
              launchPromoState={launchPromoState}
              changeOrder={changeOrder}
              order={order}
            />
          ) : (
            <Loading />
          )}
        </Mainlayout.Content>
      </Mainlayout>

      <ModalLead
        state={state}
        launchModal={launchModal}
        handleValues={handleValues}
        setState={setState}
        user={user}
      />
      <ModalAddLead
        vehiculo={state.checkLead.vehicle}
        state={state}
        launchModal={launchModal}
        launchToggle={launchToggle}
        handleValues={handleValues}
        setState={setState}
        user={user}
      />
      <ModalCambioPosesion
        state={state}
        setState={setState}
        launchModal={launchModal}
      />
      <ModalFilters
        footerButtonText="Ver coches"
        state={state.modal_filters}
        dispose={() => launchModal("modal_filters", null, false)}
      >
        <FiltersBlock
          state={state}
          getData={getData}
          reset={reset}
          getData_From_To={getData_From_To}
          getData_Days={getData_Days}
          getData_IVA={getData_IVA}
          getData_Disponible={getData_Disponible}
          getData_Reservado={getData_Reservado}
          getData_Historical={getData_Historical}
          brandDisabled={brandDisabled}
          brandList={brandList}
        />
      </ModalFilters>
    </>
  );
};

const mapStateToProps = (store) => ({
  vehicle_search: store.auth.vehicle_search,
});

const mapDispatch = dispatcher(["vehicleSearch"]);

export default connect(mapStateToProps, mapDispatch)(withRouter(Stock));
