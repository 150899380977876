import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Label,
  Col,
} from "reactstrap";
import Filter from "components/Filter";
import styles from "./index.module.scss";

const ModalTraslado = ({ changeLocal, createVehicleDraft, setChangeLocal }) => {
  const [local, setLocal] = useState();
  const handleFilter = (value) => {
    setLocal(value);
  };
  const toggle = () => {
    setChangeLocal({ open: false, id_document: null });
    setLocal(null);
  };

  return (
    <Modal className="modal-lg" isOpen={changeLocal?.open === true} toggle={toggle}>
      <ModalHeader>Trasladar Vehículo</ModalHeader>
      <ModalBody className="p-4">
        <Row className={styles.centerRow}>
          <Col xs="12" sm="4">
            <Label className={"m-0"}>Ubicación</Label>
            <Filter
              parentValues={local}
              key={"local"}
              multi={false}
              sendData={(name, value) => {
                handleFilter(value);
              }}
              name={"Locales"}
              query={"concesionarios_flexicar"}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() =>
            createVehicleDraft({ id: changeLocal?.id_document, dealer: local })
          }
        >
          Aceptar
        </Button>
        <Button color="primary" onClick={() => toggle()}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalTraslado;
