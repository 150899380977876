import { useState } from "react";
import { useTranslation } from "react-i18next";

import CarouselImages from "views/NewLead/FormLead/CarouselImages/CarouselImages";
import ModalUploadMultiImages from "components/UploadMultiNoCallComponent";
import ModalDeleteImages from "views/NewLead/ModalDeleteImages";

const UploadCarousel = ({ images, uploadCb, deleteCb, deleteAllCb }) => {
  const { t } = useTranslation("fichaLeadsCompra");

  const [showAddImagesModal, setShowAddImagesModal] = useState(false);
  const [showEditImagesModal, setShowEditImagesModal] = useState(false);

  const toggleAddImagesModal = () => setShowAddImagesModal((prev) => !prev);
  const toggleEditImagesModal = () => setShowEditImagesModal((prev) => !prev);

  return (
    <>
      <CarouselImages images={images} />
      <div className="d-flex justify-content-around">
        <button className="btn btn-flexicar" onClick={toggleAddImagesModal}>
          {t("addPhotos")}
        </button>
        <button className="btn btn-flexicar" onClick={toggleEditImagesModal}>
          {t("edditPhotos")}
        </button>
      </div>

      <ModalUploadMultiImages
        show={showAddImagesModal}
        toggle={toggleAddImagesModal}
        uploadCb={uploadCb}
      />
      
      <ModalDeleteImages
        images={images}
        show={showEditImagesModal}
        toggle={toggleEditImagesModal}
        deleteCb={deleteCb}
        deleteAllCb={deleteAllCb}
      />
    </>
  );
};

export default UploadCarousel;
