import React, { useState, useEffect, Fragment, useContext, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Spinner } from "reactstrap";

import Pagination from "./components/Pagination";
import styles from "./index.module.scss";
import TableStock from "./components/TableStock";
import { dispatcher } from "../../redux/actions/dispatchers";
import errorCall from "services/errorCall";
import { useDebouncedEffect } from "../../helpers/useDebouncedEffect";
import { getDrafts } from "services/Vehicle/vehicle.service";
import { UserContext } from "contexts/UserContext";
import { useTranslation } from "react-i18next";

import { getActiveLocals } from "services/Employees/getActiveLocals";

import SearchFilterExtended from "components/SearchFilterExtended";

const Stock = ({ vehicle_search, history, vehicleSearch }) => {
  const [callBackSetState, setCallBackSetState] = useState({
    searchCars: false,
    filter: false,
    filterList_Km: false,
    filterList_Euros: false,
    filterList_Days: false,
    filterList_Anio: false,
  });
  const { rol } = useContext(UserContext);

  const [cars, setCars] = useState([]);
  const [totalCars, setTotalCars] = useState(0);
  const [paginationMin, setPaginationMin] = useState(0);
  const [showNumb, setShowNumb] = useState(25);
  const [searchValue, setSearchValue] = useState("");

  const [localSelectOptions, setLocalSelectOptions] = useState([]);
  const [localsSelected, setLocalsSelected] = useState([]);

  const { t } = useTranslation('vehicleValidationList');

  const [state, setState] = useState({
    filterIVA: { label: "", value: "" },
    filterDisponible: true,
    filterReservado: true,
    filterHistorical: {
      label: rol?.some((e) => e === 18 || e === 19) ? "aplica_historico" : "",
      value: rol?.some((e) => e === 18 || e === 19) ? true : false,
    },
    filterList: [],
    filterDays: [],
    filterAnio: [],
    filterKm: [],
    filterEuros: [],
    filters: {
      Marcas: [],
      Modelos: [],
      Locales: [],
      Provincias: [],
      Carroceria: [],
      Combustible: [],
      Transmision: [],
      Plazas: [],
      EmpresaStock: [],
    },
    km: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    anio: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    euros: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    days_in_stock: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    cv: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    // brandList: [0],
    // brandDisabled: true,
    modal_check: false,
    modal_add: false,
    modal_conflict: false,
    checkLead: {
      toggle: false,
      vehicle_id: null,
      vehicle: null,
      nombre: "",
      telefono: "",
      cliente: null,
      id_existente_lead: null,
      condiciones: false,
      comercial: false,
      provincia: "",
      municipio: "",
      estado: "",
      origen: "",
      forma_contacto: "",
      observaciones: "",
      email: "",
      telefono_secundario: "",
      apellido_1: "",
      apellido_2: "",
    },
  });

  useEffect(() => {
    (async () => {
      const responseLocalSelectOptions = await getActiveLocals();
      setLocalSelectOptions(
        responseLocalSelectOptions.map((x) => ({
          value: x.id,
          label: x.name,
        }))
      );
    })();
  }, []);


  let page = paginationMin / showNumb + 1;
  //Montaje de la página con consulta sobre todos los coches en Stock
  useEffect(() => {
    if (callBackSetState.searchCars === true) {
      if (vehicle_search != null) {
        const ant_val = vehicle_search;
        setSearchValue(ant_val.searchValue);
        setState(ant_val);
        setPaginationMin(ant_val.paginationMin);
        setShowNumb(ant_val.showNumb);
        renderFilterList(ant_val);
        vehicleSearch("");
      } else {
        document.title = "Flexicar | Validación de vehiculo";
        const loadCars = async () => {
          try {
            const dataCall = {
              historical: "",
              filters: "",
              states: "",
              data: "",
              min: paginationMin,
              limit: showNumb,
              showAvg: false,
              page: page,
              size: showNumb,
              order: "date",
              orderDir: "DESC",
            };
            const drafts = await getDrafts(dataCall);
            await setCars(drafts?.results);
            await setTotalCars(drafts?.total);
          } catch (e) {
            errorCall(e);
          }
        };

        loadCars();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callBackSetState]);

  //Comprobamos los permisos de usuario activar el acceso en STATE

  //Paginación recibiendo 'valor de inicio' y 'número de valores por página'
  const changePagination = (min, num) => {
    setPaginationMin(min);
    setShowNumb(num);
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  //Cambial el estado de filterList, que renderizará una lista con los filtros
  //aplicados en cada Componente sobre el input de búsqueda
  const renderFilterList = (prevState) => {
    const selectedState = prevState ? prevState : state;
    let filterList = [];
    for (let key in selectedState.filters) {
      if (selectedState.filters[key].length > 0) {
        selectedState.filters[key].forEach((v, i) => {
          filterList.push(v.label);
        });
      }
    }
    setState({ ...selectedState, filterList: filterList });
  };

  //Lanza la consulta de búsqueda al servidor
  const searchCars = useCallback(
    async (searchInfo, prevState) => {
      const selectedState = prevState ? prevState : state;

      try {
        const dataCall = {
          historical: selectedState.filterHistorical.value,
          min: paginationMin,
          limit: showNumb,
          showAvg: false,
          plate: searchValue,
          locals: localsSelected,
          page: 1,
          size: showNumb,
          order: "date",
          orderDir: "DESC"
        };

        const response = await getDrafts(dataCall);
        setCallBackSetState((prevCallBackState) => ({ ...prevCallBackState, searchCars: false }));

        setCars(response.results);
        setTotalCars(response.total);
      } catch (e) {
        errorCall(e);
      }
    },
    [paginationMin, searchValue, showNumb, state, localsSelected]
  );

  //Lanza la búsqueda de stock al rellenar el input
  const handleChange = (event) => {
    setPaginationMin(0);
    setSearchValue(event.target.value);
  };

  useDebouncedEffect(
    () => searchValue?.length >= 6 && searchCars(searchValue),
    300,
    [searchValue, localsSelected]
  );

  const launchModal = (modal, vehicle_id, change) => {
    setState({
      ...state,
      [modal]: change,
      checkLead: {
        ...state.checkLead,
        vehicle: vehicle_id,
        cliente: null,
      },
    });
  };

  const loadVehiclePage = (id) => {
    let data = state;
    data.searchValue = searchValue;

    data.paginationMin = paginationMin;
    data.showNumb = showNumb;

    vehicleSearch(data);
    history.push(`/main/validacion-vehiculo/${id}`);
  };

  useEffect(() => {
    if (
      state.modal_check === false &&
      state.modal_add === false &&
      state.modal_conflict === false
    ) {
      document.body.classList.remove("modal-open");
    }
  }, [state.modal_check, state.modal_add, state.modal_conflict]);

  useEffect(() => {
    if (searchValue.length === 0) {
      searchCars();
    }
  }, [searchValue, searchCars]);

  return (
    <div className="App">
      {cars ? (
        <Fragment>
          <Container className="themed-container mt-4" fluid={true}>
            <Row className="m-0">
              <Col className="col-12 col-md-12">
                <div className={`clearfix mt-3 mb-3 ${styles.container2}`}>
                  <span className="tittle ml-4">{t("vehiclesValidation")}</span>
                </div>
              </Col>
              <Col className="col-12">
                <Row className="mb-1 float-md-right">
                  <div className="col-12 col-md-8 p-0 ml-md-0 ml-3">
                    <SearchFilterExtended
                      searchLabel={t("plate")}
                      searchValue={searchValue}
                      handleChange={handleChange}
                      dropdownLabel="Local"
                      dropdownPlaceholder="Todos"
                      dropdownSelectOptions={localSelectOptions}
                      dropdownSelected={localsSelected}
                      setDropdownSelected={setLocalsSelected}
                    />
                  </div>
                  <Col md="2" xs="6" className="text-right mt-4">
                    <Pagination
                      changePagination={changePagination}
                      total={totalCars}
                      showNumb={showNumb}
                    />
                  </Col>
                  <Col md="2" xs="6" className="text-right mt-4">
                    <span className="text-success">
                      {t("vehiclesValidation")}:{" "}
                    </span>
                    <span className="text-primary">{totalCars}</span>
                  </Col>
                </Row>

                <TableStock
                  cars={cars}
                  totalCars={totalCars}
                  setTotalCars={setTotalCars}
                  paginationMin={paginationMin}
                  showNumb={showNumb}
                  rol={rol}
                  loadVehiclePage={loadVehiclePage}
                  changePagination={changePagination}
                  launchModal={launchModal}
                  page={page}
                  setCallBackSetState={setCallBackSetState}
                  callBackSetState={callBackSetState}
                />
              </Col>
            </Row>
          </Container>
        </Fragment>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <Spinner type="grow" className="flexicarColor" />
          <p className="flexicarColor"> CARGANDO Vehiculos...</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  vehicle_search: store.auth.vehicle_search,
});

const mapDispatch = dispatcher(["vehicleSearch"]);

export default connect(mapStateToProps, mapDispatch)(withRouter(Stock));
