import { getCookie } from "../../helpers/getCookie";
import instance from "./BaseService";

export const getFinance = async (price, year, cashPrice, carMonth) => {
  const response = await instance.get(
    `/api/v1/finance?financePrice=${price}&carYear=${year}&cashPrice=${cashPrice}&carMonth=${carMonth}`,
    {
      headers: { Authorization: `Bearer ${getCookie("JWT")}` },
      params: {
        /* limit: 20, offset: offset */
      },
    }
  );
  return response.data;
};
