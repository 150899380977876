import { API } from "services/urlConstants";
import { put } from "../axiosCalls";

export const updateAgentSales = async (id_lead,id_agent) => {
    try {
      const response = await put(
        `${API.CHANGE_AGENT_VENTAS}/${id_lead}/agent/`,{
          id: id_agent
        }
      );

     return response
  
    } catch (error) {
     throw(error)
    } 
  };
