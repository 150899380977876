import React, { useState } from "react";
import "./index.css";
import Uploader from "../Uploader";
import Preview from "../Preview";
import { Spinner } from "reactstrap";
import { deleteVehicleImage } from "services/Vehicle/vehicle.service";
import { errorToast } from "helpers/toastFunctions";

const Gallery = ({
  imagesPreviewUrls,
  setImagesPreviewUrls,
  vehicle_id,
  type,
}) => {
  const [imageLoad, setImageLoad] = useState(true);
  const getImagesPreviewUrls = (result) => {
    setImagesPreviewUrls((oldArray) => [...oldArray, result]);
  };
  const deleteImage = async (id) => {
    if (imagesPreviewUrls.length > 0) {
      try {
        const filterImages = await imagesPreviewUrls.filter(
          (image) => image.id !== id
        );

        await setImagesPreviewUrls(filterImages);
        await deleteVehicleImage(vehicle_id, id, type);
      } catch (e) {
        errorToast(e);
      }
    }
  };

  return (
    <div>
      <Uploader
        imagesPreviewUrls={imagesPreviewUrls}
        getImagesPreviewUrls={getImagesPreviewUrls}
        id={vehicle_id}
        imageLoad={imageLoad}
        setImageLoad={setImageLoad}
        type={type}
      />

      {imageLoad ? (
        <Preview
          imagesPreviewUrls={imagesPreviewUrls}
          getImagesPreviewUrls={getImagesPreviewUrls}
          deleteImage={deleteImage}
          setImagesPreviewUrls={setImagesPreviewUrls}
          vehicle_id={vehicle_id}
          type={type}
        />
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <Spinner type="grow" className="flexicarColor" />
          <p className="flexicarColor"> CARGANDO Imagenes...</p>
        </div>
      )}
    </div>
  );
};

export default Gallery;
