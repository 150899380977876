import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { errorToast, successToast } from "../../helpers/toastFunctions";
import { useHistory } from "react-router-dom";
import { updateAgentSales } from "services/Leads/leads.service";
import { useTranslation } from "react-i18next";

const ModalCambioPosesion = ({ launchModal, state, setState }) => {
  const history = useHistory();
  const { t } = useTranslation('modalCambioPosesion');
  const { checkLead, modal_aviso } = state;

  const changePossession = async () => {
    const id_client = checkLead.cliente;
    const id_lead = checkLead.id_existente_lead;
    const id_agente_modificador = checkLead.mi_id;

    const response = await updateAgentSales(id_lead,id_agente_modificador);

    if (response.error) {
      errorToast(
        <span>
          <span className="bold">{t("errors.error")}&nbsp;</span>
          <span>{t("errors.reasignError")}</span>
        </span>
      );
    }
    if (response.status === 204) {
      successToast(<span>El Agente se ha reasignado correctamente</span>);
      history.push(`/main/lead-venta/${id_lead}/${id_client}`);
    }

      if (response.error) {
        errorToast(
          <span>
            <span className="bold">{t("errors.error")}&nbsp;</span>
            <span>{t("errors.reasignError")}</span>
          </span>
        );
        }
      if (response.status === 204) {
   
        successToast(<span>El Agente se ha reasignado correctamente</span>)
        history.push(`/main/lead-venta/${id_lead}/${id_client}`)
      }
    
  };

  return (
    <Modal
      className="modal-lg"
      isOpen={state.modal_conflict}
      toggle={() => launchModal("modal_conflict", state.checkLead.vehicle, false)}
    >
      <ModalHeader>{modal_aviso ? "Crear Lead" : "Cambio de Agente" }</ModalHeader>
      <ModalBody className="p-4">
        <center>
          Antes de continuar debes confirmar que has leído el aviso.
          <br />
          El cliente ya existe, y está asociado a:
          {state.checkLead?.mi_id ? (
            <React.Fragment>
              <h2>{state.checkLead?.nombre_agente}</h2>
              <h4>Estado: {state.checkLead?.estado_anterior}</h4>
            </React.Fragment>
          ) : (
            ""
          )}
        </center>
      </ModalBody>
      <ModalFooter>
        {modal_aviso ? (
          <Button
            color="primary"
            onClick={() => {
              launchModal("modal_add", state.checkLead.vehicle, true);
              setState({
                ...state,
                modal_conflict: false,
                modal_add:true
              });
            }}
          >
           Confirmar Crear nuevo Lead
          </Button>
        ) : (
          <Button color="primary" onClick={changePossession}>
            Confirmar cambio de posesión
          </Button>
        )}

        <Button
          color="secondary"
          onClick={() =>
            launchModal("modal_conflict", state.checkLead.vehicle, false)
          }
        >
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCambioPosesion