import { createContext, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { get } from "services/axiosCalls";
import { getUserData, login } from "services/Auth/AuthService";
import { getCookie, removeCookie, setCookie } from "helpers/getCookie";
import { errorToast } from "helpers/toastFunctions";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
  const router = useHistory();

  const [employee, setEmployee] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [passwordChangeRequired, setPasswordChangeRequired] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    getCookie("JWT") ? true : false
  );

  const logIn = async (credentials) => {
    try {
      const response = await login(credentials);
      setCookie("JWT", response.data.jwt, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      });
      setIsAuthenticated(true);
    } catch (err) {
      errorToast(err.response.data.message);
    }
  };

  const logOut = () => {
    removeCookie("JWT");
    setIsAuthenticated(false);
    setEmployee({});
  };

  const checkUser = useCallback(async () => {
    try {
      const response = await get("/api/employee/validate");
      if (response) {
        if (response.activo === 0) router.push("/main/login");
        else if (
          response.cambiar_pwd === 1 ||
          response.password_caducada === 1
        ) {
          router.push("/main/cambiar-password");
          setPasswordChangeRequired(true);
        }
      }
    } catch (err) {
      errorToast(err.response.data.message);
    }
  }, [router]);

  const loadUser = useCallback(async () => {
    try {
      const user = await getUserData();
      setEmployee(user);
      setIsLoading(false);
      await checkUser();
    } catch (err) {
      errorToast(err.response.data.message);
    }
  }, [checkUser]);

  useEffect(() => {
    if (isAuthenticated) loadUser();
  }, [isAuthenticated, loadUser]);

  const value = {
    user: employee.id,
    userType: employee.id_tipo,
    rol: employee.rolesId,
    local: employee.localesId,
    permission: employee.permissionsId,
    name: employee.name,
    phone: employee.telefono1,
    email: employee.email,
    isLoading,
    isAuthenticated,
    passwordChangeRequired,
    setPasswordChangeRequired,
    logIn,
    logOut,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
