import React, { useRef, useEffect, useState, useCallback } from "react";
import classnames from "classnames";

import styles from "./index.module.scss";
import useWindowSize from "hooks/useWindowSize";

const OverflowWrapper = ({ children }) => {
  const navRef = useRef(null);
  const [hiddingMap, setHiddingMap] = useState({});

  const { width } = useWindowSize();

  const handleIntersection = useCallback((entries) => {
    let updatedEntries = {};
    entries.forEach((entry) => {
      if (!entry.isIntersecting || entry.intersectionRatio < 1) {
        updatedEntries[entry.target.dataset?.targetid] = true;
      } else {
        updatedEntries[entry.target.dataset?.targetid] = false;
      }
    });
    setHiddingMap(updatedEntries);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: 1,
    });

    Array.from(navRef.current.children).forEach((item) => {
      if (item.dataset?.targetid) {
        observer.observe(item);
      }
    });
    return () => {
      observer.disconnect();
    };
  }, [children, handleIntersection, width]);

  return (
    <>
      <div className={styles.toolbarWrapper} ref={navRef}>
        {React.Children.map(children, (child) => {
          if (
            child?.props?.data?.value &&
            hiddingMap.hasOwnProperty(child.props?.data?.value) &&
            hiddingMap[child.props?.data?.value]
          ) {
            return React.cloneElement(child, {
              className: classnames(child.props?.className, {
                visible: false,
                invisible: true,
              }),
            });
          } else {
            return React.cloneElement(child, {
              className: classnames(child.props?.className, {
                visible: true,
                invisible: false,
              }),
            });
          }
        })}
      </div>
      <div className={styles.overflowStyle}>
        {Object.keys(hiddingMap).filter((key) => hiddingMap[key]).length > 0 ? (
          `+ ${Object.keys(hiddingMap).filter((key) => hiddingMap[key]).length}`
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default OverflowWrapper;
