import { useState, useEffect, useReducer, useContext } from "react";
import Select from "react-select";
import { Label, Table } from "reactstrap";
import { MdClose, MdDone, MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";

import styles from "./index.module.scss";
import Mainlayout from "layouts/MainLayout";
import { filterReducer, initialEmployeeFilters } from "./filters";
import { UserContext } from "contexts/UserContext";
import { get } from "services/axiosCalls";
import { errorToast } from "helpers/toastFunctions";

const EmployeeList = () => {
  const { user: employeeId } = useContext(UserContext);
  const [employees, setEmployees] = useState([]);
  const [employeesFiltered, setEmployeesFiltered] = useState([]);
  const [employeeFilters, dispatchFilters] = useReducer(
    filterReducer,
    initialEmployeeFilters
  );

  const [employeeTypesOptions, setEmployeeTypesOptions] = useState([]);
  const [localesOptions, setLocalesOptions] = useState([]);
  const employeeStatesOptions = [
    { value: 1, label: "Activo" },
    { value: 0, label: "Inactivo" },
  ];

  useEffect(() => {
    // Get all the employee types from employees
    get(`/api/employee/${employeeId}/types`)
      .then((res) =>
        setEmployeeTypesOptions(
          res.map((x) => ({
            value: x.id,
            label: x.nombre,
          }))
        )
      )
      .catch((err) => errorToast(err));

    // Get all the employee locals from employees
    get(`/api/employee/${employeeId}/locales`)
      .then((res) =>
        setLocalesOptions(res.map((x) => ({ value: x.id, label: x.nombre })))
      )
      .catch((err) => errorToast(err.response.data.message));
  }, [employeeId]);

  useEffect(() => {
    (async () =>
      get(`/api/employee/list`)
        .then((res) => setEmployees(res))
        .catch((err) => errorToast(err)))();
  }, [employeeId]);

  useEffect(() => {
    // Check every filter condition else return the employee
    setEmployeesFiltered(
      employees.filter((emp) => {
        let condition = true;
        if (employeeFilters.state !== "")
          condition &= emp.activo === employeeFilters.state;
        if (employeeFilters.types.length !== 0)
          condition &= employeeFilters.types.includes(emp.type.id);
        if (employeeFilters.locales.length !== 0)
          condition &= employeeFilters.locales.every((localId) =>
            emp.locales.some((x) => x.id === localId)
          );

        return condition;
      })
    );
  }, [employees, employeeFilters]);

  return (
    <Mainlayout>
      <Mainlayout.Content full contentTitle="LISTA DE EMPLEADOS">
        <div className="col-12 col-lg-10 d-flex flex-column align-self-center">
          <div className="mt-3 w-100">
            <a className="btn orange-btn" href="/main/agregar-empleado">
              CREAR NUEVO EMPLEADO
            </a>

            <div className="d-flex flex-column flex-lg-row w-100">
              <div className={styles.filter}>
                <Label>Selecciona uno o varios tipos de empleados</Label>
                <Select
                  isMulti
                  placeholder="Seleccionar....."
                  options={employeeTypesOptions}
                  onChange={(values) =>
                    dispatchFilters({
                      type: "changed_types",
                      nextEmpTypes: values,
                    })
                  }
                />
              </div>
              <div className={styles.filter}>
                <Label>Selecciona uno o varios locales</Label>
                <Select
                  isMulti
                  placeholder="Seleccionar....."
                  options={localesOptions}
                  onChange={(values) =>
                    dispatchFilters({
                      type: "changed_locales",
                      nextEmpLocales: values,
                    })
                  }
                />
              </div>
              <div className={styles.filter}>
                <Label>Selecciona el estado del empleado</Label>
                <Select
                  isClearable
                  placeholder="Seleccionar....."
                  options={employeeStatesOptions}
                  onChange={(values) =>
                    dispatchFilters({
                      type: "changed_state",
                      nextState: values,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column mt-4">
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              <span className="text-success">
                Empleados totales:{" "}
                <span className="text-primary">{employees.length}</span>
              </span>
              {employeesFiltered.length !== 0 ? (
                <span className="text-success">
                  Empleados filtrados:{" "}
                  <span className="text-primary">
                    {employeesFiltered.length}
                  </span>
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className="mt-2" style={{ overflowX: "auto" }}>
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>Tipo de empleado</th>
                    <th>Nombre</th>
                    <th>Activo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {employeesFiltered.map((emp) => (
                    <tr key={emp.id}>
                      <td>{emp.type.nombre}</td>
                      <td>{emp.nombre_apellidos}</td>
                      <td>
                        {
                          {
                            1: <MdDone size={24} color="#26A69A" />,
                            0: <MdClose size={24} color="ff0000" />,
                          }[emp.activo]
                        }
                      </td>
                      <td>
                        <div>
                          <a href={`/main/ver-empleado/${emp.id}`}>
                            <FaEye size={24} color="#039BE5" />
                          </a>
                          <a href={`/main/editar-empleado/${emp.id}`}>
                            <MdEdit size={24} color="#039BE5" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Mainlayout.Content>
    </Mainlayout>
  );
};

export default EmployeeList;
