import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MdError } from "react-icons/md";

import styles from "./index.module.scss";

import Mainlayout from "layouts/MainLayout";
import { API } from "services/urlConstants";
import { UserContext } from "contexts/UserContext";
import { errorToast } from "helpers/toastFunctions";
import instance from "services/base";
import { createCaseFormSchema } from "schema/postventaSchema";

const CreateCase = () => {
	const { t } = useTranslation("createCase");
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: zodResolver(createCaseFormSchema),
		defaultValues: async () => {
			try {
				const response = await instance.post(API.GET_DOCUMENTACION_FIRMAS, {
					vehicle_id: vehicleId
				});
				const docs = response.data;

				if (docs && docs.length > 0) {
					const contract = docs.filter((e) => e.tipo === 1);
					if (contract && contract.length > 0) {
						const contractParse = contract[0].datos;

						return {
							fullName: contractParse.nombre,
							phone: contractParse.telefono.toString(),
							email: contractParse.email
						};
					}
				}
			} catch (err) {
				errorToast(err?.response?.data?.message || t("errors.load"));
			}
		}
	});

	const history = useHistory();
	const { vehicleId } = useParams();
	const { user: userId } = useContext(UserContext);

	const onSubmit = async (formData) => {
		try {
			await instance.post(API.CREATE_CASE, {
				vehicle_id: vehicleId,
				titulo: formData.title,
				descripcion: formData.description,
				agente: userId,
				nombre: formData.fullName,
				telefono: formData.phone,
				email: formData.email
			});

			history.push(`/main/vehicle/${vehicleId}?tab=postventa`);
		} catch (err) {
			errorToast(err?.response?.data?.message || t("errors.create"));
		}
	};

	const barButtons = (
		<>
			<button
				className="btn btn-flexicar-orange"
				onClick={() => history.push(`/main/vehicle/${vehicleId}?tab=postventa`)}
			>
				{t("buttons.back")}
			</button>
		</>
	);

	const error = (name) =>
		errors[name] && (
			<span className={styles.customInputError}>
				<MdError size={20} />
				{errors[name].message}
			</span>
		);

	return (
		<Mainlayout>
			<Mainlayout.Content
				contentTitle={t("contentTitle")}
				buttons={barButtons}
			>
				<form className={styles.form}>
					<div>
						<div className={styles.inputWrapper}>
							<label>{t("form.fullName")}</label>
							<input
								type="text"
								{...register("fullName")}
							/>
							{error("fullName")}
						</div>

						<div className={styles.inputWrapper}>
							<label>{t("form.phone")}</label>
							<input
								type="text"
								{...register("phone")}
							/>
							{error("phone")}
						</div>

						<div className={styles.inputWrapper}>
							<label>{t("form.email")}</label>
							<input
								type="text"
								{...register("email")}
							/>
							{error("email")}
						</div>
					</div>

					<div>
						<div className={styles.inputWrapper}>
							<label>{t("form.title")}</label>
							<input
								type="text"
								{...register("title")}
							/>
							{error("title")}
						</div>
					</div>

					<div>
						<div className={styles.inputWrapper}>
							<label>{t("form.description")}</label>
							<textarea {...register("description")} />
							{error("description")}
						</div>
					</div>
				</form>

				<div className={styles.actions}>
					<button
						className="btn orange-btn"
						onClick={() => history.goBack()}
					>
						{t("buttons.cancel")}
					</button>
					<button
						className="btn btn-flexicar-orange"
						onClick={handleSubmit(onSubmit)}
					>
						{t("buttons.accept")}
					</button>
				</div>
			</Mainlayout.Content>
		</Mainlayout>
	);
};

export default CreateCase;
