import { successToast } from "helpers/toastFunctions";
import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { Button, Col, Container, CustomInput, Row } from "reactstrap";
import {
  getDealers,
  patchDealer,
  postDealer,
} from "services/Dealers/dealers.service";
import AddDealerModal from "./Components/AddDealerModal";
import AddressModal from "./Components/AddressModal";
import DealersTable from "./Components/DealersTable";
import DeleteDealerModal from "./Components/DeleteDealerModal";
import OptionalDealerModal from "./Components/OptionalDealerModal";
import SchedulesDealerModal from "./Components/SchedulesDealerModal";
import SearchFilterInput from "./Components/searchFilterInput";

import styles from "./index.module.scss";

const Dealers = () => {
  const [dealers, setDealers] = useState(null);
  const [dealersFiltered, setDealersFiltered] = useState(null);
  const [actualPage, setActualPage] = useState(1);
  const [localType, setLocalType] = useState({
    concesionario: false,
    caseta: false,
  });
  const [limit] = useState(20);
  const [dealersCount, setDealersCount] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalDeleteDealer, setModalDeleteDealer] = useState(false);
  const [modalAddDealer, setModalAddDealer] = useState(false);
  const [modalOptionalDealer, setModalOptionalDealer] = useState(false);
  const [modalSchedules, setModalSchedules] = useState(false);
  const [dealerReady, setDealerReady] = useState(false);
  const [modalAddress, setModalAddress] = useState(false);
  const [dealerId, setDealerId] = useState(null);

  const [newDealer, setNewDealer] = useState({
    id: undefined,
    nombre: undefined,
    tpv: undefined,
    local_type: undefined,
    email: undefined,
    telefono: undefined,
    movil: undefined,
    direccion: undefined,
    coordinate_longitude: undefined,
    coordinate_latitude: undefined,
    schedule: undefined,
    slug: undefined,
    administrationPhone: undefined,
    administrationEmail: undefined,
    encargadoLocal: undefined,
    society: undefined,
  });

  useEffect(() => {
    if (dealerReady) {
      delete newDealer.encargado;
      if (newDealer.id) {
        let id = newDealer.id;
        delete newDealer.localtypes;
        delete newDealer.encargado_local;
        delete newDealer.id;

        patchDealer(`/dealers/${id}`, newDealer)
          .then((res) => {
            setDealerReady(false);
            getDealers(`/dealers`)
              .then((response) => {
                setDealersHandler(response.data);
                successToast("Local actualizado correctamente.");
              })
              .catch((e) => {
                throw e;
              });
            cancelHandler();
          })
          .catch((e) => {
            throw e;
          });
      } else {
        postDealer(`/dealers`, newDealer)
          .then((resp) => {
            setDealerReady(false);
            getDealers(`/dealers`)
              .then((response) => {
                setDealersHandler(response.data);
                setDealersCount(response.count);
                successToast("Local añadido correctamente.");
              })
              .catch((e) => {
                throw e;
              });
            cancelHandler();
          })
          .catch((e) => {
            throw e;
          });
      }
    } else {
      getDealers(`/dealers`)
        .then((response) => {
          setDealersHandler(response.data);
          setDealersCount(response.count);
        })
        .catch((e) => {
          throw e;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerReady]);

  const cancelHandler = () => {
    setModalOptionalDealer();
    setNewDealerHandler({
      id: undefined,
      nombre: undefined,
      tpv: undefined,
      local_type: undefined,
      email: undefined,
      telefono: undefined,
      movil: undefined,
      direccion: undefined,
      coordinate_longitude: undefined,
      coordinate_latitude: undefined,
      schedule: undefined,
      slug: undefined,
      administrationPhone: undefined,
      administrationEmail: undefined,
      encargadoLocal: undefined,
    });
  };

  const setModalDeleteDealerHandler = (id) => {
    setModalDeleteDealer(!modalDeleteDealer);
    setDealerId(id);
  };

  const setModalAddDealerHandler = () => {
    setModalAddDealer(!modalAddDealer);
  };

  const setModalAddressHandler = () => {
    setModalAddress(!modalAddress);
  };

  const setModalSchedulesHandler = (data) => {
    setModalSchedules(data);
  };

  const setModalOptionalDealerHandler = () => {
    setModalOptionalDealer(!modalOptionalDealer);
  };

  const editSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const localTypeHandler = (e) => {
    if (Number(e.target.id) === 1) {
      if (localType.concesionario) {
        setLocalType({ concesionario: false, caseta: false });
        setDealersFiltered(dealers);
      } else {
        setLocalType({ concesionario: true, caseta: false });
        setDealersFiltered(
          dealers.filter((dealer) => {
            return dealer.tipo === 1;
          })
        );
      }
    }

    if (Number(e.target.id) === 2) {
      if (localType.caseta) {
        setLocalType({ concesionario: false, caseta: false });
        setDealersFiltered(dealers);
      } else {
        setLocalType({ concesionario: false, caseta: true });
        setDealersFiltered(
          dealers.filter((dealer) => {
            return dealer.tipo === 2;
          })
        );
      }
    }
  };

  const setNewDealerHandler = (data) => {
    setNewDealer(data);
  };

  const setDealersHandler = (data) => {
    setDealers(data);
  };

  const setDealersCountHandler = (data) => {
    setDealersCount(data);
  };

  const nextPage = () => {
    if (actualPage < Math.ceil(dealersCount / limit))
      setActualPage(actualPage + 1);
  };

  const previousPage = () => {
    if (actualPage > 1) setActualPage(actualPage - 1);
  };

  useEffect(() => {
    getDealers(`/dealers`)
      .then((response) => {
        setDealersFiltered(response.data);
        setDealersCount(response.count);
      })
      .catch((e) => {
        throw e;
      });
  }, [dealers]);

  return (
    <Container fluid>
      <Row className="m-1">
        <Col className={`col-2 mt-3 mb-4 ${styles.filtersContent}`}>
          <Row className="ml-2">
            <FaFilter size={20} color="#007bff" /> Filtros
          </Row>
          <Row className="ml-1">
            <SearchFilterInput
              dealers={dealers}
              editSearchTerm={editSearchTerm}
              searchTerm={searchTerm}
            />
          </Row>
          <Row className="ml-2 justify-content-start">
            <CustomInput
              className="mx-2"
              type="switch"
              id="1"
              name="concesionariosFilter"
              label="Concesionarios"
              onClick={localTypeHandler}
              checked={localType.concesionario}
            />
            <CustomInput
              className="mx-2"
              type="switch"
              id="2"
              name="casetasFilter"
              label="Casetas"
              onClick={localTypeHandler}
              checked={localType.caseta}
            />
          </Row>
        </Col>
        <Col className="col-10">
          <Row>
            <Col className="col-12">
              <div className={`clearfix mt-3 mb-3 ${styles.container2}`}>
                <span className="tittle ml-4">LISTA DE CONCESIONARIOS</span>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col className="col-3">
              <Button
                outline
                block
                className="mt-2 mb-3 orange-btn"
                onClick={setModalAddDealer}
              >
                ✚ Añadir Concesionario
              </Button>
            </Col>

            <Col className="col-3 mt-3">
              <h6 className="text-success">
                {localType === 2
                  ? `Casetas totales: ${dealersCount}`
                  : localType === 1
                  ? `Concesionarios totales: ${dealersCount}`
                  : `Cantidad Total: ${dealersCount}`}
              </h6>
            </Col>
          </Row>
          <Row>
            <AddDealerModal
              newDealer={newDealer}
              modalAddDealer={modalAddDealer}
              setModalAddDealer={setModalAddDealerHandler}
              setModalAddress={setModalAddressHandler}
              setNewDealerHandler={setNewDealerHandler}
            ></AddDealerModal>
            <AddressModal
              modalAddress={modalAddress}
              setModalAddress={setModalAddressHandler}
              setModalAddDealer={setModalAddDealerHandler}
              newDealer={newDealer}
              setNewDealerHandler={setNewDealerHandler}
              setModalSchedules={setModalSchedulesHandler}
            ></AddressModal>
          </Row>
          <Row>
            <SchedulesDealerModal
              setModalAddress={setModalAddressHandler}
              newDealer={newDealer}
              setNewDealerHandler={setNewDealerHandler}
              modalSchedules={modalSchedules}
              setModalSchedules={setModalSchedulesHandler}
              setModalOptionalDealer={setModalOptionalDealerHandler}
              setDealersHandler={setDealersHandler}
              setDealersCount={setDealersCountHandler}
            ></SchedulesDealerModal>
          </Row>
          <Row>
            <OptionalDealerModal
              setModalSchedules={setModalSchedulesHandler}
              newDealer={newDealer}
              setNewDealerHandler={setNewDealerHandler}
              modalOptionalDealer={modalOptionalDealer}
              setModalOptionalDealer={setModalOptionalDealerHandler}
              setDealersHandler={setDealersHandler}
              setDealersCount={setDealersCountHandler}
              setDealerReady={setDealerReady}
              dealerReady={dealerReady}
              cancelHandler={cancelHandler}
            ></OptionalDealerModal>
          </Row>
          <Row>
            <DeleteDealerModal
              modalDeleteDealer={modalDeleteDealer}
              setModalDeleteDealerHandler={setModalDeleteDealerHandler}
              setDealers={setDealers}
              dealerId={dealerId}
              setDealersCount={setDealersCount}
            ></DeleteDealerModal>
          </Row>
          <Row>
            <Col className="col-12">
              <DealersTable
                className="table-wrapper"
                dealers={dealers}
                dealersFiltered={dealersFiltered}
                searchTerm={searchTerm}
                actualPage={actualPage}
                limit={limit}
                dealersCount={dealersCount}
                setModalDeleteDealerHandler={setModalDeleteDealerHandler}
                setModalAddDealer={setModalAddDealerHandler}
                setNewDealer={setNewDealerHandler}
                setDealersCount={setDealersCount}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <nav className="">
              <ul className="pagination">
                <li className="page-item">
                  {/* eslint-disable-next-line  */}
                  <a
                    className={`page-link ${styles.cursor_pointer}`}
                    onClick={previousPage}
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item">
                  <h6 className="page-link">
                    {searchTerm === "" ? actualPage : 1}/
                    {searchTerm === "" ? Math.ceil(dealersCount / limit) : 1}
                  </h6>
                </li>
                <li className="page-item">
                  {/* eslint-disable-next-line  */}
                  <a
                    className={`page-link ${styles.cursor_pointer}`}
                    onClick={nextPage}
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dealers;
