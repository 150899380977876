import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import { post } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import errorCall from "services/errorCall";
import filterForSpecialChars from "../../helpers/filterForSpecialChars";
import "./index.scss"

const Filter = ({
  disabled,
  query,
  name,
  multi,
  sendData,
  parentValues,
  setter,
  className,
  data,
}) => {
  const [options, setOptions] = useState([]);
  const [filterValues, setFilterValues] = useState([]);

  const launchQuery = async () => {
    try {
      const dataCall = {
        data_query: query,
        data_call: data ? data : null,
      };
      if (query) {
        const response = await post(API.FILTROS, { dataCall });
        if (query === "estado_lead_compra") {
         setOptions(response.filter((option) => option.nombre !== "Formulario Nuevo"));
        }
        else {
          setOptions(response);
        }
      }
    } catch (e) {
      errorCall(e);
    }
  };

  useEffect(() => {
    if (parentValues && parentValues !== filterValues) {
      setFilterValues(parentValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValues]);

  const onChange = (value) => {
    sendData(setter ? setter : name, value);
    setFilterValues(value);
  };

  return (
    <div>
      {options !== null ? (
        <Select
          color={"#FF5D0E"}
          options={options?.map((x, y) => {
            return {
              label: x.nombre,
              value: x.id,
            };
          })}
          searchFn={filterForSpecialChars}
          placeholder={name}
          values={filterValues}
          multi={multi}
          disabled={disabled}
          noDataRenderer={() => (
            <span style={{ textAlign: "center" }}>Cargando...</span>
          )}
          onChange={(value) => onChange(value)}
          className={`fix_selector_arrow ${className} form-control dropdown-select`}
          onDropdownOpen={() => launchQuery()}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

export default Filter;
