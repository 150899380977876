import PreparationTable from ".//PreparationTable";
import PreparationState from "./PreparationState";
import Verification from "./Verification/Verification";

const VehiclePreparation = ({ vehicle }) => {
	return (
		<>
			<PreparationTable />
			<PreparationState />
			<Verification />
		</>
	);
};
export default VehiclePreparation;
