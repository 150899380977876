import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Filter from "components/Filter";

import {
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import styles from "./index.module.scss";
import { getVideos, patchVideo } from "services/youtubeApi.service";
import {
  getYoutubeVideoId,
  defaultRoles,
} from "views/VideoTutorial/helpers/videoTutorials";
import YoutubeEmbed from "../YoutubeEmbeded";

const UpdateVideoModal = (props) => {
  const {
    modalUpdateVideo,
    modalUpdateVideoHandler,
    videoData,
    onVideosHandler,
    adminRoles,
  } = props;
  const [roles, setRoles] = useState();

  const getDataAddMulti = (filter, value) => {
    setRoles({
      ...roles,
      [filter]: value,
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      title: videoData ? videoData.title : "",
      description: videoData ? videoData.description : "",
      url: videoData ? videoData.url : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoData]);

  const onSubmit = (data) => {
    delete data.url;
    Object.assign(data, {
      roles: defaultRoles(roles, adminRoles),
    });

    patchVideo(`/videos/${videoData.id}`, data)
      .then((response) => {
        getVideos(`/videos`)
          .then((response) => {
            modalUpdateVideoHandler();
            onVideosHandler(response.data);
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e) => {
        throw e;
      });
  };

  return (
    <div>
      <Modal isOpen={modalUpdateVideo} toggle={modalUpdateVideoHandler}>
        <ModalHeader
          toggle={modalUpdateVideoHandler}
          className={styles.modal_header}
        >
          Editar Video
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Row className="m-3">
                <input
                  className={`${errors.title && "is-invalid"} ${styles.input}`}
                  placeholder="Título"
                  {...register("title", { required: true })}
                />
                {errors.title?.type === "required" && (
                  <div className="invalid-feedback">Introduzca título</div>
                )}
              </Row>
              <Row className="m-3">
                <textarea
                  className={`${errors.title && "is-invalid"} ${styles.input}`}
                  placeholder="Descripción"
                  {...register("description", { required: true })}
                />
                {errors.description?.type === "required" && (
                  <div className="invalid-feedback">
                    Introduzca descripción del video
                  </div>
                )}
              </Row>
              <Row className="m-3">
                <input
                  className={`${errors.title && "is-invalid"} ${styles.input}`}
                  type="url"
                  placeholder="url"
                  {...register("url", { required: true })}
                  readOnly
                />
              </Row>
              <Row>
                {videoData ? (
                  <Filter
                    className={`${styles.filter}`}
                    key={"roles"}
                    setter={"roles"}
                    multi={true}
                    sendData={getDataAddMulti}
                    parentValues={videoData.roles}
                    name={"Roles"}
                    query={`roles`}
                  />
                ) : (
                  <Filter
                    className={`${styles.filter}`}
                    key={"roles"}
                    setter={"roles"}
                    multi={true}
                    sendData={getDataAddMulti}
                    name={"Roles"}
                    query={`roles`}
                  />
                )}
              </Row>

              <YoutubeEmbed
                embedId={videoData && getYoutubeVideoId(videoData.url)}
              />
            </Container>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            className={styles.btn_custom}
            onClick={handleSubmit(onSubmit)}
          >
            Editar
          </Button>{" "}
          <Button color="secondary" onClick={modalUpdateVideoHandler}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UpdateVideoModal;
