import { z } from "zod";

const nullSelectMessage = (issue, ctx) => {
	if ((issue.code === "invalid_type" && ctx.data === null) || ctx.data === undefined)
		return { message: "Campo obligatorio" };
	return issue.message;
};

export const isSelect = z.object(
	{
		label: z.string(),
		value: z.number()
	},
	{ errorMap: nullSelectMessage }
);
export const isDate = z.date({ required_error: "Campo obligatorio" });
