import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ProtectedRoute from "components/ProtectedRoute";

import Stock from "./views/Stock/Stock";
import Vehicle from "./views/Vehicle/Vehicle";
import Casos from "./views/Casos/Casos";
import Documentation from "./views/Documentation/Documentation";
import NewContract from "./views/NewContract/NewContract";
import Turnos from "./views/Turnos/Turnos";
import Ejs from "./views/Ejs/Ejs";
import BookingCalendar from "./views/BookingCalendar/BookingCalendar";
import NewLead from "./views/NewLead/NewLead";
import DeliveredCalendar from "./views/DeliveredCalendar/DeliveredCalendar";
import LeadsList from "views/LeadsList/LeadsList";
import LeadVenta from "views/LeadVenta/LeadVenta";
import LeadsVentaList from "views/LeadsVentaList/LeadsVentaList";
import VideoTutorial from "./views/VideoTutorial/VideoTutorial";
import Dealers from "views/Dealers/Dealers";
import Administration from "views/Administration/Administration";
import VehicleValidation from "views/VehicleValidation";
import VehicleList from "views/VehicleList";
import Caso from "views/Caso";
import UserRoles from "views/UserRoles";
import EmployeeList from "views/EmployeeList";
import LeadsAgentsList from "views/LeadsAgentsList";
import EditEmployee from "views/EditEmployee";
import SeeEmployee from "views/SeeEmployee";
import AddEmployee from "views/AddEmployee";
import ChangePassword from "views/ChangePassword";
import Appointments from "views/Appointments";
import Login from "views/Login";
import PreparationComment from "views/PreparationComment";
import CreateCase from "views/CreateCase";
import WithdrawalReceptionForm from "views/WithdrawalReceptionForm";
import AppointmentContextProvider from "views/Appointments/AppointmentContext";

const Routes = () => (
	<Switch>
		<Route
			exact
			path="/"
		>
			<Redirect to="/main/admin" />
		</Route>
		<ProtectedRoute
			exact
			path="/main/stock"
			component={Stock}
		/>
		<ProtectedRoute
			exact
			path="/main/vehicle/:id"
			component={Vehicle}
		/>
		<ProtectedRoute
			exact
			path="/main/casos"
			component={Casos}
		/>
		<ProtectedRoute
			exact
			path="/main/vehicle/:vehicleId/caso/:caseId"
			component={Caso}
		/>
		<ProtectedRoute
			excat
			path="/main/vehicle/:vehicleId/crear-caso"
			component={CreateCase}
		/>
		<ProtectedRoute
			exact
			path="/main/vehicle/:vehicleId/caso/:caseId/nuevo-documento"
			component={WithdrawalReceptionForm}
		/>
		<ProtectedRoute
			exact
			path="/main/vehicle/:vehicleId/caso/:caseId/editar-documento/:documentId"
			component={WithdrawalReceptionForm}
		/>
		<ProtectedRoute
			exact
			path="/main/postventa_contratos"
			component={Documentation}
		/>
		<ProtectedRoute
			exact
			path="/main/lead-venta/:id_lead/:id_cliente"
			component={LeadVenta}
		/>
		<ProtectedRoute
			exact
			path="/main/listaleadsventa"
			component={LeadsVentaList}
		/>
		<ProtectedRoute
			exact
			path="/main/nuevo_contrato"
			component={NewContract}
		/>
		<ProtectedRoute
			exact
			path="/main/nuevo_contrato/:id"
			component={NewContract}
		/>
		<ProtectedRoute
			exact
			path="/main/turnos"
			component={Turnos}
		/>
		<ProtectedRoute
			exact
			path="/main/docu/:id"
			component={Ejs}
		/>
		<ProtectedRoute
			exact
			path="/main/reservas"
			component={BookingCalendar}
		/>
		<ProtectedRoute
			exact
			path="/main/lead-tasacion"
			component={NewLead}
		/>
		<ProtectedRoute
			exact
			path="/main/lead-tasacion/:id"
			component={NewLead}
		/>
		<ProtectedRoute
			exact
			path="/main/entregas"
			component={DeliveredCalendar}
		/>
		<ProtectedRoute
			exact
			path="/main/listaleads"
			component={LeadsList}
		/>
		<ProtectedRoute
			exact
			path="/main/validacion-vehiculo"
			component={VehicleList}
		/>
		<ProtectedRoute
			exact
			path="/main/validacion-vehiculo/:id"
			component={VehicleValidation}
		/>
		<ProtectedRoute
			exact
			path="/main/edicion-vehiculo/:id"
			component={VehicleValidation}
		/>
		<ProtectedRoute
			exact
			path="/main/admin"
			component={Administration}
		/>
		<ProtectedRoute
			exact
			path="/main/locales"
			component={Dealers}
		/>
		<ProtectedRoute
			exact
			path="/main/video-tutorial"
			component={VideoTutorial}
		/>
		<ProtectedRoute
			exact
			path="/main/roles-usuario/:id"
			component={UserRoles}
		/>
		<ProtectedRoute
			exact
			path="/main/citas-ventas"
			component={() => (
				<AppointmentContextProvider type="venta">
					<Appointments />
				</AppointmentContextProvider>
			)}
		/>
		<ProtectedRoute
			exact
			path="/main/citas-compras"
			component={() => (
				<AppointmentContextProvider type="compra">
					<Appointments />
				</AppointmentContextProvider>
			)}
		/>
		<ProtectedRoute
			exact
			path="/main/citas-post-venta"
			component={() => (
				<AppointmentContextProvider type="post-venta">
					<Appointments />
				</AppointmentContextProvider>
			)}
		/>
		<ProtectedRoute
			exact
			path="/main/cambiar-password"
			component={ChangePassword}
		/>
		<ProtectedRoute
			exact
			path="/main/agregar-empleado"
			component={AddEmployee}
		/>
		<ProtectedRoute
			exact
			path="/main/ver-empleado/:id"
			component={SeeEmployee}
		/>
		<ProtectedRoute
			exact
			path="/main/editar-empleado/:id"
			component={EditEmployee}
		/>
		<ProtectedRoute
			exact
			path="/main/lista-empleados"
			component={EmployeeList}
		/>
		<ProtectedRoute
			exact
			path="/main/vehicle/:vehicleId/registro-preparacion"
			component={PreparationComment}
		/>
		<ProtectedRoute
			exact
			path="/main/vehicle/:vehicleId/registro-preparacion/:commentId"
			component={PreparationComment}
		/>

		<ProtectedRoute
			exact
			path="/main/lista-agentes-leads"
			component={LeadsAgentsList}
		/>
		<Route
			exact
			path="/main/login"
			component={Login}
		/>
	</Switch>
);

export default Routes;
