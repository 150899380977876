import React, { useState, useContext } from "react";
import { postWithStatus } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { errorToast } from "../../../helpers/toastFunctions";
import Filter from "components/Filter";
import { UserContext } from "contexts/UserContext";
import { useTranslation } from "react-i18next";

const ModalCancelReservation = ({
  reservation,
  setModal,
  status
}) => { 
  const { t } = useTranslation("modalCancelReservation");
  const [reason, setReason] = useState("");
  const [idStatus, setIdStatus] = useState([]);
  const { user } = useContext(UserContext);

  const getData = (f, value) => {
    setIdStatus(value)
  }

  const saveData = async () => {
    if(!idStatus[0]){
      return errorToast(<span>{t("errors.errorSelect")}</span>)
    }
    try{
      let response = await postWithStatus(API.CANCEL_RESERVA, {
        id_lead: reservation.id_lead,
        id_vehicle: reservation.id_vehiculo,
        id_status: idStatus[0].value,
        name_status: idStatus[0].label,
        reason,
        id_reservation: reservation.id,
        id_agent: user,
      });
      if(response.status === 204){
        window.location.reload();
      }
    } catch(e) {
      errorToast(<span>{t("errors.errorCancel")}</span>);
    }
  }

  return (
    <Modal
    className="modal-lg"
    centered
    isOpen={status}
    toggle={() => setModal()}
  >
    <ModalHeader>Cancelar Reserva</ModalHeader>
    <ModalBody className="p-4">
    <Filter
      key={"MotivoCancelación"}
      setter={"setFilterReason"}
      multi={false}
      sendData={getData}
      parentValues={idStatus}
      name={"Motivo de Cancelación"}
      query={`cancellation_reason`}
    />

      <Input className={"mt-2"} placeholder={"Añadir comentario (opcional)"} value={reason} onChange={(e)=>setReason(e.target.value)}/>
    </ModalBody>
    <ModalFooter>
      <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => saveData()}>Guardar</button>
      <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => setModal()}>Cerrar</button>
    </ModalFooter>
  </Modal>
  );
};

export default ModalCancelReservation;