import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";

import "./styles/global.scss";
import Routes from "./Routes.jsx";
import CustomNavbar from "components/Navbar/CustomNavbar";
import { UserContext } from "contexts/UserContext";
import Toast from "components/Toast";

const App = () => {
  const { rol } = useContext(UserContext);
  const router = useHistory();

  useEffect(() => {
    const cookie = Cookies.get("JWT");
    if (!cookie) router.push("/main/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <header>{rol && <CustomNavbar />}</header>
      <main>
        <Routes />
      </main>
      <footer></footer>
      <Toast />
    </div>
  );
};

export default App;
