import React, { useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import styles from "./index.module.scss";
import YoutubeEmbed from "../YoutubeEmbeded/index";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdEdit, MdOutlineCancel } from "react-icons/md";

const VideosTable = (props) => {
  const {
    visibilityHandler,
    isAdmin,
    setModalDeleteVideoHandler,
    updateVideoHandler,
    videos,
    selectedRoles,
    searchTerm,
    setVideosCount,
  } = props;

  const dynamicSearch = () => {
    let videosData = [];
    videosData = videos?.filter((video) => {
      return video?.title
        .toLowerCase()
        .replace(/\s+/g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          searchTerm
            .toLowerCase()
            .replace(/\s+/g, "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    });
    return videosData;
  };

  const verifySelectedRoles = (rolesList, video) => {
    let verified = false;
    video.roles?.forEach((role) => {
      if (rolesList.includes(role.id)) {
        verified = true;
      }
    });
    return verified;
  };

  const searchByRole = () => {
    const videosData = dynamicSearch();
    let newVideosData = [];
    if (!selectedRoles.length) {
      newVideosData = videosData;
    } else {
      newVideosData = videosData?.filter((video) => {
        return verifySelectedRoles(selectedRoles, video);
      });
    }
    return newVideosData;
  };

  useEffect(() => {
    dynamicSearch();
    searchByRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, selectedRoles]);

  const getVideosRows = () => {
    let videosRow = [];
    searchByRole()?.map((video) => {
      return videosRow.push(
        <tr style={{ fontSize: "15px" }} key={video.id}>
          <td>{video.title}</td>
          <td>{`${Math.floor(video.duration / 60)}'${video.duration % 60}`}</td>

          {isAdmin ? (
            <td>
              <a href={video.url} target="_blank" rel="noreferrer">
                {video.url}
              </a>
            </td>
          ) : (
            <td>
              <h6>{video.content}</h6>
              <h6>{video.description}</h6>
            </td>
          )}
          {isAdmin ? (
            <td className={styles.cursor_pointer}>
              <MdOutlineCancel
                className="mx-2"
                title="Eliminar"
                size={30}
                color={"#007bff"}
                role="button"
                onClick={() => setModalDeleteVideoHandler(video.id)}
              />

              {video.posted ? (
                <FaEye
                  className="mx-2"
                  title="Ver"
                  size={30}
                  color={"#007bff"}
                  role="button"
                  onClick={() => visibilityHandler(video.id, video.posted)}
                />
              ) : (
                <FaEyeSlash
                  className="mx-2"
                  title="Ver"
                  size={30}
                  color={"#007bff"}
                  role="button"
                  onClick={() => visibilityHandler(video.id, video.posted)}
                />
              )}

              <MdEdit
                className="mx-2"
                title="Modificar"
                size={30}
                color={"#007bff"}
                role="button"
                onClick={() => updateVideoHandler(video)}
              />
            </td>
          ) : (
            <td className={styles.cursor_pointer}>
              <YoutubeEmbed embedId={video.url.split("/")[3]} />
            </td>
          )}
        </tr>
      );
    });

    setVideosCount(videosRow.length);

    if (videosRow.length > 0) {
      return videosRow;
    } else {
      return (
        <h2 className="m-4">
          No existen videos que coincidan con sus criterios de búsqueda.
        </h2>
      );
    }
  };
  return (
    <table className="table table-striped fl-table">
      <thead>
        <tr style={{ fontSize: "18px" }}>
          <th>Título</th>
          <th>Duración</th>
          {isAdmin ? <th>URL</th> : <th>Descripción</th>}
        </tr>
      </thead>
      {videos ? (
        <tbody className={""}>{getVideosRows()}</tbody>
      ) : (
        <div className="m-5">
          <Row className="justify-content-center">
            <Col className="col-12">
              <Spinner type="grow" className="flexicarColor" />
            </Col>
          </Row>
        </div>
      )}
    </table>
  );
};

export default VideosTable;
