import { useContext, useMemo } from "react";
import { HiDocumentText } from "react-icons/hi";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";

import styles from "./index.module.scss";
import { AppointmentContext } from "../AppointmentContext";

const AppointmentStateModal = ({ showModalLeadState, setShowModalLeadState }) => {
	const { type, calendarConfig, currentFilter, eventsFilteredByLeadState } =
		useContext(AppointmentContext);

	const appointments = useMemo(
		() => eventsFilteredByLeadState[currentFilter] || [],
		[eventsFilteredByLeadState, currentFilter]
	);

	return (
		<Modal
			className="modal-lg"
			centered
			isOpen={showModalLeadState}
			toggle={() => setShowModalLeadState((prev) => !prev)}
		>
			<ModalHeader toggle={() => setShowModalLeadState((prev) => !prev)}>
				Lista de {currentFilter}
			</ModalHeader>
			<ModalBody>
				{appointments.length === 0 ? (
					<h3 className="text-center">No hay citas para mostrar</h3>
				) : (
					<Table className={styles.eventModalTable}>
						<thead>
							<tr>
								<th>Cita</th>
								<th>Cliente</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{appointments.map((appointment) => {
								const resourceId =
									type === "post-venta" ? appointment.case.id : appointment.lead.id;
								const appointmentDate =
									type === "post-venta"
										? appointment.appointmentDate
										: appointment.lead.appointmentDate;
								const clientName =
									type === "post-venta"
										? appointment.client.fullName
										: `${appointment.client.name ?? ""} 
                      ${appointment.client.surname1 ?? ""}
                      ${appointment.client.surname2 ?? ""}
                    `;

								return (
									<tr
										key={`cita-modal-${resourceId}`}
										style={{
											// add 0.15 alpha to the hexa bg color
											backgroundColor: appointment.bgColor + "26"
										}}
									>
										<td>
											{new Date(appointmentDate).toLocaleString("es-ES", {
												year: "numeric",
												month: "numeric",
												day: "numeric",
												hour: "numeric",
												minute: "numeric"
											})}
										</td>
										<td>{clientName}</td>
										<td>
											<a
												href={calendarConfig.getChipLink(appointment)}
												target="_blank"
												rel="noopener noreferrer"
											>
												<HiDocumentText
													size={24}
													color="#2196F3"
												/>
											</a>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				)}
			</ModalBody>
		</Modal>
	);
};

export default AppointmentStateModal;
