import { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import { AiOutlineCheckCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import styles from "./index.module.scss";
import PaneHeader from "components/PaneHeader";
import Loading from "views/Loading/Loading";
import {
	getStateBubbleConfig,
	preparationStateOptions,
	preparationStateOptionsColors
} from "constants/preparation";
import OverflowedInput from "./OverflowedInput";
import { useMediaQuery } from "hooks/useMediaQuery";
import Collapsible from "components/Collapsible";
import { formatSpanishFullDate } from "helpers/formatDate";
import instance from "services/base";
import { IS_SUPER_ADMIN } from "constants/roles";
import { UserContext } from "contexts/UserContext";
import { errorToast } from "helpers/toastFunctions";
import RouteLeavingGuard from "../../RouteLeavingGuard";

const doesntApplyOption = preparationStateOptions[0];
const goodOption = preparationStateOptions[1];

const PreparationState = () => {
	const { id: vehicleId } = useParams();
	const { userType } = useContext(UserContext);
	const isMobile = useMediaQuery("(max-width: 980px)");

	const [validated, setValidated] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const [showFinishPreparationNotFilledModal, setShowFinishPreparationNotFilledModal] =
		useState(false);
	const [showFinishPreparationErrorModal, setShowFinishPreparationErrorModal] = useState(false);
	const [showFinishPreparationSureModal, setShowFinishPreparationSureModal] = useState(false);

	const loadComponents = async () => {
		const { data } = await instance.get(`/api/vehicles/${vehicleId}/preparation-components`);
		setIsLoading(false);
		setValidated(data.validation);

		const components = data.componentsStatus.map((component) => {
			const { initialStateId, finalStateId, ...rest } = component;

			return {
				...rest,
				initialState: preparationStateOptions.find((opt) => opt.value === component.initialStateId),
				finalState: preparationStateOptions.find((opt) => opt.value === component.finalStateId)
			};
		});

		return { components };
	};

	const {
		register,
		control,
		handleSubmit,
		formState: { isDirty },
		reset
	} = useForm({
		defaultValues: loadComponents
	});
	const { fields: components, update: updateComponent } = useFieldArray({
		control,
		name: "components",
		keyName: "generatedId"
	});

	const saveDraft = async (formData) => {
		const componentsPayload = formData.components.map((x) => ({
			id: x.component.id,
			initialStateId: x.initialState?.value || null,
			finalStateId: x.finalState?.value || null,
			comment: x.comment || null
		}));

		try {
			await instance.post(`/api/vehicles/${vehicleId}/preparation-components`, componentsPayload);
			reset({}, { keepValues: true, keepDirty: false, keepDefaultValues: false });
		} catch (err) {
			errorToast(
				err?.response?.data?.message || "Error al guardar el draft del estado de preparación"
			);
		}
	};

	const checkFormIsFilled = (formData) => {
		const isFilled = formData.components.every(
			({ initialState, finalState }) => initialState && finalState
		);
		!isFilled && setShowFinishPreparationNotFilledModal(true);

		return isFilled;
	};

	const validatePreparation = (formData) => {
		const isValidPreparation = formData.components.every(
			({ initialState, finalState, comment }) => {
				// Estado inicial "No aplica" no requiere estado final.
				if (initialState.value === preparationStateOptions[0].value) {
					return true;
				}
				// Estado inicial “Bien” no requiere estado final.
				else if (initialState.value === preparationStateOptions[1].value) {
					return true;
				}
				// Estado inicial “Regular” o “Mal” requieren un estado final + comentario para finalizar preparación.
				else if (
					(initialState.value === preparationStateOptions[2].value ||
						initialState.value === preparationStateOptions[3].value) &&
					finalState?.value &&
					comment &&
					comment !== ""
				) {
					return true;
				}

				return false;
			}
		);

		isValidPreparation
			? setShowFinishPreparationSureModal(true)
			: setShowFinishPreparationErrorModal(true);

		return isValidPreparation;
	};

	const finishPreparation = async (formData) => {
		if (isDirty) await saveDraft(formData);

		try {
			const { data } = await instance.post(
				`/api/vehicles/${vehicleId}/preparation-components/validation`
			);
			setValidated(data);
		} catch (err) {
			errorToast(err?.response?.data?.message || "Error al finalizar la preparación");
		}
	};

	const cancelPreparation = async () => {
		try {
			await instance.delete(`/api/vehicles/${vehicleId}/preparation-components/validation`);
			setValidated(null);
		} catch (err) {
			errorToast(err?.response?.data?.message || "Error al cancelar la preparación");
		}
	};

	const ComponentMobileTitle = ({ field }) => {
		const initialBubbleConfig = useMemo(
			() => getStateBubbleConfig(field.initialState),
			[field.initialState]
		);
		const finalBubbleConfig = useMemo(
			() => getStateBubbleConfig(field.finalState),
			[field.finalState]
		);

		return (
			<div className={styles.bubblesTitle}>
				<span
					className={styles.bubblesTitleText}
					title={field.component.name}
				>
					{field.component.name}
				</span>
				<div className={styles.bubbles}>
					<div
						className={styles.stateBubble}
						style={{
							borderColor: initialBubbleConfig.borderColor,
							color: initialBubbleConfig.color
						}}
					>
						{initialBubbleConfig.letter}
					</div>

					{(initialBubbleConfig.letter !== "i" || finalBubbleConfig.letter !== "i") && (
						<>
							<span>{">"}</span>
							<div
								className={styles.stateBubble}
								style={{
									borderColor: finalBubbleConfig.borderColor,
									color: finalBubbleConfig.color
								}}
							>
								{finalBubbleConfig.letter}
							</div>
						</>
					)}
				</div>
			</div>
		);
	};

	const SelectController = ({ name, options }) => {
		const [, idx, type] = name.split(".");
		const component = components[idx];

		return (
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<Select
						{...field}
						// Ignore the overflow: hidden of .collapsibleBody
						menuPortalTarget={document.body}
						isDisabled={validated}
						onChange={(opt) => {
							if (type === "initialState" && opt.value === doesntApplyOption.value) {
								updateComponent(idx, {
									...component,
									initialState: opt,
									finalState: doesntApplyOption
								});
							} else if (type === "initialState" && opt.value === goodOption.value) {
								updateComponent(idx, { ...component, initialState: opt, finalState: goodOption });
							} else {
								field.onChange(opt);
							}
						}}
						options={options}
						placeholder="Seleccione.."
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								borderColor:
									preparationStateOptionsColors[field.value?.label]?.borderColor || "#ccc"
							}),
							singleValue: (baseStyles, state) => ({
								...baseStyles,
								color: preparationStateOptionsColors[field.value?.label]?.color || "black"
							})
						}}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary: "#fe9800",
								primary25: "#ffc548"
							}
						})}
					/>
				)}
			/>
		);
	};

	const paneButtons = (
		<>
			{IS_SUPER_ADMIN(userType) && validated && (
				<button
					className="btn-flexicar-orange"
					onClick={cancelPreparation}
				>
					Habilitar Preparación
				</button>
			)}
			{!validated && (
				<button
					className="btn-flexicar-inverse"
					onClick={handleSubmit(saveDraft)}
					disabled={!isDirty}
				>
					Guardar borrador
				</button>
			)}

			<button
				className={`${validated ? "btn-flexicar-inverse" : "btn-flexicar-orange"}`}
				onClick={handleSubmit((formData) => {
					const isFilled = checkFormIsFilled(formData);
					isFilled && validatePreparation(formData);
				})}
				disabled={validated}
			>
				{validated ? "Preparación finalizada" : "Finalizar preparación"}
			</button>
		</>
	);

	return (
		<>
			<RouteLeavingGuard when={isDirty} />
			{isLoading ? (
				<Loading />
			) : (
				<>
					<PaneHeader
						title={
							<div>
								Estado Preparación{" "}
								{validated ? <AiOutlineCheckCircle size={22} /> : <AiOutlineInfoCircle size={22} />}
							</div>
						}
						buttons={paneButtons}
					/>

					{validated && (
						<div className={styles.validatedBy}>
							<div>
								Vehículo verificado por:{" "}
								{`${validated.createdBy.name}  ${validated.createdBy.surname1} ${validated.createdBy.surname2}`}
							</div>
							<div>{formatSpanishFullDate(validated.createdAt)}</div>
						</div>
					)}

					{!isMobile && (
						<div className={styles.componentsHeader}>
							<div>&nbsp;</div>
							<span>Estado Inicial</span>
							<span>Comentario</span>
							<span>Estado final</span>
						</div>
					)}

					<form className={styles.componentsForm}>
						{isMobile
							? components.map((component, idx) => {
									return (
										<Collapsible
											key={`RHF-fieldarray-${component.component.id}`}
											header={<ComponentMobileTitle field={component} />}
										>
											<fieldset disabled={validated}>
												<div className={styles.component}>
													<div>
														<span>Estado inicial</span>
														<SelectController
															name={`components.${idx}.initialState`}
															options={preparationStateOptions}
														/>
													</div>

													<div>
														<span>Comentario</span>
														<OverflowedInput
															control={control}
															register={{
																...register(`components.${idx}.comment`)
															}}
														/>
													</div>
													<div>
														<span>Estado final</span>
														<SelectController
															name={`components.${idx}.finalState`}
															options={preparationStateOptions}
														/>
													</div>
												</div>
											</fieldset>
										</Collapsible>
									);
							  })
							: components.map((component, idx) => {
									return (
										<fieldset
											key={`RHF-fieldarray-${component.component.id}`}
											disabled={validated}
										>
											<div className={styles.component}>
												<span
													className={styles.componentName}
													title={component.component.name}
												>
													{component.component.name}
												</span>
												<SelectController
													name={`components.${idx}.initialState`}
													options={preparationStateOptions}
												/>

												<OverflowedInput
													control={control}
													register={{
														...register(`components.${idx}.comment`)
													}}
												/>

												<SelectController
													name={`components.${idx}.finalState`}
													options={preparationStateOptions}
												/>
											</div>
										</fieldset>
									);
							  })}
					</form>
				</>
			)}

			{showFinishPreparationNotFilledModal && (
				<Modal
					className="modal-xl"
					centered
					isOpen={showFinishPreparationNotFilledModal}
					toggle={() => setShowFinishPreparationNotFilledModal(false)}
				>
					<ModalHeader toggle={() => setShowFinishPreparationNotFilledModal(false)}>
						Rellena todos los campos
					</ModalHeader>
					<ModalBody>
						Es necesario completar el estado de todos los conceptos para poder finalizar
						preparación.
					</ModalBody>
					<ModalFooter>
						<button
							className="btn-flexicar-orange"
							onClick={() => setShowFinishPreparationNotFilledModal(false)}
						>
							Aceptar
						</button>
					</ModalFooter>
				</Modal>
			)}

			{showFinishPreparationErrorModal && (
				<Modal
					className="modal-xl"
					centered
					isOpen={showFinishPreparationErrorModal}
					toggle={() => setShowFinishPreparationErrorModal(false)}
				>
					<ModalHeader toggle={() => setShowFinishPreparationErrorModal(false)}>
						Error al finalizar preparación
					</ModalHeader>
					<ModalBody>
						No se puede finalizar la preparación. El estado inicial “Mal”, “Regular”, debe mostrar
						un estado final correspondiente. Revisa los conceptos y marca un estado final si aplica.
					</ModalBody>
					<ModalFooter>
						<button
							className="btn-flexicar-orange"
							onClick={() => setShowFinishPreparationErrorModal(false)}
						>
							Aceptar
						</button>
					</ModalFooter>
				</Modal>
			)}

			{showFinishPreparationSureModal && (
				<Modal
					className="modal-xl"
					centered
					isOpen={showFinishPreparationSureModal}
					toggle={() => setShowFinishPreparationSureModal(false)}
				>
					<ModalHeader toggle={() => setShowFinishPreparationSureModal(false)}>
						Finalizar preparación
					</ModalHeader>
					<ModalBody>
						Se va a finalizar la preparación. Esta acción solo será reversible por un Admistrador
						General. ¿Quieres continuar?
					</ModalBody>
					<ModalFooter>
						<button
							className="btn-flexicar-inverse"
							onClick={() => setShowFinishPreparationSureModal(false)}
						>
							Cancelar
						</button>
						<button
							className="btn-flexicar-orange"
							onClick={async () => {
								await handleSubmit(finishPreparation)();
								setShowFinishPreparationSureModal(false);
							}}
						>
							Aceptar
						</button>
					</ModalFooter>
				</Modal>
			)}
		</>
	);
};

export default PreparationState;
