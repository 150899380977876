import React, { useState, useEffect } from "react";
import { post } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./index.module.scss";
import { errorToast, successToast } from "../../../helpers/toastFunctions";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useTranslation } from "react-i18next";
import { PHONES } from "constants/phones";

const ModalMail = ({
  launchModal,
  status,
  email,
  vehicle_marca,
  vehicle_modelo,
  vehicle_url,
  agent_phone,
  agent_name,
}) => {
  const { t } = useTranslation("modalMail");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const rawContentState = convertToRaw(editorState.getCurrentContent());

  const markup = draftToHtml(rawContentState);

  useEffect(() => {
    const html = `Buenos días, soy <strong>${agent_name?.nombre} ${
      agent_name?.apellido1
    } ${agent_name?.apellido2} </strong>, asesor/a comercial de Flexicar.

Hemos recibido un correo interesándose por <a style="color: #d34600" href="${vehicle_url}">${vehicle_marca} ${vehicle_modelo}</a>.

<strong>Le financiamos hasta el 100% del importe del vehículo</strong>, pudiendo <strong>pagar cómodas cuotas</strong>, para que pueda conducir su coche de inmediato.

Le propongo que contacte conmigo para ampliar la información en <strong>el teléfono <a style="color: #d34600" href="tel:+34${PHONES.call_center_shell.replace(
      /\s/g,
      ""
    )}">${
      PHONES.call_center_shell
    }</a> / <a style="color: #d34600" href="tel:+34${agent_phone?.replace(
      /\s/g,
      ""
    )}">${agent_phone} </a> o a través de <a style="color: #d34600" href="https://api.whatsapp.com/send?phone=+34${agent_phone?.replace(
      /\s/g,
      ""
    )}">Whatsapp</a></strong>.
También, nos ponemos a su disposición, para <strong>poderle dar cita directamente</strong> en el concesionario, a la hora y día que más le convenga, <strong>si nos quiere visitar</strong>. 

Le invitamos a que vea más vehículos en <a style="color: #d34600" href="https://www.flexicar.es">www.flexicar.es</a>.

Quedo a su entera disposición.

Reciba un cordial saludo.`;
    const contentBlock = htmlToDraft(html);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [
    email,
    vehicle_marca,
    vehicle_modelo,
    vehicle_url,
    agent_phone,
    agent_name
  ]);

  const saveMesaje = async () => {
    try {
      const response = await post(API.SEND_EMAIL_MESSAGE_VENTA, {
        email,
        marca: vehicle_marca,
        modelo: vehicle_modelo,
        html: markup
      });
      if (response.error) {
        return errorToast(<span>{t("errors.errorSending")}</span>);
      } else {
        launchModal();
        return successToast("Email enviado correctamente");
      }
    } catch (e) {
      return errorToast(<span>{t("errors.errorSending")}</span>);
    }
  };

  const onEditorStateChange = (e) => {
    setEditorState(e);
  };

  return (
    <Modal
      className="modal-lg"
      centered
      isOpen={status}
      toggle={() => launchModal()}
    >
      <ModalHeader>Enviar email al cliente</ModalHeader>
      <ModalBody className="p-4">
        <Editor
          editorState={editorState}
          onEditorStateChange={(e) => onEditorStateChange(e)}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-light btn-outline-dark btn-flexicar"
          onClick={() => saveMesaje()}
        >
          Enviar
        </button>
        <button
          type="button"
          className={`btn btn-light btn-outline-dark btn-flexicar ${styles.button}`}
          onClick={() => launchModal()}
        >
          Cerrar
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalMail;
