import { forwardRef, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

/**
 * Props for the FormattedNumberInput component.
 * @typedef {Object} FormattedNumberInputProps
 * @property {(string|number)} value - The value, which can be a string or a number.
 * @property {function} onChange - A function called when the value changes.
 * @property {string} [placeholder="0.00 €"] - The placeholder for the input.
 * @property {string} [mask] - The format mask for the value.
 * @property {string} [prefix] - The prefix to display before the value.
 * @property {string} [suffix=" €"] - The suffix to display after the value.
 * @property {"input"|"text"} [displayType="input"] - The type of display ("input" or "text").
 * @property {(string|boolean)} [thousandSeparator="."] - The thousands separator, which can be a string or a boolean value.
 * @property {string} [decimalSeparator=","] - The decimal separator.
 * @property {number} [decimalScale=2] - The number of decimal places to display.
 * @property {number} [decimalPow=0] - The power to which the value is raised to get the numeric representation.
 * @property {string} [className] - Additional CSS classes for the component.
 */

/**
 * @type React.FC<FormattedNumberInputProps>
 */
const FormattedNumberInput = forwardRef(
  (
    {
      value,
      onChange,
      mask,
      placeholder = "0.00 €",
      prefix,
      suffix = " €",
      displayType = "input",
      thousandSeparator = ".",
      decimalSeparator = ",",
      decimalScale = 2,
      decimalPow = 0,
      className,
      ...rest
    },
    ref
  ) => {
    const [currency, setCurrency] = useState(value / Math.pow(10, decimalPow));

    useEffect(() => {
      setCurrency(value / Math.pow(10, decimalPow));
    }, [value, decimalPow]);

    return (
      <NumericFormat
        {...rest}
        getInputRef={ref}
        className={className}
        placeholder={placeholder}
        mask={mask}
        value={currency}
        displayType={displayType}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        onValueChange={(values) => {
          const decimalValue = values.floatValue;
          setCurrency(decimalValue);
          onChange?.(
            isNaN(decimalValue) ? 0 : decimalValue * Math.pow(10, decimalPow)
          );
        }}
        valueIsNumericString
        prefix={prefix}
        suffix={suffix}
      />
    );
  }
);

FormattedNumberInput.name = "FormattedNumberInput";
export default FormattedNumberInput;
