import instance from "../base";
import { getCookie } from "../../helpers/getCookie";

const COMMERCIAL_PROPOSAL_ROUTE = "/commercial-proposals";

export const postCommercialProposal = async (data) => {
     const response = await instance.post(`/api${COMMERCIAL_PROPOSAL_ROUTE}`, data, {
       headers: {
         Authorization: `Bearer ${getCookie("JWT")}`,
       },
     });
     return response
 };

 export const getCommercialProposal = async (id) => {
  const response = await instance.get(`/api/commercial-proposals/${id}`,{
    headers: {
      Authorization: `Bearer ${getCookie("JWT")}`,
    },
  });
  return response
};

export const deleteCommercialProposal = async (id) => {
  const response = await instance.delete(`/api/commercial-proposals/${id}`,{
    headers: {
      Authorization: `Bearer ${getCookie("JWT")}`,
    },
  });
  return response
};
