import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { post } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";
import { errorToast, successToast } from "../../../helpers/toastFunctions";
import { MdEmail } from "react-icons/md";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const ModalEnviarMail = ({ nombre, email }) => {
  const { t } = useTranslation("modalSendEmail");
  const [conversacion, setConversacion] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const newConversation = async () => {
    try {
      const response = await post(API.SEND_EMAIL_MESSAGE, {
        nombre,
        conversacion,
        email,
        template: 7
      });
      if (response.error) {
        setModal(false);
        return errorToast(<span>{t("errors.errorMessage")}</span>);
      } else {
        setConversacion();
        setModal(false);
        return successToast("Email enviado correctamente");
      }
    } catch {
      return errorToast(<span>{t("errors.errorMessage")}</span>);
    }
  };

  return (
    <>
      <div>
        <MdEmail color="white" size={30} role="button" onClick={toggle} />

        <Modal isOpen={modal}>
          <ModalHeader className={`${styles.modal_header}`}>
            Nuevo Email
          </ModalHeader>
          <ModalBody>
            <div className="col-md-12 col-sm-12 form-inline">
              <h3>¡Hola {nombre}!</h3>
              <p>
                Nos ponemos en contacto con usted, por una tasación que ha hecho
                de su vehículo a través de nuestra página web flexicar.es.
              </p>
              <textarea
                id="conversacion"
                placeholder={"Mensaje"}
                className="form-control ds-input mt-1"
                type="text-area"
                value={conversacion}
                rows="10"
                cols="50"
                onChange={(event) => setConversacion(event.target.value)}
              />
              <p>
                Muchas gracias.
                <br />
                ¡Saludos!
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className={`${styles.btn_custom}`} onClick={newConversation}>
              Enviar Email
            </Button>
            {""}
            <Button color="secondary" onClick={toggle}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default ModalEnviarMail;
