import { environment } from "config/environment.config";

export const getYoutubeVideoId = (url) => {
  if (url.includes("v=")) {
    if (url.includes("&")) {
      return url.split("v=")[1].split("&")[0];
    } else {
      return url.split("v=")[1];
    }
  } else {
    return url.split("/")[3];
  }
};

export const checkChannelId = (data) => {
  if (data.items.length === 0) {
    return false;
  } else {
    return data.items[0].snippet.channelId === environment.CHANNEL_ID_YOUTUBE
      ? true
      : false;
  }
};

export const getDuration = (duration) => {
  if (duration.includes("M")) {
    return (
      parseInt(duration.split("PT")[1].split("M")[0] * 60) +
      parseInt(duration.split("PT")[1].split("M")[1].split("S")[0])
    );
  } else {
    return parseInt(duration.split("PT")[1].split("S")[0]);
  }
};

export const defaultRoles = (roles, adminRoles) => {
  let rolesValue = [];

  if (roles && roles !== []) {
    roles.roles.forEach((item) => rolesValue.push(item.value));
  }

  adminRoles.forEach((elem) => rolesValue.push(elem));

  let rolesValueFilter = rolesValue.filter((item, index) => {
    return rolesValue.indexOf(item) === index;
  });

  return rolesValueFilter;
};
