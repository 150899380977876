import { UncontrolledTooltip } from "reactstrap";
import { MdInfo } from "react-icons/md";

/**
 * A tooltip component that displays information when hovering over an information icon.
 * @component
 *
 * @param {Object} props - The component's properties.
 * @param {string} props.name - The ID of the target element to which the tooltip will be associated.
 * @param {string} props.text - The text to be displayed in the tooltip.
 *
 * @returns {JSX.Element} The Tooltip component.
 */
const Tooltip = ({ name, text }) => (
	<>
		<MdInfo
			id={name}
			color="#007bff"
			size={25}
			style={{ cursor: "pointer" }}
		/>

		<UncontrolledTooltip target={name}>{text}</UncontrolledTooltip>
	</>
);

export default Tooltip;
