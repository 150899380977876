import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AiOutlineCalendar } from "react-icons/ai";
import Select from "react-select";
import {
	format,
	setHours,
	setMinutes,
	addDays,
	isToday,
	compareAsc,
	compareDesc,
	isAfter,
	isBefore
} from "date-fns";
import DatePicker from "react-datepicker";
import ESDateLocale from "date-fns/locale/es";
import PTDateLocale from "date-fns/locale/pt";

import styles from "./index.module.scss";
import instance from "services/base";
import Locale from "constants/Locale";
import { createAfterSalesAppointmentSchema } from "schema/postventaSchema";
import FormError from "components/FormError";
import { errorToast, successToast } from "helpers/toastFunctions";
import Card from "components/Card";
import { formatSpanishFullDate } from "helpers/formatDate";
import { MdOutlineDelete } from "react-icons/md";
import { WarningPanel } from "components/InfoBox";
import GenericModal from "components/GenericModal";
import Loading from "views/Loading/Loading";
import ModalUpdateCaseEmail from "./ModalUpdateCaseEmail";

const defaultAppointmentDateTimes = {
	min: setHours(setMinutes(new Date(), 30), 9),
	max: setHours(setMinutes(new Date(), 0), 20)
};

const CasoAppointments = ({ caseInfo, setCaseInfo }) => {
	const {
		formState: { errors },
		handleSubmit,
		control,
		watch,
		reset,
		getValues
	} = useForm({
		resolver: zodResolver(createAfterSalesAppointmentSchema)
	});

	const { ready, t, i18n } = useTranslation("caseAppointments");
	const { caseId } = useParams();

	const [isLoading, setIsLoading] = useState(true);

	const [locales, setLocales] = useState([{}]);
	const [activeAppointments, setActiveAppointments] = useState([]);
	const [inactiveAppointments, setInactiveAppointments] = useState([]);

	const appointmentDate = watch("appointmentDate");
	const [appointmentDateTimes, setAppointmentDateTimes] = useState(defaultAppointmentDateTimes);

	const [showCancelAppointmentModal, setShowCancelAppointmentModal] = useState(false);
	const [showUpdateCaseEmailModal, setShowUpdateCaseEmailModal] = useState(false);
	const [appointmentIdToDelete, setAppointmentIdToDelete] = useState(undefined);

	const loadLocales = useCallback(async () => {
		try {
			const localesResponse = await instance.get(
				"/api/me/locals?localType=1&flexicarOnly=true&active=true"
			);
			setLocales(
				localesResponse.data.map(({ id, name }) => ({
					value: id,
					label: name
				}))
			);
		} catch (err) {
			errorToast(errorToast(err?.response?.data?.message || t("errors.load.locales")));
		}
	}, [t]);

	const loadAppointmentList = useCallback(async () => {
		try {
			const appointmentsResponse = await instance.get(
				`/api/post-venta/cases/${caseId}/appointments`
			);
			const { notCancelled, cancelled } = appointmentsResponse.data.reduce(
				(acc, x) => {
					x.cancelled === null
						? compareAsc(new Date(x.appointmentDate), new Date()) === 1
							? acc.notCancelled.push(x)
							: acc.cancelled.push(x)
						: acc.cancelled.push(x);
					return acc;
				},
				{ notCancelled: [], cancelled: [] }
			);

			setActiveAppointments(notCancelled);
			setInactiveAppointments(
				cancelled.sort((a, b) =>
					compareDesc(new Date(a.appointmentDate), new Date(b.appointmentDate))
				)
			);
		} catch (err) {
			errorToast(err?.response?.data?.message || t("errors.load.appointments"));
		}
	}, [t, caseId]);

	const onDateChange = (date) => {
		let dateWithHour = date;

		if (dateWithHour.getHours() === 0) {
			if (isToday(dateWithHour)) {
				if (new Date().getHours() >= 20) {
					dateWithHour = setHours(setMinutes(addDays(dateWithHour, 1), 30), 9);
				} else {
					const newDate = new Date().getHours() + 1;
					dateWithHour =
						newDate <= 20
							? setHours(dateWithHour, newDate)
							: setHours(setMinutes(addDays(dateWithHour, 1), 30), 9);
				}
			} else if (isAfter(dateWithHour, new Date())) {
				dateWithHour = setHours(setMinutes(dateWithHour, 30), 9);
			}
		} else {
			if (isBefore(dateWithHour, new Date())) {
				const newDate = new Date().getHours() + 1;
				dateWithHour =
					newDate <= 20
						? setHours(dateWithHour, newDate)
						: setHours(setMinutes(addDays(dateWithHour, 1), 30), 9);
			}
		}

		return dateWithHour;
	};

	const createAppointment = async (formData) => {
		const { appointmentDate, appointmentLocal } = formData;
		try {
			await instance.post(`/api/post-venta/cases/${caseId}/appointments`, {
				localId: appointmentLocal.value,
				date: format(appointmentDate, "yyyy-MM-dd'T'HH:mm")
			});
			successToast(t("success.createAppointment"));

			// Change to state 'Citado'
			setCaseInfo((prev) => ({
				...prev,
				estadoId: 2
			}));

			await loadAppointmentList();
		} catch (err) {
			if (err?.response?.status === 409) errorToast(t("errors.duplicatedAppointment"));
			else errorToast(err?.response?.data?.message || t("errors.createAppointment"));
		} finally {
			reset({ appointmentLocal: "", appointmentDate: undefined });
		}
	};

	const cancelAppointmentById = async () => {
		try {
			await instance.delete(`api/post-venta/cases/${caseId}/appointments/${appointmentIdToDelete}`);

			await loadAppointmentList();
			setAppointmentIdToDelete(undefined);
			setShowCancelAppointmentModal(false);

			// Change to state 'Abierto'
			setCaseInfo((prev) => ({
				...prev,
				estadoId: 1
			}));

			successToast(t("success.cancelAppointment"));
		} catch (err) {
			errorToast(err?.response?.data?.message || t("errors.cancelAppointment"));
		}
	};

	const filterSundays = (date) => {
		return date.getDay() !== 0;
	};

	useEffect(() => {
		(async () => {
			if (ready) {
				await loadLocales();
				await loadAppointmentList();
				setIsLoading(false);
			}
		})();
	}, [ready, loadLocales, loadAppointmentList]);

	useEffect(() => {
		const currentDate = new Date();
		const { min, max } = defaultAppointmentDateTimes;

		if (isToday(appointmentDate ?? new Date())) {
			// Si la hora actual es menor que la min
			// muestra todas las horas
			if (currentDate.getTime() < min.getTime())
				setAppointmentDateTimes(defaultAppointmentDateTimes);
			// Si la hora acutal es menor que la max
			// muestra las horas entre la acutal y la final
			else if (currentDate.getTime() < max.getTime())
				setAppointmentDateTimes({ min: currentDate, max });
		}
		// Si es otro dia habilitamos todas las horas por defecto
		else setAppointmentDateTimes(defaultAppointmentDateTimes);
	}, [appointmentDate]);

	const AppointmentCard = ({ appointment }) => {
		const [content, setContent] = useState('');
	  
		useEffect(() => {
		  const isCancelled = appointment.cancelled;
		  const agent = isCancelled ? appointment.cancelled.cancelledBy : appointment.createdAgent;
		  const fullName = `${agent.name} ${agent.firstSurname} ${agent.secondSurname}`;
	  
		  const newContent = `
			${fullName} 
			${isCancelled ? t("card.cancelled") : t("card.content")}
			${isCancelled ? formatSpanishFullDate(appointment.cancelled.cancelledAt.replace("Z", "")) : formatSpanishFullDate(appointment.createdAt.replace("Z", ""))}
		  `;
	  
		  setContent(newContent);
		}, [appointment]);
	  
		const cardTitle = `${t("card.title")} ${formatSpanishFullDate(appointment.appointmentDate)}h`;
		const cardSubtitle = `${t("card.subtitle")} ${appointment.dealership.name}`;
	  
		let cancelAction = null;
		if (!appointment.cancelled && compareAsc(new Date(appointment.appointmentDate), new Date()) === 1) {
		  cancelAction = (
			<MdOutlineDelete
			  className="pointer"
			  size={24}
			  color="#D34600"
			  onClick={() => {
				setAppointmentIdToDelete(appointment.id);
				setShowCancelAppointmentModal(true);
			  }}
			/>
		  );
		}
	  
		return (
		  <Card
			title={cardTitle}
			subtitle={cardSubtitle}
			actions={cancelAction}
			content={content}
		  />
		);
	  };

	if (isLoading || !ready) return <Loading />;

	return (
		<>
			<div className={styles.appointments}>
				<form
					className={styles.formAppointment}
					onSubmit={handleSubmit((data) =>
						caseInfo?.email ? createAppointment(data) : setShowUpdateCaseEmailModal(true)
					)}
				>
					<div>
						<Controller
							name="appointmentLocal"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									options={locales}
									placeholder={t("localSelectPlaceholder")}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											primary: "#fe9800",
											primary25: "#ffc548"
										}
									})}
								/>
							)}
						/>
						<FormError error={errors["appointmentLocal"]} />
					</div>

					<div>
						<Controller
							name="appointmentDate"
							control={control}
							render={({ field }) => (
								<div style={{ display: "flex" }}>
									<DatePicker
										{...field}
										selected={field.value}
										onChange={(date) => {
											const dateWithHour = onDateChange(date);
											field.onChange(dateWithHour);
										}}
										autoComplete="off"
										locale={
											{
												[Locale.ES]: ESDateLocale,
												[Locale.PT]: PTDateLocale
											}[i18n.language]
										}
										showTimeSelect
										minDate={
											isAfter(new Date(), setHours(setMinutes(new Date(), 0), 20))
												? addDays(new Date(), 1)
												: new Date()
										}
										timeFormat="HH:mm"
										timeIntervals={30}
										minTime={appointmentDateTimes.min}
										maxTime={appointmentDateTimes.max}
										timeCaption="Horas"
										filterDate={filterSundays}
										dateFormat="dd/MM/yyyy - HH:mm"
										placeholderText={t("datePickerPlaceholder")}
										onKeyDown={(e) => e.preventDefault()}
									/>
									<div style={{ position: "relative", display: "flex", alignItems: "center" }}>
										<AiOutlineCalendar
											size={22}
											style={{ position: "absolute", right: "10px" }}
										/>
									</div>
								</div>
							)}
						/>
						<FormError error={errors["appointmentDate"]} />
					</div>

					<button
						className="btn btn-flexicar-orange"
						type="submit"
					>
						{t("createAppointmentButton")}
					</button>
				</form>

				<div className={styles.appointmentsActiveWrapper}>
					<h3>{t("appointmentsTitle")}</h3>
					<div>
						{activeAppointments.length > 0 ? (
							activeAppointments.map((appointment) => (
								<AppointmentCard
									key={appointment.id}
									appointment={appointment}
								/>
							))
						) : (
							<WarningPanel text={t("noAppointments")} />
						)}
					</div>
				</div>

				<div className={styles.appointmentsInctiveWrapper}>
					<h3>{t("historicalTitle")}</h3>
					<div>
						{inactiveAppointments.length > 0 ? (
							inactiveAppointments.map((appointment) => (
								<AppointmentCard
									key={appointment.id}
									appointment={appointment}
								/>
							))
						) : (
							<WarningPanel text={t("noHistorical")} />
						)}
					</div>
				</div>
			</div>

			{showCancelAppointmentModal && (
				<GenericModal
					isOpen={showCancelAppointmentModal}
					setIsOpen={setShowCancelAppointmentModal}
					headContent={t("cancelAppointmentModal.title")}
					bodyContent={t("cancelAppointmentModal.content")}
					footerContent={
						<div className={styles.modalButtons}>
							<button
								className="btn btn-flexicar-inverse"
								onClick={() => setShowCancelAppointmentModal(false)}
							>
								{t("cancelAppointmentModal.cancelButton")}
							</button>
							<button
								className="btn btn-flexicar-orange"
								onClick={cancelAppointmentById}
							>
								{t("cancelAppointmentModal.acceptButton")}
							</button>
						</div>
					}
				/>
			)}

			{showUpdateCaseEmailModal && (
				<ModalUpdateCaseEmail
					show={showUpdateCaseEmailModal}
					setShow={setShowUpdateCaseEmailModal}
					caseInfo={caseInfo}
					setCaseInfo={setCaseInfo}
					createAppointment={createAppointment}
					getCreateAppointmentData={getValues}
				/>
			)}
		</>
	);
};

export default CasoAppointments;
