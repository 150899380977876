import React, { useState, useEffect, useContext, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Filter from "components/Filter";
import { post, put } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import { errorToast, successToast } from "../../helpers/toastFunctions";
import { UserContext } from "contexts/UserContext";
import { useTranslation } from "react-i18next";

const ModalChangeAgent = ({ id_lead, setModal, status, id_cliente }) => {
  const { t } = useTranslation("modalChangeAgent");
  const [lead, setLead] = useState(null);
  const [filterGestor, setFilterGestor] = useState(null);
  const { user } = useContext(UserContext);

  const getLeadData = useCallback(
    async (id_lead) => {
      const response = await post(API.GET_LEAD_VENTA, {
        agent: user,
        id: id_lead,
      });
      setLead(response[0]);
    },
    [user]
  );

  useEffect(() => {
    getLeadData(id_lead);
  }, [id_lead, getLeadData]);

  const updateData = async (f, sellAgent) => {
    try {
      const response = await put(
        `${API.CHANGE_AGENT_VENTAS}/${id_lead}/agent/`,
        { id: sellAgent[0].value }
      );
      if (response.status === 204) {
        successToast("Gestor actualizado correctamente");
      }
      setFilterGestor(null);
    } catch (error) {
      errorToast(<span>{t("errors.errorChangeAgent")}</span>);
    } finally {
      setModal(false);
    }
  };

  return (
    <Modal
      className="modal-md"
      centered
      isOpen={status}
      toggle={() => setModal()}
    >
      <ModalHeader>Cambiar Agente</ModalHeader>
      <ModalBody className="p-4">
        <Filter
          key={"Gestor"}
          setter={"setFilterGestor"}
          multi={false}
          sendData={updateData}
          parentValues={
            filterGestor
              ? [
                  {
                    label: lead?.nombre_agente,
                    value: lead?.id_agente,
                  },
                ]
              : ""
          }
          name={lead?.nombre_agente}
          query={`agente_venta`}
          // data={[userType, user, userType]}
        />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ModalChangeAgent;
