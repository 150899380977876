import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

const CarouselImages = ({ images }) => {
  const [items] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = (image_url) => {
    setModal(!modal);
    setImageUrl(image_url);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const generateCarouselItems = (images, maxImgHeight) => {
    const slides = images.map((img) => {
      return (
        <CarouselItem
          className="custom-tag1"
          tag="div"
          key={img.id}
          style={{ maxHeight: `${maxImgHeight}px`, height: "auto" }}
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
        >
          <figure
            style={{
              maxHeight: `${maxImgHeight - 100}px`, // Just an example for overflow
              overflow: "hidden",
              margin: "1rem 0 1rem",
            }}
          >
            <img
              src={img.url}
              style={{ width: "100%", cursor: "zoom-in" }}
              alt="Images array"
              onClick={() => toggle(img.url)}
            />
          </figure>
        </CarouselItem>
      );
    });
  
    return slides;
  };


  return (
    <div>
      <style>
        {`.custom-tag {
              max-width: 100%;
              height: 300px;
              background: black;
            }`}
      </style>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {generateCarouselItems(images, 500)}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
      

      
      <Modal isOpen={modal} size="xl" >
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {generateCarouselItems(images, 900)}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CarouselImages;
