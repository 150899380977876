import { format, parse } from "date-fns";

import { get } from "services/axiosCalls";
import instance from "services/base";

export const getPurchaseAppointments = async (startDate, endDate) => {
	const response = await get(
		`/api/appointments/purchase?startDate=${startDate}&endDate=${endDate}`
	);
	return response;
};

export const getEmployeeLocals = async (employeeId) => {
	const response = await get(`/api/employee/${employeeId}/locales?onlyActive=true`);
	return response;
};

export const getSalesAppointments = async (startDate, endDate) => {
	const response = await get(`/api/appointments/sales?startDate=${startDate}&endDate=${endDate}`);
	return response;
};

export const getAfterSalesAppointments = async (startDate, endDate) => {
	const parsedStartDate = format(parse(startDate, "dd-MM-yyyy", new Date()), "yyyy-MM-dd'T'HH:mm");
	const parsedEndDate = format(
		parse(endDate, "dd-MM-yyyy", new Date()).setHours(23, 59, 59, 59),
		"yyyy-MM-dd'T'HH:mm"
	);

	const response = await instance.get(
		`/api/appointments
			?dateStart=${parsedStartDate}
			&dateEnd=${parsedEndDate}
			&cancelled=false
		`
	);

	return response.data;
};
