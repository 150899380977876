import React, { useState, useCallback, useEffect } from "react";

import { Row, Col } from "reactstrap";

import styles from "./index.module.scss";

import classes from "./index.module.scss";

import TabPaneHeader from "../TabPaneHeader";
import { useTranslation } from "react-i18next";

import { getDocument, getStock } from "services/Vehicle/vehicle.service";
import errorCall from "services/errorCall";
import AdditionalDocumentType from "constants/AdditionalDocumentType";
import AdditionalInfoTable from "components/AdditionalInfoTable";

const AdditionalInfoStock = ({ vehicle }) => {
  const { t } = useTranslation('vehicleValidation');
  const [vehicleInfo, setVehicleInfo] = useState({});
  const [documents, setDocuments] = useState([])
  const [viewingDocument, setViewingDocument] = useState(false);


  const getVehicleInfo = useCallback(async () => {
    if (!vehicle.vehicle_id_flexicar) return;
    try {
      const response = await getStock(vehicle.vehicle_id_flexicar);
      setVehicleInfo(response);
      setDocuments(response.documents)
    } catch (e) {
      errorCall(e);
    }
  }, [vehicle.vehicle_id_flexicar]);

  useEffect(() => {
    getVehicleInfo();
  }, [getVehicleInfo]);

  const handleViewDocument = async (documentId) => {
    setViewingDocument(documentId);

    try {
      const type = "stock"
      const id = vehicle.vehicle_id_flexicar;
      const documentUrl = await getDocument(id, type, documentId);

      if (documentUrl) {
        window.open(documentUrl, "_blank");
      }
    } catch (error) {
      console.error("Error al obtener el documento:", error);
    } finally {
      setViewingDocument(null);
    }
  };
  return (
    <>
      <TabPaneHeader
        title="INFO ADICIONAL"
        buttonType="infoadicional"
        vehicle={vehicle}
      />
      <Row className={styles.centerRow}>
        <Col xs="12" sm="12">
          <h4 className={classes.bold}>{t("interestDocuments")}</h4>
        </Col>
        {documents.length > 0 && (
          <AdditionalInfoTable
            documents={documents}
            AdditionalDocumentType={AdditionalDocumentType}
            viewingDocument={viewingDocument}
            handleViewDocument={handleViewDocument}
            isStock={true}
          />
        )}

        <Col xs="12" sm="6">
          <div className={classes.align}>
            <span> {t("lastDateRevision")}</span>
            <span className={classes.bold}>
              {" "}
              {vehicleInfo.lastInspectionDate
                ? vehicleInfo.lastInspectionDate
                : t("noLastRevisionDate")}
            </span>
          </div>
        </Col>

        <Col xs="12" sm="6">
          <div className={classes.align}>
            <span> {t("lastKmRevision")}</span>
            <span className={classes.bold}>
              {" "}
              {vehicleInfo.inspectionKm
                ? vehicleInfo.inspectionKm + " Km"
                : t("noLastRevision")}
            </span>
          </div>
        </Col>
        <Col xs="12" sm="6">
          <div className={classes.align}>
            <span> {t("timingBeltChange")}</span>
            <span className={classes.bold}>
              {vehicleInfo.cambeltReplaceKm ? "SI" : "NO"}
            </span>
          </div>
        </Col>
        <Col xs="12" sm="6">
          {vehicleInfo.cambeltReplaceKm && (
            <div className={classes.align}>
              <span> {t("kmChangeBelt")}</span>
              <span className={classes.bold}>
                {vehicleInfo.cambeltReplaceKm.toLocaleString()} Km
              </span>
            </div>
          )}
        </Col>
        <Col xs="12" sm="6">
          <div className={classes.align}>
            <span className={classes.bold}> </span>
            <span> </span>
          </div>
        </Col>
        <Col xs="12" sm="12">
          <div className={classes.alignComment}>
            <span> {t("comments")}</span>
            <span className={classes.bold}>
              {" "}
              {vehicleInfo.comment
                ? vehicleInfo.comment.text
                : t("noComments")}
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AdditionalInfoStock;
