import React, { useState, useEffect, useContext, Fragment } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Container,
  Row,
  Col,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import Select from "react-dropdown-select";
import { post } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import errorCall from "services/errorCall";
import revision from "helpers/revision";
import Filter from "components/Filter";
import styles from "./index.module.scss";
import { errorToast, successToast } from "helpers/toastFunctions";
import DatePicker from "react-datepicker";
import BankOptions from "constants/bankOptions"
import DocumentationType from "constants/documentationType";
import { UserContext } from "contexts/UserContext";
import RolType from "constants/roles";
import { useTranslation } from 'react-i18next';
import validationPostalCode from "helpers/validationPostalCode";
import Locale from "constants/Locale";
import FormattedNumberInput from "components/FormattedNumberInput";
import validationIBAN from "helpers/validationIBAN";
import Tooltip from "components/Tooltip";

const ModalUpdateContract = ({
  open,
  close,
  document,
  recall,
  recallGarantia,
  user,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation(['contractEdition'])
  const [localDocument, setLocalDocument] = useState({});
  const [pago, setPago] = useState();
  const [premium, setPremium] = useState(false);
  const [warrantyExtra, setWarrantyExtra] = useState(false);
  const [transporte, setTransporte] = useState(false);
  const [financia, setFinancia] = useState(false);
  const [vehiculo] = useState({
    marca_vehiculo: "",
    modelo: "",
    matriculacion: "",
    combustible: "",
    ccambios: "",
    carroceria: "",
    version: "",
    jato: true,
    id_jato: null,
    modify: false,
  });

  const { rol } = useContext(UserContext);
  
  useEffect(() => {
    if (document?.datos) {
      const parseDatos = document.datos;
      if (!parseDatos.id_gestoria) {
        parseDatos.id_gestoria = 0;
        parseDatos.gestoria = "Ninguna";
      }

      if (!parseDatos.meses_financia) {
        parseDatos.meses_financia = 24;
      }

      setLocalDocument(parseDatos);
      if (parseDatos.garantia) {
        setPremium(true);
      } else {
        setPremium(false);
      }
      if (parseDatos.garantia_extra) {
        setWarrantyExtra(true);
      } else {
        setWarrantyExtra(false);
      }

      if (parseDatos.transporte) {
        setTransporte(true);
      } else {
        setTransporte(false);
      }
      if (parseDatos.financia) {
        setFinancia(true);
      } else {
        setFinancia(false);
      }
      if (parseDatos.forma_pago) {
        setPago(parseDatos.forma_pago === "4" ? 4 : null);
      }
    }
  }, [document]);
  useEffect(() => {
    if (localDocument.forma_pago === 4) {
      setPago(localDocument.forma_pago);
    } else {
      setPago();
    }
  }, [localDocument.forma_pago]);

  const getEmpresaDataById = async (value, tipo) => {
    const id = value[0].value;
    if (id) {
      const empresaData = await post(API.GET_EMPRESA_BY_ID, {
        id,
      });
      if (tipo === DocumentationType.CONTRATO_VENTA && id && empresaData) {
        setLocalDocument({
          ...localDocument,
          empresa_id: empresaData.res.id,
          empresa_nombre: empresaData.res.nombre,
          sello: empresaData.res.sello,
          empresa_CIF: empresaData.res.CIF,
          empresa_direccion: empresaData.res.direccion,
        });
      }

      if (tipo === DocumentationType.RESERVA) {
        setLocalDocument({
          ...localDocument,
          empresa_id: empresaData.res.id,
          empresa: empresaData.res.nombre,
          sello: empresaData.res.sello,
          empresa_cif: empresaData.res.CIF,
        });
      }

      if (tipo === DocumentationType.COMPRA_DIRECTA || tipo === DocumentationType.GESTION_VENTA) {
        setLocalDocument({
          ...localDocument,
          empresa_id: empresaData.res.id,
          empresa_nombre: empresaData.res.nombre,
          // empresa_cuenta: empresaData.res.nombre,
          sello: empresaData.res.sello,
          empresa_CIF: empresaData.res.CIF,
          empresa_direccion: empresaData.res.direccion,
        });
      }
    }
  };
  const handleSelectOptions = (option, type) => {
		const selectedOption = option[0].value;
		setLocalDocument({ ...localDocument, [type]: selectedOption });
	};

  const handleGestoria = (tipo, element) => {
    setLocalDocument({
      ...localDocument,
      gestoria: element[0].label,
      id_gestoria: element[0].value,
    });
  };

  const handleFilterLocal = (value) => {
    setLocalDocument({
      ...localDocument,
      provincia_local: value[0].label,
      id_provincia_local: value[0].value,
    });
  };

  const handleFilter = (value, type) => {
    setLocalDocument({
      ...localDocument,
      [`${type}_nombre`]: value[0].label,
      [`${type}_id`]: value[0].value,
    });
  };
  const handleFilterEntrega = (value, type) => {
    setLocalDocument({
      ...localDocument,
      [`${type}`]: value[0].label,
      [`${type}_id`]: value[0].value,
    });
  };
  const saveUpdate = async () => {
    let revision;

    if (document.tipo === DocumentationType.CONTRATO_VENTA) {
      revision = revisionContract();
      
    } else if (document.tipo === DocumentationType.RESERVA) {
      revision = revisionReseve();
    } else if (document.tipo === DocumentationType.COMPRA_DIRECTA || document.tipo === DocumentationType.GESTION_VENTA) {
      revision = revisionBuy();
    }
    if (revision) {
      try {
        const localDocumentParsed = {
          ...localDocument,
          marca: vehiculo.marca_vehiculo[0]?.label
            ? vehiculo.marca_vehiculo[0].label
            : localDocument.marca,
          modelo: vehiculo.modelo[0]?.label
            ? vehiculo.modelo[0].label
            : localDocument.modelo,
        };
        if (localDocument.coste_transferencia) {
          localDocumentParsed.coste_transferencia = parseFloat(
            localDocument.coste_transferencia
          );
        }
        if (localDocument.importe_transporte) {
          localDocumentParsed.importe_transporte = parseFloat(
            localDocument.importe_transporte
          );
        }
        if (localDocument.importe_garantia) {
          localDocumentParsed.importe_garantia = parseFloat(
            localDocument.importe_garantia
          );
        }
        if (localDocument.importe_garantia_extra) {
          localDocumentParsed.importe_garantia_extra = parseFloat(
            localDocument.importe_garantia_extra
          );
        }
        if (localDocument.iban_garantia_extra) {
					localDocumentParsed.iban_garantia_extra = localDocument.iban_garantia_extra;
				}
        if (localDocument.pvp) {
          localDocumentParsed.pvp = parseFloat(localDocument.pvp);
          localDocumentParsed.pago_financiado = parseFloat(localDocument.pvp);
        }
        if (localDocument.aplica_iva) {
          localDocumentParsed.aplica_iva = parseFloat(localDocument.aplica_iva);
        }
        if (localDocument.banco_code) {
          localDocumentParsed.banco_code = parseFloat(localDocument.banco_code);
        }
        if (localDocument.kilometros) {
          localDocumentParsed.kilometros = parseFloat(localDocument.kilometros);
        }
        if (localDocument.id_gestoria) {
          localDocumentParsed.id_gestoria = parseFloat(
            localDocument.id_gestoria
          );
        }
        if (localDocument.id_provincia) {
          localDocumentParsed.id_provincia = parseFloat(
            localDocument.id_provincia
          );
        }
        if (localDocument.meses_financia) {
          localDocumentParsed.meses_financia = parseFloat(
            localDocument.meses_financia
          );
        }
        if (localDocument.comision_tienda) {
          localDocumentParsed.comision_tienda = parseFloat(
            localDocument.comision_tienda
          );
        }
        if (localDocument.comision_financia) {
          localDocumentParsed.comision_financia = parseFloat(
            localDocument.comision_financia
          );
        }
        if (vehiculo.marca_vehiculo) {
          localDocumentParsed.marca = vehiculo.marca_vehiculo[0].label;
        }
        if (vehiculo.modelo) {
          localDocumentParsed.modelo = vehiculo.modelo[0].label;
        }
        if (localDocument.entrega) {
          localDocumentParsed.entrega = localDocument.entrega[0].label ? localDocument.entrega[0].label : localDocument.entrega;
        }
        if (localDocument.entidad_financiera) {
          localDocumentParsed.entidad_financiera = localDocument.entidad_financiera[0].label? localDocument.entidad_financiera[0].label : localDocument.entidad_financiera;
        }
        if (localDocument.tipo_venta) {
          localDocumentParsed.tipo_venta = localDocument.tipo_venta[0].label ? localDocument.tipo_venta[0].label : localDocument.tipo_venta;
        }
       if (localDocument.seguro_auto) {
					localDocumentParsed.seguro_auto = localDocument.seguro_auto;
					if (
						localDocument.financia_seguro &&
						localDocument.financia_seguro &&
						localDocument.importe_seguro
					) {
						localDocumentParsed.financia_seguro = localDocument.financia_seguro;
						localDocumentParsed.aseguradora_auto = localDocument.aseguradora_auto;
						localDocumentParsed.importe_seguro = parseFloat(localDocument.importe_seguro);
					}
				} else {
					localDocumentParsed.importe_seguro = 0;
				}
        const callData = {
          data: JSON.stringify(localDocumentParsed),
          id: document.documentId,
          matricula_venta: localDocument?.contrato_vinculado_matricula,
          importe: localDocument?.importe_compra,
        };
        const realWarrantyId = warrantyExtra ? 3 : premium ? 2 : 1;
				const callDataGarantia = {
					warrantyTypeId: realWarrantyId,
					id: document.documentId
				};
        if (document.tipo === DocumentationType.CONTRATO_VENTA) {
          await post(API.CHANGE_GARANTIA, callDataGarantia);
        }

        await post(API.UPDATE_CONTRACT, callData);

        recall();
        if (recallGarantia) {
          recallGarantia();
        }
        close();
        successToast(t("succesToastUpdate"));
      } catch (e) {
        errorCall(t("errorUpdateContract"));
      }
    }
  };
  const revisionContract = () => {
		const notEmptyInputs = [
			"nombre",
			"telefono",
			"email",
			"direccion",
			"matricula",
			"CP",
			"poblacion",
			"pvp",
			"kilometros",
			"bastidor",
			"fecha_ultima_itv",
			"fecha_caducidad_itv",
			"tipo_venta"
		];
		let result = true;
		notEmptyInputs.forEach((e) => {
			if (!revision(t, "empty", e, localDocument[e])) {
				result = false;
			}
		});
		if (localDocument.DNI && !revision(t, "Document", "DNI", localDocument.DNI)) {
			result = false;
		}
		if (localDocument.CP && !revision(t, "CP", "Código Postal", localDocument.CP)) {
			result = false;
		}
		if (localDocument.telefono && !revision(t, "phone", "telefono", localDocument.telefono)) { 
			result = false;
		}
    if(localDocument.garantia_extra && localDocument.iban_garantia_extra ===""){
      errorToast(<span>{t("errors.emptyIBAN")}</span>);
      result = false
    }
    if(localDocument.garantia_extra && localDocument.iban_garantia_extra !=="" && !revision(t,"cuenta","IBAN Garantía extra",localDocument.iban_garantia_extra)){
      result = false
    }
    if (localDocument.garantia_extra && localDocument.importe_garantia_extra === "") {
      errorToast(<span>{t("errors.emptyExtraWarranty")}</span>);
      result = false;
    }
    if (localDocument.importe_garantia_extra && parseInt(localDocument.importe_garantia_extra) < 0) {
      errorToast(<span>{t("errors.zeroExtraWarranty")}</span>);
      result = false;
    }
		if (localDocument.financia && !(localDocument.importe_financia > 0)) {
			errorToast(<span>{t("errors.errorDiscount")}</span>);
			result = false;
		}
		if (localDocument.transporte && !localDocument.entrega) {
			errorToast(<span>{t("errors.errorDelivery")}</span>);
			result = false;
		}
		if (localDocument.entrega === "Tienda" && !localDocument.local_entrega_id) {
			errorToast(<span>{t("errors.errorStore")}</span>);
			result = false;
		}
		if (localDocument.seguro_auto) {
			if (localDocument.financia_seguro && !localDocument.financia) {
				errorToast(t("errors.unfunded"));
				result = false;
			}
			if (localDocument.importe_seguro <= 0) {
				errorToast(t("errors.amountInsourance"));
				result = false;
			}
		
		}
    return result;
	};
  const revisionReseve = () => {
    const notEmptyInputs = [
      "nombre",
      "telefono",
      "email",
      "pvp",
      "matricula",
      "kilometros",
      "importe",
    ];
    let result = true;
    notEmptyInputs.forEach((e) => {
      if (!revision(t, "empty", e, localDocument[e])) {
        result = false;
      }
    });
    if (localDocument.dni && !revision(t, "Document", "DNI", localDocument.dni)) {
      result = false;
    }
    if (
      localDocument.telefono &&
      !revision(t, "phone", "telefono", localDocument.telefono)
    ) {
      result = false;
    }
    if(localDocument.CP && !revision(t, "CP","CP",localDocument.CP)){
      result = false;
    }
    return result;
  };

  const revisionBuy = () => {
    const notEmptyInputs = [
      "nombre",
      "apellido1",
      "apellido2",
      "telefono",
      "email",
      "direccion",
      "CP",
      "provincia",
      "poblacion",
      "importe_compra",
      "matricula",
      "kilometros",
      "forma_pago",
    ];
    let result = true;
    notEmptyInputs.forEach((e) => {
      if (!revision(t, "empty", e, localDocument[e])) {
        result = false;
      }
    });
    if (localDocument.dni && !revision(t, "Document", "DNI", localDocument.dni)) {
      result = false;
    }

    if (
      localDocument.telefono &&
      !revision(t, "phone", "telefono", localDocument.telefono)
    ) {
      result = false;
    }
    if (
      localDocument.forma_pago === "3" &&
      !revision(t, "empty", "cuenta", localDocument.cuenta)
    ) {
      result = false;
    }
    if(localDocument.CP && !revision(t, "CP","CP",localDocument.CP)){
      result = false;
    }
    if (
			i18n.language === Locale.PT &&
			CONTRACT_PURCHASE &&
			localDocument.reserva_dominio === "1"
		) {
			result = validateDomainReserve();
		}
    return result;
  };

  const handleSimpleInput = (e, type) => {
    setLocalDocument({ ...localDocument, [type]: e.target.value });
  };

  const handleUpperInput = (e, type) => {
    setLocalDocument({
      ...localDocument,
      [type]: e.target.value.toUpperCase(),
    });
  };
  const handleData = (e, type) => {
    setLocalDocument({ ...localDocument, [type]: e });
  };
  const handleProvincia = (tipo, element) => {
    setLocalDocument({
      ...localDocument,
      provincia: element[0].label,
      id_provincia: element[0].value,
    });
  };
  const handlePago = (tipo, element) => {
    setLocalDocument({
      ...localDocument,
      forma_pago: element[0].value,
    });
  };
  const switchPremium = () => {
    if (premium) {
      setLocalDocument({
        ...localDocument,
        garantia: false,
        garantia_extra:false,
        importe_garantia_extra:0,
        importe_garantia: 0,
      });
    } else {
      setLocalDocument({
        ...localDocument,
        garantia: true,
      });
    }
    setPremium(!premium);
  };

  const switchWarrantyExtra = () => {
    if (warrantyExtra) {
      setLocalDocument({
        ...localDocument,
        garantia_extra: false,
        importe_garantia_extra: 0,
      });
    } else {
      setLocalDocument({
        ...localDocument,
        garantia_extra: true,
      });
    }
    setWarrantyExtra(!warrantyExtra);
  };

  const switchTrasporte = () => {
    if (transporte) {
      setLocalDocument({
        ...localDocument,
        transporte: false,
        importe_transporte: 0,
      });
    } else {
      setLocalDocument({
        ...localDocument,
        transporte: true,
      });
    }
    setTransporte(!transporte);
  };

  const switchFinancia = () => {
    if (financia) {
      setLocalDocument({
        ...localDocument,
        financia: false,
        importe_financia: 0,
        meses_financia: 24,
      });
    } else {
      setLocalDocument({
        ...localDocument,
        financia: true,
      });
    }
    setFinancia(!financia);
  };
  const validateMatricula = async () => {
    const matricula = localDocument.matricula_contrato_vinculado;

    const matricula_contract = await post(API.GET_CONTRACT, {
      matricula,
    });

    if (matricula_contract && matricula_contract?.[0]?.id) {
      setLocalDocument({
        ...localDocument,
        id_vehiculo_GVO: matricula_contract?.[0].id,
        contrato_vinculado_marca: matricula_contract?.[0].marca,
        contrato_vinculado_modelo: matricula_contract?.[0].modelo,
        contrato_vinculado_matricula: matricula_contract?.[0].matricula,
        vehicle_id_gvo: matricula_contract?.[0].vehicle_id,
      });
    } else {
      errorToast(<span>{t("errors.errorContract")}</span>);
    }
  };

  const validateDomainReserve = () => {
		const checks = {
			missingBancoReserva: {
				condition: revision(
					t,
					"empty",
					"banco_reserva_dominio",
					localDocument.banco_reserva_dominio
				),
				errorMessage: <span>{t("errors.errorMissingBancoReserva")}</span>
			},
			validValorReserva: {
				condition: localDocument.valor_reserva_dominio > 0,
				errorMessage: <span>{t("errors.errorvalidValorReserva")}</span>
			},
			validIBANorEntidadReferencia: {
				condition:
					(validationIBAN(localDocument.banco_iban) && !localDocument.entidad_referencia_reserva_dominio) ||
					(localDocument.entidad_referencia_reserva_dominio && !validationIBAN(localDocument.banco_iban)),
				errorMessage: <span>{t("errors.errorValidIBANorEntidadReferencia")}</span>
			}
		};

		const conditionsMet = Object.values(checks).every((check) => check.condition);

		if (conditionsMet) {
			return true;
		} else {
			Object.keys(checks).forEach((key) => {
				if (!checks[key].condition) {
					errorToast(<span>{checks[key].errorMessage}</span>);
				}
			});
			return false;
		}
	};
    const TYPE_BOOKING = document?.tipo === DocumentationType.RESERVA
    const CONTRACT_SALE = document?.tipo === DocumentationType.CONTRATO_VENTA
    const CONTRACT_PURCHASE = document?.tipo === DocumentationType.COMPRA_DIRECTA
    const TYPE_MANAGEMENT = document?.tipo === DocumentationType.GESTION_VENTA

  return (
    <Modal className={"modal-xl"} isOpen={open} toggle={close}>
      <ModalHeader>{t('dataModification')}</ModalHeader>
      <ModalBody className="p-4">
        <Container>
          <h4 className={styles.bold}>{t('clientData')}</h4>
          <Row className={styles.centerRow}>
            <Col xs="12" sm="4">
              <Label>{t('name')}</Label>
              <Input
                placeholder={"Nombre"}
                className="form-control ds-input mt-1"
                type="text"
                invalid={localDocument.nombre === ""}
                value={localDocument.nombre}
                onChange={(e) => {
                  handleSimpleInput(e, "nombre");
                }}
              />
            </Col>
            {(TYPE_BOOKING ||
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="4">
                  <Label>{t('firstName')}</Label>
                  <Input
                    placeholder={t('firstName')}
                    className="form-control ds-input mt-1"
                    type="text"
                    value={localDocument.apellido1}
                    invalid={localDocument.apellido1 === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "apellido1");
                    }}
                  />
                </Col>
              )}
            {(TYPE_BOOKING ||
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="4">
                  <Label>{t('secondName')}</Label>
                  <Input
                    placeholder={t('secondName')}
                    className="form-control ds-input mt-1"
                    type="text"
                    value={localDocument.apellido2}
                    invalid={localDocument.apellido2 === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "apellido2");
                    }}
                  />
                </Col>
              )}
            {(CONTRACT_SALE ||
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="4">
                  <Label>{t('idPerson')}</Label>
                  <Input
                    placeholder={t('idPerson')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={localDocument.DNI === ""}
                    value={localDocument.DNI}
                    onChange={(e) => {
                      handleUpperInput(e, "DNI");
                    }}
                  />
                </Col>
              )}
            {TYPE_BOOKING && (
              <Col xs="12" sm="4">
                <Label>{t('idPerson')}</Label>
                <Input
                  placeholder={t('idPerson')}
                  className="form-control ds-input mt-1"
                  type="text"
                  invalid={localDocument.dni === ""}
                  value={localDocument.dni}
                  onChange={(e) => {
                    handleUpperInput(e, "dni");
                  }}
                />
              </Col>
            )}
            <Col xs="12" sm="4">
              <Label>{t('phone')}</Label>
              <Input
                disabled={
                  localDocument.forma_pago === "pasarela" &&
                  TYPE_BOOKING
                }
                placeholder={t('phone')}
                className="form-control ds-input mt-1"
                type="text"
                invalid={localDocument.telefono === ""}
                value={localDocument.telefono}
                onChange={(e) => {
                  handleSimpleInput(e, "telefono");
                }}
              />
            </Col>
            <Col xs="12" sm="4">
              <Label>{t('email')}</Label>
              <Input
                disabled={
                  localDocument.forma_pago === "pasarela" &&
                  TYPE_BOOKING
                }
                placeholder={t('email')}
                className="form-control ds-input mt-1"
                invalid={localDocument.email === ""}
                type="text"
                value={localDocument.email}
                onChange={(e) => {
                  handleSimpleInput(e, "email");
                }}
              />
            </Col>
          </Row>
          <Row className={styles.centerRow}>
            {(CONTRACT_SALE ||
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label>{t('direction')}</Label>
                  <Input
                    placeholder={t('direction')}
                    className="form-control ds-input mt-1"
                    invalid={localDocument.direccion === ""}
                    type="text"
                    value={localDocument.direccion}
                    onChange={(e) => {
                      handleSimpleInput(e, "direccion");
                    }}
                  />
                </Col>
              )}
            {(CONTRACT_SALE ||
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12"sm="3">
								<Label>{t("postalCode")}</Label>
                {localDocument && (
									<Input
										placeholder={t("postalCode")}
										className="form-control ds-input mt-1"
										type="text"
										invalid={!validationPostalCode(localDocument.CP)}
										value={localDocument.CP}
										onChange={(e) => {
											handleSimpleInput(e, "CP");
										}}
									/>
								)}
							  </Col>
              )}
            {(CONTRACT_SALE ||
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label>{t('province')}</Label>
                  <div className={styles.filterAdjust}>
                    <Filter
                      multi={false}
                      key={"Provincias"}
                      sendData={handleProvincia}
                      parentValues={[
                        {
                          label: localDocument?.provincia,
                          value: localDocument?.id_provincia,
                        },
                      ]}
                      name={t('province')}
                      query={"provincias"}
                    />
                  </div>
                </Col>
              )}

            {(CONTRACT_SALE ||
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label>{t('town')}</Label>
                  <Input
                    placeholder={t('town')}
                    className="form-control ds-input mt-1"
                    type="text"
                    invalid={localDocument.poblacion === ""}
                    value={localDocument.poblacion}
                    onChange={(e) => {
                      handleSimpleInput(e, "poblacion");
                    }}
                  />
                </Col>
              )}
          </Row>
          {CONTRACT_PURCHASE && <h4 className={styles.bold}>{t('buyData')}</h4>}
          {CONTRACT_SALE && <h4 className={styles.bold}>{t('sellData')}</h4>}
          <Row className={styles.centerRow}>
            {(CONTRACT_SALE || TYPE_BOOKING) && (
              <Col xs="12" sm="3">
                <Label>{t('price')}</Label>
                <Input
                  placeholder={t('price')}
                  className="form-control ds-input mt-1"
                  type="number"
                  value={localDocument.pvp}
                  invalid={localDocument.pvp === ""}
                  onChange={(e) => {
                    handleSimpleInput(e, "pvp");
                  }}
                />
              </Col>
            )}

            {(CONTRACT_PURCHASE || TYPE_MANAGEMENT) && (
              <>
              <Col xs="12" sm="4">
                <Label className={"m-0"}>{t('purchaseAmount')} (€)</Label>
                 <FormattedNumberInput
                  placeholder={t('price')}
                  className="form-control ds-input mt-1"
                  value={localDocument.importe_compra}
                  disabled={!rol?.some(
                    (e) =>
                      e === RolType.GENERAL_ADMIN ||
                      e === RolType.CENTRAL_APPRAISER ||
                      e === RolType.ADMINISTRATION
                  )}
                  invalid={localDocument.importe_compra === ""}
                  onChange={(value) => {
                    setLocalDocument({ ...localDocument, importe_compra: value });
                    }}
                   displayType="input"
                />
              </Col>
              
              <Col xs="12" sm="4">
                    <Label className={"m-0"}>{t('paymentMethod')}</Label>
                    <div className={styles.filterAdjust}>
                      <Input
                        placeholder={t('selectPaymentMethod')}
                        className="form-control ds-input mt-1 pointer"
                        type="select"
                        value={localDocument.forma_pago}
                        onChange={(e) => {
                          handleSimpleInput(e, "forma_pago");
                        }}
                      >
                        <option value={0}>{t('selectPaymentMethod')}</option>
                        <option value={1}>{t('cash')}</option>
                        <option value={2}>{t('cheque')}</option>
                        <option value={3}>{t('transfer')}</option>
                        {CONTRACT_PURCHASE ? (
                          <option value={4}>{t('carForPay')}</option>
                        ) : null}{" "}
                      </Input>
                    </div>
                  </Col>
                  {i18n.language === Locale.PT && (
               <Col xs="12" sm="4">
               <Label style={{fontSize: '10px'}} className={"m-0"}>{t('reservaDominio')}*</Label>
                  <Input
                    placeholder={t('reservaDominioChoice')}
                    className="form-control ds-input mt-1 pointer"
                    type="select"
                    value={localDocument.reserva_dominio}
                    onChange={(e) => {
                      handleSimpleInput(e, "reserva_dominio");
                    }}
                  >
                    <option value={0}>{t('No')}</option>
                    <option value={1}>{t('Sí')}</option>
                  </Input>
                  </Col>
                  )}
                  </>
                  
            )}

            {TYPE_BOOKING&& (
              <Col xs="12" sm="3">
                <Label>{t('amount')}</Label>
                <Input
                  disabled={
                    localDocument.forma_pago === "pasarela" &&
                    TYPE_BOOKING
                  }
                  placeholder={t('amount')}
                  className="form-control ds-input mt-1"
                  type="number"
                  value={localDocument.importe}
                  invalid={localDocument.importe === ""}
                  onChange={(e) => {
                    handleSimpleInput(e, "importe");
                  }}
                />
              </Col>
            )}

            {TYPE_MANAGEMENT && (
              <>
                <Col xs="12" sm="3">
                  <Label>Comisión caso de venta (€)</Label>
                  <Input
                    placeholder={"Comisión caso de venta"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.importe_venta}
                    invalid={localDocument.importe_venta === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "importe_venta");
                    }}
                  />
                </Col>
                <Col xs="12" sm="3">
                  <Label>Gastos y com. mensuales (€)</Label>
                  <Input
                    placeholder={"Gastos y comisiones mensuales"}
                    className="form-control ds-input mt-1"
                    type="text"
                    value={localDocument.comision_retirada}
                    invalid={localDocument.comision_retirada === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "comision_retirada");
                    }}
                  />
                </Col>
                <Col xs="12" sm="3">
                  <Label>Penalizacion retirada (€)</Label>
                  <Input
                    placeholder={"Penalizacion en caso de retirada"}
                    className="form-control ds-input mt-1"
                    type="text"
                    value={localDocument.penalizacion_retirada}
                    invalid={localDocument.penalizacion_retirada === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "penalizacion_retirada");
                    }}
                  />
                </Col>
              </>
            )}

            {CONTRACT_SALE && (
              <Col xs="12" sm="3">
                <Label style={{ cursor: "pointer", marginLeft: "30px" }} check>
                  <Input
                    type="checkbox"
                    onChange={switchFinancia}
                    checked={financia}
                  />
                  Financiación
                </Label>
              </Col>
            )}
            {financia && CONTRACT_SALE && (
              <Fragment>
                <Col xs="12" sm="3">
                  <Label>Descuento financiación</Label>
                  <Input
                    placeholder={"Importe de la garantia"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.importe_financia}
                    invalid={
                      localDocument.importe_financia === "" ||
                      localDocument.importe_financia === "0"
                    }
                    onChange={(e) => {
                      handleSimpleInput(e, "importe_financia");
                    }}
                  />
                </Col>
								<Col xs="12"sm="6">
										<Label>Entidad financiera:</Label>
										<Select
											color={"#FF5D0E"}
											options={BankOptions}
											placeholder={"Entidad financiera"}
											values={
												Array.isArray(localDocument?.entidad_financiera)
													? localDocument?.entidad_financiera
													: [
															{
																label: localDocument?.entidad_financiera,
																value: localDocument?.entidad_financiera
															}
														 ]
												}
											noDataRenderer={() => (
												<span style={{ textAlign: "center" }}>Sin datos</span>
											)}
											onChange={(e) => {
												handleData(e, "entidad_financiera");
											}}
											/>
								</Col>	
              </Fragment>
            )
          }
             {CONTRACT_SALE && (
              <Fragment>
                <Col xs="12" sm="6">
                  <Label className={styles.companyAdjust}>
                  {t('company')}
                    <Filter
                      key={t('company')}
                      multi={false}
                      sendData={(name, value) => {
                        getEmpresaDataById(value, document.tipo);
                      }}
                      parentValues={[
                        {
                          label: localDocument?.empresa_nombre,
                          value: localDocument?.empresa_id,
                        },
                      ]}
                      name={t('company')}
                      query={`empresas`}
                      data={document.documentId}
                    />
                  </Label>
                </Col>
                <Col xs="12" sm="6">
                  <Label className={styles.companyAdjust}>
                    Provincia del Local
                    <Filter
                      key={"provincia_local"}
                      multi={false}
                      sendData={(n, value) => handleFilterLocal(value)}
                      parentValues={[
                        {
                          label: localDocument?.provincia_local,
                          value: localDocument?.id_provincia_local,
                        },
                      ]}
                      name={"Provincia del local"}
                      query={`provincias`}
                      data={document.documentId}
                    />
                  </Label>
                </Col>
              </Fragment>
            )}
        </Row>
          { CONTRACT_SALE && (
          <Row className={styles.centerRow}>
              <Col xs="6" sm="6">
                <Label>{t("insouranceAuto")}
                </Label>
                <Select
                  color={"#FF5D0E"}
                  options={[
                    { label: "Sí", value: true },
                    { label: "No", value: false }
                  ]}
                  values={localDocument.seguro_auto
                    ? [{ label: "Sí", value: true }]
                    : [{ label: "No", value: false }]}
                  onChange={(e) => handleSelectOptions(e, "seguro_auto")} />
              </Col>
              {localDocument.seguro_auto ? (
                <>
                  <Col xs="6" sm="6">
                    <Label> {t("companyInsourance")} </Label>
                    <Select
                      color={"#FF5D0E"}
                      options={[{ label: "Mutua", value: "Mutua" }]}
                      values={Array.isArray(localDocument?.aseguradora_auto)
                        ? localDocument?.aseguradora_auto
                        : [
                          {
                            label: localDocument?.aseguradora_auto,
                            value: localDocument?.aseguradora_auto,
                          },
                        ]}
                      onChange={(e) => handleSelectOptions(e, "aseguradora_auto")} />
                  </Col>
                  <Col xs="6" sm="6">
                    <Label> {t("fund")} </Label>
                    <Select
                      color={"#FF5D0E"}
                      options={[
                        { label: "Sí", value: true },
                        { label: "No", value: false }
                      ]}
                      values={localDocument.financia_seguro
                        ? [{ label: "Sí", value: true }]
                        : [{ label: "No", value: false }]}
                      onChange={(e) => handleSelectOptions(e, "financia_seguro")} />
                      {!localDocument.financia && localDocument.financia_seguro && (
									      <span
										      className={styles.warningText}
								        >
										      Debes Marcar Financiación
									      </span>
								)}
                  </Col>
                  <Col xs="6" sm="6">
                    <Label> {t("insouranceAmount")}</Label>
                    <FormattedNumberInput
                      onChange={(value) => setLocalDocument({ ...localDocument, importe_seguro: value })}
                      value={localDocument.importe_seguro} />
                  </Col>
              
                </>
              ):
              <Col xs="6" sm="6"/>
              }
        </Row>
         )}
         
            {TYPE_BOOKING && (
              <Col xs="12" sm="12">
                <Label className={styles.companyAdjust}>
                {t('company')}
                  <Filter
                    key={t('company')}
                    multi={false}
                    sendData={(name, value) => {
                      getEmpresaDataById(value, document.tipo);
                    }}
                    parentValues={[
                      {
                        label: localDocument?.empresa,
                        value: localDocument?.empresa_id,
                      },
                    ]}
                    name={"Empresas"}
                    query={`empresas`}
                    data={document.documentId}
                  />
                </Label>
              </Col>
            )}
       
          {i18n.language === Locale.PT && localDocument.reserva_dominio === '1' && (
            <Row className={styles.centerRow}>
               <Col xs="12" sm="3">
                 <Label style={{fontSize: '10px'}} className={"m-0"}>{t('bancoDeReservaDePropiedade')}*</Label>
                    <Input
                      placeholder={t('bancoDeReservaDePropiedade')}
                      className="form-control ds-input mt-1"
                      type="text"
                      maxLength={50}
                      required={localDocument.reserva_dominio === '1'}
                      value={localDocument.banco_reserva_dominio}
                      onChange={(e) => {
                      handleSimpleInput(e, "banco_reserva_dominio");
                      }}
                      />
               </Col>
               <Col xs="12" sm="3">
                 <Label style={{fontSize: '12px'}} className={"m-0"}>{t('bancoIban')}*</Label>
                    <Input
                      placeholder={t('bancoIban')}
                      className="form-control ds-input mt-1"
                      type="text"
                      required={localDocument.reserva_dominio === '1' && !localDocument.entidad_referencia_reserva_dominio}
                      value={localDocument.banco_iban}
                      onChange={(e) => {
                      handleSimpleInput(e, "banco_iban");
                      }}
                      />
               </Col>
               <Col xs="12" sm="3">
                 <Label style={{fontSize: '12px'}} className={"m-0"}>{t('entidadYReferencia')}*</Label>
                    <Input
                      placeholder={t('entidadYReferencia')}
                      className="form-control ds-input mt-1"
                      maxLength={50}
                      type="text"
                      required={localDocument.reserva_dominio === '1' && !localDocument.banco_iban}
                      value={localDocument.entidad_referencia_reserva_dominio}
                      onChange={(e) => {
                      handleSimpleInput(e, "entidad_referencia_reserva_dominio");
                      }}
                      />
               </Col>
               <Col xs="12" sm="3">
                 <Label style={{fontSize: '12px'}} className={"m-0"}>{t('valorReserva')}</Label>

                      <FormattedNumberInput
                         value={localDocument.valor_reserva_dominio}
                         maxLength={12}
                         onChange={(value) => {
                          setLocalDocument({ ...localDocument, valor_reserva_dominio: value });
                          }}
                         displayType="input"
                          />

               </Col>
               </Row>
                )}  

          <Row className={styles.centerRow}>
            {(CONTRACT_PURCHASE || TYPE_MANAGEMENT) && (
              <Col xs="12" sm="3">
                <Label className={"m-0"}> </Label>
                {t('company')}
                <Filter
                  key={t('company')}
                  multi={false}
                  sendData={(name, value) => {
                    getEmpresaDataById(value, document.tipo);
                  }}
                  parentValues={[
                    {
                      label: localDocument?.empresa_nombre,
                      value: localDocument?.empresa_id,
                    },
                  ]}
                  name={"Empresas"}
                  query={`empresa_user`}
                  data={user}
                />
              </Col>
            )}

            {localDocument?.empresa_id &&
              (CONTRACT_PURCHASE || TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label className={"m-0"}> </Label>
                  {t('local')}
                  <Filter
                    key={"local"}
                    multi={false}
                    sendData={(name, value) => {
                      handleFilter(value, "local");
                    }}
                    parentValues={[
                      {
                        label: localDocument?.local_nombre,
                        value: localDocument?.local_id,
                      },
                    ]}
                    name={"Empresas"}
                    query={`locales_empresa`}
                    data={localDocument.empresa_id}
                  />
                </Col>
              )}

            {CONTRACT_PURCHASE && (
              <Col xs="12" sm="3">
                <Label style={{fontSize: '10px'}} className={"m-0"}>{t('clientBankAccount')}</Label>
                <Input
                  placeholder={t('numberAccount')}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={localDocument.cuenta}
                  invalid={localDocument.cuenta === ""}
                  onChange={(e) => {
                    handleSimpleInput(e, "cuenta");
                  }}
                />
              </Col>
              
            )}
             {(CONTRACT_SALE ||
              CONTRACT_PURCHASE ||
              TYPE_MANAGEMENT) && (
                <Col xs="12" sm="3">
                  <Label className={styles.companyAdjust}>
                  {t('agency')}
                    <Filter
                      key={"Gestoria"}
                      multi={false}
                      sendData={handleGestoria}
                      parentValues={[
                        {
                          label: localDocument?.gestoria,
                          value: localDocument?.id_gestoria,
                        },
                      ]}
                      name={"Empresas"}
                      query={`gestorias`}
                    />
                  </Label>
                </Col>
              )}
            
          </Row>

          


          

          <Row className={styles.centerRow}>
            {CONTRACT_SALE && (
              <>
                <Col xs="12" sm="6">
                  <Label>Coste de transferencia</Label>
                  <Input
                    placeholder={"Coste de transferencia"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.coste_transferencia}
                    onChange={(e) => {
                      handleSimpleInput(e, "coste_transferencia");
                    }}
                  />
                </Col>
                <Col xs="12" sm="6">
                  <Label>Fecha última itv</Label>
                  <DatePicker
                    selected={
                      localDocument.fecha_ultima_itv ? new Date(localDocument.fecha_ultima_itv) : null
                    }
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => handleData(date, "fecha_ultima_itv")}
                    className="form-control ds-input mt-1"
                  />
                </Col>
                <Col xs="12" sm="6">
                  <Label>Fecha caducidad itv</Label>
                  <DatePicker
                    selected={
                      localDocument.fecha_caducidad_itv ? new Date(localDocument.fecha_caducidad_itv) : null
                    }
                    className="form-control ds-input mt-1"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => handleData(date, "fecha_caducidad_itv")}
                  />
                </Col>
              </>
            )}

            {CONTRACT_SALE && (
              <Col xs="12" sm="6">
                <Label>Forma de pago</Label>
                <div className={styles.filterAdjust}>
                  <Filter
                    multi={false}
                    key={"Forma de pago"}
                    sendData={handlePago}
                    parentValues={[
                      {
                        label: localDocument?.forma_pago,
                        value: localDocument?.forma_pago,
                      },
                    ]}
                    name={"Formas de Pago"}
                    query={"forma_pago"}
                  />
                </div>
              </Col>
            )}

            {((CONTRACT_SALE && localDocument?.forma_pago === "Otros") ||
              (CONTRACT_SALE &&
                localDocument?.forma_pago === "Coche como forma de pago")) && (
                <Row className={styles.centerRow}>
                  <Col xs="12" sm="12">
                    <Label>Observaciones sobre los pagos</Label>
                    <textarea
                      placeholder={"Observaciones"}
                      className="form-control ds-input mt-1"
                      type="text"
                      value={localDocument.observaciones_pago}
                      onChange={(e) => {
                        handleSimpleInput(e, "observaciones_pago");
                      }}
                    />
                  </Col>
                </Row>
              )}
          </Row>
       <h4 className={styles.bold}>{t('vehicleData')}</h4>
          <Row className={styles.centerRow}>
            <Col xs="12" sm="4">
              <Label>{t('km')}</Label>
              <Input
                placeholder={t('km')}
                className="form-control ds-input mt-1"
                type="number"
                value={localDocument.kilometros}
                invalid={localDocument.kilometros === ""}
                onChange={(e) => {
                  handleSimpleInput(e, "kilometros");
                }}
              />
            </Col>
            <Col xs="12" sm="4">
              <Label>{t('plate')}</Label>
              <Input
                placeholder={t('plate')}
                className="form-control ds-input mt-1"
                type="text"
                value={localDocument.matricula}
                invalid={localDocument.matricula === ""}
                onChange={(e) => {
                  handleSimpleInput(e, "matricula");
                }}
              />
            </Col>
            <Col xs="12" sm="4">
              <Label>{t('chassisNumber')}</Label>
              <Input
                placeholder={t('chassisNumber')}
                className="form-control ds-input mt-1"
                type="text"
                value={localDocument.bastidor}
                invalid={localDocument.bastidor === ""}
                onChange={(e) => {
                  handleSimpleInput(e, "bastidor");
                }}
              />
            </Col>

            {(CONTRACT_PURCHASE || TYPE_MANAGEMENT) && (
              <>
              
                {CONTRACT_PURCHASE &&
                  pago &&
                  !localDocument.id_vehiculo_GVO ? (
                  <>
                    <div className="p-3 my-2 rounded col-12 col-sm-12">
                      <Toast style={{ maxWidth: "100%" }}>
                        <ToastHeader>Coche Vinculado</ToastHeader>
                        <ToastBody>
                          <Row>
                            <Col xs="12" sm="8">
                              <Input
                                placeholder={"Matricula"}
                                className="form-control ds-input mt-1"
                                value={
                                  localDocument.matricula_contrato_vinculado
                                }
                                invalid={
                                  localDocument.forma_pago === 4 &&
                                  localDocument.matricula_contrato_vinculado ===
                                  ""
                                }
                                onChange={(e) => {
                                  handleSimpleInput(
                                    e,
                                    "matricula_contrato_vinculado"
                                  );
                                }}
                              />
                            </Col>
                            <Col xs="12" sm="4">
                              <Button
                                className="btn-flexicar right no-print"
                                onClick={() => validateMatricula()}
                              >
                                Buscar vehiculo asociado
                              </Button>
                            </Col>{" "}
                          </Row>
                        </ToastBody>
                      </Toast>
                    </div>
                  </>
                ) : null}

                {pago && localDocument.id_vehiculo_GVO ? (
                  <>
                    <div className="p-3 my-2 rounded col-12 col-sm-12">
                      <Toast style={{ maxWidth: "100%" }}>
                        <ToastHeader>Coche Vinculado</ToastHeader>
                        <ToastBody>
                          <Row>
                            <Col xs="12" sm="8">
                              <p>
                                {localDocument.contrato_vinculado_marca}{" "}
                                {localDocument.contrato_vinculado_modelo}{" "}
                                {localDocument.matricula_contrato_vinculado}{" "}
                                <a
                                  href={`/main/vehicle/${localDocument.vehicle_id_gvo}?tab=datos`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Ver vehículo
                                </a>
                              </p>
                            </Col>
                            <Col xs="12" sm="4">
                              <Button
                                className="btn-flexicar right no-print"
                                onClick={() =>
                                  setLocalDocument({
                                    ...localDocument,
                                    id_vehiculo_GVO: "",
                                    contrato_vinculado: "",
                                    contrato_vinculado_marca: "",
                                    contrato_vinculado_modelo: "",
                                    vehicle_id_gvo: "",
                                  })
                                }
                              >
                                Cambiar coche asociado
                              </Button>
                            </Col>
                          </Row>
                        </ToastBody>
                      </Toast>
                    </div>
                  </>
                ) : null}
                {localDocument?.forma_pago === "3" ? (
                  <>
                    <div className="p-3 my-2 rounded col-12 col-sm-12">
                      <Toast style={{ maxWidth: "100%" }}>
                        <ToastHeader>Cuenta Bancaria</ToastHeader>
                        <ToastBody>
                          <Row>
                            <Col xs="12" sm="8">
                              <Input
                                placeholder={"Cuenta bancaria"}
                                className="form-control ds-input mt-1"
                                type="text"
                                value={localDocument.cuenta}
                                onChange={(e) => {
                                  handleSimpleInput(e, "cuenta");
                                }}
                              />
                            </Col>
                          </Row>
                        </ToastBody>
                      </Toast>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </Row>

          <Row className={styles.centerRow}>
            {CONTRACT_SALE && (
              <>
                <Col xs="6" sm="3">
                  <Label>Tipo de venta</Label>
                  <Select
                    color={"#FF5D0E"}
                    options={[{ label: 'Presencial', value: 'Presencial' }, { label: 'Online', value: 'Online' }]}
                    placeholder={"Tipo de venta"}
                    values={
                      Array.isArray(localDocument?.tipo_venta)
                        ? localDocument?.tipo_venta
                        : [
                            {
                              label: localDocument?.tipo_venta,
                              value: localDocument?.tipo_venta,
                            },
                          ]
                    }
                   noDataRenderer={() => (
                      <span style={{ textAlign: "center" }}>Sin datos</span>
                    )}
                    onChange={(e) => handleData(e, "tipo_venta")}
                  />  </Col>
                <Col xs="6" sm="3">
                  <Label style={{ cursor: "pointer", marginLeft: "30px" }} check>
                    <Input
                      type="checkbox"
                      onChange={switchTrasporte}
                      checked={transporte}
                    />
                    Transporte
                  </Label>
                </Col>
              </>
            )}
            {transporte && CONTRACT_SALE && (
              <>
                <Col xs="6" sm="3">
                  <Label>Importe de transporte</Label>
                  <Input
                    placeholder={"Importe de transporte"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.importe_transporte}
                    onChange={(e) => {
                      handleSimpleInput(e, "importe_transporte");
                    }}
                  />
                </Col>
                <Col xs="6" sm="3">
                  <Label>Forma de entrega</Label>
                  <Select
                    color={"#FF5D0E"}
                    options={[{ label: 'Tienda', value: 'Tienda' }, { label: 'Domicilio', value: 'Domicilio' }]}
                    placeholder={"Entrega"}
                    values={
                      Array.isArray(localDocument?.entrega)
                        ? localDocument?.entrega
                        : [
                            {
                              label: localDocument?.entrega,
                              value: localDocument?.entrega,
                            },
                          ]
                    }
                    noDataRenderer={() => (
                      <span style={{ textAlign: "center" }}>Sin datos</span>
                    )}
                    onChange={(e) => handleData(e, "entrega")}
                  />
                </Col>
                {localDocument?.entrega && (localDocument?.entrega === "Tienda" || localDocument?.entrega[0]?.value === "Tienda") ?
                  <>
                    <Col xs="6" sm="6">
                      <Label>Tienda de Entrega:</Label>
                      <Filter
                        key={"locales"}
                        multi={false}
                        sendData={(name, value) => {
                          handleFilterEntrega(value, "local_entrega");
                        }}
                        parentValues={localDocument?.local_entrega_id ? [{ label: localDocument?.local_entrega, value: localDocument?.local_entrega_id }] : null}
                        name={"Local de entrega"}
                        query={`sedes_locales`}
                      />
                    </Col>
                  </>
                  :
                  <>
                    <Col xs="12" sm="12">
                      <span style={{ cursor: "pointer", marginLeft: "30px" }} check>
                        <Input
                          type="checkbox"
                          onChange={() => {
                            setLocalDocument({
                              ...localDocument,
                              entrega_cambio: !localDocument?.entrega_cambio,
                            })
                          }}
                          checked={localDocument?.entrega_cambio}
                        />
                        Utilizar dirección de facturación
                      </span></Col>
                    {!localDocument.entrega_cambio ?
                      <>
                        <Col xs="6" sm="3">
                          <Label>Provincia:</Label>
                          <Filter
                            multi={false}
                            key={"Provincias"}
                            sendData={(name, value) => {
                              setLocalDocument({ ...localDocument, provincia_entrega: value[0].label, id_provincia_entrega: value[0].value })
                            }}
                            parentValues={[
                              {
                                label: localDocument?.provincia_entrega,
                                value: localDocument?.id_provincia_entrega,
                              },
                            ]}
                            name={"Provincias"}
                            query={"provincias"}
                          />
                        </Col>
                        <Col xs="6" sm="3">
                          <Label>Población:</Label>
                          <Input className="w-100" value={localDocument?.poblacion_entrega} onChange={(e) => {
                            handleSimpleInput(e, "poblacion_entrega");
                          }} />
                        </Col>
                        <Col xs="6" sm="3">
                          <Label>Dirección:</Label>
                          <Input className="w-100" value={localDocument?.direccion_entrega} onChange={(e) => {
                            handleSimpleInput(e, "direccion_entrega");
                          }} />
                        </Col>
                        <Col xs="6" sm="3">
                          <Label className="tittle_sub ml-1">C.P.:</Label>
                          <Input className="w-100" value={localDocument?.CP_entrega} onChange={(e) => {
                            handleSimpleInput(e, "CP_entrega");
                          }} />
                        </Col></>
                      : null}
                  </>
                }
              </>
            )}
          </Row>
          <Row className={styles.centerRow}>
            {CONTRACT_SALE && (
              <Col xs="6" sm="3">
                <Label style={{ cursor: "pointer", marginLeft: "30px" }} check>
                  <Input
                    type="checkbox"
                    onChange={switchPremium}
                    checked={premium}
                  />
                  Garantía Premium
                </Label>
              </Col>
            )}
            
            {premium && CONTRACT_SALE && (
                <Col xs="6" sm="3">
                  <Label>Importe de la garantía</Label>
                  <Input
                    placeholder={"Importe de la garantia"}
                    className="form-control ds-input mt-1"
                    type="number"
                    value={localDocument.importe_garantia}
                    invalid={localDocument.importe_garantia === ""}
                    onChange={(e) => {
                      handleSimpleInput(e, "importe_garantia");
                    } } />
                </Col>
            )}
          </Row>
        {premium && (
            <Row className={styles.centerRow}>
              <Col xs="6" sm="3">
                  <Label style={{ cursor: "pointer", marginLeft: "30px", display: 'flex' }} check>
                    <Input
                      type="checkbox"
                      onChange={switchWarrantyExtra}
                      checked={warrantyExtra}/>
                      Garantía Premium Extra
                      <Tooltip
                        name="tooltip-extra-garantia"
                        text={t("tooltipExtraWarranty")}
                      />
                  </Label>
                </Col>
            {warrantyExtra && CONTRACT_SALE && (
              <>
                <Col xs="12" sm="3">
                  <Label>Importe de la garantia Extra </Label>
                    <Input
                      placeholder={"Importe de la garantia Extra"}
                      className="form-control ds-input mt-1"
                      type="number"
                      value={localDocument.importe_garantia_extra}
                      onChange={(e) => {
                        handleSimpleInput(e, "importe_garantia_extra");
                      }} />
                </Col>
                <Col xs="12" sm="6">
                    <Label>IBAN garantía Extra</Label>
                      <Input
                        className="form-control ds-input mt-1"
                        type="text"
                        value={localDocument.iban_garantia_extra}
                        onChange={(e) => {
                          handleSimpleInput(e, "iban_garantia_extra");
                        } } />
                  </Col>
                </>
            )}
          </Row>
        )}
          <Row className={styles.centerRow}>
            <Col xs="12" sm="12">
              <Label>{t('observations')}</Label>
              <textarea
                placeholder={t('observations')}
                className="form-control ds-input mt-1"
                type="text"
                value={localDocument.observaciones}
                onChange={(e) => {
                  handleSimpleInput(e, "observaciones");
                }}
              />
            </Col>
          </Row>
          {CONTRACT_SALE && (
            <Row className={styles.centerRow}>
              <Col xs="12" sm="12">
                <h6 className="mt-4">Conformidades sobre el estado del vehículo: </h6>
                <textarea style={{ width: '100%' }} value={localDocument?.conformidades} onChange={(e) => {
                  handleSimpleInput(e, "conformidades");
                }} maxlength="300" />
                <small>{localDocument.conformidades ? localDocument.conformidades.length : '0'}/300 carácteres</small>
              </Col></Row>
          )}
          {(CONTRACT_PURCHASE || TYPE_MANAGEMENT) && (
            <Row className={styles.centerRow}>
              <Col xs="12" sm="12">
                <Label>{t('annexed')}</Label>
                <textarea
                  placeholder={t('annexed')}
                  className="form-control ds-input mt-1"
                  type="text"
                  value={localDocument.anexo}
                  onChange={(e) => {
                    handleSimpleInput(e, "anexo");
                  }}
                />
              </Col>
            </Row>
          )}
        </Container>

        <div>
          <Button
            className="btn-flexicar mt-4 mr-5 right no-print"
            onClick={saveUpdate}
          >
            {t('save')}
          </Button>
          <br />
          <div className="m-5" id="template"></div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={close}>
          {t('close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalUpdateContract;
